.hi-john-message,
.streamline-abandoned-cart {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.hi-john-message {
  line-height: 38px;
  display: inline-block;
  min-width: 36px;
}
.streamline-abandoned-cart {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  line-height: 51px;
  font-family: inherit;
}
.automate-retargeting-of,
.button-name6 {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.automate-retargeting-of {
  align-self: stretch;
}
.button-name6 {
  width: 96px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 96px;
  text-decoration: none;
}
.button8 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.streamline-abandoned-cart-reco-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--headings-h3-semi-bold-size);
}
.hi-john-message-parent,
.section-01-inner,
.streamline-abandoned-cart-reco-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.hi-john-message-parent {
  align-self: stretch;
  /* gap: var(--gap-49xl); */
}
.section-01-inner {
  /* width: 507px; */
  padding: var(--padding-18xl-8) 0 0;
  box-sizing: border-box;
  min-width: 42%;
  /* max-width: 100%; */
}
.vector-icon2,
.vector-icon3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vector-icon3 {
  top: 62.5px;
  left: 150.5px;
  width: 322px;
  height: 325.3px;
  z-index: 1;
}
.frame-wrapper15,
.vector-container {
  margin: 0 !important;
  position: absolute;
}
.vector-container {
  height: 100%;
  width: 545.9px;
  top: 0;
  right: -90.9px;
  bottom: 0;
}
.frame-wrapper15 {
  height: 480.8px;
  width: 455px;
  right: -234px;
  bottom: -238.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.hi-john {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  display: inline-block;
  min-width: 92px;
}
.youve-left-two {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
}
.hi-john-parent,
.image-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.hi-john-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-5);
}
.image-inner {
  width: 369px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  box-sizing: border-box;
}
.image-83-icon {
  height: 134.1px;
  width: 134.1px;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
}
.dropdown-list,
.dropdown-list1 {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
}
.dropdown-list {
  align-self: stretch;
}
.dropdown-list1 {
  width: 109px;
}
.path-processor,
.path-processor-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.path-processor {
  align-self: stretch;
  gap: var(--gap-6xs-7);
  flex-shrink: 0;
}
.path-processor-wrapper {
  flex: 1;
  padding: var(--padding-17xl-9) 0 0;
  box-sizing: border-box;
  min-width: 110px;
}
.image-83-parent,
.shape-collection-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.image-83-parent {
  flex: 1;
  gap: var(--gap-21xl-2);
}
.shape-collection-inner {
  width: 370.6px;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  box-sizing: border-box;
}
.shape-collection-child1 {
  align-self: stretch;
  height: 1.4px;
  position: relative;
  border-top: 1.4px solid var(--brand-ivory);
  box-sizing: border-box;
}
.shape-collection3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm-4);
  max-width: 100%;
}
.image-13-icon1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.image-13-wrapper {
  height: 72.1px;
  width: 137.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.get-demo-button,
.get-demo-button1 {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
}
.get-demo-button {
  align-self: stretch;
}
.get-demo-button1 {
  width: 109px;
}
.get-demo-button-parent,
.star-stacker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.get-demo-button-parent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-6xs-7);
}
.star-stacker {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 var(--padding-sm-4);
  box-sizing: border-box;
  min-width: 110px;
}
.data-aggregator1,
.frame-parent35,
.image-importer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent35 {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-17xl-8);
}
.data-aggregator1,
.image-importer {
  align-items: flex-start;
  box-sizing: border-box;
}
.image-importer {
  width: 370.6px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
}
.data-aggregator1 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 var(--padding-7xs-6);
  gap: var(--gap-11xl-4);
}
.checkout {
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: 0.01em;
  font-family: var(--body-footnote-regular);
  color: var(--brand-ivory);
  text-align: left;
  display: inline-block;
  min-width: 93px;
}
.get-demo2,
.icon-indexer,
.image {
  display: flex;
  align-items: flex-start;
}
.get-demo2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm-4) var(--padding-23xl);
  background-color: var(--brand-indigo);
  border-radius: 13.41px;
  flex-direction: row;
  justify-content: flex-start;
}
.icon-indexer,
.image {
  max-width: 100%;
}
.icon-indexer {
  width: 367.6px;
  flex-direction: row;
  justify-content: center;
}
.image {
  flex: 1;
  border-radius: var(--br-sm-6);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-26xl-3) var(--padding-4xl-4) var(--padding-27xl-1) var(--padding-6xl-1);
  box-sizing: border-box;
  gap: var(--gap-31xl);
  z-index: 2;
}
.frame-child14 {
  height: 81px;
  width: 81px;
  position: absolute;
  margin: 0 !important;
  top: -36.2px;
  right: -31px;
  z-index: 3;
}
.frame-parent34 {
  position: absolute;
  top: 0;
  left: 180px;
  width: 430px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.image-84-icon2 {
  position: absolute;
  top: 13.1px;
  left: -13.1px;
  width: 209.9px;
  height: 255.8px;
  object-fit: cover;
}
.image-84-wrapper {
  position: absolute;
  top: 166.8px;
  left: 0;
  border-radius: 13.12px;
  background-color: var(--brand-amethyst);
  width: 196.8px;
  height: 264px;
  overflow: hidden;
  z-index: 3;
}
.frame-parent33 {
  /* height: 596.7px; */
  flex: 1;
  position: relative;
  /* min-width: 396px;
  max-width: 100%; */
  font-size: var(--headings-h5-semi-bold-size);
}
.section-01,
.section-01-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.section-01.ivory {
  background-color: var(--brand-ivory);
}
.section-01.ivory-mid {
  background-color: var(--web-secondary-ivory-mid);
}
.section-01 {
  flex: 1;
  border-radius: var(--br-xs);
  flex-wrap: wrap;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-72xl-2) var(--padding-151xl) var(--padding-61xl-1) var(--padding-116xl);
  gap: var(--gap-159xl);
}
.section-01-wrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 var(--padding-9xs);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1600px) {
  .section-01-inner {
    flex: 1;
  }
  .section-01 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .frame-parent33,
  .section-01-inner {
    min-width: 100%;
  }
  .section-01 {
    gap: 178px 89px;
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-66xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .hi-john-message {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .streamline-abandoned-cart {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .hi-john-message-parent {
    gap: 34px 68px;
  }
  .image-83-icon {
    flex: 1;
  }
  .frame-parent35,
  .image-83-parent {
    flex-wrap: wrap;
  }
  .frame-parent33 {
    height: auto;
    min-height: 596.7000000000007;
  }
  .section-01 {
    gap: 178px 44px;
    padding: var(--padding-40xl) var(--padding-23xl) var(--padding-33xl) var(--padding-14xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .hi-john-message {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .streamline-abandoned-cart {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .hi-john-message-parent {
    gap: 17px 68px;
  }
  .hi-john {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .checkout {
    font-size: var(--body-small-regular-size);
  }
  .section-01 {
    gap: 178px 22px;
    padding-top: var(--padding-19xl);
    padding-right: var(--padding-xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
