.engagement-conversion {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h3-semi-bold-size);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name28,
.unlocking-ecommerce-success {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.unlocking-ecommerce-success {
  align-self: stretch;
}
.button-name28 {
  width: 145px;
  display: inline-block;
  text-decoration: none;
}
.button31,
.engagement-conversion-parent,
.frame-wrapper37 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button31 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.engagement-conversion-parent,
.frame-wrapper37 {
  flex-direction: column;
}
.engagement-conversion-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.frame-wrapper37 {
  width: 460px;
  padding: var(--padding-220xl) 0 0;
  box-sizing: border-box;
  min-width: 460px;
  max-width: 100%;
}
.vector-icon26 {
  height: 440px;
  width: 455px;
  position: absolute;
  margin: 0 !important;
  right: -162px;
  bottom: -289px;
}
.segments2 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  display: inline-block;
  min-width: 114px;
}
.ebaglogo3,
.eleonora-ivanova-marketingauto-child3 {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.eleonora-ivanova-marketingauto-child3 {
  width: 99px;
}
.ebaglogo3 {
  flex: 1;
  min-width: 147px;
}
.eleonora-ivanova-marketingauto4,
.progress-bar1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.eleonora-ivanova-marketingauto4 {
  flex: 1;
  gap: var(--gap-5xl);
}
.progress-bar1 {
  align-self: stretch;
  padding: 0 0 var(--padding-3xs) var(--padding-10xs);
  box-sizing: border-box;
}
.eleonora-ivanova-marketingauto-child4,
.reachpurchaseconversionrateboo {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.reachpurchaseconversionrateboo {
  width: 99px;
}
.eleonora-ivanova-marketingauto-child4 {
  flex: 1;
  min-width: 147px;
}
.eleonora-ivanova-marketingauto5,
.table {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.eleonora-ivanova-marketingauto5 {
  flex: 1;
  gap: var(--gap-5xl);
}
.table {
  align-self: stretch;
  padding: 0 0 var(--padding-3xs) var(--padding-10xs);
  box-sizing: border-box;
}
.ismycustomerdatasafewith-relev,
.whatresultscan-iexpectfromusin {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.ismycustomerdatasafewith-relev {
  width: 99px;
}
.whatresultscan-iexpectfromusin {
  flex: 1;
  min-width: 147px;
}
.canvas,
.eleonora-ivanova-marketingauto6,
.segments-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.eleonora-ivanova-marketingauto6 {
  flex: 1;
  gap: var(--gap-5xl);
}
.canvas,
.segments-container {
  align-self: stretch;
  padding: 0 0 0 var(--padding-10xs);
  box-sizing: border-box;
}
.segments-container {
  flex-direction: column;
  padding: 0 0 var(--padding-lgi);
  gap: var(--gap-xl);
}
.company-resources1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
}
.profile3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}
.product-data-center5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.path-child {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 51.18px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.anthony-smith2 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 113px;
}
.grid,
.path1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.grid {
  flex-direction: column;
  padding: var(--padding-sm) 0 0;
}
.path1 {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-base);
  min-width: 125px;
}
.button33 {
  width: 38px;
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.align1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0 0;
}
.header-logo-container {
  align-self: stretch;
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.align-vertical,
.path-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.align-vertical {
  width: 70px;
  flex-direction: column;
  padding: var(--padding-3xl) 0 0;
  box-sizing: border-box;
}
.path-parent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-8xl-5);
  font-size: var(--body-small-regular-size);
}
.product-data-center-parent2 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-lgi);
}
.frame-parent97,
.product-data-center-parent2,
.star-shape {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.star-shape {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-8xl);
  font-size: var(--body-default-semi-bold-size);
}
.frame-parent97 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-18xl) var(--padding-20xl) var(--padding-21xl) var(--padding-16xl);
  box-sizing: border-box;
  gap: var(--gap-10xl);
  max-width: 100%;
  z-index: 1;
}
.vuesaxlinearcommand-icon1 {
  height: 48.1px;
  width: 48.1px;
  position: relative;
}
.input-collector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 var(--padding-4xs-5);
}
.add-rules1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  min-width: 65px;
}
.about-team-why-work {
  margin: 0 !important;
  position: absolute;
  top: 143px;
  right: -98.2px;
  border-radius: 9.01px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl-1) var(--padding-11xl) var(--padding-17xl-8) var(--padding-11xl-8);
  gap: var(--gap-xs);
  z-index: 2;
  font-size: var(--font-size-sm-5);
}
.vuesaxlinearfilter-add-icon1 {
  height: 49.4px;
  width: 49.4px;
  position: relative;
}
.function-executor {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs) 0 var(--padding-xs-3);
}
.add-filters1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}
.button32,
.data-center {
  justify-content: flex-start;
}
.data-center {
  margin: 0 !important;
  position: absolute;
  top: -42px;
  right: -54.6px;
  border-radius: 9.25px;
  background-color: var(--brand-indigo);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-15xl-8) var(--padding-9xl-3) var(--padding-18xl-9) var(--padding-10xl-3);
  gap: var(--gap-2xs-9);
  z-index: 2;
  font-size: var(--font-size-sm-9);
  color: var(--brand-ivory);
}
.button32 {
  align-self: stretch;
  flex-direction: row;
  position: relative;
  text-decoration: none;
}
.button32,
.frame-parent96,
.i-11,
.logic-gate12 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.i-11 {
  width: 789px;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 40px;
  box-sizing: border-box;
  min-width: 789px;
  /* min-height: 746px; */
  font-size: var(--headings-h5-semi-bold-size);
}
.frame-parent96,
.logic-gate12 {
  flex-direction: row;
}
.frame-parent96 {
  width: 1465px;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.logic-gate12 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-45xl) var(--padding-77xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .frame-wrapper37,
  .i-11 {
    flex: 1;
  }
  .frame-parent96 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1225px) {
  .i-11 {
    min-width: 100%;
  }
  .logic-gate12 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .engagement-conversion {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-wrapper37 {
    padding-top: 0;
    box-sizing: border-box;
    min-width: 100%;
  }
  .frame-parent97 {
    padding-top: var(--padding-5xl);
    padding-bottom: var(--padding-7xl);
  }
  .frame-parent97,
  .i-11,
  .logic-gate12 {
    box-sizing: border-box;
  }
  .i-11 {
    padding: var(--padding-46xl) var(--padding-70xl) var(--padding-46xl) var(--padding-71xl);
  }
  .logic-gate12 {
    padding-bottom: var(--padding-43xl);
  }
}
@media screen and (max-width: 450px) {
  .engagement-conversion {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .segments2 {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .eleonora-ivanova-marketingauto4,
  .eleonora-ivanova-marketingauto5,
  .eleonora-ivanova-marketingauto6,
  .path-parent {
    flex-wrap: wrap;
  }
  .i-11,
  .logic-gate12 {
    box-sizing: border-box;
  }
  .i-11 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
  }
  .logic-gate12 {
    padding-bottom: var(--padding-21xl);
  }
}
