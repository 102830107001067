.how-it-works2 {
  width: 109px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 109px;
  white-space: nowrap;
}
.faq-wrapper a,
.results-wrapper a,
.solutions-wrapper a,
.integrations-wrapper a {
  color: var(--brand-indigo);
}
.how-it-works-wrapper,
.integrations-wrapper,
.solutions-wrapper,
.results-wrapper,
.faq-wrapper {
  padding: var(--padding-5xs) var(--padding-base) !important;
}
.how-it-works-wrapper:hover,
.integrations-wrapper:hover,
.solutions-wrapper:hover,
.results-wrapper:hover,
.faq-wrapper:hover {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
}
.how-it-works-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  padding: var(--padding-5xs) 0;
}
.integrations1 {
  width: 100px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 100px;
  white-space: nowrap;
}
.integrations-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.solutions1 {
  width: 77px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 77px;
  white-space: nowrap;
}
.solutions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.results1 {
  width: 61px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 61px;
  white-space: nowrap;
}
.results-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.faq1 {
  width: 34px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 34px;
  white-space: nowrap;
}
.faq-wrapper,
.menu-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.faq-wrapper {
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.menu-items {
  width: 653px;
  overflow-x: auto;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.iterative-structure,
.sub-menu {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.overview .iterative-structure {
  padding: 0;
}
.overview .sub-menu {
  flex: 1;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
}
.sub-menu {
  flex: 1;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
}
.iterative-structure {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-89xl) var(--padding-12xs);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
