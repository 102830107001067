.supercharge-your-ecommerce {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
}

.button2.youtubeButton {
  background-color: var(--brand-indigo);
}
.the-only-platform {
  width: 625px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.the-only-platform-merging-auto-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.button-name2 {
  width: auto !important;

  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button2,
.network-switch {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button2:hover {
  background-color: var(--color-lightsteelblue);
}
.button2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-13xl) !important;
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.network-switch {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  gap: 20px;
}
.supercharge-your-ecommerce-pro-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  max-width: 100%;
}
.frame-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-17xl) 0 var(--padding-18xl);
  box-sizing: border-box;
  max-width: 100%;
}
.h1-child {
  height: 623px;
  width: 623px;
  position: relative;
  border-radius: var(--br-13xl);
  object-fit: contain;
  display: none;
  max-width: 100%;
}
.frame-child,
.reporting {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-lightsteelblue);
  width: 100%;
  height: 100%;
}
.frame-child {
  top: 273px;
  left: 273px;
  background-color: var(--brand-amethyst);
  width: 960px;
  height: 960px;
  z-index: 1;
}
.menu-item-icon,
.reporting-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.menu-item-icon {
  right: 0;
  bottom: 0;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 2;
}
.menu-item-icon1 {
  position: absolute;
  top: 729.3px;
  left: 312px;
  border-radius: 61.58px;
  width: 77px;
  height: 77px;
  overflow: hidden;
  object-fit: cover;
  z-index: 5;
}
.menu-item-parent {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.marketing-automation {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  /* line-height: 18px; */
  font-weight: 600;
}
.frame-item {
  height: 36px;
  width: 35.2px;
  position: relative;
  border-radius: 3.39px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 36px;
}
.marketing-automation-parent {
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
  flex-shrink: 0;
  z-index: 5;
}
.company-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 4;
}
.relava-symbol-icon {
  width: 98.9px;
  height: 244.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 5;
}
.company-parent {
  height: 667.7px;
  width: 668.1px;
  margin: 0 !important;
  position: absolute;
  top: -204.9px;
  right: -594.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-185xl-9) var(--padding-257xl-4) var(--padding-186xl) var(--padding-257xl);
  box-sizing: border-box;
}
.view-in-browser {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
  min-width: 42px;
}
.view-in-browser-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs);
}
.heyday,
.home,
.kids,
.man,
.woman {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 16px;
  display: inline-block;
  font-weight: 900;
  min-width: 43px;
}
.home,
.kids,
.man,
.woman {
  font-weight: 600;
  min-width: 27px;
}
.home,
.kids,
.man {
  min-width: 16px;
}
.home {
  min-width: 21px;
}
.logic-gate10,
.team-blog {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.team-blog {
  border-radius: 2.02px;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-9xs) var(--padding-smi) var(--padding-8xs-1) var(--padding-smi-7);
  gap: var(--gap-xl);
  font-size: var(--font-size-5xs-5);
}
.logic-gate10 {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xs-1);
  gap: var(--gap-7xs-6);
}
.hi-mark {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 16px;
  display: inline-block;
  min-width: 39px;
}
.come-back-and,
.hi-mark {
  font-weight: 600;
}
.special-offers-are-container {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-5xs-5);
  letter-spacing: -0.01em;
}
.communicationcenter-parent,
.hi-mark-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.hi-mark-parent {
  width: 162.1px;
  flex-direction: column;
  gap: var(--gap-11xs-5);
}
.communicationcenter-parent {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-9xs-9);
  gap: var(--gap-8xs-5);
  font-size: var(--font-size-7xs-6);
}
.see-all-new {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
  min-width: 71px;
}
.get-demo-container,
.get-demo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.get-demo1 {
  border-radius: 4.69px;
  background-color: var(--brand-indigo);
  padding: var(--padding-8xs-7) var(--padding-6xs);
  white-space: nowrap;
}
.get-demo-container {
  padding: 0 var(--padding-27xl) 0 var(--padding-27xl-9);
  font-size: var(--font-size-6xs);
  color: var(--brand-ivory);
}
.c-t-a {
  align-self: stretch;
  border-radius: 4.75px;
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-4xs-7) var(--padding-3xs) var(--padding-lgi-5) var(--padding-2xs-1);
  gap: var(--gap-4xs-7);
  font-size: var(--font-size-2xs-1);
}
.c-t-a,
.frame-parent3,
.frame-wrapper2,
.why-releva1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.why-releva1 {
  flex: 1;
  border-radius: 4.04px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-3xs-3) var(--padding-6xs-9) var(--padding-2xs);
  gap: var(--gap-8xs);
  flex-shrink: 0;
  z-index: 4;
}
.frame-parent3,
.frame-wrapper2 {
  flex-direction: row;
}
.frame-parent3 {
  width: 215px;
  position: relative;
}
.frame-wrapper2 {
  align-self: stretch;
  font-size: var(--font-size-7xs-6);
}
.frame-parent2,
.frame-wrapper1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent2 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-30xl);
}
.frame-wrapper1 {
  width: 292px;
  align-items: flex-start;
  padding: var(--padding-22xl) 0 0;
  box-sizing: border-box;
}
.image-84-icon {
  position: absolute;
  width: calc(100% - 13.3px);
  top: 5.3px;
  right: 6.7px;
  left: 6.6px;
  max-width: 100%;
  overflow: hidden;
  height: 94.3px;
  object-fit: cover;
}
.reporting-analytics {
  width: 85px;
  height: 85px;
  position: relative;
  border-radius: 67.97px;
  background-color: var(--color-plum);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 5;
}
.for-you,
.recommend {
  margin: 0;
}
.recommend-for-you-container {
  width: 91px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 15.36px;
  font-weight: 600;
  display: inline-block;
}
.privacy-policy {
  align-self: stretch;
  height: 0.5px;
  position: relative;
  border-top: 0.5px solid var(--brand-ivory);
  box-sizing: border-box;
}
.image-15-icon {
  height: 43.7px;
  width: 34px;
  position: relative;
  object-fit: cover;
}
.ecommerce-owners,
.marketing-specialist {
  position: relative;
  border-radius: 21.76px;
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
}
.ecommerce-owners {
  align-self: stretch;
  height: 5.9px;
}
.marketing-specialist {
  width: 27.5px;
  height: 5.2px;
}
.ecommerce-owners-parent,
.frame-wrapper5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ecommerce-owners-parent {
  align-self: stretch;
  gap: var(--gap-9xs-3);
}
.frame-wrapper5 {
  width: 62.2px;
  padding: var(--padding-smi-8) 0 0;
  box-sizing: border-box;
}
.frame-wrapper4,
.image-15-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.image-15-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-wrapper4 {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs) 0 var(--padding-3xs-6);
}
.privacy-policy1 {
  align-self: stretch;
  height: 0.5px;
  position: relative;
  border-top: 0.5px solid var(--brand-ivory);
  box-sizing: border-box;
}
.privacy-policy-parent,
.recommend-for-you-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs-1);
}
.recommend-for-you-parent {
  gap: var(--gap-xs-9);
}
.shopify-integration {
  height: 5.2px;
  width: 49.8px;
  position: absolute;
  margin: 0 !important;
  right: 21.6px;
  bottom: 3.8px;
  border-radius: 21.76px;
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-inner,
.image-94-icon {
  position: relative;
  flex-shrink: 0;
}
.image-94-icon {
  height: 21px;
  width: 46.5px;
  object-fit: cover;
}
.frame-inner {
  align-self: stretch;
  height: 5.9px;
  border-radius: 21.76px;
  background-color: var(--brand-ivory);
  overflow: hidden;
}
.frame-wrapper6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs-8) 0 0;
}
.image-94-parent {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-2xl-2);
}
.image-94-parent,
.p1-inner,
.shopify-integration-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.shopify-integration-parent {
  flex: 1;
  justify-content: flex-start;
  position: relative;
}
.p1-inner {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-11xs-9) var(--padding-5xs) var(--padding-6xs);
}
.line-div {
  align-self: stretch;
  height: 0.5px;
  position: relative;
  border-top: 0.5px solid var(--brand-ivory);
  box-sizing: border-box;
}
.image-99-icon {
  height: 31.2px;
  width: 33.7px;
  position: relative;
  object-fit: cover;
}
.savesitespeed,
.simplify-your-tech-stackacumbe {
  position: relative;
  border-radius: 21.76px;
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
}
.savesitespeed {
  align-self: stretch;
  height: 5.9px;
}
.simplify-your-tech-stackacumbe {
  width: 36.7px;
  height: 5.2px;
}
.data-aggregator,
.data-aggregator-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.data-aggregator {
  align-self: stretch;
  gap: var(--gap-9xs-3);
}
.data-aggregator-wrapper {
  width: 62.2px;
  padding: var(--padding-4xs-3) 0 0;
  box-sizing: border-box;
}
.image-99-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-wrapper7,
.line-parent,
.p1 {
  display: flex;
  justify-content: flex-start;
}
.frame-wrapper7 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-2xs) 0 var(--padding-3xs-6);
}
.line-parent,
.p1 {
  flex-direction: column;
}
.line-parent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-sm-8);
}
.p1 {
  width: 171.3px;
  border-radius: 5.24px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-mid-5) var(--padding-smi-4) var(--padding-sm-8) var(--padding-2xs-9);
  box-sizing: border-box;
  gap: var(--gap-base);
  z-index: 4;
}
.personalisation {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 117.8px;
}
.error-handler1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xs) 0 0;
}
.unleashthepowerofpostpurchases-icon {
  height: 36px;
  width: 35.2px;
  position: relative;
  border-radius: 3.39px;
  overflow: hidden;
  flex-shrink: 0;
}
.icon {
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-base) var(--padding-xs);
  gap: var(--gap-xs);
  z-index: 4;
}
.icon,
.output-sorter,
.p1-parent {
  display: flex;
  justify-content: flex-start;
}
.output-sorter {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--body-small-regular-size);
}
.p1-parent {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-49xl-3);
}
.frame-wrapper3 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xl);
}
.frame-parent1,
.frame-wrapper3,
.reporting-analytics-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.reporting-analytics-parent {
  width: 265.3px;
  flex-direction: column;
  gap: var(--gap-27xl);
  font-size: var(--font-size-smi-9);
}
.frame-parent1 {
  position: absolute;
  top: 235.7px;
  left: 222px;
  width: 1034.9px;
  flex-direction: row;
  padding: var(--padding-211xl-3) var(--padding-23xl) var(--padding-280xl-3);
  box-sizing: border-box;
  gap: var(--gap-323xl);
  max-width: 100%;
}
.frame-div {
  margin-top: -404px;
  margin-left: -106px;
  height: 1506px;
  width: 1506px;
  position: relative;
  flex-shrink: 0;
}
.frame-container,
.h1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.h1 {
  align-self: stretch;
  height: 698px;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  z-index: 1;
  text-align: left;
  font-size: var(--body-small-regular-size);
}
.frame-container {
  width: 1294px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-101xl);
}
.data-driven-marketers section.releva-homepage-child,
.pricing-page section.releva-homepage-child,
.about-us section.releva-homepage-child,
.overview section.releva-homepage-child,
.product-page section.releva-homepage-child,
.releva-homepage section.releva-homepage-child {
  padding: var(--padding-136xl) var(--padding-xl) 0 var(--padding-5xl);
}
.releva-homepage-child {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .frame-parent1 {
    flex-wrap: wrap;
  }
  .frame-div {
    min-height: 1506;
  }
  .frame-div,
  .h1 {
    height: auto;
  }
  .releva-homepage-child {
    padding-bottom: var(--padding-82xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .supercharge-your-ecommerce {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .supercharge-your-ecommerce-pro-parent {
    gap: 16px 32px;
  }
  .frame-container {
    gap: 60px 120px;
  }
  .releva-homepage-child {
    padding-bottom: var(--padding-47xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .b1-icon {
    height: auto !important;
  }
  .frame-parent4 .frame-parent5,
  .frame-parent4 .frame-parent6,
  .frame-parent4 .frame-parent7,
  .frame-parent4 .reporting1 {
    width: 46%;
  }
  .social-proof-section-wrapper {
    margin-top: 0px;
  }
  .releva-homepage section.releva-homepage-child {
    padding: 120px var(--padding-xl) 0 var(--padding-5xl);
  }
  .supercharge-your-ecommerce {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .frame-container {
    gap: 30px 120px;
  }
}
