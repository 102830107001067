.image-126-icon {
  position: absolute;
  top: 25px;
  left: 37px;
  width: 180px;
  height: 559px;
  object-fit: cover;
}
.image-126-wrapper,
.result-merger-child {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.image-126-wrapper {
  height: 485px;
  width: 254px;
  border-radius: 17.11px;
  background-color: var(--color-lightsteelblue);
  min-width: 254px;
  z-index: 1;
}
.result-merger-child {
  align-self: stretch;
  height: 180px;
  border-radius: 16.93px;
  max-width: 100%;
}
.recommendations-icon {
  height: 465px;
  width: 482px;
  position: absolute;
  margin: 0 !important;
  right: -171px;
  bottom: -298px;
}
.h {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 83px;
}
.h-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xl) 0 var(--padding-9xl-5);
}
.weekday {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
}
.linked-in,
.map-function {
  justify-content: flex-start;
}
.linked-in {
  flex: 1;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-56xl) var(--padding-18xl) var(--padding-89xl) var(--padding-19xl);
  z-index: 1;
}
.map-function {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.i-05,
.map-function,
.result-merger {
  display: flex;
  align-items: flex-start;
}
.result-merger {
  width: 215px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xs);
  min-width: 215px;
}
.i-05 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-mini);
  min-width: 513px;
  max-width: 513px;
}
.hour-weekday-freedom,
.save-over-8 {
  align-self: stretch;
  position: relative;
}
.hour-weekday-freedom {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
}
.save-over-8 {
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.hour-weekday-freedom-pass-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  padding: 100px 0 0;
}
.fills,
.fills-inner {
  display: flex;
  max-width: 100%;
}
.fills-inner {
  width: 436px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 436px;
  min-height: 466px;
  font-size: var(--headings-h2-semi-bold-size);
}
.fills {
  width: 1384px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-140xl);
}
.join-the-40,
.join-the-marketing {
  align-self: stretch;
  position: relative;
}
.join-the-40 {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.join-the-marketing {
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.join-the-40-revenue-club-parent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.frame-wrapper34,
.join-the-40-revenue-club-parent,
.normalizer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper34 {
  width: 460px;
  flex-direction: column;
  padding: var(--padding-263xl) 0 0;
  box-sizing: border-box;
  min-width: 460px;
  max-width: 100%;
}
.normalizer {
  flex-direction: row;
  padding: 0 var(--padding-22xl);
}
.revenue-club,
.shopify1 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
}
.revenue-club {
  flex: 1;
  letter-spacing: -0.01em;
  line-height: 17px;
}
.joiner,
.normalizer-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.joiner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-12xs);
  text-align: center;
  font-size: var(--body-small-regular-size);
}
.normalizer-parent {
  width: 215px;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-138xl-5) var(--padding-17xl) var(--padding-138xl-5) var(--padding-18xl);
  box-sizing: border-box;
  min-width: 215px;
  z-index: 1;
}
.vuesaxlineardirect-up-icon9 {
  margin-top: -71.70000000000073px;
  margin-left: -54.400000000001455px;
  height: 45.5px;
  width: 45.5px;
  position: relative;
  flex-shrink: 0;
}
.vuesaxlinearshopping-cart-icon1 {
  height: 60px;
  width: 60px;
  position: relative;
  flex-shrink: 0;
}
.boost1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 23px;
  font-weight: 600;
}
.boost-container,
.vuesaxlineardirect-up-group {
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.boost-container {
  width: 108px;
  flex-direction: column;
  padding: var(--padding-lgi-8) 0 0;
}
.vuesaxlineardirect-up-group {
  align-self: stretch;
  height: 108px;
  border-radius: 16.93px;
  background-color: var(--brand-lemon);
  overflow-x: auto;
  flex-direction: row;
  padding: var(--padding-7xl-2) var(--padding-17xl) var(--padding-2xl-8) var(--padding-18xl);
  gap: var(--gap-2xs-5);
}
.vector-icon11 {
  height: 440px;
  width: 455px;
  position: absolute;
  margin: 0 !important;
  right: -137px;
  bottom: -291px;
}
.frame-child47 {
  height: 361px;
  flex: 1;
  position: relative;
  border-radius: 17.11px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.frame-parent78,
.vector-parent5 {
  display: flex;
  justify-content: flex-start;
}
.vector-parent5 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.frame-parent78 {
  width: 254px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-base);
  min-width: 254px;
  text-align: center;
  font-size: var(--font-size-17xl);
}
.i-06 {
  /* width: 789px; */
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-smi);
  /* min-width: 789px; */
  font-size: var(--font-size-48xl-7);
}
.fills1,
.frame-parent77,
.i-06 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent77 {
  width: 1465px;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.fills1 {
  align-self: stretch;
  justify-content: flex-end;
  font-size: var(--headings-h3-semi-bold-size);
}
.vector-icon12 {
  height: 641.9px;
  width: 621.7px;
  position: absolute;
  margin: 0 !important;
  right: -616.7px;
  bottom: -380.9px;
  object-fit: contain;
}
.vuesaxlineardirect-up-icon10 {
  height: 60px;
  width: 60px;
  position: relative;
}
.positioning {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl);
}
.x1 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 120px;
}
.data-aggregator3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
}
.return-on-investment2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
}
.positioning-parent,
.vector-parent6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.positioning-parent {
  flex: 1;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-117xl) var(--padding-18xl) var(--padding-143xl) var(--padding-19xl);
  z-index: 1;
}
.vector-parent6 {
  width: 215px;
  flex-direction: row;
  position: relative;
  min-width: 215px;
}
.image-84-icon4 {
  width: 319px;
  height: 389px;
  position: relative;
  object-fit: contain;
}
.i-07,
.image-84-frame {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.image-84-frame {
  height: 485px;
  width: 254px;
  border-radius: 17.11px;
  background-color: var(--brand-amethyst);
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-10xl);
  min-width: 254px;
  z-index: 1;
}
.i-07 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  gap: var(--gap-smi);
  min-width: 513px;
  max-width: 100%;
}
.where-50x-roi {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.releva-consistently-delivers {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.fills-child,
.fills2,
.where-50x-roi-isnt-a-dream-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.where-50x-roi-isnt-a-dream-parent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.fills-child,
.fills2 {
  max-width: 100%;
}
.fills-child {
  width: 436px;
  flex-direction: column;
  padding: var(--padding-263xl) 0 0;
  box-sizing: border-box;
  min-width: 436px;
  font-size: var(--headings-h3-semi-bold-size);
}
.fills2 {
  width: 1384px;
  flex-direction: row;
  gap: var(--gap-140xl);
}
.double-your-conversions {
  margin: 0;
  align-self: stretch;
  height: 102px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.releva-doubles-purchase {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.double-your-conversions-parent,
.frame-wrapper35 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.double-your-conversions-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.frame-wrapper35 {
  width: 496px;
  padding: var(--padding-263xl) 0 0;
  box-sizing: border-box;
  min-width: 496px;
  max-width: 100%;
}
.buubles-icon,
.i-08-child {
  position: absolute;
  overflow: hidden;
}
.buubles-icon {
  height: 48.66%;
  width: 79.09%;
  top: 61.26%;
  right: -1.39%;
  bottom: -9.92%;
  left: 22.31%;
  max-width: 100%;
  max-height: 100%;
}
.i-08-child {
  top: 400px;
  left: 185px;
  border-radius: 13.59px;
  width: 204px;
  height: 202px;
  object-fit: cover;
  z-index: 1;
}
.vuesaxlinearactivity-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xl) 0 var(--padding-6xl-5);
}
.x2 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}
.x-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) var(--padding-5xs);
  margin-top: -8px;
}
.purchase-rates {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  margin-top: -8px;
}
.frame-parent80 {
  position: absolute;
  top: 145px;
  left: 185px;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  width: 204px;
  height: 236px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) var(--padding-51xl);
  box-sizing: border-box;
}
.image-87-icon {
  align-self: stretch;
  height: 324px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.image-87-wrapper {
  position: absolute;
  top: 144px;
  left: 401px;
  border-radius: 13.59px;
  background-color: var(--color-lightsteelblue);
  width: 204px;
  height: 458px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs) 0 0;
  box-sizing: border-box;
  z-index: 1;
}
.i-08 {
  /* height: 746px; */
  flex: 1;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  min-width: 513px;
  max-width: 100%;
  font-size: var(--font-size-48xl-7);
}
.fills3,
.frame-parent79,
.shapes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent79 {
  width: 1465px;
  justify-content: flex-start;
  gap: var(--gap-161xl);
}
.fills3,
.shapes {
  /* align-self: stretch; */
  justify-content: center;
  font-size: var(--headings-h3-semi-bold-size);
}
.shapes {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-77xl);
  text-align: left;
  font-size: var(--font-size-48xl-7);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1500px) {
  .fills-inner {
    flex: 1;
    min-height: auto;
  }
  .fills {
    flex-wrap: wrap;
  }
  .frame-wrapper34,
  .i-06 {
    flex: 1;
  }
  .frame-parent77 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .fills-child {
    flex: 1;
  }
  .fills2 {
    flex-wrap: wrap;
  }
  .frame-wrapper35 {
    flex: 1;
  }
  .frame-parent79 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .fills {
    gap: 59px 79px;
  }
  .i-06 {
    min-width: 100%;
  }
  .fills2 {
    gap: 59px 79px;
  }
  .frame-parent79 {
    gap: 180px 90px;
  }
}
@media screen and (max-width: 850px) {
  .image-126-wrapper {
    flex: 1;
  }
  .h {
    font-size: var(--font-size-35xl);
    line-height: 74px;
  }
  .result-merger {
    flex: 1;
  }
  .i-05 {
    flex-wrap: wrap;
    /* padding-top: var(--padding-66xl);
    padding-bottom: var(--padding-65xl); */
    box-sizing: border-box;
    min-width: 100%;
  }
  .hour-weekday-freedom {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .fills-inner {
    min-width: 100%;
  }
  .fills {
    gap: 59px 40px;
  }
  .join-the-40 {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-wrapper34 {
    min-width: 100%;
  }
  .shopify1 {
    font-size: var(--font-size-35xl);
    line-height: 74px;
  }
  .normalizer-parent {
    flex: 1;
    padding-top: var(--padding-83xl);
    padding-bottom: var(--padding-83xl);
    box-sizing: border-box;
  }
  .boost1 {
    font-size: var(--font-size-10xl);
    line-height: 16px;
  }
  .frame-parent78 {
    flex: 1;
  }
  .i-06 {
    flex-wrap: wrap;
    /* padding-top: var(--padding-65xl);
    padding-bottom: var(--padding-66xl); */
    box-sizing: border-box;
  }
  .x1 {
    font-size: var(--font-size-35xl);
    line-height: 74px;
  }
  .positioning-parent {
    padding-top: var(--padding-69xl);
    padding-bottom: var(--padding-86xl);
    box-sizing: border-box;
  }
  .vector-parent6 {
    flex: 1;
  }
  .image-84-icon4 {
    align-self: stretch;
    width: auto;
  }
  .image-84-frame {
    flex: 1;
  }
  .i-07 {
    flex-wrap: wrap;
    /* padding-top: var(--padding-62xl);
    padding-bottom: var(--padding-62xl); */
    box-sizing: border-box;
    min-width: 100%;
  }
  .where-50x-roi {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .fills-child {
    min-width: 100%;
  }
  .fills2 {
    gap: 59px 40px;
  }
  .double-your-conversions {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-wrapper35 {
    min-width: 100%;
  }
  .x2 {
    font-size: var(--font-size-35xl);
    line-height: 74px;
  }
  .i-08 {
    min-width: 100%;
  }
  .frame-parent79 {
    gap: 180px 45px;
  }
  .shapes {
    gap: 48px 96px;
  }
}
@media screen and (max-width: 450px) {
  .h {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .hour-weekday-freedom {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .fills {
    gap: 59px 20px;
  }
  .join-the-40 {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .frame-wrapper34 {
    padding-top: 0;
    box-sizing: border-box;
  }
  .shopify1 {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .boost1 {
    font-size: var(--font-size-3xl);
    line-height: 12px;
  }
  .x1 {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .where-50x-roi {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .fills-child {
    padding-top: 0;
    box-sizing: border-box;
  }
  .fills2 {
    gap: 59px 20px;
  }
  .double-your-conversions {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .frame-wrapper35 {
    padding-top: 0;
    box-sizing: border-box;
  }
  .x2 {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .frame-parent79 {
    gap: 80px 22px;
  }
  .shapes {
    gap: 24px 96px;
  }
  .hour-weekday-freedom-pass-parent {
    padding: 0;
  }
}
