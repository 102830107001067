.releva-team {
  width: 103px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 103px;
}
.releva-team-wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  white-space: nowrap;
}
.ceo {
  width: 38px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 38px;
}
.ceo-wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--color-linen-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
}
.cto {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 38px;
}
.cto-wrapper {
  width: 38px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.customer-success-team {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.customer-success-team-wrapper {
  width: 208px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.sales-team {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 96px;
}
.sales-team-wrapper {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.marketing-team {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.marketing-team-wrapper {
  width: 131px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.technical-team {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 126px;
}
.technical-team-wrapper {
  width: 126px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.technical-team1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.page,
.technical-team-container {
  display: flex;
  align-items: flex-start;
}
.technical-team-container {
  width: 190px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
  box-sizing: border-box;
}
.page {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-xl);
}
.georgi-georgiev2 {
  position: absolute;
  top: 192px;
  left: 38.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 125px;
}
.photo-icon1,
.vector-icon33 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.photo-icon1 {
  top: 23px;
  left: 38.3px;
  width: 138px;
  height: 158px;
  object-fit: cover;
  z-index: 2;
}
.decision-maker2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.ceo-and-co-founder,
.output-filter1 {
  position: relative;
  flex-shrink: 0;
}
.output-filter1 {
  margin-top: -217px;
  align-self: stretch;
  height: 217px;
}
.ceo-and-co-founder {
  width: 125px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 125px;
}
.function-applier2 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-25xl) 0 var(--padding-19xl-3);
  font-size: var(--font-size-smi);
}
.condition-splitter3,
.function-applier2,
.georgi {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.georgi {
  height: 226px;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-174xl) var(--padding-base) var(--padding-5xs) var(--padding-5xl);
}
.condition-splitter3 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-3xs);
}
.hristina-petkova {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  z-index: 1;
}
.vector-icon34 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.removebg-preview-1-icon {
  position: absolute;
  top: 11px;
  left: 25px;
  width: 158px;
  height: 158px;
  object-fit: cover;
  z-index: 1;
}
.data-reader {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.product-marketing-specialist,
.signal-emitter1 {
  position: relative;
  flex-shrink: 0;
}
.signal-emitter1 {
  margin-top: -204px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-160xl) var(--padding-19xl) 0;
}
.product-marketing-specialist {
  width: 177px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.value-enricher {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  font-size: var(--font-size-smi);
}
.hristina {
  align-self: stretch;
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-160xl) var(--padding-xl) var(--padding-3xl);
}
.data-gatherer1,
.hristina,
.mask {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.data-gatherer1 {
  align-self: stretch;
  gap: var(--gap-24xl);
}
.mask {
  width: 249px;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
}
.yavor-stoychev2 {
  position: absolute;
  top: 190px;
  left: 40.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 120px;
}
.error-handler-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.dsc-8093-eddit-min-removebg-pr-icon {
  position: absolute;
  top: 16px;
  left: 17px;
  width: 168px;
  height: 158px;
  object-fit: cover;
  z-index: 2;
}
.result-splitter {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.cto-and-co-founder,
.data-combiner {
  position: relative;
  flex-shrink: 0;
}
.data-combiner {
  margin-top: -215px;
  align-self: stretch;
  height: 215px;
}
.cto-and-co-founder {
  width: 125px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 125px;
}
.value-sorter {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-27xl) 0 var(--padding-19xl-3);
  font-size: var(--font-size-smi);
}
.sequence-processor,
.value-sorter,
.yavor {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.yavor {
  height: 226px;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-171xl) var(--padding-lgi) var(--padding-2xs);
}
.sequence-processor {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-3xs) var(--padding-4xs);
}
.radoslav-danev {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 123px;
}
.value-sequencer-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.f409cde-7e16-46f2-9d53-bae5633-icon {
  position: absolute;
  top: 23px;
  left: 25px;
  width: 157px;
  height: 157px;
  object-fit: cover;
  z-index: 2;
}
.sequence-monitor {
  width: 100%;
  height: 204px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  left: 0;
}
.customer-success-specialist1,
.result-formatter {
  position: relative;
  flex-shrink: 0;
}
.result-formatter {
  margin-top: -211px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-167xl) var(--padding-23xl) 0;
}
.customer-success-specialist1 {
  /* width: 172px; */
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.radosalv,
.tree-splitting {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tree-splitting {
  flex-direction: row;
  padding: 0 var(--padding-lg) 0 var(--padding-mid);
  font-size: var(--font-size-smi);
}
.radosalv {
  width: 249px;
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-169xl) var(--padding-xl) var(--padding-smi);
}
.georgi-keranov1 {
  position: absolute;
  top: 179px;
  left: 41.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 119px;
}
.vector-icon35 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon1 {
  position: absolute;
  top: 24px;
  left: 33.3px;
  width: 139px;
  height: 142px;
  object-fit: cover;
  z-index: 2;
}
.result-extractor,
.tree-reducer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.result-extractor {
  margin: 0 !important;
  right: 0;
  bottom: 0;
}
.tree-reducer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.software-engineer,
.value-transformer1 {
  position: relative;
  flex-shrink: 0;
}
.value-transformer1 {
  margin-top: -204px;
  align-self: stretch;
  height: 204px;
}
.software-engineer {
  /* width: 112px; */
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 112px;
}
.georgi-keranov,
.software-engineer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.software-engineer-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-32xl) 0 var(--padding-25xl-3);
  font-size: var(--font-size-smi);
}
.georgi-keranov {
  align-self: stretch;
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-161xl) var(--padding-base) var(--padding-2xl) var(--padding-5xl);
}
.vector-icon36 {
  height: 204px;
  width: 207.3px;
  position: absolute;
  margin: 0 !important;
  right: -21.3px;
  bottom: -34px;
  object-fit: contain;
  z-index: 1;
}
.dobri-removebg-preview-1-icon {
  height: 167px;
  width: 167px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}
.vector-parent13 {
  margin-top: -176px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.dobri-nedev {
  width: 95px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 95px;
}
.dobri-nedev-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl);
}
.junior-full-stack {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-smi);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.dobri,
.frame-parent138 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent138 {
  width: 163px;
  align-items: flex-start;
}
.dobri {
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-end;
  padding: var(--padding-135xl) var(--padding-23xl) var(--padding-3xl) var(--padding-19xl);
  gap: var(--gap-4xs);
}
.error-handler11 {
  width: 249px;
  gap: var(--gap-24xl);
}
.error-handler10,
.error-handler11,
.result-merger1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.error-handler10 {
  align-self: stretch;
  gap: var(--gap-24xl);
}
.result-merger1 {
  flex: 1;
  padding: var(--padding-11xs) var(--padding-8xs) 0 0;
  box-sizing: border-box;
  min-width: 171px;
}
.boryana-antonova {
  position: absolute;
  top: 188px;
  left: 32.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.photo-icon2,
.vector-icon37 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.photo-icon2 {
  top: 24px;
  left: 22.3px;
  width: 155px;
  height: 155px;
  object-fit: cover;
  z-index: 2;
}
.vector-parent14 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.boryana-antonova-parent,
.customer-success-manager {
  position: relative;
  flex-shrink: 0;
}
.boryana-antonova-parent {
  margin-top: -213px;
  width: 207.3px;
  height: 213px;
}
.customer-success-manager {
  width: 169px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.data-merger {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-lgi) 0 var(--padding-8xl);
  font-size: var(--font-size-smi);
}
.boryana,
.boryana-wrapper {
  display: flex;
  justify-content: flex-start;
}
.boryana {
  height: 226px;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-170xl) var(--padding-base) var(--padding-xs);
}
.boryana-wrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 var(--padding-xs);
}
.maria-ivanova {
  position: absolute;
  top: 189px;
  left: 46.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 107px;
}
.valueaddedproduct-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon2 {
  position: absolute;
  top: 26px;
  left: 20.3px;
  width: 160px;
  height: 160px;
  object-fit: cover;
  z-index: 2;
}
.data-joiner {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.sales-director,
.signal-splitter {
  position: relative;
  flex-shrink: 0;
}
.signal-splitter {
  margin-top: -214px;
  align-self: stretch;
  height: 214px;
}
.sales-director {
  /* width: 85px; */
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  /* min-width: 85px; */
}
.data-selector {
  /* align-self: stretch; */
  flex-direction: row;
  /* padding: 0 var(--padding-46xl) 0 var(--padding-38xl-3); */
  font-size: var(--font-size-smi);
}
.data-combiner1,
.data-selector,
.maria {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.maria {
  align-items: center;
  justify-content: center;
  height: 226px;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-171xl) var(--padding-base) var(--padding-2xs) var(--padding-5xl);
}
.data-combiner1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-11xs);
}
.dean-dupalov {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 107px;
}
.merchant-platform-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon3 {
  position: absolute;
  top: 5px;
  left: 16px;
  width: 167px;
  height: 167px;
  object-fit: cover;
  z-index: 2;
}
.output-transformer {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.junior-full-stack1,
.value-comparator3 {
  position: relative;
  flex-shrink: 0;
}
.value-comparator3 {
  margin-top: -204px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-160xl) var(--padding-29xl-3) 0 var(--padding-33xl);
}
.junior-full-stack1 {
  /* width: 156px; */
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.data-processor1,
.dean {
  align-self: stretch;
  display: flex;
}
.data-processor1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* padding: 0 var(--padding-5xl-3) 0 var(--padding-8xl); */
  font-size: var(--font-size-smi);
}
.dean {
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-160xl) var(--padding-2xl) var(--padding-3xl) var(--padding-lgi);
}
.asen-antonov {
  position: absolute;
  top: 179px;
  left: 46.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 109px;
}
.vector-icon38 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.mask-group-6-removebg-preview-icon {
  position: absolute;
  top: 25px;
  left: 29px;
  width: 143px;
  height: 143px;
  object-fit: cover;
  z-index: 2;
}
.error-handler13,
.result-parser {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.error-handler13 {
  margin: 0 !important;
  right: 0;
  bottom: 0;
}
.result-parser {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.senior-data-scientist,
.tree-analyzer {
  position: relative;
  flex-shrink: 0;
}
.tree-analyzer {
  margin-top: -204px;
  align-self: stretch;
  height: 204px;
}
.senior-data-scientist {
  width: 123px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 123px;
}
.asen,
.data-mapper1,
.error-handler12,
.mask1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.data-mapper1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-26xl) 0 var(--padding-20xl-3);
  font-size: var(--font-size-smi);
}
.asen,
.error-handler12,
.mask1 {
  flex-direction: column;
}
.asen {
  align-self: stretch;
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-160xl) var(--padding-xl) var(--padding-3xl);
}
.error-handler12,
.mask1 {
  gap: var(--gap-24xl);
}
.error-handler12 {
  align-self: stretch;
}
.mask1 {
  flex: 1;
  padding: 0 var(--padding-6xs) 0 0;
  box-sizing: border-box;
  min-width: 168px;
}
.dimitrios-ntousikos {
  position: absolute;
  top: 190px;
  left: 26px;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.vector-icon39 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon4 {
  position: absolute;
  top: 21px;
  left: 15px;
  width: 162px;
  height: 162px;
  object-fit: cover;
  z-index: 2;
}
.vector-parent15 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.dimitrios-ntousikos-parent {
  margin-top: -215px;
  align-self: stretch;
  height: 215px;
  position: relative;
  flex-shrink: 0;
}
.business-development-manager {
  width: 193px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  flex-shrink: 0;
}
.business-development-manager-wrapper,
.dimitri {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.business-development-manager-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-2xs) 0 var(--padding-8xs);
  font-size: var(--font-size-smi);
}
.dimitri {
  height: 236px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-171xl) var(--padding-lgi) var(--padding-2xs);
}
.automation-icon {
  height: 204px;
  width: 207.3px;
  position: absolute;
  margin: 0 !important;
  right: -29.3px;
  bottom: -25px;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon5 {
  height: 179px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  flex-shrink: 0;
  z-index: 2;
}
.customer-data-segmentation3 {
  margin-top: -179px;
  width: 156px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.radostin-minchev {
  width: 138px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  flex-shrink: 0;
}
.radostin-minchev-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-4xs) 0 var(--padding-mid);
}
.junior-full-stack2 {
  width: 156px;
  position: relative;
  font-size: var(--font-size-smi);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  flex-shrink: 0;
}
.radostin,
.sequence-sorter {
  display: flex;
  justify-content: flex-start;
}
.radostin {
  height: 236px;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-137xl) var(--padding-23xl) var(--padding-xl);
}
.sequence-sorter {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 var(--padding-8xs);
}
.daren-stoev {
  width: 94px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 94px;
}
.value-added-product2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon6 {
  position: absolute;
  top: 9px;
  left: 20px;
  width: 160px;
  height: 160px;
  object-fit: cover;
  z-index: 2;
}
.pattern-matcher {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.data-aggregator9,
.input-filter4 {
  display: flex;
  align-items: flex-start;
}
.input-filter4 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--padding-160xl) var(--padding-34xl-3) 0;
  position: relative;
  flex-shrink: 0;
}
.data-aggregator9 {
  margin-top: -204px;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-10xs);
}
.junior-machine-learning {
  width: 205px;
  position: relative;
  font-size: var(--font-size-smi);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  flex-shrink: 0;
}
.daren {
  align-self: stretch;
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-162xl) var(--padding-lg) var(--padding-xl) var(--padding-lgi);
}
.daren,
.dimitri-parent,
.mask2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dimitri-parent {
  align-self: stretch;
  gap: var(--gap-24xl);
}
.mask2 {
  flex: 1;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-36xl);
  min-width: 168px;
}
.vector-icon40 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.removebg-preview-1-icon7 {
  position: absolute;
  top: 23px;
  left: 30px;
  width: 147px;
  height: 147px;
  object-fit: cover;
  z-index: 1;
}
.vector-parent16 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.irena-yordanova {
  position: absolute;
  top: 187px;
  left: 35px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 124px;
  z-index: 1;
}
.customer-success-specialist2,
.frame-parent139 {
  position: relative;
  flex-shrink: 0;
}
.frame-parent139 {
  margin-top: -212px;
  align-self: stretch;
  height: 212px;
}
.customer-success-specialist2 {
  /* width: 172px; */
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.customer-success-specialist-wrapper,
.irena {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.customer-success-specialist-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-lg) 0 var(--padding-mid);
  font-size: var(--font-size-smi);
}
.irena {
  align-self: stretch;
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-170xl) var(--padding-smi) var(--padding-xs) var(--padding-8xl);
}
.asen-ivanov {
  position: absolute;
  top: 187px;
  left: 49px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 95px;
}
.vector-icon41 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon8 {
  position: absolute;
  top: 26px;
  left: 21px;
  width: 152px;
  height: 152px;
  object-fit: cover;
  z-index: 2;
}
.vector-parent17 {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.asen-ivanov-parent,
.business-development-manager1 {
  position: relative;
  flex-shrink: 0;
}
.asen-ivanov-parent {
  margin-top: -212px;
  align-self: stretch;
  height: 212px;
}
.business-development-manager1 {
  width: 193px;
  font-size: var(--font-size-smi);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.asen1 {
  align-self: stretch;
  height: 236px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-170xl) var(--padding-smi) var(--padding-xs) var(--padding-8xl);
}
.krasimira-georgieva {
  width: 156px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.vector-icon42 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 1;
}
.removebg-preview-1-icon9 {
  position: absolute;
  top: 24px;
  left: 26.3px;
  width: 145px;
  height: 145px;
  object-fit: cover;
  z-index: 3;
}
.vector-parent18 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.krasimira-georgieva-parent {
  margin-top: -204px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-160xl) var(--padding-3xl-3) 0;
  position: relative;
  flex-shrink: 0;
}
.removebg-preview-1 {
  width: 135px;
  height: 147px;
  position: relative;
  display: none;
}
.junior-full-stack3 {
  width: 163px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  flex-shrink: 0;
}
.asen-parent,
.krasimira,
.value-mapper-tree {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.value-mapper-tree {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-6xl) 0 var(--padding-lgi-3);
  font-size: var(--font-size-smi);
}
.asen-parent,
.krasimira {
  flex-direction: column;
}
.krasimira {
  height: 236px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  padding: var(--padding-161xl) var(--padding-base) var(--padding-2xl) var(--padding-5xl);
}
.asen-parent {
  gap: var(--gap-24xl);
}
.asen-parent,
.layer,
.mask3 {
  align-items: flex-start;
}
.mask3 {
  width: 249px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-36xl);
}
.layer {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-xs);
}
.container3,
.layer,
.panel,
.slide {
  display: flex;
  max-width: 100%;
}
.slide {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-mid);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
}
.container3,
.panel {
  justify-content: flex-start;
}
.panel {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-77xl);
}
.container3 {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-37xl) var(--padding-23xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1325px) {
  .layer,
  .page {
    flex-wrap: wrap;
  }
  .container3 {
    padding-left: var(--padding-9xl);
    padding-right: var(--padding-9xl);
    padding-bottom: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .panel {
    gap: 48px 96px;
  }
  .container3 {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .dobri,
  .georgi,
  .georgi-keranov,
  .hristina,
  .radosalv,
  .yavor {
    padding-top: var(--padding-106xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .dobri,
  .georgi-keranov,
  .hristina,
  .radosalv,
  .yavor {
    padding-top: var(--padding-97xl);
  }
  .dobri,
  .georgi-keranov,
  .radosalv,
  .yavor {
    padding-top: var(--padding-104xl);
  }
  .dobri,
  .georgi-keranov,
  .radosalv {
    padding-top: var(--padding-103xl);
  }
  .dobri,
  .georgi-keranov {
    padding-top: var(--padding-98xl);
  }
  .dobri {
    padding-top: var(--padding-81xl);
  }
  .error-handler10 {
    gap: 21px 43px;
  }
  .asen,
  .boryana,
  .dean,
  .maria {
    padding-top: var(--padding-104xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .asen,
  .dean {
    padding-top: var(--padding-97xl);
  }
  .mask1 {
    gap: 21px 43px;
  }
  .dimitri {
    padding-top: var(--padding-104xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .daren,
  .radostin {
    padding-top: var(--padding-82xl);
    box-sizing: border-box;
  }
  .daren {
    padding-top: var(--padding-99xl);
  }
  .dimitri-parent {
    gap: 21px 43px;
  }
  .mask2 {
    gap: 27px 55px;
  }
  .asen1,
  .irena,
  .krasimira {
    padding-top: var(--padding-104xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .krasimira {
    padding-top: var(--padding-98xl);
  }
  .panel {
    gap: 24px 96px;
  }
}
