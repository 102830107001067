.carousel,
.omnichannel-mastery {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.carousel {
  line-height: 38px;
  display: inline-block;
  min-width: 36px;
}
.omnichannel-mastery {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  line-height: 51px;
  font-family: inherit;
}
.blank-line,
.from-viber-and,
.releva-doesnt-stop {
  margin: 0;
}
.button-name17,
.from-viber-and-container {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.from-viber-and-container {
  align-self: stretch;
}
.button-name17 {
  width: 110px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button19 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
  text-decoration: none;
}
.gallery {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--headings-h3-semi-bold-size);
}
.gallery,
.tool-tip,
.wizard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tool-tip {
  align-self: stretch;
  gap: var(--gap-52xl);
}
.wizard {
  /* width: 518px; */
  padding: var(--padding-21xl-1) 0 0;
  box-sizing: border-box;
  /* min-width: 518px;
  max-width: 100%; */
}
.icon-new-white-1 {
  width: 90px;
  height: 94.9px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.icon-new-white-1-parent,
.logo-icon-white {
  align-self: stretch;
  overflow: hidden;
  flex-shrink: 0;
}
.logo-icon-white {
  height: 40px;
  position: relative;
  max-width: 100%;
}
.icon-new-white-1-parent {
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-36xl) var(--padding-34xl-3) var(--padding-37xl-9) var(--padding-33xl);
  gap: var(--gap-smi);

  z-index: 1;
}
.frame-wrapper27 {
  width: 204.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-2xl-3);
  box-sizing: border-box;
}
.vector-icon10 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.path54-icon {
  width: 83.8px;
  height: 84.2px;
  position: relative;
  margin: 0 !important;
  top: -54px;
}
.whatsapp {
  margin: 0 !important;
  position: absolute;
  top: 177px;
  left: 37px;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 28px;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.path54-parent {
  width: 204.3px;
  height: 259.8px;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-35xl-8) var(--padding-18xl-3) var(--padding-35xl-8) var(--padding-18xl);
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.frame-parent65,
.relava-symbol-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 var(--padding-13xl-8);
}
.frame-parent65 {
  width: 204.3px;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-37xl) var(--padding-30xl-7) var(--padding-42xl-7);
  box-sizing: border-box;
  gap: var(--gap-4xl);
  z-index: 1;
}
.frame-icon1,
.vector-parent4 {
  position: relative;
  flex-shrink: 0;
}
.vector-parent4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-16xl-1) var(--padding-xl) var(--padding-2xl-3) var(--padding-2xl);
  gap: var(--gap-mini-2);

  margin-left: -182.7px;
}
.frame-icon1 {
  height: 82px;
  width: 82px;
  overflow: hidden;
}
.frame-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xl) 0 var(--padding-7xl-5);
}
.facebook-wordmark-logo-brandlo-icon {
  align-self: stretch;
  height: 24.1px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent66,
.frame-wrapper28 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent66 {
  align-self: stretch;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  padding: var(--padding-47xl) var(--padding-15xl-3) var(--padding-37xl-7) var(--padding-16xl);
  gap: var(--gap-12xl);
  flex-shrink: 0;

  z-index: 1;
}
.frame-wrapper28 {
  height: 553.1px;
  width: 204.3px;
  margin-left: -182.7px;
}
.frame-parent64,
.section-051,
.steps {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.frame-parent64 {
  /* width: 637.3px; */
  align-items: flex-end;
  justify-content: flex-end;
  /* min-width: 637.2999999999993px; */
  /* font-size: var(--font-size-7xl);
  color: var(--color-mediumspringgreen); */
}
.section-051,
.steps {
  align-items: flex-start;
  box-sizing: border-box;
}
.section-051 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  justify-content: space-between;
  padding: var(--padding-69xl-9) var(--padding-82xl-7) var(--padding-69xl-9) var(--padding-116xl);
  gap: var(--gap-xl);
}
.steps {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-45xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1550px) {
  .frame-parent64,
  .wizard {
    flex: 1;
  }
  .section-051 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1250px) {
  .frame-parent64,
  .wizard {
    min-width: 100%;
  }
  .section-051,
  .steps {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-32xl);
    box-sizing: border-box;
  }
  .steps {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
  }
}
@media screen and (max-width: 825px) {
  .carousel {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .omnichannel-mastery {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .tool-tip {
    gap: 35px 71px;
  }
  .wizard {
    padding-top: var(--padding-7xl);
    box-sizing: border-box;
  }
  .vector-parent4 {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .section-051 {
    padding: var(--padding-39xl) var(--padding-6xl) var(--padding-39xl) var(--padding-14xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .carousel {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .omnichannel-mastery {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .tool-tip {
    gap: 18px 71px;
  }
  .whatsapp {
    font-size: var(--font-size-2xl);
    line-height: 22px;
  }
  .section-051 {
    padding-top: var(--padding-19xl);
    padding-bottom: var(--padding-19xl);
    box-sizing: border-box;
  }
}
