.img-4947-4-1-parent {
  align-self: stretch;
  margin-bottom: 50px;
  position: relative;
  font-size: var(--font-size-17xl);
  color: var(--w-background);
}
.releva-ai-hub .parent6,
.releva-ai-hub .customize-your-generated {
  height: auto;
}
.releva-ai-hub .call-stack {
  padding: 0;
}
.ai-hub .clip-path-group.heroImage {
  height: 48px;
}
.img-4947-4-1-parent .AIHubHolder {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.img-4947-4-1-parent .AIHubHolder h1 {
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-size: var(--font-size-17xl);
  color: #faeadf;
  margin-bottom: -30px;
}
.ai-hub {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.clip-path-group-parent {
  /* position: absolute; */
  /* height: 8.95%;
  width: 36.47%;
  top: 83.12%;
  right: 26.34%;
  bottom: 7.93%;
  left: 37.19%; */
  font-size: 68px;
}
.img-4947-4-1 {
  width: 100%;
  object-fit: cover;
}
.call-stack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs);
  box-sizing: border-box;
  margin: 50px 0;
}
.releva-ai-hub .image-118-icon {
  height: auto;
  flex: 1;
  position: initial;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.block-statement {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1728px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.image-119-icon.empowering {
  border-radius: var(--br-5xs);
  width: 100%;
}
.releva-ai-hub .frame-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-8xl);
  width: 100%;
}
.releva-ai-hub .block-8 {
  margin-top: 150px;
  background-color: transparent;
}
.releva-ai-hub .frame-child3,
.releva-ai-hub .frame-container,
.releva-ai-hub .frame-wrapper {
  align-self: stretch;
  max-width: 100%;
  justify-content: center;
}
.awardsHolder {
  padding: 0 50px;
}
.array-operator {
  margin-top: 150px;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-13xl);
  box-sizing: border-box;
}
.variable-update {
  width: 50%;
  padding: var(--padding-50xl) 0 0;
  box-sizing: border-box;
  min-width: 50%;
}
.do-you-know {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  font-size: 20px;
}
.do-you-have {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  font-size: 20px;
}
.struggling-to-persuade {
  /* width: 384px; */
  height: 38px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  font-size: 20px;
}
.releva-ai-hub .global-scope {
  margin: 150px 0;
}

.releva-icon,
.symbol-icon {
  height: 31.7px;
  width: 12.8px;
  position: relative;
  flex-shrink: 0;
  debug_commit: 1de1738;
}
.releva-icon {
  height: 27.2px;
  width: 113.7px;
}
.releva-ai-hub .releva-logo {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-smi-8);
}

.menu-inner,
.releva-ai-hub .releva-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.releva-ai-hub .releva-logo-wrapper {
  align-self: stretch;
  flex-direction: row;
}
.menu-inner {
  width: 207px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
}
.item-name {
  width: 66px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 66px;
}
.menu-item-link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name1 {
  width: 77px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 77px;
}
.menu-item-link1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name2 {
  width: 91px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 91px;
}
.menu-item-link2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name3 {
  width: 88px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 88px;
}
.menu-item-link3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name4 {
  width: 58px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 58px;
}
.menu-item-link4 {
  flex-direction: row;
  justify-content: flex-start;
}
.center-items,
.input-filter,
.menu-item-link4 {
  display: flex;
  align-items: flex-start;
}
.center-items {
  margin: 0;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--padding-2xl);
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.input-filter {
  width: 630px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.item-name5 {
  width: 57px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 57px;
  white-space: nowrap;
}
.menu-item-link-wrapper,
.menu-item-link5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu-item-link-wrapper {
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
}
.button-name {
  width: 110px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 110px;
}
.button {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  color: var(--web-secondary-ivory-light);
}
.button,
.items-left,
.menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.items-left {
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  gap: var(--gap-base);
}
.menu {
  width: 1600px;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.image-117-icon,
.menu-wrapper {
  align-self: stretch;
  max-width: 100%;
}
.menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.image-117-icon {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.youve-never-created {
  position: absolute;
  top: 699px;
  left: 329px;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  display: inline-block;
  width: 1256px;
  height: 84.3px;
}
.clip-path-group {
  /* position: absolute; */
  /* height: 48.4%;
  width: 22.68%;
  top: 25.62%;
  right: 67.92%;
  bottom: 25.98%;
  left: 9.4%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%; */
}
.ai-hub {
  position: initial;
  top: 0;
  left: 0;
  letter-spacing: -0.04em;
  line-height: 48px;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #faeadf;
  font-size: 48px;
}
.clip-path-group-parent {
  /* position: absolute;
  height: 8.95%;
  width: 36.47%;
  top: 83.12%;
  right: 26.34%;
  bottom: 7.93%;
  left: 37.19%; */
  font-size: 68px;
}
.relevas-ai-hub {
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h1-semi-bold-size);
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
}
.create-document-collaborate {
  width: 625px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.break-point .button1 .button-name1 {
  width: auto;
}
.switch-case-ai {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.button-name1 {
  width: 172px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.break-point,
.button1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button1 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
}
.break-point {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  color: var(--web-secondary-ivory-light);
}
.else-statement,
.while-loop {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.while-loop {
  width: 1221px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.else-statement {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.call-stack,
.group-icon {
  align-self: stretch;
  max-width: 100%;
}
.group-icon {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
}
.craft-messages-that,
.language-and-channel {
  position: absolute;
  top: 25px;
  left: 0;
  letter-spacing: -0.02em;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  width: 223px;
}
.language-and-channel {
  top: 0;
  font-size: var(--body-large-semi-bold-size);
  line-height: 18px;
  font-weight: 800;
}
.craft-messages-that-resonate-a-parent {
  height: 65px;
  width: 223px;
  position: relative;
}
.heroimage-inner {
  width: 275.5px;
  margin: 0 !important;
  position: absolute;
  top: 143.4px;
  left: 434.2px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-xs);
  box-sizing: border-box;
  z-index: 2;
}
.transform-your-marketing,
.will-be-the {
  position: absolute;
  top: 64.4px;
  left: 0;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  width: 334px;
}
.transform-your-marketing {
  top: 0;
  font-size: var(--body-large-semi-bold-size);
  line-height: 26px;
  font-weight: 800;
  width: 291px;
  height: 40px;
}
.will-be-the-year-of-general-ai-parent {
  height: 112.5px;
  width: 334px;
  position: relative;
  max-width: 100%;
}
.heroimage-child {
  width: 382.8px;
  height: 152px;
  margin: 0 !important;
  position: absolute;
  top: 58.4px;
  left: 1167.8px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-xs);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 3;
}
.align-your-content,
.brand-identity {
  position: absolute;
  top: 25px;
  left: 0;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  width: 229px;
}
.brand-identity {
  top: 0;
  font-size: var(--body-large-semi-bold-size);
  line-height: 18px;
  font-weight: 800;
}
.align-your-content-with-your-b-parent {
  height: 73px;
  width: 229px;
  position: relative;
}
.frame-div {
  width: 266.7px;
  margin: 0 !important;
  position: absolute;
  top: 466.4px;
  left: 434.2px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-xs);
  box-sizing: border-box;
  z-index: 4;
}
.audience,
.know-your-audience {
  position: absolute;
  top: 25px;
  left: 0;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  width: 242px;
}
.audience {
  top: 0;
  font-size: var(--body-large-semi-bold-size);
  line-height: 18px;
  font-weight: 800;
  width: 165px;
}
.know-your-audience-speak-thei-parent {
  height: 73px;
  width: 242px;
  position: relative;
}
.heroimage-inner1 {
  width: 301px;
  margin: 0 !important;
  position: absolute;
  top: 528.4px;
  left: 1146.8px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-xs);
  box-sizing: border-box;
  z-index: 5;
}
.frame-icon,
.heroimage {
  position: relative;
  display: none;
  max-width: 100%;
}
.heroimage {
  width: 1913px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 788px;
  text-align: left;
  font-size: var(--body-default-semi-bold-size);
}
.frame-icon {
  width: 789px;
  max-height: 100%;
  overflow: hidden;
  object-fit: contain;
}
.frame-child {
  position: absolute;
  top: calc(50% + 203.5px);
  left: calc(50% - 338.5px);
  border-radius: var(--br-5xs);
  width: 677px;
  height: 408px;
  overflow: hidden;
}
.channel,
.users-preferred {
  margin: 0;
}
.users-preferred-channel-container {
  position: absolute;
  top: 94px;
  left: calc(50% - 94px);
  letter-spacing: -0.04em;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  width: 186px;
}
.vuesaxlineardirect-up-icon {
  position: absolute;
  top: 15.6px;
  left: calc(50% - 21px);
  width: 42.4px;
  height: 42.4px;
}
.frame-item,
.group-1-6,
.mouse-square-icon {
  position: absolute;
  top: 57px;
  left: 28px;
  width: 157px;
  height: 137px;
  overflow: hidden;
}
.frame-item,
.mouse-square-icon {
  height: 21.71%;
  width: 18.95%;
  top: 21.45%;
  right: 42.35%;
  bottom: 56.84%;
  left: 38.7%;
  max-width: 100%;
  max-height: 100%;
}
.mouse-square-icon {
  height: 45.39%;
  width: 36%;
  top: 14.47%;
  right: 29%;
  bottom: 40.13%;
  left: 35%;
}
.users-preferred-channel-parent {
  position: absolute;
  top: 229px;
  left: 382px;
  border-radius: 11.97px;
  background-color: var(--color-plum);
  width: 200px;
  height: 152px;
  overflow: hidden;
}
.language,
.select-suitable {
  margin: 0;
}
.select-suitable-language-container {
  position: absolute;
  top: 101px;
  left: calc(50% - 47.8px);
  letter-spacing: -0.04em;
  line-height: 18px;
  font-weight: 600;
}
.vuesaxlineardirect-up-icon1 {
  position: absolute;
  top: 15.6px;
  left: calc(50% - 20.8px);
  width: 42.4px;
  height: 42.4px;
}
.language-circle-1-icon,
.select-suitable-language-parent {
  position: absolute;
  top: 22px;
  left: 32px;
  width: 71px;
  height: 71px;
  overflow: hidden;
}
.select-suitable-language-parent {
  top: 423px;
  left: 515px;
  border-radius: 11.97px;
  background-color: var(--brand-lemon);
  width: 133.6px;
  height: 152px;
}
.frame-inner,
.rectangle-div {
  position: absolute;
  top: 93px;
  left: 8px;
  border-radius: 28.26px;
  background-color: var(--color-seashell-100);
  width: 78px;
  height: 7px;
}
.rectangle-div {
  top: 103px;
  width: 50px;
}
.order-now {
  height: 8px;
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
  display: inline-block;
}
.order-now-wrapper,
.rectangle-parent {
  position: absolute;
  border-radius: var(--br-9xs);
}
.order-now-wrapper {
  top: 120px;
  left: 26px;
  background-color: var(--color-plum);
  width: 42px;
  height: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-10xs);
  box-sizing: border-box;
  white-space: nowrap;
}
.rectangle-parent {
  top: 143px;
  left: 29px;
  background-color: var(--web-secondary-ivory-light);
  width: 94px;
  height: 140px;
  overflow: hidden;
}
.frame-child1,
.frame-child2 {
  position: absolute;
  top: 96px;
  left: 8px;
  border-radius: 28.26px;
  background-color: var(--color-seashell-100);
  width: 78px;
  height: 7px;
}
.frame-child2 {
  top: 106px;
  width: 50px;
}
.order-now1 {
  height: 8px;
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
  display: inline-block;
}
.order-now-container,
.rectangle-group {
  position: absolute;
  border-radius: var(--br-9xs);
}
.order-now-container {
  top: 121px;
  left: 26px;
  background-color: var(--color-plum);
  width: 42px;
  height: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-10xs);
  box-sizing: border-box;
  white-space: nowrap;
}
.rectangle-group {
  top: 143px;
  left: 133px;
  background-color: var(--web-secondary-ivory-light);
  width: 94px;
  height: 140px;
  overflow: hidden;
}
.shoes-heels-and-jewelry-stylis-icon,
.shoes-heels-and-jewelry-stylis-icon1 {
  position: absolute;
  top: 129px;
  left: -3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.shoes-heels-and-jewelry-stylis-icon1 {
  top: 113px;
  left: 26px;
}
.mohammad-metri-e-0on3vgrbc-uns-icon {
  position: absolute;
  top: 137px;
  left: 115px;
  width: 119px;
  height: 119px;
  object-fit: cover;
}
.see-all-offers {
  height: 12px;
  flex: 1;
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
}
.releva-ai-hub .get-demo {
  position: absolute;
  top: 304px;
  left: 72px;
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  width: 109px;
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs-3) var(--padding-3xl);
  box-sizing: border-box;
  white-space: nowrap;
  font-size: var(--font-size-3xs);
  color: var(--brand-ivory);
}
.frame-group,
.group-child {
  position: absolute;
  left: 0;
  overflow: hidden;
}
.frame-group {
  top: -6px;
  border-radius: 12.76px;
  background-color: var(--color-seashell-100);
  width: 251px;
  height: 347px;
}
.group-child {
  top: 0;
  border-radius: var(--br-81xl);
  background-color: var(--brand-periwinkle);
  width: 100%;
  height: 100%;
}
.p14-inner {
  position: absolute;
  top: -8px;
  left: 208px;
  width: 98px;
  height: 98px;
}
.stephanie-liverani-zz5lqe-vsmy-icon {
  position: absolute;
  top: 0;
  left: 203px;
  width: 107px;
  height: 107px;
  object-fit: cover;
}
.hi-jane {
  position: absolute;
  top: -48px;
  left: 21px;
  font-size: var(--body-large-semi-bold-size);
  letter-spacing: -0.02em;
  line-height: 30.19px;
  font-weight: 600;
}
.come-check-them-out,
.we-selected-special {
  margin: 0;
}
.we-selected-special-container {
  position: absolute;
  top: -93px;
  left: 21px;
  font-size: var(--small-600-size);
  letter-spacing: -0.01em;
  display: inline-block;
  width: 336px;
  height: 42px;
}
.p14 {
  position: absolute;
  top: 430px;
  left: 94px;
  width: 357px;
  height: 353px;
  text-align: left;
  font-size: var(--font-size-6xs);
}
.frame-parent {
  width: 789px;
  height: 501px;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--brand-periwinkle);
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  max-width: 100%;
}
.achievements-recognition {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-size: var(--headings-h1-semi-bold-size);
}
.relevas-global-impact {
  /* width: 527px; */
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.arithmetic-operator {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.achievements-recognition-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  /* max-width: 100%; */
}
.frame-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.frame-child3 {
  height: 223px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.why-your-ecommerce {
  /* width: 1002px; */
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  font-size: var(--headings-h1-semi-bold-size);
}
.function-define {
  width: 1306px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}

.convey-in-the {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.struggling-to-persuade-your-au-parent {
  align-self: stretch;
  border-radius: var(--br-xs);
  /* background-color: var(--brand-ivory); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  max-width: 100%;
}
.expression-list {
  width: 432px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  max-width: 100%;
}
.interactiveHeading1:hover,
.interactiveHeading2:hover,
.interactiveHeading3:hover {
  cursor: pointer;
  background-color: var(--brand-ivory);
}

.interactiveText.text2,
.interactiveText.text3,
.slider2,
.slider3 {
  display: none;
}
.variable-update {
  padding: 0;
}
.expression-list1,
.statement-list,
.variable-update {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.expression-list1 {
  width: 432px;
  flex-direction: row;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
}
.statement-list,
.variable-update {
  flex-direction: column;
}
.statement-list {
  align-self: stretch;
  gap: 48.5px;
}

.image-116-icon {
  height: 501px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  min-width: 513px;
}
.variable-declare {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: 88px;
  text-align: left;
  font-size: var(--body-large-semi-bold-size);
}
.array-operator,
.object-operator,
.variable-declare {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.object-operator {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 74px;
}
.global-scope {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frame-child4 {
  position: absolute;
  top: calc(50% + 203.5px);
  left: calc(50% - 338.5px);
  border-radius: var(--br-5xs);
  width: 677px;
  height: 408px;
  overflow: hidden;
}
.channel1,
.users-preferred1 {
  margin: 0;
}
.users-preferred-channel-container1 {
  position: absolute;
  top: 94px;
  left: calc(50% - 94px);
  letter-spacing: -0.04em;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  width: 186px;
}
.vuesaxlineardirect-up-icon2 {
  position: absolute;
  top: 15.6px;
  left: calc(50% - 21px);
  width: 42.4px;
  height: 42.4px;
}
.group-1-61 {
  position: absolute;
  top: 57px;
  left: 28px;
  width: 157px;
  height: 137px;
  overflow: hidden;
}
.frame-child5,
.mouse-square-icon1 {
  position: absolute;
  height: 21.71%;
  width: 18.95%;
  top: 21.45%;
  right: 42.35%;
  bottom: 56.84%;
  left: 38.7%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mouse-square-icon1 {
  height: 45.39%;
  width: 36%;
  top: 14.47%;
  right: 29%;
  bottom: 40.13%;
  left: 35%;
}
.users-preferred-channel-group {
  position: absolute;
  top: 229px;
  left: 382px;
  border-radius: 11.97px;
  background-color: var(--color-plum);
  width: 200px;
  height: 152px;
  overflow: hidden;
}
.language1,
.select-suitable1 {
  margin: 0;
}
.select-suitable-language-container1 {
  position: absolute;
  top: 101px;
  left: calc(50% - 47.8px);
  letter-spacing: -0.04em;
  line-height: 18px;
  font-weight: 600;
}
.vuesaxlineardirect-up-icon3 {
  position: absolute;
  top: 15.6px;
  left: calc(50% - 20.8px);
  width: 42.4px;
  height: 42.4px;
}
.language-circle-1-icon1,
.select-suitable-language-group {
  position: absolute;
  top: 22px;
  left: 32px;
  width: 71px;
  height: 71px;
  overflow: hidden;
}
.select-suitable-language-group {
  top: 423px;
  left: 515px;
  border-radius: 11.97px;
  background-color: var(--brand-lemon);
  width: 133.6px;
  height: 152px;
}
.frame-child6,
.frame-child7 {
  position: absolute;
  top: 93px;
  left: 8px;
  border-radius: 28.26px;
  background-color: var(--color-seashell-100);
  width: 78px;
  height: 7px;
}
.frame-child7 {
  top: 103px;
  width: 50px;
}
.order-now2 {
  height: 8px;
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
  display: inline-block;
}
.order-now-frame,
.rectangle-container {
  position: absolute;
  border-radius: var(--br-9xs);
}
.order-now-frame {
  top: 120px;
  left: 26px;
  background-color: var(--color-plum);
  width: 42px;
  height: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-10xs);
  box-sizing: border-box;
  white-space: nowrap;
}
.rectangle-container {
  top: 143px;
  left: 29px;
  background-color: var(--web-secondary-ivory-light);
  width: 94px;
  height: 140px;
  overflow: hidden;
}
.frame-child8,
.frame-child9 {
  position: absolute;
  top: 96px;
  left: 8px;
  border-radius: 28.26px;
  background-color: var(--color-seashell-100);
  width: 78px;
  height: 7px;
}
.frame-child9 {
  top: 106px;
  width: 50px;
}
.order-now3 {
  height: 8px;
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
  display: inline-block;
}
.order-now-wrapper1,
.rectangle-parent1 {
  position: absolute;
  border-radius: var(--br-9xs);
}
.order-now-wrapper1 {
  top: 121px;
  left: 26px;
  background-color: var(--color-plum);
  width: 42px;
  height: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-10xs);
  box-sizing: border-box;
  white-space: nowrap;
}
.rectangle-parent1 {
  top: 143px;
  left: 133px;
  background-color: var(--web-secondary-ivory-light);
  width: 94px;
  height: 140px;
  overflow: hidden;
}
.shoes-heels-and-jewelry-stylis-icon2,
.shoes-heels-and-jewelry-stylis-icon3 {
  position: absolute;
  top: 129px;
  left: -3px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.shoes-heels-and-jewelry-stylis-icon3 {
  top: 113px;
  left: 26px;
}
.mohammad-metri-e-0on3vgrbc-uns-icon1 {
  position: absolute;
  top: 137px;
  left: 115px;
  width: 119px;
  height: 119px;
  object-fit: cover;
}
.see-all-offers1 {
  height: 12px;
  flex: 1;
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
}
.get-demo1 {
  position: absolute;
  top: 304px;
  left: 72px;
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  width: 109px;
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs-3) var(--padding-3xl);
  box-sizing: border-box;
  white-space: nowrap;
  font-size: var(--font-size-3xs);
  color: var(--brand-ivory);
}
.frame-parent2,
.group-item {
  position: absolute;
  left: 0;
  overflow: hidden;
}
.frame-parent2 {
  top: -6px;
  border-radius: 12.76px;
  background-color: var(--color-seashell-100);
  width: 251px;
  height: 347px;
}
.group-item {
  top: 0;
  border-radius: var(--br-81xl);
  background-color: var(--brand-periwinkle);
  width: 100%;
  height: 100%;
}
.p14-child {
  position: absolute;
  top: -8px;
  left: 208px;
  width: 98px;
  height: 98px;
}
.stephanie-liverani-zz5lqe-vsmy-icon1 {
  position: absolute;
  top: 0;
  left: 203px;
  width: 107px;
  height: 107px;
  object-fit: cover;
}
.hi-jane1 {
  position: absolute;
  top: -48px;
  left: 21px;
  font-size: var(--body-large-semi-bold-size);
  letter-spacing: -0.02em;
  line-height: 30.19px;
  font-weight: 600;
}
.come-check-them-out1,
.we-selected-special1 {
  margin: 0;
}
.we-selected-special-container1 {
  position: absolute;
  top: -93px;
  left: 21px;
  font-size: var(--small-600-size);
  letter-spacing: -0.01em;
  display: inline-block;
  width: 336px;
  height: 42px;
}
.p141 {
  position: absolute;
  top: 430px;
  left: 94px;
  width: 357px;
  height: 353px;
  text-align: left;
  font-size: var(--font-size-6xs);
}
.frame-parent1 {
  width: 789px;
  height: 501px;
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--brand-periwinkle);
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  max-width: 100%;
}
.seamless-integration-and {
  width: 600px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 188%;
}
.seamless-integration-and-easy-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-smi) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.we-are-extremely {
  width: 626px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 189%;
  flex-shrink: 0;
}
.frame-parent3 {
  width: 331.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  max-width: 100%;
}
.frame-child10 {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 76.43px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frame-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-58xl) 0 var(--padding-54xl);
}
.andreas-schneider,
.mercari {
  margin: 0;
}
.andreas-schneider-mercari-container {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
}
.frame-parent5,
.frame-wrapper1 {
  display: flex;
  align-items: flex-start;
}
.frame-parent5 {
  width: 214px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frame-wrapper1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-225xl) 0 var(--padding-182xl);
}
.button-name2 {
  width: 136px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.button2 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  white-space: nowrap;
  color: var(--web-secondary-ivory-light);
}
.frame-parent4,
.testimonial-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent4 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-9xs);
  font-size: var(--body-small-regular-size);
}
.testimonial-left {
  margin-left: -947px;
  width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  flex-shrink: 0;
  align-items: flex-start;
  padding: var(--padding-28xl) var(--padding-22xl) var(--padding-6xl) var(--padding-359xl-5);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  debug_commit: 1de1738;
}
.value-added-product {
  width: 600px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  display: inline-block;
  max-width: 174%;
  flex-shrink: 0;
}
.i-hope-releva {
  width: 626px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
  max-width: 192%;
}
.i-hope-releva-becomes-a-big-co-wrapper,
.value-added-product-helping-yo-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.i-hope-releva-becomes-a-big-co-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-lgi);
  font-size: var(--body-default-semi-bold-size);
}
.value-added-product-helping-yo-parent {
  flex-direction: column;
  padding: 0;
  gap: var(--gap-xs);
}
.image-2-icon,
.image-3-icon {
  position: absolute;
  left: 0;
  max-height: 100%;
  object-fit: cover;
}
.image-2-icon {
  height: calc(100% - 1.5px);
  top: 0.1px;
  bottom: 1.4px;
  width: 64.2px;
}
.image-3-icon {
  height: 100%;
  top: 0;
  bottom: 0;
  width: 65.2px;
  z-index: 1;
}
.image-2-parent {
  height: 97.7px;
  width: 47.7px;
  position: absolute;
  margin: 0 !important;
  bottom: -21.4px;
  left: -0.2px;
}
.frame-wrapper3,
.frame-wrapper4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper4 {
  height: 64px;
  width: 64px;
  border-radius: 76.43px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}
.frame-wrapper3 {
  align-self: stretch;
  padding: 0 0 0 var(--padding-54xl);
}
.andreas-schneider1,
.mercari1 {
  margin: 0;
}
.andreas-schneider-mercari-container1 {
  width: 214px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.frame-parent6 {
  width: 120.5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: var(--gap-5xs);
  font-size: var(--body-small-regular-size);
}
.frame-parent6,
.testimonial-right,
.testimonials,
.tree-root {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.testimonial-right {
  width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-28xl) var(--padding-359xl-5) var(--padding-67xl) var(--padding-46xl);
  gap: var(--gap-13xl);
  debug_commit: 1de1738;
}
.testimonials,
.tree-root {
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.testimonials {
  width: 1920.5px;
  overflow-x: auto;
  flex-shrink: 0;
  padding: var(--padding-4xl) var(--padding-546xl) 0 var(--padding-547xl);
  gap: var(--gap-138xl-5);
  text-align: center;
  font-size: var(--headings-h3-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.tree-root {
  padding: 0 var(--padding-11xs) 0 var(--padding-10xs-5);
}
.try-releva-ai {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.button-name3 {
  width: 71px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 71px;
}
.button3,
.expression-tree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button3 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
}
.expression-tree {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
  font-size: var(--body-default-semi-bold-size);
  color: var(--w-background);
}
.global-variable-table {
  width: 876px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-16xl);
  max-width: 100%;
}
.local-variable-table {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  margin: 200px 0;
}
.how-releva-ai,
.releva-ai-hub1 {
  align-self: stretch;
  position: relative;
}
.how-releva-ai {
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.releva-ai-hub1 {
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.title {
  width: 667px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.brand-identity1 {
  width: 118px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 118px;
  white-space: nowrap;
}
.brand-identity-wrapper:hover,
.create-content-wrapper:hover,
.generated-content-wrapper:hover,
.ai-chat-wrapper:hover {
  background-color: var(--brand-ivory);
  border-radius: var(--br-5xs);
}
.brand-identity-wrapper,
.create-content-wrapper,
.generated-content-wrapper,
.ai-chat-wrapper {
  padding: var(--padding-5xs) var(--padding-base);
}
.brand-identity-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}
.create-content {
  width: 129px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 129px;
  white-space: nowrap;
}
.create-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: var(--padding-5xs) 0; */
}
.generated-content {
  width: 161px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  white-space: nowrap;
}
.generated-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: var(--padding-5xs) 0; */
}
.mail-and-push .menu-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.releva-ai-hub.socialMediaPage .parent6,
.releva-ai-hub.socialMediaPage .parent6 .customize-your-generated {
  height: auto;
}
.omni-personalization .howitworks3-ai-wrapper,
.omni-personalization .howitworks1-wrapper {
  margin-bottom: 50px;
}
.omni-personalization .title,
.omni-personalization .menu-items,
.omni-personalization .ai-chat,
.omni-personalization .generated-content,
.omni-personalization .create-content,
.omni-personalization .brand-identity1 {
  width: auto;
}
.ai-chat {
  width: 59px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 59px;
  white-space: nowrap;
}
.ai-chat-wrapper,
.menu-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.ai-chat-wrapper {
  justify-content: flex-start;
  /* padding: var(--padding-5xs) 0; */
}
.menu-items {
  width: 679px;
  overflow-x: auto;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.howitworks,
.sub-menu {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}
.sub-menu {
  height: 92px;
  background-color: var(--web-secondary-ivory-light);
  justify-content: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--body-default-semi-bold-size);
  margin-bottom: 50px;
}
.howitworks {
  justify-content: center;
  gap: var(--gap-lg);
}
.div {
  position: relative;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  display: inline-block;
  min-width: 36px;
}
.wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.description-and-persona,
.extract-your-brand {
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  display: inline-block;
}
.extract-your-brand {
  align-self: stretch;
  /* height: 66px; */
  font-size: var(--headings-h3-semi-bold-size);
  font-weight: 600;
  flex-shrink: 0;
}
.description-and-persona {
  flex: 1;
  font-weight: 500;
  max-width: 100%;
}
.description-and-persona-for-co-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 var(--padding-11xs-5);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-9xl);
}
.simply-provide-your {
  align-self: stretch;
  height: 66px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
}
.frame-parent7-ai,
.howitworks1-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent7-ai {
  align-self: stretch;
  gap: var(--gap-mid);
}
.howitworks1-inner {
  width: 611px;
  padding: 0 0 var(--padding-115xl);
  box-sizing: border-box;
  min-width: 611px;
  min-height: 457px;
}
.body-icon,
.buttons-icon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.body-icon {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0.96%;
  bottom: 0;
  left: 1.03%;
  object-fit: cover;
}
.buttons-icon {
  height: 22.08%;
  width: 99.52%;
  top: 18.1%;
  right: 0.48%;
  bottom: 59.82%;
  left: 0;
}
.rectangle {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
}
.front-camera {
  position: absolute;
  height: 3.32%;
  width: 25.33%;
  top: 4.52%;
  right: 37.21%;
  bottom: 92.15%;
  left: 37.45%;
}
.brand,
.tone {
  margin: 0;
}
.brand-tone {
  position: absolute;
  top: 76px;
  left: calc(50% - 31.8px);
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlineardirect-up-icon4 {
  position: absolute;
  top: 15.6px;
  left: calc(50% - 20.8px);
  width: 42.4px;
  height: 42.4px;
}
.brand-tone-parent,
.voice-cricle-1-icon {
  position: absolute;
  top: 24px;
  left: 41px;
  width: 47px;
  height: 47px;
  overflow: hidden;
}
.brand-tone-parent {
  top: 394px;
  left: -31px;
  border-radius: 11.97px;
  background-color: var(--brand-lemon);
  width: 133.6px;
  height: 152px;
}
.brand1,
.description {
  margin: 0;
}
.brand-description {
  position: absolute;
  top: 76px;
  left: calc(50% - 55.8px);
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.textalign-center-1-icon,
.vector-icon {
  position: absolute;
  top: 37px;
  left: 54px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.vector-icon {
  height: 25%;
  width: 28.44%;
  top: 19.74%;
  right: 36.38%;
  bottom: 55.26%;
  left: 35.18%;
  max-width: 100%;
  max-height: 100%;
}
.brand-description-parent {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 11.97px;
  background-color: var(--color-plum);
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.iphone-14-pro-inner {
  position: absolute;
  top: 41px;
  left: 251px;
  width: 133.6px;
  height: 152px;
  color: #391a4a;
}
.profile-circle-1-icon {
  width: 46px;
  height: 46px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.brand2,
.persona {
  margin: 0;
}
.brand-persona {
  align-self: stretch;
  flex: 1;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.iphone-14-pro-child,
.profile-circle-1-parent {
  position: absolute;
  display: flex;
  justify-content: flex-start;
}
.profile-circle-1-parent {
  top: 308px;
  left: 266px;
  border-radius: 11.97px;
  background-color: var(--brand-amethyst);
  height: 126px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  color: var(--w-background);
}
.iphone-14-pro-child {
  top: 212px;
  left: -98px;
  flex-direction: row;
  align-items: flex-start;
}
.iphone-14-pro {
  height: 590.1px;
  width: 292.1px;
  position: relative;
}
.p30 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.p30,
.right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.image-119-icon,
.right-wrapper {
  position: relative;
  max-width: 100%;
}
.right-wrapper {
  height: 590.1px;
  width: 483px;
  display: none;
  text-align: center;
  font-size: var(--font-size-3xl);
}
.image-119-icon {
  width: 486.5px;
  max-height: 100%;
  object-fit: cover;
}
.howitworks1,
.howitworks1-wrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.dummyLink {
  text-decoration: none;
  color: var(--brand-indigo);
}
.howitworks1 {
  border-radius: var(--br-xs);
  flex: 1;
  background-color: var(--brand-ivory);
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-13xl) var(--padding-101xl) var(--padding-13xl) 118.8px;
  mix-blend-mode: normal;
  gap: var(--gap-xl);
}
.howitworks1-wrapper {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-16xl) 0 35.5px;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
}
.create-engaging-ai-generated,
.div1-ai {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
  display: inline-block;
}
.div1-ai {
  line-height: 38px;
  min-width: 41px;
}
.create-engaging-ai-generated {
  align-self: stretch;
  height: 63.8px;
  font-size: var(--headings-h3-semi-bold-size);
  line-height: 51px;
  text-align: center;
  flex-shrink: 0;
}
.effortlessly-with-relevas {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28.8px;
  text-align: center;
}
.icon-01 {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
}
.choose-what-type {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
}
.select-your-desired {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  white-space: pre-wrap;
}
.choose-what-type-of-content-yo-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frame-parent8,
.icon-01-parent-ai {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.icon-01-parent-ai {
  width: 423px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10px var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-9xl);
}
.frame-parent8 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-43xl);
}
.conditional-split-inner {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  font-size: var(--headings-h5-semi-bold-size);
}
.conditional-split,
.conditional-split-inner,
.howitworks2 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.conditional-split {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-16xl);
}
.howitworks2 {
  width: 1427px;
  flex-direction: row;
  justify-content: center;
}
.howitworks2-wrapper,
.howitworks3-ai-wrapper {
  align-self: stretch;
  flex-direction: row;
  box-sizing: border-box;
}
.howitworks2-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  margin: 150px 0;
}
.howitworks3-ai-wrapper {
  justify-content: flex-start;
  padding: 0 var(--padding-16xl) 0 35.5px;
  margin-bottom: 150px;
}
.function-call-stack,
.howitworks3-ai-wrapper,
.loop-nesting {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.function-call-stack {
  width: 1728px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-105xl);
}
.loop-nesting {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.div2 {
  position: relative;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  display: inline-block;
  min-width: 42px;
}
.ai-chat1,
.ai-generated-content-creation {
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
}
.ai-chat1 {
  width: 640px;
  height: 27px;
  font-size: var(--headings-h3-semi-bold-size);
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.ai-generated-content-creation {
  align-self: stretch;
  font-size: var(--font-size-9xl);
  font-weight: 500;
}
.blank-line,
.discover-the-most {
  margin: 0;
}
.discover-the-most-container {
  width: 612px;
  height: 67px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.how-it-works-6-inner,
.parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.parent {
  align-self: stretch;
  gap: var(--gap-2xl);
}
.how-it-works-6-inner {
  width: 762px;
  padding: 209.2px 0 0;
  box-sizing: border-box;
  min-width: 762px;
}
.unknown-removebg-preview-1-icon {
  position: absolute;
  top: 151px;
  left: 85px;
  width: 288px;
  height: 442px;
  object-fit: cover;
}
.elevate-your-wardrobe {
  position: absolute;
  top: 5.27%;
  left: 11.26%;
  letter-spacing: -0.04em;
  line-height: 32px;
  font-weight: 600;
}
.collection-of-trendy,
.dive-into-a,
.sophistication-with-our {
  margin: 0;
}
.dive-into-a-container {
  position: absolute;
  top: 14.51%;
  left: 12.06%;
  font-size: var(--body-large-semi-bold-size);
  letter-spacing: -0.04em;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
}
.unknown-removebg-preview-1-parent {
  position: absolute;
  height: 68.52%;
  width: 53.98%;
  top: 15.81%;
  right: 26.05%;
  bottom: 15.66%;
  left: 19.97%;
  border-radius: var(--br-base-3);
  background-color: var(--brand-periwinkle);
  overflow: hidden;
  text-align: left;
}
.ai-generated,
.image-6 {
  position: absolute;
  top: 40px;
}
.image-6 {
  left: 36px;
  width: 131px;
  height: 129px;
  mix-blend-mode: normal;
}
.ai-generated {
  left: calc(50% - 67px);
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 500;
}
.tailored-to-your,
.users {
  margin: 0;
}
.images,
.tailored-to-your-container {
  position: absolute;
  top: 122px;
  left: calc(50% - 76px);
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 500;
}
.images {
  top: 81px;
  left: calc(50% - 65px);
  font-size: var(--font-size-17xl);
  font-weight: 600;
}
.image-6-parent {
  position: absolute;
  height: 31.48%;
  width: 29.52%;
  top: 0;
  right: 0;
  bottom: 68.52%;
  left: 70.48%;
  border-radius: 13.59px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  font-size: var(--font-size-4xl);
  color: var(--w-background);
}
.image-61 {
  position: absolute;
  top: 40px;
  left: 36px;
  width: 131px;
  height: 129px;
  mix-blend-mode: normal;
}
.content,
.engaging,
.generate {
  position: absolute;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.generate {
  top: 52px;
  left: calc(50% - 50px);
  white-space: pre-wrap;
}
.content,
.engaging {
  top: 134px;
  left: calc(50% - 45px);
}
.engaging {
  top: 93px;
  left: calc(50% - 80px);
  font-size: var(--headings-h4-semi-bold-size);
}
.how-it-works-02,
.image-6-group {
  position: absolute;
  bottom: 0;
  left: 0;
}
.image-6-group {
  height: 31.48%;
  width: 29.52%;
  top: 68.52%;
  right: 70.48%;
  border-radius: 13.59px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  font-size: var(--headings-h5-semi-bold-size);
}
.how-it-works-02 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}
.right1 {
  height: 664px;
  width: 691px;
  position: relative;
  display: none;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-6xl);
}
.p30-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 94px;
  top: 39px;
  transform: scale(1.647);
}
.wrapper-p30 {
  position: absolute;
  top: 45px;
  left: 378px;
  width: 292.1px;
  height: 590.1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-121-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.wrapper-p30-parent {
  height: 664.5px;
  width: 691.5px;
  position: relative;
  min-width: 691.5px;
  max-width: 100%;
}
.how-it-works-6,
.how-it-works-6-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.how-it-works-6 {
  width: 1644px;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: var(--padding-29xl) var(--padding-sm);
  gap: var(--gap-4xs);
}
.how-it-works-6-wrapper {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.ecommerce-brands {
  width: 654px;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  display: inline-block;
}
.ecommerce-brands-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.already-use-releva,
.imoteka-icon {
  align-self: stretch;
  position: relative;
}
.already-use-releva {
  font-size: var(--headings-h5-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.imoteka-icon {
  width: 153.4px;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 35px;
}
.ebag-icon {
  width: 75.5px;
}
.adres-icon,
.ebag-icon,
.mercari-icon,
.trendo-icon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 35px;
}
.trendo-icon {
  width: 82.7px;
  z-index: unset;
}
.adres-icon,
.mercari-icon {
  width: 142.6px;
}
.mercari-icon {
  width: 167.5px;
}
.data-transformer {
  width: 1337.6px;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-21xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.data-transformer-wrapper,
.frame-parent9 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.data-transformer-wrapper {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.frame-parent9 {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-6xl);
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.control-flow {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
}
.output-processor,
.split-merge {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.split-merge {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 126px;
}
.output-processor {
  align-self: stretch;
  letter-spacing: -0.03em;
  line-height: 38px;
  min-width: 27px;
}
.output-processor-wrapper,
.split-merge-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.output-processor-wrapper {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-smi) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.split-merge-parent {
  width: 154px;
  flex-direction: row;
  gap: var(--gap-12xs);
}
.increase-in-conversion {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.increase-in-conversion-rates-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-6xs-4) 0 0;
  font-size: var(--body-small-regular-size);
}
.frame-parent11 {
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div3,
.div4 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.div3 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 128px;
}
.div4 {
  align-self: stretch;
  letter-spacing: -0.03em;
  line-height: 38px;
  min-width: 27px;
}
.container,
.group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.container {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-smi) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.group {
  width: 156px;
  flex-direction: row;
  gap: var(--gap-12xs);
}
.increase-in-average {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.increase-in-average-order-valu-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-5xs-4) 0 0;
  font-size: var(--body-small-regular-size);
}
.releva-homepage .frame-parent12 .frame-parent22 {
  width: 100%;
}
.releva-homepage .benefits-section div {
  width: 100%;
}
.releva-homepage .frame-parent12 div {
  /* max-width: 1328px; */
  align-self: auto;
}
.releva-homepage .frame-parent12 {
  width: 100%;
  justify-content: center;
  align-items: center;
}
.frame-parent12 {
  width: 176px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div5,
.div6 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.div5 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 128px;
}
.div6 {
  align-self: stretch;
  letter-spacing: -0.03em;
  line-height: 38px;
  min-width: 27px;
}
.frame,
.parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.parent1 {
  width: 159px;
  flex-direction: row;
  gap: var(--gap-9xs);
}
.increase-in-sales {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.increase-in-sales-volumes-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-2xs-4) 0 0;
  font-size: var(--body-small-regular-size);
}
.frame-parent13 {
  width: 179px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div7,
.times {
  position: relative;
  font-weight: 600;
  flex-shrink: 0;
  debug_commit: 1de1738;
}
.div7 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  z-index: 1;
}
.times {
  align-self: stretch;
  letter-spacing: -0.03em;
  line-height: 38px;
  display: inline-block;
  min-width: 82px;
}
.frame-wrapper7,
.parent2,
.times-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.times-wrapper {
  width: 82px;
  flex-direction: column;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
  margin-left: -2px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-wrapper7,
.parent2 {
  flex-direction: row;
}
.parent2 {
  flex: 1;
}
.frame-wrapper7 {
  align-self: stretch;
  padding: 0 0 0 var(--padding-4xl);
}
.investment,
.return-on {
  margin: 0;
}
.return-on-investment {
  width: 168.6px;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.frame-parent14 {
  width: 226px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-parent10,
.frame-wrapper6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent10 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-wrapper6 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-11xs-5) 0 var(--padding-11xs);
  box-sizing: border-box;
}
.control-flow-parent {
  width: 1330px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 48.2px;
  max-width: 100%;
}
.frame-wrapper5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-2xl) 49.3px var(--padding-xl);
}
.frame-wrapper5,
.line-div,
.line-wrapper {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}
.line-div {
  width: 1331px;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
}
.line-wrapper {
  height: 58.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-38xl-3);
}
.try-releva-ai1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.button-name4 {
  width: 71px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 71px;
}
.button-wrapper,
.button4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button4 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
}
.button-wrapper {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--body-default-semi-bold-size);
  color: var(--w-background);
}
.try-releva-ai-hub-free-for-14-parent {
  width: 865px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-16xl);
  max-width: 100%;
}
.frame-section,
.frame-wrapper8 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frame-wrapper8 {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--headings-h2-semi-bold-size);
}
.frame-section {
  flex-direction: column;
  justify-content: flex-start;
  gap: 65.7px;
  text-align: center;
  font-size: var(--headings-big-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.releva-goes-beyond {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.it-empowers-you {
  width: 617px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.it-empowers-you-to-unlock-the-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--body-large-semi-bold-size);
}
.it-empowers-you-to-unlock-the-wrapper,
.value-combiner,
.value-combiner-wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.value-combiner {
  width: 1066px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.value-combiner-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.web-search {
  width: 423px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 20px;
  z-index: 1;
}
.title1 {
  width: 369px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  max-width: 100%;
}
.integrates-personalized-custom {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.symbol-library,
.web-search-multi-channel-pe {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.releva-ai-hub .symbol-library {
  width: 376px;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
  height: auto;
  margin: 0;
}
.web-search-multi-channel-pe {
  width: 433px;
  margin: 0 !important;
  /* position: absolute;
  top: 5px;
  left: 0; */
  flex-direction: column;
  gap: var(--gap-xs);
}
.mail-push {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 20px;
  z-index: 1;
}
.title2 {
  width: 369px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xl) 0 var(--padding-2xl) var(--padding-3xs);
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100%;
}
.is-designed-to {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.is-designed-to-enable-seamless-wrapper,
.mail-push-center {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.is-designed-to-enable-seamless-wrapper {
  width: 386px;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
}
.mail-push-center {
  width: 433px;
  margin: 0 !important;
  /* position: absolute;
  top: 5px;
  left: 433px; */
  flex-direction: column;
  gap: var(--gap-xs);
}
.message-hub1 {
  width: 423px;
  /* position: relative; */
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 20px;
  z-index: 1;
}
.title3 {
  width: 369px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xl) 0 var(--padding-2xl) var(--padding-3xs);
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100%;
}
.is-a-centralized {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.is-a-centralized-platform-desi-wrapper,
.message-hub {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.is-a-centralized-platform-desi-wrapper {
  width: 386px;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
}
.message-hub {
  width: 433px;
  margin: 0 !important;
  /* position: absolute;
  top: 5px;
  left: 866px; */
  flex-direction: column;
  gap: var(--gap-xs);
}
.social-media {
  width: 423px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 20px;
  z-index: 1;
}
.title4 {
  width: 369px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xl) 0 var(--padding-2xl) var(--padding-3xs);
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100%;
}
.enables-brands-to {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.enables-brands-to-extend-their-wrapper,
.social-media-hub {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.enables-brands-to-extend-their-wrapper {
  width: 365px;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
}
.social-media-hub {
  /* width: 433px;
  margin: 0 !important; */
  /* position: absolute;
  top: 225px;
  left: 206.5px; */
  flex-direction: column;
  gap: var(--gap-xs);
}
.cdp-for-offline,
.web-push {
  margin: 0;
}
.separator {
  margin-bottom: 150px;
}
.cdp-for-offline-container1 {
  width: 100%;
}
.cdp-for-offline-container {
  width: 443px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 20px;
  z-index: 1;
}
.title5 {
  width: 369px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  max-width: 100%;
}
.integrates-seamlessly-with {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.cdp-for-offline-shops-email,
.integrates-seamlessly-with-ema-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.integrates-seamlessly-with-ema-wrapper {
  width: 386px;
  flex-direction: row;
  padding: 0 var(--padding-6xs);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
}
.cdp-for-offline-shops-email {
  /* width: 453px;
  margin: 0 !important; */
  /* position: absolute;
  top: 225px;
  left: 639.5px; */
  flex-direction: column;
  gap: var(--gap-xs);
}
.frame-parent15,
.position-arranger {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent15 {
  width: 1299px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 74.1px;
}
.position-arranger {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
  box-sizing: border-box;
}
.simple-powerful-tool-impres-parent {
  width: 1728px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-105xl);
  max-width: 100%;
}
.releva-ai-hub-inner {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 64.9px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.releva-ai-hub-inner,
.testimonials1,
.tree-root1 {
  flex-direction: row;
  max-width: 100%;
}
.testimonials1 {
  width: 1920.5px;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-4xl) var(--padding-546xl) 0 var(--padding-547xl);
  box-sizing: border-box;
  gap: var(--gap-138xl-5);
}
.tree-root1 {
  padding: 0 var(--padding-11xs) 0 var(--padding-10xs-5);
}
.footer-wrapper,
.releva-ai-hub,
.tree-root1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.footer-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-7xs);
  max-width: 100%;
}
.releva-ai-hub {
  width: 100%;
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  padding: 0;
  gap: var(--gap-105xl);
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--body-footnote-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .howitworks1-inner,
  .image-119-icon {
    flex: 1;
  }
  .howitworks1 {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .how-it-works-6-inner,
  .wrapper-p30-parent {
    flex: 1;
  }
  .how-it-works-6 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1225px) {
  .center-items {
    display: none;
  }
  .input-filter {
    width: 0;
  }
  .variable-update {
    flex: 1;
  }
  .variable-declare {
    flex-wrap: wrap;
  }
  .block-statement {
    gap: var(--gap-43xl);
  }
  .testimonials {
    gap: var(--gap-60xl);
    padding-left: var(--padding-264xl);
    padding-right: var(--padding-263xl);
    box-sizing: border-box;
  }
  .howitworks1-inner {
    min-width: 100%;
  }
  .howitworks1,
  .howitworks1-wrapper {
    padding-left: var(--padding-40xl);
    padding-right: var(--padding-41xl);
    box-sizing: border-box;
  }
  .howitworks1-wrapper {
    padding-left: var(--padding-12xl);
    padding-right: var(--padding-12xl);
  }
  .function-call-stack {
    gap: var(--gap-43xl);
  }
  .how-it-works-6-inner {
    min-width: 100%;
  }
  .frame-parent10 {
    flex-wrap: wrap;
  }
  .frame-section {
    gap: var(--gap-14xl);
  }
  .simple-powerful-tool-impres-parent {
    gap: var(--gap-43xl);
  }
  .releva-ai-hub-inner {
    padding-bottom: var(--padding-8xl);
    box-sizing: border-box;
  }
  .testimonials1 {
    gap: var(--gap-60xl);
    padding-left: var(--padding-264xl);
    padding-right: var(--padding-263xl);
    box-sizing: border-box;
  }
  .releva-ai-hub {
    gap: var(--gap-43xl);
  }
}
@media screen and (max-width: 850px) {
  .youve-never-created {
    font-size: var(--font-size-10xl);
    line-height: 66px;
  }
  .ai-hub,
  .relevas-ai-hub {
    font-size: var(--font-size-35xl);
    line-height: 66px;
  }
  .relevas-ai-hub {
    font-size: var(--font-size-42xl);
  }
  .while-loop {
    gap: var(--gap-base);
  }
  .achievements-recognition,
  .why-your-ecommerce {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .image-116-icon,
  .variable-update {
    min-width: 100%;
  }
  .variable-declare {
    gap: var(--gap-25xl);
  }
  .object-operator {
    gap: var(--gap-18xl);
  }
  .block-statement {
    gap: var(--gap-12xl);
  }
  .seamless-integration-and {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-wrapper1 {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-103xl);
    box-sizing: border-box;
  }
  .testimonial-left {
    padding-left: var(--padding-170xl);
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .value-added-product {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .testimonial-right {
    padding: var(--padding-12xl) var(--padding-170xl) var(--padding-37xl) var(--padding-13xl);
    box-sizing: border-box;
  }
  .testimonials {
    gap: var(--gap-20xl);
    padding-left: var(--padding-122xl);
    padding-right: var(--padding-122xl);
    box-sizing: border-box;
  }
  .how-releva-ai,
  .try-releva-ai {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .div {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .extract-your-brand {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .howitworks1 {
    padding: var(--padding-2xl) var(--padding-11xl) var(--padding-2xl) var(--padding-10xl);
    box-sizing: border-box;
  }
  .div1-ai {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .create-engaging-ai-generated {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-parent8 {
    gap: var(--gap-12xl);
  }
  .conditional-split-inner {
    gap: var(--gap-37xl);
  }
  .conditional-split {
    gap: var(--gap-mid);
  }
  .function-call-stack {
    gap: var(--gap-12xl);
  }
  .div2 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .ai-chat1 {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .engaging,
  .images {
    font-size: var(--font-size-10xl);
    line-height: 19px;
  }
  .engaging {
    font-size: var(--font-size-7xl);
  }
  .wrapper-p30-parent {
    min-width: 100%;
  }
  .how-it-works-6 {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
  .ecommerce-brands {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .data-transformer {
    justify-content: center;
  }
  .split-merge {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .output-processor {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div3 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .div4 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div5 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .div6 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div7 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .times {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .control-flow-parent {
    gap: var(--gap-5xl);
  }
  .try-releva-ai1 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .frame-section {
    gap: var(--gap-base);
  }
  .releva-goes-beyond {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .frame-parent15 {
    gap: var(--gap-18xl);
  }
  .simple-powerful-tool-impres-parent {
    gap: var(--gap-12xl);
  }
  .testimonials1 {
    gap: var(--gap-20xl);
    padding-left: var(--padding-122xl);
    padding-right: var(--padding-122xl);
    box-sizing: border-box;
  }
  .releva-ai-hub {
    gap: var(--gap-12xl);
  }
}
@media screen and (max-width: 450px) {
  .releva-ai-hub .howitworks1-inner,
  .releva-ai-hub .howitworks1-inner .simply-provide-your {
    height: 100%;
  }
  .youve-never-created {
    font-size: var(--font-size-3xl);
    line-height: 49px;
  }
  .ai-hub,
  .relevas-ai-hub {
    font-size: var(--font-size-22xl);
    line-height: 49px;
  }
  .relevas-ai-hub {
    font-size: var(--font-size-27xl);
  }
  .language-and-channel {
    font-size: var(--body-small-regular-size);
    line-height: 14px;
  }
  .audience,
  .brand-identity,
  .hi-jane,
  .transform-your-marketing {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .audience,
  .brand-identity,
  .hi-jane {
    line-height: 14px;
  }
  .hi-jane {
    line-height: 24px;
  }
  .achievements-recognition,
  .why-your-ecommerce {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .do-you-have,
  .do-you-know,
  .struggling-to-persuade {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .statement-list {
    gap: var(--gap-5xl);
  }
  .variable-update {
    padding-top: var(--padding-26xl);
    box-sizing: border-box;
  }
  .variable-declare {
    gap: var(--gap-3xl);
  }
  .object-operator {
    gap: var(--gap-lg);
  }
  .block-statement {
    gap: var(--gap-mini);
  }
  .hi-jane1 {
    font-size: var(--body-small-regular-size);
    line-height: 24px;
  }
  .seamless-integration-and {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .frame-wrapper1,
  .testimonial-left {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-wrapper1 {
    padding-right: var(--padding-xl);
  }
  .testimonial-left {
    gap: var(--gap-5xl);
  }
  .value-added-product {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .testimonial-right,
  .testimonials {
    gap: var(--gap-base);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .testimonials {
    gap: var(--gap-xl);
    padding-left: var(--padding-xl);
  }
  .try-releva-ai {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .global-variable-table {
    gap: var(--gap-mid);
  }
  .how-releva-ai {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .div {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .extract-your-brand {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .description-and-persona {
    font-size: var(--font-size-3xl);
    line-height: 41px;
  }
  .howitworks1-inner {
    padding-bottom: var(--padding-68xl);
    box-sizing: border-box;
  }
  .image-119-icon {
    display: none;
  }
  .brand-description,
  .brand-persona,
  .brand-tone {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .div1-ai {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .create-engaging-ai-generated {
    font-size: var(--font-size-7xl);
    line-height: 31px;
    height: auto;
  }
  .choose-what-type {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .frame-parent8 {
    gap: var(--gap-mini);
  }
  .conditional-split-inner {
    gap: var(--gap-9xl);
    height: 450px;
  }
  .function-call-stack {
    gap: var(--gap-mini);
  }
  .div2 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .ai-chat1 {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .ai-generated-content-creation {
    font-size: var(--font-size-3xl);
    line-height: 41px;
  }
  .how-it-works-6-inner {
    padding-top: var(--padding-117xl);
    box-sizing: border-box;
  }
  .elevate-your-wardrobe {
    font-size: var(--body-large-semi-bold-size);
    line-height: 26px;
  }
  .dive-into-a-container {
    font-size: var(--body-small-regular-size);
    line-height: 20px;
  }
  .ai-generated,
  .tailored-to-your-container {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .images {
    font-size: var(--font-size-3xl);
    line-height: 14px;
  }
  .content,
  .engaging,
  .generate {
    font-size: var(--font-size-lgi);
    line-height: 19px;
  }
  .engaging {
    line-height: 14px;
  }
  .ecommerce-brands {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .already-use-releva {
    font-size: var(--font-size-lgi);
    line-height: 22px;
  }
  .split-merge {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .output-processor {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div3 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .div4 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div5 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .div6 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div7 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .times {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .try-releva-ai1 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .try-releva-ai-hub-free-for-14-parent {
    gap: var(--gap-mid);
  }
  .releva-goes-beyond {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .cdp-for-offline-container,
  .it-empowers-you,
  .web-search {
    font-size: var(--body-small-regular-size);
    line-height: 22px;
  }
  .cdp-for-offline-container,
  .web-search {
    line-height: 21px;
  }
  .frame-parent15 {
    gap: var(--gap-lgi);
  }
  .simple-powerful-tool-impres-parent {
    gap: var(--gap-mini);
  }
  .releva-ai-hub-inner {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .testimonials1 {
    gap: var(--gap-xl);
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .web-search-multi-channel-pe,
  .mail-push-center,
  .message-hub,
  .social-media-hub,
  .cdp-for-offline-shops-email {
    position: initial;
    flex: 1;
    width: auto;
    max-width: 100% !important;
  }
  .web-search {
    width: 100%;
  }
  .features {
    height: auto;
  }
  .img-4947-4-1-parent .AIHubHolder h1 {
    line-height: 32px;
    margin-bottom: 15px;
  }
  .conditional-split-inner,
  .howitworks1-inner {
    height: 520px;
  }
  .releva-ai-hub .block-8,
  .howitworks3-ai-wrapper,
  .howitworks2-wrapper,
  .array-operator,
  .local-variable-table,
  .releva-ai-hub .global-scope {
    margin: 50px 0;
  }
}
