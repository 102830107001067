.releva-icon11,
.symbol-icon11 {
  height: 31.7px;
  width: 12.8px;
  position: relative;
}
.releva-icon11 {
  height: 27.2px;
  width: 113.7px;
}
.releva-logo-parent,
.releva-logo11 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.releva-logo11 {
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-smi-8);
}
.releva-logo-parent {
  margin-top: -3391px;
  align-items: flex-start;
  padding: 0 var(--padding-51xl) 3346px;
  flex-shrink: 0;
}
.case-studies2 {
  margin: 0;
  width: 1186px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.case-studies-title-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-lgi) var(--padding-2xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.discover-how-releva {
  width: 561px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.case-studies-description-paren {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-29xl) var(--padding-2xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.all-case-studies:hover {
  cursor: pointer;
}
.all-case-studies {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
  padding: var(--padding-5xs) var(--padding-base) !important;
  color: var(--brand-indigo);
  text-decoration: none;
}
.all-case-studies-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.slow-moving-consumer-goods {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.slow-moving-consumer-goods-wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  white-space: nowrap;
}
.medium-turnover-goods {
  width: 206px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  white-space: nowrap;
}
.medium-turnover-goods-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.fast-moving-consumer-goods {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.fast-moving-consumer-goods-wrapper,
.case-studies-content-for-each .menu-items3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.fast-moving-consumer-goods-wrapper {
  padding: var(--padding-5xs) 0;
}
.case-studies-content-for-each .menu-items3 {
  overflow-x: auto;
  gap: var(--gap-41xl);
  max-width: 100%;
  width: auto;
}
.frame-child14,
.sub-menu3 {
  box-sizing: border-box;
  max-width: 100%;
}
.case-studies-content-for-each .sub-menu3 {
  width: 1727px;
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.frame-child14 {
  align-self: stretch;
  width: 1479px;
  position: relative;
  border-top: 1px solid var(--type-color1);
}
.case-studies-content-for-each-inner {
  width: 1726px;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-smi);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}
.slow-moving-consumer-goods1 {
  margin: 0;
  /* width: 440px; */
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.slow-moving-title-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-29xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}
.image-89-icon {
  width: 14px;
  height: 24px;
  position: absolute;
  margin: 0 !important;
  top: 796px;
  right: 6px;
  object-fit: contain;
}
.all-case-studies1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.all-case-studies-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.slow-moving-consumer-goods2 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.slow-moving-consumer-goods-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.medium-turnover-goods1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.medium-turnover-goods-container {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  white-space: nowrap;
}
.fast-moving-consumer-goods1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.fast-moving-consumer-goods-container,
.menu-items4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.fast-moving-consumer-goods-container {
  padding: var(--padding-5xs) 0;
}
.menu-items4 {
  overflow-x: auto;
  gap: var(--gap-41xl);
  max-width: 100%;
}
.sub-menu-slow-moving-parent,
.sub-menu4 {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.sub-menu4 {
  flex: 1;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
}
.sub-menu-slow-moving-parent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-43xl) var(--padding-8xl) var(--padding-44xl);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.medium-turnover-goods2 {
  margin: 0;
  width: 440px;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.medium-turnover-title-parent {
  width: 816px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-18xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}
.image-90-icon {
  width: 14px;
  height: 24px;
  position: absolute;
  margin: 0 !important;
  top: 1664px;
  right: -16px;
  object-fit: contain;
}
.all-case-studies2 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.consumer-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.slow-moving-consumer-goods3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.consumer-items1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.medium-turnover-goods3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.consumer-items2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.fast-moving-consumer-goods2 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.consumer-items3,
.menu-items5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.consumer-items3 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  padding: var(--padding-5xs) var(--padding-base);
  white-space: nowrap;
}
.menu-items5 {
  overflow-x: auto;
  gap: var(--gap-41xl);
  max-width: 100%;
}
.fast-menu,
.sub-menu5 {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.sub-menu5 {
  flex: 1;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
}
.fast-menu {
  width: 1835px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-35xl) var(--padding-mid);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.dabov-banner-icon {
  align-self: stretch;
  height: 160px;
  position: relative;
  border-radius: var(--br-7xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.ellipse-div {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-fuchsia-100);
}
.fast-moving-consumer-goods3 {
  text-decoration: none;
  position: relative;
  color: inherit;
}
.ellipse-parent {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color);
}
.dabov-specialty-coffee {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.conversion-rate-from {
  position: relative;
}
.dabov-study-row {
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  min-width: 323px;
  max-width: 100%;
}
.dabov-study-row-child {
  align-self: stretch;
  height: 160px;
  position: relative;
  border-radius: var(--br-7xs);
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frame-child15 {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-fuchsia-100);
}
.fast-moving-consumer-goods4 {
  text-decoration: none;
  position: relative;
  color: inherit;
}
.ellipse-group {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color);
}
.cash-credit-case {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.click-rate-from {
  position: relative;
}
.dabov-cash,
.dabov-studies,
.dabov-study-row1 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.dabov-study-row1 {
  flex: 1;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  min-width: 323px;
}
.dabov-cash,
.dabov-studies {
  flex-direction: row;
  justify-content: center;
}
.dabov-studies {
  width: 700px;
  flex-wrap: wrap;
  gap: var(--gap-13xl);
}
.dabov-cash {
  width: 1020px;
  padding: 0 var(--padding-xl) var(--padding-197xl);
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: var(--body-footnote-regular-size);
}
.all-case-studies3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.slow-goods-items {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  white-space: nowrap;
}
.medium-turnover-goods4 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.slow-goods-items1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.slow-moving-consumer-goods4 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.slow-goods-items2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0;
}
.fast-moving-consumer-goods5 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  white-space: nowrap;
}
.menu-items6,
.slow-goods-items3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.slow-goods-items3 {
  padding: var(--padding-5xs) 0;
}
.menu-items6 {
  overflow-x: auto;
  gap: var(--gap-41xl);
  max-width: 100%;
}
.slow-menu,
.sub-menu6 {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.sub-menu6 {
  width: 1727px;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
}
.slow-menu {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-36xl) var(--padding-7xl);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.praktis-ardes-banner {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-7xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frame-child16 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--color-blueviolet-200);
}
.frame-child16,
.slow-moving-consumer-goods5 {
  position: relative;
}
.ellipse-container {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color-blueviolet-200);
}
.momax-case-study {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.conversion-rate-from1 {
  position: relative;
}
.praktis-ardes-row {
  height: 304px;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.image-8-icon {
  height: 180px;
  width: 313px;
  position: relative;
  object-fit: cover;
  display: none;
}
.modern-promotion-business-agen {
  height: 160px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.image-8-parent,
.praktis-ardes-row-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-8-parent {
  margin-top: -10px;
  flex: 1;
  padding: var(--padding-3xs) 0;
  background-image: url(/public/image-8@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.praktis-ardes-row-inner {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-7xs);
  background-color: var(--w-background);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-child17 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--color-blueviolet-200);
}
.frame-child17,
.slow-moving-consumer-goods6 {
  position: relative;
}
.ellipse-parent1 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color-blueviolet-200);
}
.aiko-case-study {
  align-self: stretch;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
  font-family: var(--font-raleway);
}
.aiko-case-study,
.conversion-rate-from2 {
  position: relative;
}
.praktis-ardes-row1 {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-20xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.image-9-icon {
  height: 179px;
  width: 313px;
  position: relative;
  object-fit: cover;
  display: none;
}
.modern-promotion-business-agen1 {
  height: 160px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.image-9-parent,
.praktis-ardes-row-child {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-9-parent {
  margin-top: -9px;
  flex: 1;
  padding: var(--padding-4xs) 0 var(--padding-3xs);
  background-image: url(/public/image-9@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.praktis-ardes-row-child {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-7xs);
  background-color: var(--w-background);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-child18 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--color-blueviolet-200);
}
.frame-child18,
.slow-moving-consumer-goods7 {
  position: relative;
}
.ellipse-parent2 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color-blueviolet-200);
}
.address-real-estate {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.average-open-rate {
  position: relative;
}
.praktis-ardes-row2 {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-4xs) var(--padding-19xl) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.image-9-icon1 {
  height: 179px;
  width: 313px;
  position: relative;
  object-fit: cover;
  display: none;
}
.modern-promotion-business-agen2 {
  height: 170px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.image-9-group,
.praktis-ardes-row-inner1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-9-group {
  margin-top: -9px;
  flex: 1;
  padding: var(--padding-4xs) 0 0;
  background-image: url(/public/image-9@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.praktis-ardes-row-inner1 {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-7xs);
  background-color: var(--w-background);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-child19 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--color-blueviolet-200);
}
.frame-child19,
.slow-moving-consumer-goods8 {
  position: relative;
}
.ellipse-parent3 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color-blueviolet-200);
}
.praktis-case-study {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.conversion-rate-from3 {
  position: relative;
}
.praktis-ardes-row3 {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.praktis-ardes,
.slow-studies {
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.praktis-ardes {
  width: 1432px;
  display: grid;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  grid-template-columns: repeat(4, minmax(250px, 1fr));
}
.slow-studies {
  align-self: stretch;
  display: flex;
  justify-content: center;
  padding: 0 var(--padding-3xl) var(--padding-25xl) var(--padding-xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-footnote-regular-size);
  color: var(--type-color);
  font-family: var(--body-footnote-regular);
}
.sport-unico-banner {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-7xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.frame-child20 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--brand-indigo);
}
.frame-child20,
.medium-turnover-goods5 {
  position: relative;
}
.ellipse-parent4 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--brand-indigo);
}
.sport-depot-case {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.click-rate-from1 {
  position: relative;
}
.sport-unico-row {
  height: 304px;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.image-9-icon2 {
  height: 179px;
  width: 313px;
  position: relative;
  object-fit: cover;
  display: none;
}
.modern-promotion-business-agen3 {
  height: 160px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.image-9-container,
.sport-unico-row-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-9-container {
  margin-top: -9px;
  flex: 1;
  padding: var(--padding-4xs) 0 var(--padding-3xs);
  background-image: url(/public/image-9@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.sport-unico-row-inner {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-7xs);
  background-color: var(--w-background);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-child21 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--brand-indigo);
}
.frame-child21,
.medium-turnover-goods6 {
  position: relative;
}
.ellipse-parent5 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--brand-indigo);
}
.trendo-case-study {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.click-rate-from2 {
  position: relative;
}
.sport-unico-row1 {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.image-9-icon3 {
  height: 179px;
  width: 313px;
  position: relative;
  object-fit: cover;
  display: none;
}
.modern-promotion-business-agen4 {
  height: 170px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.image-9-parent1,
.sport-unico-row-child {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-9-parent1 {
  margin-top: -9px;
  flex: 1;
  padding: var(--padding-4xs) 0 0;
  background-image: url(/public/image-9@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.sport-unico-row-child {
  align-self: stretch;
  height: 160px;
  border-radius: var(--br-7xs);
  background-color: var(--w-background);
  overflow: hidden;
  flex-shrink: 0;
}
.frame-child22 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--brand-indigo);
}
.frame-child22,
.medium-turnover-goods7 {
  position: relative;
}
.ellipse-parent6 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--brand-indigo);
}
.gianni-case-sztudy {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
}
.conversion-rate-from4 {
  position: relative;
}
.sport-unico-row2,
.sport-unico-studies {
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.sport-unico-row2 {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.sport-unico-studies {
  width: 1432px;
  display: grid;
  flex-direction: row;
  gap: var(--gap-13xl);
  grid-template-columns: repeat(4, minmax(250px, 1fr));
}
.case-studies-content-for-each,
.sport-unico {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  text-align: left;
  color: var(--type-color);
  font-family: var(--body-footnote-regular);
}
.sport-unico {
  width: 1834px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-31xl);
  max-width: 100%;
  flex-shrink: 0;
  font-size: var(--body-footnote-regular-size);
}
.case-studies-content-for-each {
  width: 100%;
  /* height: 5388px; */
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-12xs) var(--padding-74xl);
  gap: var(--gap-smi);
  line-height: normal;
  letter-spacing: normal;
  font-size: var(--font-size-8xl);
  margin-bottom: 60px;
}
@media screen and (max-width: 450px) {
  .case-studies .section-01 {
    gap: 16px 32px;
  }
}
@media screen and (max-width: 1500px) {
  .case-studies-content-for-each {
    height: auto;
  }
}
@media screen and (max-width: 1200px) {
  .releva-logo-parent {
    padding-bottom: 919px;
    box-sizing: border-box;
  }
  .menu-items3,
  .menu-items4 {
    gap: var(--gap-11xl);
  }
  .sub-menu-slow-moving-parent {
    padding-left: var(--padding-12xl);
    padding-right: var(--padding-12xl);
    box-sizing: border-box;
  }
  .menu-items5 {
    gap: var(--gap-11xl);
  }
  .fast-menu {
    padding-left: var(--padding-8xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
  .menu-items6 {
    gap: var(--gap-11xl);
  }
  .slow-menu {
    padding-left: var(--padding-8xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
  .praktis-ardes,
  .sport-unico-studies {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(250px, 434px));
  }
}
@media screen and (max-width: 825px) {
  .case-studies2 {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .dabov-studies {
    gap: var(--gap-base);
  }
  .dabov-cash {
    padding-bottom: var(--padding-121xl);
    box-sizing: border-box;
  }
  .praktis-ardes,
  .sport-unico-studies {
    gap: var(--gap-base);
    grid-template-columns: minmax(250px, 1fr);
  }
}
@media screen and (max-width: 450px) {
  .releva-logo-parent {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    padding-bottom: var(--padding-578xl);
    box-sizing: border-box;
  }
  .case-studies2 {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .medium-turnover-goods2,
  .slow-moving-consumer-goods1 {
    font-size: var(--font-size-3xl);
  }
  .sport-unico {
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }
  .case-studies-content-for-each .menu-items3 {
    gap: 10px;
  }
}
