.meet-the-team {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-size: var(--headings-h2-semi-bold-size);
  text-align: center;
}
.meet-the-minds {
  /* width: 561px; */
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.grid-layout1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--body-large-regular-size);
}
.button-set,
.dropdown-menu,
.grid-layout1 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.button-set {
  /* width: 1117px; */
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-decoration: none;
}
.dropdown-menu {
  width: 1505px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.mask-group-22x-1 {
  position: absolute;
  top: 67px;
  left: 0;
  width: 254px;
  height: 418px;
  object-fit: cover;
}
.mask-group-22x-1-wrapper {
  position: absolute;
  height: 93.81%;
  width: 50.4%;
  top: 0;
  right: 0;
  bottom: 6.19%;
  left: 49.6%;
  border-radius: 17.11px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
}
.frame-child73 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  width: 237px;
  height: 485px;
  overflow: hidden;
}
.georgi-photo-icon {
  position: absolute;
  top: 47px;
  left: 9px;
  width: 242px;
  height: 470px;
  object-fit: cover;
  z-index: 1;
}
.frame-parent134 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  width: 251px;
}
.yavor-georgi-photos {
  height: 517px;
  width: 504px;
  position: absolute;
  margin: 0 !important;
  top: -507px;
  right: -326px;
  z-index: 1;
}
.georgi-georgiev {
  width: 125px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 125px;
  z-index: 2;
}
.yavor-georgi-photos-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.ceo-co-founder {
  width: 110px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 110px;
  z-index: 3;
}
.ceo-co-founder-wrapper,
.column-layout,
.row-layout {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ceo-co-founder-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-5xs) 0 var(--padding-6xs);
  font-size: var(--font-size-smi);
}
.column-layout,
.row-layout {
  flex-direction: column;
}
.column-layout {
  flex-shrink: 0;
}
.row-layout {
  padding: var(--padding-269xl) 0 0;
}
.vector-icon31 {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.cto-co-founder,
.yavor-stoychev {
  width: 120px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 120px;
  z-index: 2;
}
.cto-co-founder {
  width: 110px;
  min-width: 110px;
  z-index: 3;
}
.cto-co-founder-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xs);
  font-size: var(--font-size-smi);
}
.element-with-photos,
.vector-parent12 {
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.vector-parent12 {
  height: 641.9px;
  width: 621.7px;
  flex-direction: column;
  padding: var(--padding-269xl) var(--padding-59xl);
  position: relative;
}
.element-with-photos {
  /* height: 746px; */
  width: 789px;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-direction: row;
  /* padding: var(--padding-330xl) var(--padding-165xl) var(--padding-40xl); */
  gap: var(--gap-36xl);
  /* min-width: 789px; */
  max-width: 100%;
}
.georgi-georgiev1 {
  width: 319px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  display: inline-block;
}
.georgi-georgiev-wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
}
.ceo-co-founder1,
.georgi-is-a {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.ceo-co-founder1 {
  width: 436px;
  font-size: var(--body-small-regular-size);
  display: inline-block;
  max-width: 100%;
}
.georgi-is-a {
  align-self: stretch;
  font-size: var(--body-default-semi-bold-size);
}
.expand-text-button {
  height: 51px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.expand-text-button-wrapper,
.frame-parent136 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.expand-text-button-wrapper {
  align-self: stretch;
  height: 51px;
  flex-direction: row;
  padding: 0 0 0 var(--padding-7xs);
  box-sizing: border-box;
  display: none;
}
.frame-parent136 {
  width: 614px;
  flex-direction: column;
  gap: var(--gap-3xs-3);
}
.yavor-stoychev1 {
  width: 306px;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  display: inline-block;
}
.yavor-stoychev-wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
}
.cto-co-founder1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.group-1-2,
.yavor-is-a {
  position: relative;
  max-width: 100%;
}
.yavor-is-a {
  width: 583px;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.group-1-2 {
  width: 608px;
  height: 51px;
  object-fit: cover;
}
.frame-parent135,
.frame-parent137 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent137 {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-3xs);
  max-width: 100%;
}
.frame-parent135 {
  gap: 50px;
}
.bar-chart,
.bar-chart-inner,
.frame-parent135,
.input-field4 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.bar-chart-inner {
  width: 694px;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-top: 250px; */
  box-sizing: border-box;
  /* min-width: 694px; */
  text-align: left;
  font-size: var(--headings-h3-semi-bold-size);
}
.bar-chart,
.input-field4 {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-79xl);
  font-size: var(--body-small-regular-size);
}
.input-field4 {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-7xl) var(--padding-17xl);
  box-sizing: border-box;
  gap: var(--gap-2xl);
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1725px) {
  .bar-chart-inner,
  .element-with-photos {
    flex: 1;
  }
  .bar-chart {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1325px) {
  .element-with-photos {
    padding: var(--padding-208xl) var(--padding-73xl) var(--padding-19xl);
    box-sizing: border-box;
    min-width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .meet-the-team {
    font-size: var(--font-size-27xl);
    line-height: 66px;
  }
  .vector-parent12 {
    padding-top: var(--padding-168xl);
    padding-bottom: var(--padding-168xl);
    box-sizing: border-box;
  }
  .element-with-photos {
    gap: 55px 27px;
    padding: var(--padding-129xl) var(--padding-27xl) var(--padding-6xl);
    box-sizing: border-box;
  }
  .frame-parent135 {
    gap: 50px 101px;
  }
  .bar-chart-inner {
    padding-top: var(--padding-38xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .bar-chart {
    gap: 98px 49px;
  }
}
@media screen and (max-width: 450px) {
  .meet-the-team {
    font-size: var(--font-size-15xl);
    line-height: 49px;
  }
  .meet-the-minds {
    font-size: var(--body-small-regular-size);
    line-height: 22px;
  }
  .element-with-photos {
    padding: 0;
  }
  .element-with-photos,
  .vector-parent12 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-parent135 {
    gap: 25px 101px;
  }
  .bar-chart {
    gap: 98px 24px;
  }

  .data-aggregator9,
  .customer-data-segmentation3,
  .vector-parent13 {
    margin-top: -100px;
  }

  .error-handler10 .timer {
    margin-top: -208px;
  }
  .krasimira-georgieva-parent,
  .asen-ivanov-parent,
  .frame-parent139,
  .dimitrios-ntousikos-parent,
  .tree-analyzer,
  .value-comparator3,
  .signal-splitter,
  .boryana-antonova-parent,
  .value-transformer1,
  .result-formatter,
  .data-combiner,
  .signal-emitter1,
  .timer,
  .output-filter1 {
    margin-top: -150px;
  }
}
