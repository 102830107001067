header .menu-icon {
  display: none; /* Hide menu icon by default */
  cursor: pointer;
}

header .center-items-wrapper {
  position: relative;
}
header .menu-item-link:hover {
  cursor: pointer;
}
header .menu-item-link {
  position: relative;
}

header .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 320px;
  padding: var(--padding-xl);
}

header .dropdown-item {
  padding: 10px 15px;
  white-space: nowrap;
  height: auto;
  background-color: transparent;
  color: var(--brand-indigo);
  text-decoration: none;
}

header .dropdown-item:hover {
  /* background-color: #f0f0f0; */
  text-decoration: underline;
}

.menu-item-link .item-name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-item-link .dropdown-arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

/* Rotate the arrow when the dropdown is open */
.center-items .menu-item-link.dropdown-open .dropdown-arrow {
  transform: rotate(180deg);
}

header.menu-wrapper {
  position: relative;
}
header.menu-wrapper {
  padding-top: 24px;
}
.releva-icon3,
.symbol-icon3 {
  height: 31.7px;
  width: 12.8px;
  position: relative;
  flex-shrink: 0;
  margin-left: 10px;
}
.releva-icon3 {
  height: 27.2px;
  width: 113.7px;
}
.releva-logo3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-smi-8);
}
.menu-child,
.releva-logo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.releva-logo-container {
  align-self: stretch;
  flex-direction: row;
}
.menu-child {
  width: 207px;
  min-width: 130px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
}
.item-name:hover {
  text-decoration: underline;
}
.item-name {
  width: auto;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 66px;
  text-decoration: none;
  color: var(--brand-indigo);
}
.menu-item-link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name1 {
  width: 77px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 77px;
}
.menu-item-link1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name2 {
  width: 91px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 91px;
}
.menu-item-link2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name3 {
  width: 88px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 88px;
}
.menu-item-link3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.item-name4 {
  width: 58px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 58px;
}
.menu-item-link4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.center-items,
.center-items-wrapper {
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.center-items {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--padding-2xl);
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.center-items-wrapper {
  width: 745px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.item-name5 {
  width: 57px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 57px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--brand-indigo);
}
.menu-item-link-wrapper,
.menu-item-link5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu-item-link-wrapper {
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
}
.button-name1 {
  width: 110px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button1:hover {
  background-color: var(--color-lightsteelblue);
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.button1,
.items-left,
.menu-wrapper,
.menu1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.items-left {
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-xl);
  gap: var(--gap-base);
}
.menu-wrapper,
.menu1 {
  max-width: 100%;
}
.menu1 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.menu-wrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-46xl) var(--padding-28xl) var(--padding-44xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .center-items-wrapper {
    display: none;
  }
  .menu-wrapper {
    padding-left: var(--padding-12xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .menu1 .items-left .button1 {
    display: none;
  }
  .center-items {
    /* display: none; */
  }
  .center-items-wrapper {
    width: 0;
  }
  .menu-icon {
    display: flex !important; /* Show menu icon on smaller screens */
    width: 49px;
    height: 49px;
  }
  .releva-icon3 {
    margin-left: 0;
  }
  .center-items-wrapper.dropdownOpen {
    height: 300%;
  }
  .dropdown-menu.plans {
    left: -160px;
  }
  .center-items-wrapper {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none; /* Hide menu by default */
    z-index: 1000;
  }

  .center-items-wrapper.open {
    display: block; /* Show menu when it's open */
    overflow: auto;
    position: absolute;
  }
}
.fundingHolder {
  width: 100%;
  min-width: 100%;
  text-align: center;
  margin: 0;
}
.fundingHolder p {
  text-align: left;
  margin-top: 0;
}
.fundingHolder img {
  background: #fff;
  width: 50%;
  margin-left: 10px;
  margin-top: 10px;
}
