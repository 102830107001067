.symbol-library {
  height: 835px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  text-align: left;
  font-size: var(--font-size-lgi-6);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  margin-top: 80px;
}

@media screen and (max-width: 450px) {
  .symbol-library {
    height: auto;
  }
}
