.data-storage-child {
  height: 494.6px;
  width: 557.6px;
  position: absolute;
  margin: 0 !important;
  right: -315.6px;
  bottom: -202.7px;
  object-fit: contain;
}
.hi-john1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  display: inline-block;
  min-width: 92px;
}
.youve-left-two2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
}
.data-formatter,
.data-sampler {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.data-sampler {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-5);
}
.data-formatter {
  width: 369px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  box-sizing: border-box;
}
.image-83-icon2 {
  height: 134.1px;
  width: 134.1px;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
}
.data-fuser-child {
  align-self: stretch;
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
}
.data-fuser,
.data-fuser-child,
.data-fuser-item {
  flex-shrink: 0;
}
.data-fuser-item {
  width: 109px;
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
}
.data-fuser {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-6xs-7);
}
.data-cleaver,
.data-fuser,
.data-indexer,
.data-validator {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.data-cleaver {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-17xl-9) 0 0;
  box-sizing: border-box;
  min-width: 110px;
}
.data-indexer,
.data-validator {
  flex-direction: row;
  max-width: 100%;
}
.data-validator {
  flex: 1;
  gap: var(--gap-21xl-2);
}
.data-indexer {
  width: 370.6px;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  box-sizing: border-box;
}
.data-queue-manager-child {
  align-self: stretch;
  height: 1.4px;
  position: relative;
  border-top: 1.4px solid var(--brand-ivory);
  box-sizing: border-box;
}
.data-queue-manager {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm-4);
  max-width: 100%;
}
.image-13-icon2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.image-13-container {
  height: 72.1px;
  width: 137.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.reach,
.reach1 {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
  flex-shrink: 0;
}
.reach {
  align-self: stretch;
}
.reach1 {
  width: 109px;
}
.frame-wrapper39,
.reach-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reach-parent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-6xs-7);
}
.frame-wrapper39 {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 var(--padding-sm-4);
  box-sizing: border-box;
  min-width: 110px;
}
.data-mapper,
.data-mapper-inner,
.frame-parent100 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent100 {
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-17xl-8);
}
.data-mapper,
.data-mapper-inner {
  align-items: flex-start;
  box-sizing: border-box;
}
.data-mapper-inner {
  width: 370.6px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
}
.data-mapper {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 var(--padding-7xs-6);
  gap: var(--gap-11xl-4);
}
.checkout2 {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
  min-width: 93px;
}
.get-demo10 {
  border-radius: 13.41px;
  background-color: var(--brand-indigo);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm-4) var(--padding-23xl);
}
.data-security,
.get-demo-wrapper3 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.get-demo-wrapper3 {
  width: 367.6px;
  flex-direction: row;
  justify-content: center;
  font-size: var(--body-large-regular-size);
  color: var(--brand-ivory);
}
.data-security {
  flex: 1;
  border-radius: var(--br-sm-6);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-26xl-3) var(--padding-4xl-4) var(--padding-27xl-1) var(--padding-6xl-1);
  box-sizing: border-box;
  gap: var(--gap-31xl);
  z-index: 1;
}
.data-storage-inner,
.data-storage-item {
  position: absolute;
  margin: 0 !important;
  z-index: 2;
}
.data-storage-item {
  height: 157.5px;
  width: 117.4px;
  top: 146.8px;
  left: -105px;
  border-radius: 7.83px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.data-storage-inner {
  height: 81px;
  width: 81px;
  top: -36.2px;
  right: -40px;
}
.data-storage,
.i-14 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.data-storage {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}
.i-14 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
  min-width: 513px;
  /* min-height: 746px; */
}
.abandoned-cart-recovery {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h3-semi-bold-size);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name31,
.perfect-for-ecommerce {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.perfect-for-ecommerce {
  align-self: stretch;
}
.button-name31 {
  width: 145px;
  display: inline-block;
}
.button36 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.abandoned-cart-recovery-parent,
.data-visualizer-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.abandoned-cart-recovery-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.data-visualizer-inner {
  width: 494px;
  min-width: 494px;
  min-height: 496px;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.data-flow-controller,
.data-visualizer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.data-visualizer {
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-140xl);
}
.data-flow-controller {
  width: 1570px;
  align-items: flex-start;
  padding: 0 var(--padding-45xl) var(--padding-141xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1225px) {
  .i-14 {
    min-height: auto;
  }
  .data-visualizer-inner {
    flex: 1;
    min-height: auto;
  }
  .data-visualizer {
    flex-wrap: wrap;
    gap: 159px 79px;
  }
  .data-flow-controller {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    padding-bottom: var(--padding-85xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .data-security {
    padding-top: var(--padding-10xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
  }
  .i-14 {
    padding: var(--padding-17xl) var(--padding-70xl) var(--padding-17xl) var(--padding-71xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .abandoned-cart-recovery {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .data-visualizer-inner {
    min-width: 100%;
  }
  .data-visualizer {
    gap: 59px 40px;
  }
}
@media screen and (max-width: 450px) {
  .hi-john1 {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .image-83-icon2 {
    flex: 1;
  }
  .data-validator {
    flex-wrap: wrap;
    gap: 40.2px 20px;
  }
  .frame-parent100 {
    flex-wrap: wrap;
    gap: 36.8px 18px;
  }
  .data-mapper {
    gap: 15px 30.4px;
  }
  .checkout2 {
    font-size: var(--body-small-regular-size);
  }
  .data-security {
    gap: 25px 50px;
  }
  .i-14 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .abandoned-cart-recovery {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .data-visualizer {
    gap: 59px 20px;
  }
  .data-flow-controller {
    padding-bottom: var(--padding-49xl);
    box-sizing: border-box;
  }
}
