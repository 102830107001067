.ecommerce-url {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 600;
}
.icon6,
.typing2 {
  position: relative;
  flex-shrink: 0;
}
.typing2 {
  width: 304px;
  line-height: 24px;
  display: inline-block;
}
.icon6 {
  height: 24px;
  width: 24px;
  overflow: hidden;
  min-height: 24px;
}
.input-field3,
.shape-connector1,
.text3 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.text3 {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-5xs);
}
.input-field3,
.shape-connector1 {
  flex-direction: column;
  justify-content: flex-start;
}
.input-field3 {
  height: 56px;
  border-radius: var(--br-5xs);
  background-color: var(--w-background);
  border: 2px solid var(--keatext-purple);
  box-sizing: border-box;
  padding: var(--padding-base) var(--padding-sm);
  font-size: var(--body-small-regular-size);
  color: var(--color-darkslategray);
}
.shape-connector1 {
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-dimgray);
  font-family: var(--font-noto-sans);
}
