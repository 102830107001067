.frame-child36 {
  width: 28px;
  height: 84px;
  display: none;
}
.based-on-your {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 28px;
  font-weight: 600;
}
.based-on-your-history-wrapper {
  width: 188.6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-smi) 0 var(--padding-smi-8);
  box-sizing: border-box;
}
.image-101-icon {
  height: 111px;
  width: 80px;
  position: relative;
  object-fit: contain;
}
.shopify-integration1,
.whyworkwithus {
  height: 14.2px;
  position: relative;
  border-radius: 38.28px;
  background-color: var(--brand-ivory);
}
.whyworkwithus {
  align-self: stretch;
}
.shopify-integration1 {
  width: 102.2px;
}
.frame-wrapper20,
.whyworkwithus-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.whyworkwithus-parent {
  align-self: stretch;
  gap: var(--gap-6xs-3);
}
.frame-wrapper20 {
  width: 158.9px;
  padding: var(--padding-9xl-6) 0 0;
  box-sizing: border-box;
}
.frame-wrapper19,
.image-101-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.image-101-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-wrapper19 {
  width: 347.5px;
  justify-content: flex-end;
  padding: 0 var(--padding-3xl-9) 0 var(--padding-4xl);
  box-sizing: border-box;
  max-width: 100%;
}
.merchant-pro-integration1 {
  align-self: stretch;
  height: 1.3px;
  position: relative;
  border-top: 1.3px solid var(--brand-ivory);
  box-sizing: border-box;
}
.frame-parent55 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 0 var(--padding-9xs-3);
  box-sizing: border-box;
  gap: var(--gap-2xl-4);
  max-width: 100%;
}
.image-99-icon1 {
  height: 85px;
  width: 94px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}
.call-to-action,
.frame-child37 {
  height: 14.2px;
  position: relative;
  border-radius: 38.28px;
  background-color: var(--brand-ivory);
}
.frame-child37 {
  align-self: stretch;
}
.call-to-action {
  width: 102.2px;
}
.frame-wrapper22,
.rectangle-parent6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-parent6 {
  align-self: stretch;
  gap: var(--gap-6xs-2);
}
.frame-wrapper22 {
  width: 158.9px;
  padding: var(--padding-xl-8) 0 0;
  box-sizing: border-box;
}
.frame-wrapper21,
.image-99-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.image-99-group {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-wrapper21 {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-4xl) 0 var(--padding-7xl-4);
}
.image-98-icon2 {
  width: 80px;
  height: 117px;
  position: relative;
  object-fit: contain;
  display: none;
}
.primary-container,
.primary-container-wrapper {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}
.primary-container {
  flex: 1;
  position: relative;
  border-top: 1.3px solid var(--brand-ivory);
}
.primary-container-wrapper {
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-6xs-7);
}
.image-102-icon {
  height: 90px;
  width: 81px;
  position: relative;
  object-fit: cover;
}
.logo-display,
.main-content-wrapper {
  height: 14.2px;
  position: relative;
  border-radius: 38.28px;
  background-color: var(--brand-ivory);
}
.logo-display {
  align-self: stretch;
}
.main-content-wrapper {
  width: 102.2px;
}
.frame-wrapper24,
.logo-display-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.logo-display-parent {
  align-self: stretch;
  gap: var(--gap-6xs-3);
}
.frame-wrapper24 {
  width: 158.9px;
  padding: var(--padding-mid-3) 0 0;
  box-sizing: border-box;
}
.image-102-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-parent54,
.frame-wrapper23 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper23 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-4xl) 0 var(--padding-13xl-4);
}
.frame-parent54 {
  position: absolute;
  top: 103px;
  left: 100px;
  border-radius: 12.76px;
  background-color: var(--web-secondary-ivory-light);
  width: 403.5px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-23xl) var(--padding-2xl-9) var(--padding-22xl) var(--padding-4xl-6);
  box-sizing: border-box;
  gap: var(--gap-6xl);
  max-width: 100%;
}
.sidebar-panel-icon {
  position: absolute;
  top: 68px;
  left: 400px;
  border-radius: 9.33px;
  width: 139.9px;
  height: 187.7px;
  overflow: hidden;
  object-fit: cover;
  z-index: 2;
}
.i-02 {
  /* height: 746px; */
  flex: 1;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  min-width: 513px;
  max-width: 100%;
}
.product-discovery {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name14,
.with-every-click {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.with-every-click {
  align-self: stretch;
}
.button-name14 {
  width: 110px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button16,
.product-discovery-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button16 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  flex-direction: row;
  white-space: nowrap;
  text-decoration: none;
}
.product-discovery-parent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.frame-wrapper25,
.i-02-parent,
.product-page-child {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-wrapper25 {
  width: 517px;
  flex-direction: column;
  align-items: flex-start;
  min-width: 517px;
  min-height: 474px;
  font-size: var(--headings-h3-semi-bold-size);
}
.i-02-parent,
.product-page-child {
  flex-direction: row;
}
.i-02-parent {
  width: 1465px;
  align-items: flex-end;
  gap: var(--gap-140xl);
}
.product-page-child {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 var(--padding-45xl) var(--padding-55xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  justify-content: center;
}
@media screen and (max-width: 1250px) {
  .frame-wrapper25 {
    flex: 1;
    min-height: auto;
  }
  .i-02-parent {
    flex-wrap: wrap;
    gap: 59px 79px;
  }
  .product-page-child {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .i-02 {
    min-width: 100%;
  }
  .product-discovery {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-wrapper25 {
    min-width: 100%;
  }
  .i-02-parent {
    gap: 59px 40px;
  }
  .product-page-child {
    padding-bottom: var(--padding-29xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .based-on-your {
    font-size: var(--font-size-lgi);
    line-height: 22px;
  }
  .image-101-icon {
    flex: 1;
  }
  .image-101-parent,
  .image-102-parent,
  .image-99-group {
    flex-wrap: wrap;
  }
  .i-02 {
    height: auto;
    min-height: 746;
  }
  .product-discovery {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .i-02-parent {
    gap: 59px 20px;
  }
  .product-page-child {
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
}
