.footer-parent {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  gap: 2056.2px;
  max-width: 100%;
}
footer.releva-homepage-inner {
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 1575px) {
  .footer-parent {
    /* height: auto; */
  }
}
@media screen and (max-width: 1275px) {
  .footer-parent {
    gap: 1028px 2056.2px;
  }
}
.cta-background-icon {
  width: 100%;
  height: 15px;
  position: absolute;
  margin: 0 !important;
  top: -6px;
  left: 0;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  overflow: hidden;
  flex-shrink: 0;
}
.ready-to-see {
  margin: 0;
  width: 767px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.link-assembler {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
}
.get-a-demo,
.row-stack {
  max-width: 100%;
  flex-shrink: 0;
}
.get-a-demo {
  width: 658px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.row-stack {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 var(--padding-4xs);
  box-sizing: border-box;
  text-align: center;
}
.button-name5 {
  width: 110px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button5,
.circle-pack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.button5:hover {
  background-color: var(--color-lightsteelblue);
  cursor: pointer;
}
.button5 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.circle-pack {
  align-self: stretch;
  justify-content: center;
  padding: 0 0 var(--padding-61xl);
}
.shape-collection,
.shape-collection-child {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}
.shape-collection-child {
  flex: 1;
  position: relative;
  border-top: 1px solid var(--web-secondary-indigo-mid);
}
.shape-collection {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-10xl);
}
.why-releva3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 100px;
  white-space: nowrap;
}
.e-commerce-owners,
.marketing-specialist1,
.partners {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  text-transform: capitalize;
}
.e-commerce-owners,
.marketing-specialist1 {
  white-space: nowrap;
}
.partners {
  display: inline-block;
  min-width: 56px;
}
.referral-programs {
  display: inline-block;
  min-width: 117px;
}
.merchant-pro-integration,
.referral-programs,
.shoppify-integration {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  text-transform: capitalize;
  white-space: nowrap;
}
.e-commerce-owners-parent,
.why-releva-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.e-commerce-owners-parent {
  gap: var(--gap-base);
  font-size: var(--body-footnote-regular-size);
}
.why-releva-parent {
  width: 140px;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-mid);
  min-width: 140px;
  max-width: 140px;
}
.product {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 68px;
}
.customer-data,
.product-data-center,
.product-recommendations {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  text-transform: capitalize;
}
.product-data-center,
.product-recommendations {
  white-space: nowrap;
}
.customer-data {
  display: inline-block;
  min-width: 98px;
}
.segmentation {
  display: inline-block;
  min-width: 91px;
}
.automation,
.communication-center,
.promotional-banners,
.segmentation {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  text-transform: capitalize;
}
.automation {
  display: inline-block;
  min-width: 75px;
}
.communication-center,
.promotional-banners {
  white-space: nowrap;
}
.product-data-center-parent,
.product-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.product-data-center-parent {
  gap: var(--gap-base);
  font-size: var(--body-footnote-regular-size);
}
.product-parent {
  width: 98.3px;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-mid);
  min-width: 190px;
  max-width: 198px;
}
.about2,
.company {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.company {
  line-height: 28px;
  font-weight: 600;
  min-width: 82px;
}
.about2 {
  line-height: 17px;
  text-transform: capitalize;
  min-width: 40px;
}
.team {
  min-width: 36px;
}
.careers,
.team,
.why-work-with {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  text-transform: capitalize;
  display: inline-block;
}
.why-work-with {
  min-width: 121px;
  white-space: nowrap;
}
.careers {
  min-width: 52px;
}
.about-documents,
.company-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-documents {
  gap: var(--gap-base);
  font-size: var(--body-footnote-regular-size);
}
.company-group {
  width: 98.3px;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-mid);
  min-width: 90px;
  max-width: 98px;
}
.blog,
.documents,
.e-books,
.news,
.resources {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.resources {
  line-height: 28px;
  font-weight: 600;
  min-width: 91px;
}
.blog,
.documents,
.e-books,
.news {
  line-height: 17px;
  text-transform: capitalize;
  min-width: 75px;
}
.blog,
.e-books,
.news {
  min-width: 29px;
}
.e-books,
.news {
  min-width: 37px;
}
.e-books {
  min-width: 55px;
}
.documents-parent,
.frame-parent32,
.resources-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.documents-parent {
  flex-direction: column;
  gap: var(--gap-base);
  font-size: var(--body-footnote-regular-size);
}
.frame-parent32,
.resources-parent {
  box-sizing: border-box;
}
.resources-parent {
  flex: 1 1;
  flex-direction: column;
  padding: 0 var(--padding-5xs-3) 0 0;
  gap: var(--gap-mid);
  /* min-width: 90px;
  max-width: 98px; */
}
.frame-parent32 {
  /* width: 1125px; */
  flex-direction: row;
  padding: 0;
  gap: var(--gap-225xl);
  max-width: 100%;
}
.frame-parent32 a:hover,
.frame-parent32 a:hover div {
  text-decoration: underline !important;
}
.frame-parent32 a {
  color: var(--brand-ivory) !important;
  text-decoration: none;
}
.facebook-icon,
.instagram-icon,
.linkedin-icon,
.whatsapp-icon,
.youtube-icon {
  height: 28px;
  width: 28px;
  position: relative;
  border-radius: var(--br-261xl);
  overflow: hidden;
  flex-shrink: 0;
  min-height: 28px;
}
.facebook-parent,
.frame-parent31 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg-7);
}
.frame-parent31 {
  justify-content: space-between;
  padding: 0 0 var(--padding-26xl);
  gap: var(--gap-xl);
}
.footer1 .frame-parent31 {
  align-self: center;
  max-width: 2000px;
}
.frame-parent31,
.promotional-banners1,
.shape-collection1 {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}
.promotional-banners1 {
  flex: 1;
  position: relative;
  border-top: 1px solid var(--web-secondary-indigo-mid);
}
.shape-collection1 {
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xl);
}
.releva-logo-icon {
  height: 367.6px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.releva-logo-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xl-4);
}
.releva-logo-frame,
.shape-collection-item,
.shape-collection2 {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}
.shape-collection-item {
  flex: 1;
  position: relative;
  border-top: 1px solid var(--web-secondary-indigo-mid);
}
.shape-collection2 {
  height: 25.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xl-5);
}
.releva-all-rights {
  position: relative;
  letter-spacing: -0.01em;
}
.cookies,
.privacy-policy2,
.terms-of-use {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
  color: var(--brand-ivory);
}
.terms-of-use {
  flex: 1;
  min-width: 85px;
}
.cookies,
.privacy-policy2 {
  min-width: 53px;
}
.privacy-policy2 {
  flex: 1;
  min-width: 91px;
}
.image-container {
  width: 309px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.footer-inner,
.releva-all-rights-reserved-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.releva-all-rights-reserved-parent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.footer-inner {
  width: 50%;
  justify-content: flex-start;
  /* padding: 0 var(--padding-9xs) var(--padding-9889xl-1); */
  box-sizing: border-box;
  font-size: var(--body-footnote-regular-size);
}
.logo-icon2 {
  height: 36px;
  width: 141px;
  object-fit: cover;
}
.column,
.logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.logo-wrapper {
  flex: 1;
  flex-direction: row;
  padding: var(--padding-base) 0 0;
}
.column {
  height: 52px;
  width: 320px;
  flex-direction: column;
}
.company1,
.link,
.link1,
.link2 {
  align-self: stretch;
  height: 21px;
  position: relative;
  line-height: 150%;
  display: inline-block;
  white-space: nowrap;
}
.footer-links {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.column1,
.content,
.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  gap: var(--gap-base);
}
.column1 {
  width: 178.7px;
}
.link3,
.link4,
.link5,
.link6,
.resources1 {
  align-self: stretch;
  height: 21px;
  position: relative;
  line-height: 150%;
  display: inline-block;
  white-space: nowrap;
}
.footer-links1 {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.column2,
.content1,
.footer-links1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content1 {
  align-self: stretch;
  gap: var(--gap-base);
}
.column2 {
  width: 178.7px;
}
.about3,
.link7,
.link8 {
  height: 21px;
  position: relative;
  line-height: 150%;
  display: inline-block;
}
.about3,
.link7 {
  align-self: stretch;
  white-space: nowrap;
}
.link8 {
  width: 92px;
}
.content2,
.footer-links2 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-links2 {
  display: flex;
  align-items: flex-start;
  padding: 0 var(--padding-31xl) 0 0;
  gap: var(--gap-5xs);
}
.content2 {
  gap: var(--gap-base);
}
.column3,
.columns,
.content2,
.small-columns {
  display: flex;
  align-items: flex-start;
}
.column3 {
  width: 178.7px;
  flex-direction: column;
  justify-content: flex-start;
}
.columns,
.small-columns {
  flex-direction: row;
}
.small-columns {
  width: -11812.5px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: var(--padding-base) 0 0;
  box-sizing: border-box;
  gap: var(--gap-13xl);
}
.columns {
  width: 960px;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
}
.text {
  height: 21px;
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: 100%;
}
.copyright {
  width: 940px;
  box-shadow: 0-1px 0#e4ebf3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  color: var(--color-darkslategray);
}
.footer1,
.footer2,
.image-filter {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
}
.footer2 {
  width: 1440px;
  background-color: var(--color-whitesmoke);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-45xl) var(--padding-xl) var(--padding-5xs);
  gap: var(--gap-21xl);
  max-width: -13%;
}
.footer1,
.image-filter {
  align-self: stretch;
  align-items: flex-start;
}
.image-filter {
  flex-direction: row;
  padding: 0 0 0 var(--padding-12856xl-5);
  max-width: 100%;
  font-size: var(--body-footnote-regular-size);
  color: var(--color-gray);
}
.footer-parent {
  background-color: var(--brand-indigo);
}
.footer1 {
  z-index: 3;
  max-width: 2000px;
  margin-right: -1px;
  min-height: 1304px;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--brand-indigo);
  flex-direction: column;
  padding: var(--padding-87xl) var(--padding-45xl) var(--padding-28xl-5);
  position: relative;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-ivory);
  font-family: var(--body-footnote-regular);
  align-self: center;
}
@media screen and (max-width: 1575px) {
  .frame-parent31 {
    flex-wrap: wrap;
  }
  .footer1 {
    height: auto;
  }
}
@media screen and (max-width: 1275px) {
  .frame-parent32 {
    flex-wrap: wrap;
    gap: 20px 30px;
    width: auto;
  }
  .footer-inner {
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .image-filter {
    padding-left: var(--padding-6418xl);
    box-sizing: border-box;
  }
  .footer1 {
    padding: var(--padding-xl) var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .ready-to-see {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .releva-logo-icon {
    height: auto !important;
  }
  .frame-parent32 {
    gap: 20px 60px;
  }
  .releva-all-rights-reserved-parent {
    flex-wrap: wrap;
  }
  .footer-inner {
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .copyright,
  .small-columns {
    gap: 32px 16px;
  }
  .footer2 {
    gap: 20px 40px;
  }
  .image-filter {
    padding-left: var(--padding-3199xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-see {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .resources-parent {
    flex: 1;
  }
  .frame-parent32 {
    gap: 20px 30px;
  }
  .image-container {
    gap: 40px 20px;
  }
  .image-filter {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .footer1 .frame-parent31,
  .footer1 {
    max-width: 100%;
  }
}
