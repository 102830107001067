.block-8.trustedCustomersPurple {
  color: var(--color-seashell-100);
  background-color: var(--brand-indigo);
}
.block-8.trustedCustomersPurple .imoteka-icon3 {
  width: auto;
  height: 50px;
}
.trusted-by-400 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.from-diverse-industries1 {
  width: 527px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.from-diverse-industries-and-gl-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.trusted-by-400-ecommerce-bran-parent {
  width: 948px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.block-8-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.data-gatherer-child {
  display: none;
}
.data-gatherer-child,
.imoteka-icon3 {
  height: 29px;
  width: 19.5px;
  position: relative;
}
.imoteka-icon3 {
  width: auto;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.data-gatherer {
  height: 35.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.ebag-icon3 {
  height: 35.3px;
  width: 75.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 35px;
}
.trendo-icon3 {
  width: 82.7px;
  min-height: 35px;
}
.adres-icon3,
.mercari-icon3,
.trendo-icon3 {
  height: 35.3px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.adres-icon3 {
  width: 142.6px;
  min-height: 35px;
}
.mercari-icon3 {
  width: 167.5px;
}
.button-instance-icon {
  width: 19.5px;
  height: 29px;
  position: relative;
  object-fit: contain;
  display: none;
}
.function-applier1 {
  height: 31.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-10xs-3);
  box-sizing: border-box;
}
.output-generator {
  height: 35.3px;
  width: 236.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-30xl-4);
}
.block-8,
.data-gatherer-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.block-8 {
  border-radius: var(--br-xs);
  background-color: var(--color-seashell-100);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-56xl) var(--padding-41xl-7);
  box-sizing: border-box;
  gap: var(--gap-75xl);
  max-width: 100%;
  z-index: 1;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .data-gatherer-parent {
    flex-wrap: wrap;
    justify-content: center;
  }
  .block-8 {
    padding-left: var(--padding-18xl);
    padding-right: var(--padding-18xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .trusted-by-400 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .block-8 {
    gap: 47px 94px;
  }
}
@media screen and (max-width: 450px) {
  .trusted-by-400 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .block-8 {
    gap: 23px 94px;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-20xl);
    box-sizing: border-box;
  }
}
