.overview .symbol-library {
  width: 100%;
  height: auto;
}
.overview.solutions .sliderHolder {
  margin: 64px 0;
  padding: 64px 0;
  background-color: var(--brand-ivory);
}

.overview.solutions .solutionsHero .releva-homepage-child .frame-container {
  width: 90%;
}
.overview.solutions .supercharge-your-ecommerce {
  font-size: 40px;
  line-height: 42px;
}

.overview.solutions .supercharge-your-ecommerce-pro-parent {
  align-items: center;
}
.overview.solutions .solutionsHero {
  padding: 0 64px;
  margin-bottom: 164px;
  padding-top: 64px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1700px;
}
.overview.solutions .solutionsHero .supercharge-your-ecommerce-pro-parent {
  align-items: start;
}
.overview.solutions {
  justify-content: center;
  align-items: center;
}
.overview.solutions .solutionsHero .releva-homepage-child {
  width: 50%;
  text-align: left;
  flex-direction: column;
  display: flex;
  align-items: end;
  justify-content: center;
  flex: 1 1;
  float: none;
  padding: 0;
}
.overview.solutions .logic-gate12.first {
  margin-top: 64px;
}
.overview.solutions .solutionsHero .symbol-library {
  width: 50%;
  flex: 1 1;
  flex-direction: column;
  display: flex;
  align-items: start;
  justify-content: center;
  margin: 0;
}
.overview.solutions .the-only-platform-merging-auto-wrapper {
  padding-left: 0;
  align-self: auto;
}
.overview.solutions .the-only-platform-merging-auto-wrapper .the-only-platform {
  font-weight: 400;
}
.overview.solutions .supercharge-your-ecommerce-pro-parent .network-switch {
  justify-content: left;
  padding-left: 0;
}
.overview.solutions .sliderHolder .frame-wrapper37 {
  padding-top: 60px;
}
.overview.solutions .solidPurpleBckg {
  padding: 64px 0;
  background-color: var(--brand-indigo);
  width: 100%;
}
.overview.solutions .solidPurpleBckg .elements1 {
  padding: 20px 0;
}
.overview.solutions .solidPurpleBckg .logic-gate12 {
  padding: 0;
}
.overview.solutions .solidPurpleBckg .frame-parent141 {
  margin: 64px;
  background-color: var(--brand-ivory);
  width: auto;
  border-radius: 30px;
}
.overview.solutions .solidPurpleBckg .frame-parent141 .elements1 {
  width: 100%;
}
.overview.solutions .solidPurpleBckg .frame-parent141 .elements1 .text-1,
.overview.solutions .solidPurpleBckg .frame-parent141 .elements1 .text-2,
.overview.solutions .solidPurpleBckg .frame-parent141 .elements1 .text-3 {
  width: 30%;
}
.overview.solutions .solidPurpleBckg .frame-wrapper37 {
  color: #fff;
}
.overview.solutions .symbol-library .image-118-icon.hero-image {
  position: initial;
  width: auto;
  object-fit: cover;
  height: auto;
  max-width: 80%;
}
.overview.solutions .section-01.ivory {
  background-color: var(--brand-indigo);
}
.overview.solutions .social-proof-section-child {
  margin: 64px;
  width: auto;
  color: #fff;
  font-family: var(--body-footnote-regular);
}
.overview.solutions .social-proof-section-child .div {
  font-family: var(--body-footnote-regular);
  color: #fff;
  font-size: var(--headings-big-semi-bold-size);
}
.overview .data-flow-controller > div,
.overview .logic-gate13 > div,
.overview .dijkstra-algorithm > div,
.overview .logic-gate12 > div,
.overview .section-03-wrapper > div,
.overview .pressure-control > div,
.overview .value-transformer > div {
  max-width: 1700px;
}
.overview .data-flow-controller,
.overview .logic-gate13,
.overview .dijkstra-algorithm,
.overview .logic-gate12,
.overview .section-03-wrapper,
.overview .pressure-control,
.overview .value-transformer {
  justify-content: center;
  align-items: center;
  width: 100%;
}
.releva-icon2,
.symbol-icon2 {
  height: 31.7px;
  width: 12.8px;
  position: relative;
}
.releva-icon2 {
  height: 27.2px;
  width: 113.7px;
}
.number-cruncher,
.releva-logo2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.releva-logo2 {
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-smi-8);
}
.number-cruncher {
  margin-top: -3407px;
  align-items: flex-start;
  padding: 0 var(--padding-47xl) 3375px;
  flex-shrink: 0;
}
.b4-icon {
  width: 100%;
  height: 728px;
  position: absolute;
  margin: 0 !important;
  top: 575px;
  right: 0;
  left: 0;
  overflow: hidden;
}
.b4-icon,
.how-it-works,
.logic-gate {
  max-width: 100%;
  flex-shrink: 0;
}
.how-it-works {
  margin: 0;
  width: 478px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.logic-gate {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.boosting-your-ecommerce {
  width: 490px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
}
.logic-gate1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 93px;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.quick-integrations {
  margin: 0;
  width: 617px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.logic-gate2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-5xl) var(--padding-18xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.we-provide-seamless {
  width: 617px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.logic-gate3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-52xl) var(--padding-18xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.logic-gate3,
.logic-gate4,
.solutions {
  max-width: 100%;
  flex-shrink: 0;
}
.solutions {
  margin: 0;
  width: 604px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.logic-gate4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.your-toolbox-to {
  width: 604px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.logic-gate5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-108xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.logic-gate5,
.logic-gate6,
.results {
  max-width: 100%;
  flex-shrink: 0;
}
.results {
  margin: 0;
  width: 604px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.logic-gate6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.delivering-exceptional-results {
  width: 604px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.logic-gate7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-70xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.faq,
.logic-gate7,
.logic-gate8 {
  max-width: 100%;
  flex-shrink: 0;
}
.faq {
  margin: 0;
  width: 604px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.logic-gate8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.your-questions-answered {
  width: 604px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.logic-gate9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-80xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.absolutely-we-prioritize,
.is-my-customer {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
}
.is-my-customer {
  line-height: 26px;
  font-weight: 600;
}
.absolutely-we-prioritize {
  font-size: var(--body-default-semi-bold-size);
  line-height: 28px;
}
.canvas-designer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  min-width: 842px;
  max-width: 100%;
}
.team-blog-icon {
  width: 30.1px;
  height: 32px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.value-modifier {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lgi) 0 0;
}
.c-t-a-background {
  width: 1334px;
  border-bottom: 1px solid var(--brand-ivory);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: var(--padding-17xl) var(--padding-9xs);
  max-width: 100%;
  row-gap: 20px;
  z-index: 2;
}
.c-t-a-background,
.layout-manager,
.overview {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.button-name28 {
  color: #fff;
}
.layout-manager {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.overview {
  width: 100%;
  /* height: 14108px; */
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  letter-spacing: normal;
}
@media screen and (max-width: 1225px) {
  .number-cruncher {
    padding-bottom: 927px;
    box-sizing: border-box;
  }
  .canvas-designer {
    min-width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .overview.solutions .solutionsHero .releva-homepage-child,
  .overview.solutions .solutionsHero .symbol-library {
    width: 100%;
  }
  .overview.solutions .solutionsHero .symbol-library .image-118-icon.hero-image {
    min-width: 400px;
  }
  .faq,
  .how-it-works,
  .quick-integrations,
  .results,
  .solutions {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
}
@media screen and (max-width: 450px) {
  .overview .logic-gate12 > div {
    width: auto;
    max-width: 100%;
  }
  .boosting-your-ecommerce {
    width: 100%;
  }
  .number-cruncher {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    padding-bottom: 603px;
    box-sizing: border-box;
  }
  .faq,
  .how-it-works,
  .quick-integrations,
  .results,
  .solutions {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .is-my-customer {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .overview {
    height: auto;
  }
}
.overview.webinar .webinarForm {
  width: 100%;
}

.overview.webinar .webinarForm #u_body .container {
  width: 100%;
}
.overview.webinar .webinarForm #u_body .container p {
  margin: 0;
}
