.vuesaxlinearwallet-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl);
}
.readytosee-releva {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 600;
  display: inline-block;
  min-width: 114px;
}
.readytosee-releva-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 var(--padding-2xs);
}
.save {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.c-s-card,
.facebook-wordmark-logo-brandlo {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.facebook-wordmark-logo-brandlo {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-10xs) var(--padding-sm) var(--padding-8xs-1) var(--padding-mini);
  text-align: center;
  font-size: var(--body-large-regular-size);
}
.c-s-card {
  flex: 1;
  border-radius: 13.59px;
  background-color: var(--color-lightsteelblue);
  flex-direction: column;
  padding: var(--padding-30xl) var(--padding-15xl-3) var(--padding-37xl-7) var(--padding-16xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  min-width: 153px;
  flex-shrink: 0;
}
.facebook-wordmark-logo-brandlo1,
.frame-icon2 {
  height: 82px;
  width: 82px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.facebook-wordmark-logo-brandlo1 {
  height: 24.1px;
  width: 135px;
  display: none;
}
.c-s-card-parent,
.c-s-card1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}
.c-s-card1 {
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  padding: var(--padding-70xl) var(--padding-41xl-8) var(--padding-69xl-8) var(--padding-42xl-5);
  gap: var(--gap-5xs);
}
.c-s-card-parent {
  width: 420.3px;
  gap: var(--gap-xs-7);
}
.frame-child48 {
  height: 494.6px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
}
.google-logo-9808-1-icon {
  width: 107px;
  height: 107px;
  position: relative;
  object-fit: cover;
}
.facebook-wordmark-logo-brandlo2 {
  align-self: stretch;
  height: 24.1px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 1;
}
.google-logo-9808-1-parent {
  height: 259.8px;
  width: 204.3px;
  margin: 0 !important;
  position: absolute;
  top: -19.2px;
  left: -184px;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-37xl-7) var(--padding-15xl-3) var(--padding-37xl-7) var(--padding-16xl);
  box-sizing: border-box;
  z-index: 1;
}
.frame-parent82 {
  position: absolute;
  top: 19px;
  left: 0;
  width: 557.6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.facebook-wordmark-logo-brandlo3 {
  height: 24.1px;
  width: 135px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.vuesaxlineardiagram-parent {
  position: absolute;
  top: 0;
  left: 32px;
  border-radius: 13.59px;
  background-color: var(--color-plum);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-71xl) var(--padding-42xl-8) var(--padding-70xl-8) var(--padding-43xl-5);
  gap: var(--gap-4xs);
  z-index: 1;
}
.frame-parent81 {
  height: 513.6px;
  width: 557.6px;
  position: relative;
  flex-shrink: 0;
}
.i-09,
.i-09-inner {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.i-09-inner {
  width: 421px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 var(--padding-165xl);
}
.i-09 {
  /* height: 746px; */
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  /* padding: var(--padding-88xl) var(--padding-xl) var(--padding-360xl-2); */
  gap: var(--gap-smi-2);
  min-width: 513px;
}
.releva-saves-you,
.spend-50-less {
  align-self: stretch;
  position: relative;
}
.spend-50-less {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.releva-saves-you {
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.data-aggregator-container,
.data-aggregator4,
.i-09-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.data-aggregator4 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.data-aggregator-container,
.i-09-parent {
  max-width: 100%;
}
.data-aggregator-container {
  width: 494px;
  flex-direction: column;
  padding: var(--padding-263xl) 0 0;
  box-sizing: border-box;
  min-width: 494px;
  font-size: var(--headings-h3-semi-bold-size);
}
.i-09-parent {
  width: 1442px;
  flex-direction: row;
  gap: var(--gap-140xl);
  text-align: left;
  font-size: var(--font-size-36xl);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1500px) {
  .data-aggregator-container {
    flex: 1;
  }
  .i-09-parent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .i-09 {
    padding-top: var(--padding-51xl);
    padding-bottom: var(--padding-227xl);
    box-sizing: border-box;
  }
  .i-09-parent {
    gap: 159px 79px;
  }
}
@media screen and (max-width: 850px) {
  .readytosee-releva {
    font-size: var(--font-size-25xl);
  }
  .i-09,
  .i-09-inner {
    box-sizing: border-box;
  }
  .i-09-inner {
    padding-left: var(--padding-73xl);
  }
  .i-09 {
    padding-top: 0;
    padding-bottom: 0;
    min-width: 100%;
  }
  .spend-50-less {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .data-aggregator-container {
    min-width: 100%;
  }
  .i-09-parent {
    gap: 159px 40px;
  }
}
@media screen and (max-width: 450px) {
  .readytosee-releva {
    font-size: var(--font-size-14xl);
  }
  .save {
    font-size: var(--body-small-regular-size);
    line-height: 14px;
  }
  .c-s-card-parent {
    flex-wrap: wrap;
  }
  .i-09-inner {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .i-09 {
    height: auto;
  }
  .spend-50-less {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .data-aggregator-container {
    padding-top: 0;
    box-sizing: border-box;
  }
  .i-09-parent {
    gap: 59px 20px;
  }
}
