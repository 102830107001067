.feature-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
}

.feature-cell {
  flex: 4;
  letter-spacing: -0.01em;
  line-height: 26px;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  padding: 22px;
}

.availability-cell {
  flex: 1;
  padding: 22px 0;
}
