.icon-01 {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
}
.transform-one-time-buyers {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  font-family: inherit;
}
.unleash-the-power {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.icon-01-parent,
.transform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.transform {
  align-self: stretch;
  gap: var(--gap-xs);
}
.icon-01-parent {
  width: 423px;
  gap: var(--gap-9xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 450px) {
  .transform-one-time-buyers {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
}
