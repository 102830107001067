.achievements .frame-parent30 {
  justify-content: center;
}
.achievements .experience-the-releva {
  width: 840px;
}
.achievements .c-t-a-background2 {
  gap: 20px;
}
.experience-the-releva {
  margin: 0;
  width: 540px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.icon-02 {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
}
.reporting-and-analytics {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  font-family: inherit;
}
.know-exactly-how {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.why-work-with-us,
.why-work-with-us-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.why-work-with-us {
  gap: var(--gap-xs);
}
.why-work-with-us-wrapper {
  padding: var(--padding-9xs);
}
.frame-parent30,
.reporting2 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.reporting2 {
  width: 435px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.frame-parent30 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
}
.c-t-a-background2,
.how-it-works1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}
.c-t-a-background2 {
  width: 1513px;
  gap: var(--gap-93xl);
}
.how-it-works1 {
  align-self: stretch;
  overflow: hidden;
  padding: 0 var(--padding-xl) var(--padding-8xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  margin-bottom: 150px;
}
@media screen and (max-width: 1575px) {
  .frame-parent30 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 825px) {
  .experience-the-releva {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .c-t-a-background2 {
    gap: 56px 112px;
  }
}
@media screen and (max-width: 450px) {
  .experience-the-releva {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .reporting-and-analytics {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .c-t-a-background2 {
    gap: 28px 112px;
  }
  .icon-02,
  .icon-01 {
    left: 40%;
  }
}
