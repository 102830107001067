.customers,
.value-comparator1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.customers {
  flex: 1;
}
.value-comparator1 {
  align-self: stretch;
  padding: 0 var(--padding-45xl) var(--padding-53xl);
  box-sizing: border-box;
}
@media screen and (max-width: 1225px) {
  .value-comparator1 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .value-comparator1 {
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
