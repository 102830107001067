#response-list {
  border: none;
  overflow-y: auto;
  flex: 1;
}

#response-list p,
#response-list li {
  font-size: 16px;
}

.response-container .avatar-image {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}


.response-container .response-content {
  display: flex;
  flex-direction: column;
  border: none;
}

.response-container pre {
  max-width: 100%;
  margin: 0 !important;
  white-space: break-spaces;
}

.response-container .prompt-content {
  background: transparent !important;
  padding: 0 !important;
  margin-top: 5px;
}

.response-container .prompt-content p:first-child {
  margin-top: 0;
}

.ai-image {
  width: 500px;
  height: auto;
}

.error-response {
  color: rgb(220, 0, 0) !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: -3rem;
  margin-bottom: 1rem;
  position: relative;
}

.spinner-container::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 10;
  display: none;
}

.spinner-container.loading::after {
  display: block;
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 0.2em solid #5f2a6a;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}