.more-than {
  width: 78px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}
.more-than-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-27xl) 0 var(--padding-28xl);
}
.c-t-a1,
.h21 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.c-t-a1 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 109px;
}
.h21 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.wrapper1 {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.c-t-a-button-instance-parent,
.input-processor1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.c-t-a-button-instance-parent {
  flex: 1;
  justify-content: flex-start;
}
.input-processor1 {
  width: 142px;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  font-size: var(--headings-big-semi-bold-size);
}
.revenue-increase-from {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.frame-parent101 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-plum);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-26xl) var(--padding-20xl) var(--padding-22xl);
  gap: var(--gap-11xl);
}
.more-than1 {
  width: 78px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}
.sequential-branch {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-27xl) 0 var(--padding-28xl);
}
.ismycustomerdatasafewith-relev1,
.readytosee-relevainaction1 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.ismycustomerdatasafewith-relev1 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 123px;
}
.readytosee-relevainaction1 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.readytosee-relevainaction-wrapper {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
  box-sizing: border-box;
  margin-left: -6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.ismycustomerdatasafewith-relev-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper40 {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  font-size: var(--headings-big-semi-bold-size);
}
.revenue-increase-from1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.function-tree,
.sequential-branch-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.sequential-branch-parent {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--brand-lemon);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-26xl) var(--padding-20xl) var(--padding-22xl);
  gap: var(--gap-11xl);
}
.function-tree {
  width: 249px;
  align-items: flex-start;
  gap: var(--gap-2xl);
}
.more-than2 {
  width: 78px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}
.more-than-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-27xl) 0 var(--padding-28xl);
}
.div17,
.h22 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.div17 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 123px;
}
.h22 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.wrapper2 {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
  box-sizing: border-box;
  margin-left: -6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-wrapper41,
.parent14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.parent14 {
  flex: 1;
  justify-content: flex-start;
}
.frame-wrapper41 {
  width: 150px;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  font-size: var(--headings-big-semi-bold-size);
}
.revenue-increase-from2 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.frame-parent102 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-26xl) var(--padding-20xl) var(--padding-22xl);
  gap: var(--gap-11xl);
}
.more-than3 {
  width: 78px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}
.more-than-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-27xl) 0 var(--padding-28xl);
}
.div18,
.h23 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.div18 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 123px;
}
.h23 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.wrapper3 {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs) 0 0;
  box-sizing: border-box;
  margin-left: -6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-wrapper42,
.parent15 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.parent15 {
  flex: 1;
  justify-content: flex-start;
}
.frame-wrapper42 {
  width: 150px;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs);
  box-sizing: border-box;
  font-size: var(--headings-big-semi-bold-size);
}
.increase-in-the {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.frame-parent103,
.function-tree1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent103 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-26xl) var(--padding-20xl) var(--padding-22xl);
  gap: var(--gap-11xl);
}
.function-tree1 {
  width: 249px;
  align-items: flex-start;
  gap: var(--gap-2xl);
}
.email4,
.other,
.recommender {
  width: 40px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 40px;
}
.other,
.recommender {
  width: 109px;
  min-width: 109px;
}
.other {
  width: 43px;
  min-width: 43px;
}
.figpie-icon {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.product-data-center-product-re1 {
  height: 496px;
  width: 496px;
  margin: 0 !important;
  position: absolute;
  right: -459px;
  bottom: -394px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: var(--padding-15xl) var(--padding-xs);
  box-sizing: border-box;
}
.termsofuse-cookies-privacy-pol {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.banner {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 53px;
}
.banner-wrapper {
  width: 53px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-272xl) 0 0;
  box-sizing: border-box;
  margin-left: -77px;
}
.facebook-instagram-linked-in-y2,
.return-statement,
.switch-case {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.facebook-instagram-linked-in-y2 {
  padding: 0 var(--padding-5xl) 0 0;
}
.return-statement,
.switch-case {
  align-self: stretch;
}
.switch-case {
  border-radius: var(--br-5xs);
  background-color: var(--brand-indigo);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-50xl) var(--padding-464xl) var(--padding-165xl)
    var(--padding-67xl);
  gap: var(--gap-49xl);
  height: 663px;
}
.data-container,
.data-warden,
.until-loop {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.until-loop {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
  box-sizing: border-box;
  min-width: 513px;
  color: var(--w-background);
  height: 663px;
}
.data-container,
.data-warden {
  flex-direction: row;
  justify-content: center;
}
.data-container {
  width: 1331px;
  flex-wrap: wrap;
  gap: var(--gap-2xl-5);
}
.data-warden {
  align-self: stretch;
  padding: 0 var(--padding-2xl) var(--padding-141xl) var(--padding-xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-small-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 850px) {
  .c-t-a1 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .h21 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .ismycustomerdatasafewith-relev1 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .readytosee-relevainaction1 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div17 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .h22 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div18 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .h23 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .switch-case {
    gap: 34px 68px;
    padding: var(--padding-26xl) var(--padding-222xl) var(--padding-101xl)
      var(--padding-24xl);
    box-sizing: border-box;
  }
  .until-loop {
    min-width: 100%;
  }
  .data-warden {
    padding-bottom: var(--padding-85xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .c-t-a1 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .h21 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .ismycustomerdatasafewith-relev1 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .readytosee-relevainaction1 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div17 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .h22 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div18 {
    font-size: var(--font-size-11xl);
    line-height: 40px;
  }
  .h23 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .switch-case {
    gap: 17px 68px;
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .data-warden {
    padding-bottom: var(--padding-49xl);
    box-sizing: border-box;
  }
}
