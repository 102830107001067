.pricing-page.aihub-product .availability-cell {
  display: none;
}
.pricing-page.aihub-product .data-platform-wrapper {
  background-color: #3a1a4b;
}
.pricing-page.aihub-product .data-platform-wrapper .data-platform1 {
  color: #fff;
}
.pricing-page.aihub-product .releva-pricing-plans3 {
  margin-bottom: 0 !important;
}
.pricing-page.aihub-product .releva-pricing-plans3,
.pricing-page.aihub-product .compare-plans2 {
  width: 100%;
}

/* CSS for the toggle switch */
.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-switch {
  position: relative;
  width: 60px;
  height: 34px;
}

/* Hide default checkbox */
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider */
.toggle-switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* Rounded sliders */
.toggle-switch label:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* On state */
.toggle-switch input:checked + label {
  background-color: var(--brand-indigo);
}

/* On state indicator */
.toggle-switch input:checked + label:before {
  transform: translateX(26px);
}

/* Text labels */
.toggle-label {
  font-size: 14px;
}

/* Adjusted styling for layout */
.toggle-label:first-child {
  margin-right: 10px;
}

.toggle-label:last-child {
  margin-left: 10px;
}

.pricing-page .background-child {
  position: absolute;
  top: 603px;
  left: 839px;
  width: 568px;
  height: 178px;
}
.mauDescription {
  font-size: 14px;
  font-style: italic;
  width: 100%;
  text-align: left;
  padding-left: 6%;
}
.pricing-page .mb-0 {
  margin-bottom: 0;
}
.pricing-page .mt-0 {
  margin-top: 0;
}
.pricing-page .releva-pricing-plans3 {
  margin-bottom: 80px !important;
}
.pricing-page .background-item,
.group-inner {
  position: absolute;
  top: 5775px;
  left: 254px;
  width: 432px;
  height: 52px;
}
.pricing-page .group-inner {
  top: 0;
  left: 204px;
  width: 20px;
  height: 20px;
}
.pricing-page .group-child1,
.group-child2,
.group-child3,
.group-div {
  position: absolute;
  top: 0;
  left: 408px;
  width: 20px;
  height: 20px;
}
.pricing-page .group-child2,
.group-child3,
.group-div {
  left: 612px;
}
.pricing-page .group-child3,
.group-div {
  left: 0;
}
.pricing-page .group-div {
  top: 4815px;
  left: 785px;
  width: 632px;
  display: none;
}
.pricing-page .group-child4,
.group-child5,
.group-child6,
.group-child7 {
  position: absolute;
  top: 0;
  left: 204px;
  width: 20px;
  height: 20px;
}
.pricing-page .group-child5,
.group-child6,
.group-child7 {
  left: 408px;
}
.pricing-page .group-child6,
.group-child7 {
  left: 612px;
}
.pricing-page .group-child7 {
  left: 0;
}
.pricing-page .frame-parent20,
.group-child8,
.group-child9 {
  position: absolute;
  top: 6081px;
  left: 774px;
  width: 632px;
  height: 20px;
}
.pricing-page .group-child8,
.group-child9 {
  top: 0;
  left: 204px;
  width: 20px;
}
.pricing-page .group-child9 {
  left: 408px;
}
.frame-parent21,
.group-child10,
.group-child11 {
  position: absolute;
  top: 0;
  left: 612px;
  width: 20px;
  height: 20px;
}

.frame-parent21,
.group-child11 {
  left: 0;
}

.frame-parent21 {
  top: 4901px;
  left: 785px;
  width: 632px;
}
.pricing-page .background {
  background-color: var(--w-background);
  width: 100%;
  height: 100%;
}
.pricing-page .try-releva-free3 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h2-semi-bold-size);
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
}
.pricing-page .book-a-session2,
.button-name10 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.pricing-page .book-a-session2 {
  width: 527px;
  max-width: 100%;
}
.pricing-page .button-name10 {
  min-width: 110px;
}
.pricing-page .button10,
.cta2 {
  display: flex;
  align-items: center;
}
.pricing-page .button10 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  color: var(--w-background);
}
.pricing-page .cta2 {
  position: absolute;
  top: 8833px;
  left: calc(50% - 357px);
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-16xl);
  max-width: 100%;
}
.pricing-page .try-releva-free4 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: var(--font-size-23xl);
  letter-spacing: -0.04em;
  line-height: 41px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--brand-indigo);
  text-align: center;
  display: inline-block;
  max-width: 100%;
}
.pricing-page .try-releva-free-for-30-days1 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-seashell-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.pricing-page .button-name11 {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  font-family: var(--body-footnote-regular);
  color: var(--w-background);
  text-align: center;
  display: inline-block;
  min-width: 110px;
}
.pricing-page .button11,
.cta3 {
  display: flex;
  align-items: center;
}
.pricing-page .button11 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
}
.pricing-page .cta3 {
  position: initial;
  top: 4277px;
  left: 3px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-19xl);
  max-width: 100%;
  width: 100%;
}
.pricing-page .data-platform1 {
  width: 384px;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--brand-indigo);
  text-align: left;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.pricing-page .data-platform-wrapper {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-seashell-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}

.data-platform {
  position: initial;
  left: 0;
  max-width: 100%;
}
.pricing-page .background1 {
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing-page .data-platform {
  /* top: 234px; */
  width: 100%;
  /* height: 407px; */
  margin-bottom: 20px;
}
.pricing-page .features-included1 {
  flex: 1;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--w-background);
  text-align: left;
  display: inline-block;
  max-width: 100%;
}
.pricing-page .features-included-wrapper,
.plans-inner3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.pricing-page .features-included-wrapper {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  white-space: nowrap;
}
.pricing-page .plans-inner3 {
  flex: 1;
  flex-direction: column;
  min-width: 281px;
}
.pricing-page .startup1 {
  flex: 1;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--w-background);
  text-align: left;
}
.pricing-page .plans-inner4,
.startup-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing-page .startup-container {
  width: 177px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
}
.pricing-page .plans-inner4 {
  flex-direction: column;
}
.pricing-page .growth1 {
  flex: 1;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--w-background);
  text-align: left;
}
.pricing-page .growth-container,
.plans-inner5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing-page .growth-container {
  width: 177px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  white-space: nowrap;
}
.pricing-page .plans-inner5 {
  flex-direction: column;
}
.pricing-page .standard1 {
  flex: 1;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--w-background);
  text-align: left;
}
.pricing-page .plans-inner6,
.standard-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing-page .standard-container {
  width: 177px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  white-space: nowrap;
}
.pricing-page .plans-inner6 {
  flex-direction: column;
}
.pricing-page .enterprice2 {
  flex: 1;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--w-background);
  text-align: left;
}
.pricing-page .enterprice-frame,
.plans-inner7,
.plans1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricing-page .enterprice-frame {
  width: 177px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  white-space: nowrap;
}
.pricing-page .plans-inner7,
.plans1 {
  flex-direction: column;
}
.pricing-page .plans1 {
  position: initial;
  top: 141px;
  left: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-13xl);
  max-width: 100%;
  width: 100%;
  margin: 20px 0;
}
.pricing-page .compare-plans3 {
  margin: 0;
  /* position: absolute;
  top: 0;
  left: calc(50% - 635px); */
  font-size: var(--headings-h2-semi-bold-size);
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--brand-indigo);
  text-align: center;
  display: inline-block;
  width: 100%;
}
.pricing-page .compare-plans2 {
  margin: 0;
  /* position: absolute;
  top: 2192px;
  left: 229px; */
  /* width: 100%; */
  /* height: 4450px; */
  max-width: 1200px;
}
.pricing-page .releva-pricing-plans3 {
  margin: 0;
  width: 630.9px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}
.pricing-page .emails-included1 {
  font-weight: 500;
  color: var(--color-black);
}
.pricing-page .unlimited-emails-included-container1 {
  position: absolute;
  top: 373px;
  left: 35px;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  width: 250px;
  height: 27px;
}
.pricing-page .days-free-trial1 {
  font-weight: 500;
  color: var(--color-black);
}
.pricing-page .days-free-trial-container1 {
  position: absolute;
  top: 340px;
  left: 35px;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  width: 250px;
  height: 27px;
}
.pricing-page .base-tariff-mau-usage1 {
  position: absolute;
  top: 267px;
  left: 35px;
  width: 252px;
  height: 62px;
}
.pricing-page .base-tariff-mau-usage1,
.monthly-active-users2,
.price-subscription1 {
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.pricing-page .price-subscription1 {
  position: absolute;
  top: 225px;
  left: 35px;
  text-decoration: underline;
  color: var(--color-black);
  width: 250px;
  height: 27px;
}
.pricing-page .monthly-active-users2 {
  width: 460px;
  height: 62px;
  position: relative;
  flex-shrink: 0;
  max-width: 184%;
}
.pricing-page .div28,
.title7 {
  position: absolute;
  left: 35px;
  width: 250px;
}
.pricing-page .title7 {
  top: 170px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-default-semi-bold-size);
}
.pricing-page .div28 {
  top: 117px;
  font-size: var(--font-size-23xl);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  display: inline-block;
}
.pricing-page .enterprice3,
.more-than1 {
  letter-spacing: -0.01em;
  display: inline-block;
}
.pricing-page .more-than1 {
  position: absolute;
  top: 87px;
  left: 35px;
  font-size: var(--body-default-semi-bold-size);
  line-height: 28px;
  width: 250px;
}
.pricing-page .enterprice3 {
  width: 129px;
  position: relative;
  line-height: 26px;
  font-weight: 600;
  flex-shrink: 0;
}
.pricing-page .enterprice-wrapper1 {
  position: absolute;
  top: 0;
  left: 20px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  white-space: nowrap;
  font-size: var(--body-large-regular-size);
  color: var(--w-background);
}
.pricing-page .enterprise-plan1 {
  height: 400px;
  width: 305px;
  position: relative;
}
.pricing-page .releva-pricing-plans2,
.startup-plan-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.pricing-page .startup-plan-parent {
  align-self: stretch;
  flex-direction: row;
  row-gap: 20px;
  text-align: left;
  font-size: var(--body-small-regular-size);
}
.pricing-page .releva-pricing-plans2 {
  /* position: absolute;
  top: 955px;
  left: 262px; */
  flex-direction: column;
  gap: 0;
  font-size: var(--headings-h2-semi-bold-size);
}
.pricing-page .trust-your-decisions1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
}
.pricing-page .maximize-the-value1 {
  width: 830px;
  position: relative;
  font-size: var(--headings-h5-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}
.pricing-page .header-copy1 {
  /* position: absolute;
  top: 263px;
  left: calc(50% - 669px);
  width: 1329px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  /* max-width: 100%; */
  font-size: var(--headings-h1-semi-bold-size);
  width: 100%;
}
.pricing-page {
  width: 100%;
  /* height: 9946px; */
  /* position: relative; */
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1225px) {
  .pricing-page .startup-plan-parent {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1050px) {
  .pricing-page .compare-plans2 {
    height: auto;
    min-height: 4450;
  }
}
@media screen and (max-width: 750px) {
  .pricing-page .try-releva-free3 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .pricing-page .try-releva-free4 {
    font-size: var(--font-size-15xl);
    line-height: 50px;
  }
  .pricing-page .compare-plans3,
  .releva-pricing-plans3 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .pricing-page .div28 {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .pricing-page .trust-your-decisions1 {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
}
@media screen and (max-width: 450px) {
  .pricing-page .try-releva-free3 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .pricing-page .button10 {
    width: calc(100% - 40px);
  }
  .pricing-page .try-releva-free4 {
    font-size: var(--font-size-6xl);
    line-height: 38px;
  }
  .pricing-page .button11 {
    width: calc(100% - 40px);
  }
  .pricing-page .data-platform1,
  .startup1 {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .pricing-page .compare-plans3,
  .releva-pricing-plans3 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .pricing-page .div28 {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .pricing-page .trust-your-decisions1 {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .pricing-page .maximize-the-value1 {
    font-size: var(--font-size-lgi);
    line-height: 22px;
  }
  .pricing-page .pricing-page {
    height: auto;
    min-height: 9946;
  }
}
