.div16 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  display: inline-block;
  min-width: 12px;
}
.vuesaxlinearlink-icon {
  width: 36px;
  height: 36px;
  position: relative;
}
.vuesaxlinearlink-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0 0;
}
.parent13 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.add-releva-sdk {
  width: 274px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.person {
  width: 344px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-indigo);
  border: 1px solid var(--brand-amethyst);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-3xl) var(--padding-33xl)
    var(--padding-4xl);
  gap: var(--gap-14xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-ivory);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 450px) {
  .div16 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .person {
    gap: 16px 33px;
  }
}
