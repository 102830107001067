.how-does-releva {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
}
.how-does-releva-collect-and-an-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
  box-sizing: border-box;
  min-width: 842px;
  max-width: 100%;
}
.communication-center-icon1 {
  height: 32px;
  width: 30.1px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent104,
.logic-gate14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
}
.frame-parent104 {
  width: 1334px;
  border-top: 1px solid var(--brand-ivory);
  border-bottom: 1px solid var(--brand-ivory);
  flex-wrap: wrap;
  padding: var(--padding-17xl) var(--padding-9xs);
  row-gap: 20px;
}
.logic-gate14 {
  align-self: stretch;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1225px) {
  .how-does-releva-collect-and-an-wrapper {
    min-width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .how-does-releva {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
}
