#prompt-input {
  flex-grow: 1;
  padding: 10px 10px;
  color: black;
  background-color: white !important;
  overflow: auto;
  border: 0px none !important;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 16px;
  resize: none;
  outline: 0 none !important;
  font-family: inherit;
}

#prompt-input:focus {
  box-shadow: none;
}