.custom-integrations1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
}
.we-provide-seamless2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.custom-integrations-parent {
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  flex-shrink: 0;
}
.custom-integrations-inner,
.path-finder-wrapper,
.path-finder1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.custom-integrations-inner {
  width: 1062px;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  flex-shrink: 0;
}
.path-finder-wrapper,
.path-finder1 {
  justify-content: flex-start;
}
.path-finder1 {
  width: 1081px;
  flex-wrap: wrap;
  padding: 0 var(--padding-xl) 0 0;
  box-sizing: border-box;
  gap: 16px 12.5px;
  min-height: 426px;
}
.path-finder-wrapper {
  align-self: stretch;
}
.get-started6 {
  /* width: 98px; */
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 98px;
}
.get-demo-wrapper2,
.get-demo9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.get-demo9 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-13xl);
  white-space: nowrap;
}
.get-demo-wrapper2 {
  flex-direction: column;
  padding: var(--padding-2xl) 0 0;
}
.footer-c-t-a,
.vector-icon25 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vector-icon25 {
  top: 88px;
  left: 213px;
  width: 451px;
  height: 449px;
  z-index: 1;
}
.social-media1 {
  height: 100%;
  width: 767px;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: -63px;
  bottom: 0;
}
.b-f-s-queue {
  height: 664px;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  min-width: 458px;
  max-width: 100%;
}
.b-f-s-queue,
.frame-parent95,
.maximum-path-finder {
  display: flex;
  justify-content: flex-start;
}
.maximum-path-finder {
  width: 945px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-60xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--web-secondary-ivory-light);
}
.frame-parent95 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-16xl);
  flex-shrink: 0;

  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
}
.custom-integrations,
.custom-integrations-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.custom-integrations {
  height: 1018px;
  flex: 1;
  background-color: var(--brand-indigo);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-98xl) 0 var(--padding-767xl) 10%;
  gap: var(--gap-60xl);
}
.custom-integrations-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-118xl);
  flex-shrink: 0;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-ivory);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1225px) {
  .maximum-path-finder {
    gap: var(--gap-20xl);
  }
  .custom-integrations {
    height: auto;
    gap: 39px 79px;
    padding-left: var(--padding-147xl);
    padding-top: var(--padding-30xl);
    padding-bottom: var(--padding-313xl);
    box-sizing: border-box;
  }
  .custom-integrations-wrapper {
    padding-bottom: var(--padding-70xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .custom-integrations1 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .b-f-s-queue {
    min-width: 100%;
  }
  .maximum-path-finder {
    gap: var(--gap-xl);
  }
  .frame-parent95 {
    gap: 17px 35px;
  }
  .custom-integrations {
    padding-top: var(--padding-13xl);
    padding-bottom: var(--padding-197xl);
    box-sizing: border-box;
  }
  .custom-integrations-wrapper {
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .custom-integrations1 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .custom-integrations {
    gap: 20px 79px;
    padding-left: var(--padding-xl);
    box-sizing: border-box;
    padding-bottom: 60px;
  }
  .custom-integrations-parent {
    width: 100%;
  }
  .b-f-s-queue {
    display: none;
  }
}
