.pricing-page .are-you-a1 {
  margin: 0;
  /* position: absolute;
  top: 131px;
  left: 438px; */
  font-size: var(--headings-h3-semi-bold-size);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  width: 100%;
}
.buttonsHolder {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.pricing-page .button-name70,
.learn-more-about1 {
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.pricing-page .learn-more-about1 {
  /* position: absolute;
  top: 221px;
  left: 612px; */
  font-size: var(--headings-h5-semi-bold-size);
  width: 507px;
}
.pricing-page .button-name70 {
  position: relative;
  min-width: 102px;
}
.pricing-page .shopify-button1 {
  /* position: absolute;
  top: 288px;
  left: calc(50% - 173px); */
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  color: var(--web-secondary-ivory-light);
}
.pricing-page .button-name71 {
  /* position: relative; */
  letter-spacing: -0.01em;
  line-height: 28px;
}
.pricing-page .merchant-pro-button1 {
  /* position: absolute;
  top: 288px; */
  left: calc(50% - 3.5px);
  border-radius: var(--br-5xs);
  background-color: var(--color-blueviolet-100);
  border: 4px solid var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xl);
  white-space: nowrap;
  color: var(--brand-amethyst);
}
.pricing-page .vector-icon41 {
  position: absolute;
  height: 124.83%;
  width: 31.66%;
  top: -8.88%;
  right: -4.85%;
  bottom: -15.95%;
  left: 73.2%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.pricing-page .shopify-merchantpro-buttons1 {
  /* position: absolute;
  top: 1669px; */
  /* left: 1px; */
  background-color: var(--color-seashell-100);
  width: 100%;
  /* height: 439px; */
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  padding: 150px 0;
  display: none;
}
@media screen and (max-width: 750px) {
  .pricing-page .are-you-a1 {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
}
@media screen and (max-width: 450px) {
  .pricing-page .are-you-a1 {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .pricing-page .learn-more-about1 {
    font-size: var(--font-size-lgi);
    line-height: 22px;
  }
}
