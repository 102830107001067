.web-personalization {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name13,
.your-visitor-lands {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.your-visitor-lands {
  align-self: stretch;
}
.button-name13 {
  width: 110px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
  text-decoration: none;
}
.button20:hover,
.button19:hover,
.button18:hover,
.button17:hover,
.button16:hover,
.button15:hover {
  background-color: var(--color-lightsteelblue);
}
.button15,
.paid-search,
.paid-search-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button15 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  flex-direction: row;
  white-space: nowrap;
  text-decoration: none;
}
.paid-search,
.paid-search-wrapper {
  flex-direction: column;
}
.paid-search {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.paid-search-wrapper {
  width: 519px;
  padding: var(--padding-181xl) 0 0;
  box-sizing: border-box;
  min-width: 519px;
  max-width: 100%;
}
.blazer-icon {
  height: 461.8px;
  width: 392.7px;
  position: absolute;
  margin: 0 !important;
  top: -99.7px;
  right: -164px;
  border-radius: var(--br-2xs);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.image-97-icon {
  height: 173px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.image-97-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-lgi) 0 var(--padding-xl);
}
.company-resources-box,
.output-filter-child {
  height: 14.8px;
  position: relative;
  border-radius: 44.48px;
  background-color: var(--brand-ivory);
  flex-shrink: 0;
}
.output-filter-child {
  align-self: stretch;
}
.company-resources-box {
  width: 151.8px;
}
.output-filter {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs-8);
}
.shorts,
.sneakers-icon {
  border-radius: var(--br-2xs);
  overflow: hidden;
}
.shorts {
  flex: 1;
  background-color: var(--web-secondary-ivory-light);
  border: 0.9px solid var(--web-secondary-ivory-dark);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-29xl-3) var(--padding-22xl) var(--padding-30xl-7) var(--padding-3xl-7);
  gap: var(--gap-69xl-3);
  z-index: 1;
}
.sneakers-icon {
  height: 461.8px;
  width: 392.7px;
  position: absolute;
  margin: 0 !important;
  bottom: -125.4px;
  left: -175.7px;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 2;
}
.blazer-parent {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.i-01,
.p13 {
  flex: 1;
  justify-content: flex-start;
  box-sizing: border-box;
}
.p13 {
  height: 622.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* padding: var(--padding-80xl-7) var(--padding-148xl) var(--padding-81xl); */
  max-width: 100%;
}
.i-01 {
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  /* padding: var(--padding-38xl) var(--padding-54xl); */
  min-width: 513px;
}
.container-shapes,
.container-shapes-wrapper,
.i-01 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.container-shapes {
  flex: 1;
  justify-content: center;
  gap: var(--gap-138xl);
}
.container-shapes-wrapper > div {
  width: 1593px;
  max-width: 1593px;
}
.container-shapes-wrapper {
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-45xl) var(--padding-55xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h3-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1250px) {
  .paid-search-wrapper {
    flex: 1;
  }
  .container-shapes {
    flex-wrap: wrap;
    gap: 57px 78px;
  }
  .container-shapes-wrapper {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .web-personalization {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .paid-search-wrapper {
    min-width: 100%;
  }
  .i-01,
  .p13 {
    padding: var(--padding-46xl) var(--padding-64xl);
    box-sizing: border-box;
  }
  .i-01 {
    padding: var(--padding-18xl) var(--padding-17xl);
    min-width: 100%;
  }
  .container-shapes {
    gap: 57px 39px;
  }
  .container-shapes-wrapper {
    padding-bottom: var(--padding-29xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .product-page .frame-parent57,
  .product-page .container-shapes-wrapper > div {
    width: auto;
    max-width: 100%;
  }
  .web-personalization {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .paid-search-wrapper {
    padding-top: 0;
  }
  .p13,
  .paid-search-wrapper,
  .shorts {
    box-sizing: border-box;
  }
  .shorts {
    gap: 44px 88.3px;
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-13xl);
  }
  .p13 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
  }
  .container-shapes {
    gap: 57px 20px;
  }
  .container-shapes-wrapper {
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
}
