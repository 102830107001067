.shapeHolder {
  position: relative;
  width: 100%;
}
.craft-personalized-experiences,
.image-collection.analytics,
.image-collection.automationSlider,
.image-collection.omnichannel,
.ai {
  display: none;
}
.active .craft-personalized-experiences,
.image-collection.analytics.active,
.image-collection.omnichannel.active,
.image-collection.automationSlider.active,
.ai.active {
  display: flex;
}
.the-data-driven-marketers {
  margin: 0 !important;
  /* width: 774px; */
  /* position: absolute; */
  /* top: 0;
  left: calc(50% - 387px); */
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  text-align: center;
  display: inline-block;
}
.shorts-icon {
  height: 282.5px;
  width: 240.2px;
  position: absolute;
  margin: 0 !important;
  top: -54.3px;
  right: -119.6px;
  border-radius: 6.73px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.image-94-icon1,
.recommended-for-you1 {
  position: relative;
  flex-shrink: 0;
}
.recommended-for-you1 {
  width: 104.8px;
  height: 35px;
  letter-spacing: -0.02em;
  line-height: 17.13px;
  font-weight: 600;
  display: inline-block;
}
.image-94-icon1 {
  height: 59.9px;
  width: 130.3px;
  object-fit: cover;
}
.circle,
.divider {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.circle {
  flex-direction: row;
  padding: 0 0 0 var(--padding-smi-9);
}
.divider {
  flex-direction: column;
  gap: var(--gap-9xl-1);
}
.frame-child49,
.frame-child50 {
  height: 9.1px;
  position: relative;
  border-radius: 27.21px;
  background-color: var(--brand-ivory);
}
.frame-child49 {
  align-self: stretch;
}
.frame-child50 {
  width: 92.8px;
}
.rectangle-parent8,
.sneakers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-parent8 {
  width: 143.5px;
  gap: var(--gap-7xs-3);
}
.sneakers {
  flex: 1;
  border-radius: 6.73px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.6px solid var(--web-secondary-ivory-dark);
  overflow: hidden;
  padding: var(--padding-lgi-6) var(--padding-sm-7) var(--padding-11xl-5);
  gap: var(--gap-27xl-7);
  flex-shrink: 0;

  z-index: 1;
}
.marketers-swiss-knife-inner,
.shorts-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.shorts-parent {
  width: 183.5px;
  justify-content: flex-start;
  position: relative;
}
.marketers-swiss-knife-inner {
  width: 346.5px;
  justify-content: center;
  max-width: 100%;
}
.p11,
.p12,
.p14,
.p15,
.p16,
.p17,
.p5,
.p6,
.p7,
.p8,
.p9 {
  margin: 0;
}
.div13 {
  width: 18px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 32px;
  display: inline-block;
}
.protilela1 {
  margin: 0;
}
.d561ad66-014-a311-a008-bffb4d1 {
  margin: 0;
  color: var(--color-plum);
}
.domainid-71 {
  margin: 0;
}
.span1 {
  color: var(--color-lightsteelblue);
}
.email-inforelevaai,
.group-45451,
.subscribedat {
  margin: 0;
}
.september-17-2020-63014-pm {
  margin: 0;
  color: var(--color-plum);
}
.timestamp {
  margin: 0;
}
.september-17-2020-63014-pm1 {
  margin: 0;
  color: var(--color-plum);
}
.profilejoin {
  color: var(--brand-ivory);
}
.span2 {
  color: var(--color-plum);
}
.profilejoin-1-item {
  margin: 0;
}
.null {
  font-family: var(--body-footnote-regular);
  color: var(--brand-amethyst);
}
.registeredat-null {
  margin: 0;
}
.protilela-d561ad66-014-a3-container1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 32px;
  color: var(--brand-ivory);
}
.marketers-swiss-knife,
.parent10 {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.parent10 {
  margin-top: -400px;
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--brand-indigo);
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-6xl) var(--padding-14xl) var(--padding-12xl);
  gap: var(--gap-3xl);
  font-size: var(--body-small-regular-size);
  color: var(--brand-amethyst);
}
.marketers-swiss-knife {
  height: 501px;
  width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--color-lightsteelblue);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-139xl-8) var(--padding-37xl) var(--padding-79xl-9);
  box-sizing: border-box;
  gap: var(--gap-33xl-4);
  max-width: 100%;
  z-index: 1;
}
.image-collection {
  margin-top: 0;
  align-self: stretch;
  /* display: flex; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 65%;
  right: 0;
  top: 0;
  position: absolute;
  font-size: var(--font-size-mini-7);
}
.ai-driven-personalization {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 600;
  font-family: inherit;
}
.craft-personalized-experiences {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.shopify-c-s-card.active {
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  padding: var(--padding-xl) var(--padding-5xl);
}
.shopify-c-s-card {
  gap: var(--gap-9xs);
}
.shopify-c-s-card-wrapper:hover {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  padding: var(--padding-5xs) var(--padding-base) !important;
  cursor: pointer;
}
.shopify-c-s-card,
.shopify-c-s-card-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.automated-campaigns {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.automated-campaigns-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.omnichannel-mastery1 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.omnichannel-mastery-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.comprehensive-analytics {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.comprehensive-analytics-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.comprehensive-analytics-wrapper,
.frame-parent83,
.shape-array,
.use-cases-section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent83 {
  width: 30%;
  border-radius: var(--br-xs);
  flex-direction: column;
  gap: var(--gap-29xl);
  flex-shrink: 0;

  margin-top: -1.5px;
  font-size: var(--body-large-regular-size);
}
.shape-array,
.use-cases-section {
  box-sizing: border-box;
  max-width: 100%;
}
.use-cases-section {
  /* height: 785px; */
  flex: 1;
  /* overflow-y: auto; */
  flex-direction: column;
  padding: var(--padding-263xl-5) var(--padding-180xl) var(--padding-141xl-5);
  position: relative;
}
.shape-array {
  align-self: stretch;
  flex-direction: row;
  padding: 0;
  text-align: left;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1200px) {
  .marketers-swiss-knife {
    padding-top: var(--padding-84xl);
    padding-bottom: var(--padding-45xl);
    box-sizing: border-box;
  }
  .use-cases-section {
    padding: var(--padding-165xl) var(--padding-80xl) var(--padding-85xl);
    box-sizing: border-box;
  }
  .shape-array {
    padding-bottom: var(--padding-69xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .the-data-driven-marketers {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .marketers-swiss-knife {
    padding: var(--padding-48xl) var(--padding-9xl) var(--padding-23xl);
    box-sizing: border-box;
  }
  .use-cases-section {
    padding-top: var(--padding-101xl);
    padding-bottom: var(--padding-49xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .the-data-driven-marketers {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .parent10 {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .marketers-swiss-knife {
    gap: 26px 52.4px;
  }
  .ai-driven-personalization,
  .automated-campaigns,
  .comprehensive-analytics,
  .omnichannel-mastery1 {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .frame-parent83 {
    gap: 24px 48px;
  }
  .data-driven-marketers .frame-parent83 {
    width: 100%;
  }
  .data-driven-marketers .shapeHolder .image-collection {
    position: initial;
    width: 100%;
    max-width: 90%;
    margin-top: 20px;
  }
  .use-cases-section {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .shape-array {
    padding-bottom: var(--padding-38xl);
    box-sizing: border-box;
  }
}
.releva-icon9 {
  height: 85%;
  width: 81.62%;
  top: 15%;
  right: 0;
  bottom: 0;
  left: 18.38%;
  max-width: 100%;
  max-height: 100%;
}
.releva-icon9,
.releva-logo9,
.symbol-icon9 {
  position: absolute;
  overflow: hidden;
}
.symbol-icon9 {
  height: 99.06%;
  width: 9.19%;
  top: 0;
  right: 90.81%;
  bottom: 0.94%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.releva-logo9 {
  top: calc(50% - 4811.5px);
  left: calc(50% - 798px);
  width: 139.3px;
  height: 32px;
}
.ai-driven-personalization1,
.craft-personalized-experiences1 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.ai-driven-personalization1 {
  line-height: 26px;
  font-weight: 600;
}
.craft-personalized-experiences1 {
  font-size: var(--body-small-regular-size);
  line-height: 25px;
}
.ai-driven-personalization-group {
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  padding: var(--padding-xl) var(--padding-5xl);
  gap: 4px 0;
}
.ai-driven-personalization-group,
.frame-wrapper7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.automated-campaigns1 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.automated-campaigns-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.omnichannel-mastery2 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.omnichannel-mastery-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.comprehensive-analytics1 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.comprehensive-analytics-container,
.frame-parent34 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.comprehensive-analytics-container {
  flex-direction: row;
  padding: 0 var(--padding-5xl);
}
.frame-parent34 {
  border-radius: var(--br-xs);
  flex-direction: column;
  gap: 48px 0;
}
.p54,
.p55,
.p56,
.p57,
.p58,
.p59,
.p60,
.p61,
.p62,
.p63,
.p64 {
  margin: 0;
}
.div41 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 32px;
}
.protilela4 {
  margin: 0;
}
.d561ad66-014-a311-a008-bffb4d2 {
  margin: 0;
  color: var(--color-plum);
}
.domainid-74 {
  margin: 0;
}
.span6 {
  color: var(--brand-periwinkle);
}
.email-inforelevaai3,
.group-45454,
.subscribedat3 {
  margin: 0;
}
.september-17-2020-63014-pm4 {
  margin: 0;
  color: var(--color-plum);
}
.timestamp2 {
  margin: 0;
}
.september-17-2020-63014-pm5 {
  margin: 0;
  color: var(--color-plum);
}
.profilejoin2 {
  color: var(--brand-ivory);
}
.span7 {
  color: var(--color-plum);
}
.profilejoin-1-item2 {
  margin: 0;
}
.null2 {
  font-family: var(--body-footnote-regular);
  color: var(--brand-amethyst);
}
.registeredat-null2 {
  margin: 0;
}
.protilela-d561ad66-014-a3-container4 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 32px;
  color: var(--brand-ivory);
}
.parent6,
.shorts-icon2 {
  position: absolute;
  overflow: hidden;
}
.parent6 {
  top: calc(50% + 203.5px);
  left: calc(50% - 357.55px);
  border-radius: var(--br-5xs);
  background-color: var(--brand-indigo);
  width: 677px;
  height: 408px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xl) var(--padding-14xl);
  box-sizing: border-box;
  gap: 0 22px;
}
.shorts-icon2 {
  top: -350px;
  left: 474.9px;
  border-radius: 6.73px;
  width: 240.2px;
  height: 282.5px;
  object-fit: contain;
}
.recommended-for-you3 {
  position: absolute;
  top: 19.6px;
  left: 13.7px;
  letter-spacing: -0.02em;
  line-height: 17.13px;
  font-weight: 600;
  display: inline-block;
  width: 104.8px;
}
.sneakers-child2,
.sneakers-child3 {
  position: absolute;
  top: 189.4px;
  left: 13.9px;
  border-radius: 27.21px;
  background-color: var(--brand-ivory);
  width: 143.5px;
  height: 9.1px;
}
.sneakers-child3 {
  top: 203.8px;
  width: 92.8px;
}
.image-94-icon3 {
  position: absolute;
  top: 82.8px;
  left: 26.6px;
  width: 130.3px;
  height: 59.9px;
  object-fit: cover;
}
.sneakers4 {
  position: absolute;
  top: 295.7px;
  left: 412px;
  border-radius: 6.73px;
  background-color: var(--test);
  border: 0.6px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  width: 183.5px;
  height: 243.3px;
  overflow: hidden;
  font-size: var(--font-size-mini-7);
  color: var(--brand-indigo);
}
.p252 {
  width: 715.1px;
  position: relative;
  height: 408px;
}
.image-01,
.use-cases-section-01 {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.image-01 {
  width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--brand-periwinkle);
  height: 501px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 17.92437744140625px;
  font-size: var(--body-small-regular-size);
  color: var(--brand-amethyst);
}
.use-cases-section-01 {
  position: absolute;
  top: calc(50% - 1219.5px);
  left: calc(50% - 864px);
  width: 1728px;
  height: 502px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-180xl);
  gap: 0 109px;
}
.ai-driven-personalization2 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.ai-driven-personalization-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.automate-marketing-tasks,
.automated-campaigns2 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.automated-campaigns2 {
  line-height: 26px;
  font-weight: 600;
}
.automate-marketing-tasks {
  font-size: var(--body-small-regular-size);
  line-height: 25px;
}
.automated-campaigns-parent {
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  padding: var(--padding-xl) var(--padding-5xl);
  gap: 4px 0;
}
.automated-campaigns-parent,
.frame-wrapper8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.omnichannel-mastery3 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.omnichannel-mastery-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.comprehensive-analytics2 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.comprehensive-analytics-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.frame-parent35,
.image-02 {
  position: absolute;
  border-radius: var(--br-xs);
}
.frame-parent35 {
  top: -0.5px;
  left: 199px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px 0;
}
.image-02 {
  top: 502px;
  left: 740px;
  background-color: var(--brand-amethyst);
  width: 789px;
  height: 501px;
  overflow: hidden;
}
.frame-child121,
.frame-child122 {
  position: absolute;
  top: 196.4px;
  left: 27.1px;
  width: 35.9px;
  height: 107.6px;
}
.frame-child122 {
  top: 143px;
  left: 27px;
  border-radius: 49.05px;
  background-color: var(--brand-ivory);
  width: 255px;
  height: 12px;
}
.image-98-icon4 {
  position: absolute;
  top: -17.9px;
  left: 44.8px;
  width: 102.5px;
  height: 149.9px;
  object-fit: contain;
  display: none;
}
.div42,
.page-views {
  position: absolute;
  top: 19px;
  left: 27.1px;
  letter-spacing: -0.02em;
  line-height: 38.68px;
  font-weight: 600;
}
.div42 {
  top: 84px;
  left: 27px;
  font-size: var(--font-size-29xl);
}
.frame-child123,
.frame-parent36 {
  position: absolute;
  overflow: hidden;
}
.frame-child123 {
  top: 10px;
  left: 373px;
  border-radius: var(--br-5xs);
  width: 48px;
  height: 48px;
}
.frame-parent36 {
  top: 0;
  left: 0;
  border-radius: 16.35px;
  background-color: var(--test);
  width: 429.2px;
  height: 180.6px;
}
.frame-child124,
.frame-child125 {
  position: absolute;
  top: 196.4px;
  left: 27.1px;
  width: 35.9px;
  height: 107.6px;
}
.frame-child125 {
  top: 143px;
  left: 27px;
  border-radius: 49.05px;
  background-color: var(--brand-amethyst);
  width: 255px;
  height: 12px;
}
.image-98-icon5 {
  position: absolute;
  top: -17.9px;
  left: 44.8px;
  width: 102.5px;
  height: 149.9px;
  object-fit: contain;
  display: none;
}
.div43,
.purchases {
  position: absolute;
  top: 19px;
  left: 27.1px;
  letter-spacing: -0.02em;
  line-height: 38.68px;
  font-weight: 600;
}
.div43 {
  top: 84px;
  left: 27px;
  font-size: var(--font-size-29xl);
}
.frame-child126,
.frame-parent37 {
  position: absolute;
  overflow: hidden;
}
.frame-child126 {
  top: 10px;
  left: 373px;
  border-radius: var(--br-5xs);
  width: 48px;
  height: 48px;
}
.frame-parent37 {
  top: 191px;
  left: 201px;
  border-radius: 16.35px;
  background-color: var(--brand-indigo);
  width: 429.2px;
  height: 180.6px;
  color: var(--brand-periwinkle);
}
.frame-child127,
.image-98-icon6 {
  position: absolute;
  top: 196.4px;
  left: 27.1px;
  width: 35.9px;
  height: 107.6px;
}
.image-98-icon6 {
  top: -17.9px;
  left: 44.8px;
  width: 102.5px;
  height: 149.9px;
  object-fit: contain;
  display: none;
}
.about-us .image-118-icon.hero-image {
  width: 100%;
}
.about-us .image-118-icon {
  position: initial;
  width: auto;
  height: auto;
}
.image-118-icon {
  position: absolute;
  top: 31px;
  left: 34px;
  width: 122px;
  height: 119px;
  object-fit: cover;
}
.frame-parent38 {
  position: absolute;
  top: 0;
  left: 440px;
  border-radius: 16.35px;
  background-color: var(--brand-ivory);
  width: 190px;
  height: 181px;
  overflow: hidden;
}
.frame-child128,
.image-98-icon7 {
  position: absolute;
  top: 196.4px;
  left: 27.1px;
  width: 35.9px;
  height: 107.6px;
}
.image-98-icon7 {
  top: -17.9px;
  left: 44.8px;
  width: 102.5px;
  height: 149.9px;
  object-fit: contain;
  display: none;
}
.frame-parent39,
.vuesaxlineardiagram-icon1 {
  position: absolute;
  top: 46px;
  left: 55px;
  width: 80px;
  height: 80px;
}
.frame-parent39 {
  top: 192px;
  left: 0;
  border-radius: 16.35px;
  background-color: var(--brand-ivory);
  width: 190px;
  height: 181px;
  overflow: hidden;
}
.p261 {
  /* position: absolute; */
  top: 53px;
  left: 812px;
  width: 630.2px;
  height: 373px;
  font-size: var(--headings-h5-semi-bold-size);
}
.use-cases-section-02 {
  position: absolute;
  top: calc(50% - 567.5px);
  left: calc(50% - 864px);
  width: 1728px;
  height: 502px;
  overflow: hidden;
}
.ai-driven-personalization3 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.ai-driven-personalization-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.automated-campaigns3 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.automated-campaigns-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.engage-customers-through,
.omnichannel-mastery4 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.omnichannel-mastery4 {
  line-height: 26px;
  font-weight: 600;
}
.engage-customers-through {
  font-size: var(--body-small-regular-size);
  line-height: 25px;
}
.omnichannel-mastery-group {
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-5xl);
  gap: 4px 0;
}
.comprehensive-analytics3 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.comprehensive-analytics-wrapper1,
.frame-parent40 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.comprehensive-analytics-wrapper1 {
  flex-direction: row;
  padding: 0 var(--padding-5xl);
}
.frame-parent40 {
  border-radius: var(--br-xs);
  flex-direction: column;
  gap: 48px 0;
}
.whatsapp1 {
  position: relative;
  letter-spacing: -0.03em;
  line-height: 21.94px;
  color: #fff;
}
.path54-icon1 {
  width: 65.7px;
  position: relative;
  height: 65.9px;
  mix-blend-mode: normal;
  margin-top: -43px;
}
.p27-child,
.whatsapp-parent {
  position: absolute;
  height: 48.97%;
  width: 32.11%;
  border-radius: var(--br-3xs-4);
  overflow: hidden;
}
.whatsapp-parent {
  top: 49.01%;
  right: 21.72%;
  bottom: 2.02%;
  left: 46.17%;
  background-color: var(--color-mediumspringgreen);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21.492042541503906px 29.067169189453125px 21.492042541503906px 28.9921875px;
  box-sizing: border-box;
}
.p27-child {
  top: 0;
  right: 0;
  bottom: -48.97%;
  left: 67.89%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.facebook-wordmark-logo-brandlo-icon1,
.frame-icon3 {
  width: 64.2px;
  position: relative;
  height: 64.2px;
  overflow: hidden;
  flex-shrink: 0;
}
.facebook-wordmark-logo-brandlo-icon1 {
  width: 105.8px;
  height: 18.9px;
}
.frame-parent41 {
  position: absolute;
  height: 48.97%;
  width: 32.11%;
  top: 0;
  right: 72.66%;
  bottom: -48.89%;
  left: 0;
  border-radius: 11.48px;
  background-color: #1877f2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 87.57553100585938px 26.86730194091797px 87.57553100585938px 27.419921875px;
  box-sizing: border-box;
  gap: 24px 0;
}
.messages-2-icon {
  width: 100%;
  position: absolute;
  margin: 0 !important;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 0;
}
.vuesaxlinearmessages-2 {
  width: 71.5px;
  height: 71.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  position: relative;
}
.sms {
  position: relative;
  letter-spacing: -0.03em;
}
.vuesaxlinearmessages-2-parent {
  position: absolute;
  height: 48.97%;
  width: 32.11%;
  top: 0;
  right: 38.67%;
  bottom: -48.8%;
  left: 34.22%;
  border-radius: var(--br-3xs-4);
  background-color: var(--brand-amethyst);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 86.79324340820312px 40.86231994628906px 86.79324340820312px 47.65625px;
  box-sizing: border-box;
  gap: 21px 0;
  font-size: 23px;
}
.icon-new-white-11,
.logo-icon-white1 {
  width: 70.5px;
  position: relative;
  height: 74.4px;
  overflow: hidden;
  flex-shrink: 0;
}
.logo-icon-white1 {
  width: 77.6px;
  height: 31.4px;
}
.icon-new-white-1-group {
  position: absolute;
  height: 48.97%;
  width: 32.11%;
  top: 49.11%;
  right: 55.72%;
  bottom: 1.92%;
  left: 12.17%;
  border-radius: var(--br-3xs-4);
  background-color: #7360f2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-24xl) 41.75093078613281px 44.44818115234375px 40.7421875px;
  box-sizing: border-box;
  gap: 10px 0;
}
.p271 {
  width: 498.6px;
  position: relative;
  height: 415.6px;
}
.image-03,
.use-cases-section-03 {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
}
.image-03 {
  /* width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory); */
  height: 501px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  /* padding: 0 144.59298706054688px; */
  font-size: 20.4px;
  color: var(--test);
}
.use-cases-section-03 {
  position: absolute;
  top: calc(50% + 84.5px);
  left: calc(50% - 864px);
  width: 1728px;
  height: 502px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-180xl);
  gap: 0 109px;
}
.ai-driven-personalization4 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.ai-driven-personalization-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.automated-campaigns4 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.automated-campaigns-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.omnichannel-mastery5 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.omnichannel-mastery-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.access-in-depth-data,
.comprehensive-analytics4 {
  width: 384px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.comprehensive-analytics4 {
  line-height: 26px;
  font-weight: 600;
}
.access-in-depth-data {
  font-size: var(--body-small-regular-size);
  line-height: 25px;
}
.comprehensive-analytics-parent,
.frame-parent42 {
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.comprehensive-analytics-parent {
  background-color: var(--brand-ivory);
  padding: var(--padding-xl) var(--padding-5xl);
  gap: 4px 0;
}
.frame-parent42 {
  gap: 48px 0;
}
.revenue-by-source {
  position: absolute;
  top: 22px;
  left: 27px;
  font-size: var(--headings-h5-semi-bold-size);
  letter-spacing: -0.02em;
  line-height: 38.68px;
  font-weight: 600;
}
.chart-icon {
  position: absolute;
  top: 88px;
  left: 27px;
  width: 455.4px;
  height: 150px;
  overflow: hidden;
}
.website-wrapper .website1 {
  width: auto;
}
.website1 {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.website-wrapper {
  position: absolute;
  height: 6.7%;
  top: 72.43%;
  bottom: 20.87%;
  left: calc(50% + 160px);
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
}
.linkedin {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.linkedin-wrapper {
  position: absolute;
  height: 6.7%;
  top: 72.43%;
  bottom: 20.87%;
  left: calc(50% + 94.5px);
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
}
.facebook {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.facebook-wrapper {
  position: absolute;
  height: 6.7%;
  top: 80.73%;
  bottom: 12.57%;
  left: calc(50% + 152px);
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
}
.revenue-by-source-parent {
  position: absolute;
  top: 20px;
  left: calc(50% - 174px);
  border-radius: var(--br-xs);
  background-color: #fff;
  width: 520px;
  height: 313.4px;
  overflow: hidden;
}
.frame-child129,
.image-98-icon8 {
  position: absolute;
  top: 207.3px;
  left: 28.6px;
  width: 37.9px;
  height: 113.6px;
}
.image-98-icon8 {
  top: -18.9px;
  left: 47.3px;
  width: 108.2px;
  height: 158.2px;
  object-fit: contain;
  display: none;
}
.cpc,
.top-medias {
  letter-spacing: -0.02em;
  font-weight: 600;
}
.top-medias {
  position: absolute;
  top: 19px;
  left: 28.6px;
  font-size: 25.3px;
  line-height: 40.83px;
  color: var(--brand-ivory);
}
.cpc {
  position: relative;
}
.cpc-wrapper {
  position: absolute;
  top: 67.5px;
  left: 28.5px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.banner1 {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.banner-wrapper {
  position: absolute;
  top: 67.5px;
  left: 80.2px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.reccommender {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.reccommender-wrapper {
  position: absolute;
  top: 95px;
  left: 93.9px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.push-notification {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.push-notification-wrapper {
  position: absolute;
  top: 121.4px;
  left: 80.2px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.main-board {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.main-board-wrapper {
  position: absolute;
  top: 147.8px;
  left: 93.9px;
  border-radius: 8.44px;
  background-color: var(--brand-periwinkle);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.organic {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.organic-wrapper {
  position: absolute;
  top: 95px;
  left: 28.5px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.email5 {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.email-wrapper {
  position: absolute;
  top: 122.4px;
  left: 28.5px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.catalog {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.catalog-wrapper {
  position: absolute;
  top: 147.8px;
  left: 28.5px;
  border-radius: 8.44px;
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.221426486968994px 8.442852973937988px;
}
.figpie-icon1,
.frame-parent43 {
  position: absolute;
  top: 16.5px;
  left: 267.6px;
  width: 155.6px;
  height: 155.6px;
}
.frame-parent43 {
  top: 278.6px;
  left: 128px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  width: 453px;
  height: 190.6px;
  overflow: hidden;
  font-size: var(--font-size-xs-6);
}
.p281 {
  width: 692px;
  position: relative;
  height: 428.6px;
}
.image-04,
.use-cases-section-04 {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.image-04 {
  width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--brand-periwinkle);
  height: 501px;
  flex-shrink: 0;
  flex-direction: column;
  /* padding: 35.35308837890625px var(--padding-22xl); */
  font-size: var(--font-size-2xs);
}
.use-cases-section-04 {
  position: absolute;
  top: calc(50% + 736.5px);
  left: calc(50% - 864px);
  width: 1728px;
  height: 502px;
  flex-direction: row;
  padding: 0 var(--padding-180xl);
  gap: 0 109px;
}
.slider {
  width: 100%;
  position: relative;
  background-color: var(--test);
  /* height: 2857px; */
  overflow: hidden;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
