.trendo-about-releva {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.and-of-course,
.blank-line1,
.datsko-datsev-cto,
.releva-helped-our {
  margin: 0;
}
.releva-helped-our-container {
  width: 543px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.block-9-inner,
.trendo-about-releva-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.trendo-about-releva-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.block-9-inner {
  width: 589px;
  min-width: 589px;
  min-height: 455px;
}
.vector-icon30 {
  position: absolute;
  top: 0;
  left: 0;
  width: 601px;
  height: 597.7px;
  object-fit: contain;
}
.frame-child69,
.frame-child70 {
  position: absolute;
  border-radius: 13.59px;
  width: 259.8px;
  height: 204.3px;
  overflow: hidden;
  object-fit: contain;
}
.frame-child69 {
  top: 294.9px;
  left: 40px;
  z-index: 1;
}
.frame-child70 {
  top: 0;
  left: 0;
}
.trendo-logo-1-icon {
  position: absolute;
  top: 55px;
  left: 35px;
  width: 190px;
  height: 81px;
  overflow: hidden;
  z-index: 2;
}
.frame-parent123 {
  position: absolute;
  top: 81.9px;
  left: 278px;
  width: 259.8px;
  height: 204.3px;
}
.vector-parent11 {
  height: 597.7px;
  flex: 1;
  position: relative;
  min-width: 391px;
  max-width: 100%;
}
.block-9 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-59xl-1) var(--padding-51xl) var(--padding-73xl-2)
    var(--padding-126xl);
  box-sizing: border-box;
  gap: var(--gap-176xl);
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: var(--headings-h3-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1600px) {
  .block-9-inner {
    flex: 1;
    min-height: auto;
  }
  .block-9 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .block-9-inner {
    min-width: 100%;
  }
  .block-9 {
    gap: 195px 97px;
    padding-left: var(--padding-53xl);
    padding-right: var(--padding-16xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .trendo-about-releva {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .vector-parent11 {
    min-width: 100%;
  }
  .block-9 {
    gap: 195px 49px;
    padding-left: var(--padding-17xl);
    padding-top: var(--padding-32xl);
    padding-bottom: var(--padding-41xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .trendo-about-releva {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .block-9 {
    gap: 195px 24px;
    padding-top: var(--padding-14xl);
    padding-bottom: var(--padding-20xl);
    box-sizing: border-box;
  }
}
