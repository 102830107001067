.content-with-toc {
  max-width: 75%;
  margin-left: 25%;
}
.d-none {
  display: none;
}
.content-with-toc .network-switch {
  margin-bottom: 20px;
}
.TOC-navigation {
  background-color: var(--brand-ivory);
  border-radius: var(--br-xs);
  padding: 10px;
  font-family: var(--body-footnote-regular);
  color: var(--brand-indigo);
  max-width: 18%;
  position: fixed;
  z-index: 2;
}
.TOC-navigation ul {
  list-style: none;
  padding-left: 10px;
}
.TOC-navigation a {
  text-decoration: none;
  color: #000;
  font-weight: 400;
  margin: 10px 0;
  display: inline-block;
}
.releva-icon,
.symbol-icon {
  height: 31.7px;
  width: 12.8px;
  position: relative;
}
.case-studies.contentListing {
  height: auto !important;
}
.releva-icon {
  height: 27.2px;
  width: 113.7px;
}
.releva-logo,
.releva-logo-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.releva-logo {
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-smi-8);
}
.releva-logo-wrapper {
  margin-top: -3407px;
  align-items: flex-start;
  padding: 0 var(--padding-11xs) 3343px;
}
.case-studies1 {
  margin: 0;
  width: 1186px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.case-studies-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.contrary-to-popular {
  width: 561px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.case-studies,
.contrary-to-popular-belief-lo-wrapper {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.contrary-to-popular-belief-lo-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 var(--padding-40xl) var(--padding-12xs);
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.case-studies.locked {
  height: auto;
}
.case-studies {
  width: 100%;
  height: 5355px;
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-45xl) var(--padding-59xl);
  gap: var(--gap-13xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1600px) {
  .case-studies {
    height: auto;
  }
}
@media screen and (max-width: 1275px) {
  .releva-logo-wrapper {
    padding-bottom: 1412px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .releva-logo-wrapper {
    padding-bottom: 918px;
    box-sizing: border-box;
  }
  .case-studies1 {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .case-studies {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .content-with-toc {
    max-width: 100%;
    margin: 0 auto;
  }
  .TOC-navigation {
    display: none;
  }
  .releva-logo-wrapper {
    padding-bottom: 597px;
    box-sizing: border-box;
  }
  .case-studies1 {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .case-studies {
    gap: 16px 32px;
  }
  #root > .case-studies.contentListing > div:nth-child(2),
  .case-studies.contentListing .frame-container,
  .case-studies.contentListing {
    width: 100%;
  }
}
.contentLibraryContent .quote {
  padding-left: 20px;
  border-left: 4px solid #3a1a4b;
  font-style: italic;
}
.contentLibraryContent .ivoryBackground {
  background-color: var(--brand-ivory);
  padding: 5px 20px;
  border-radius: 10px;
  margin: 60px 0;
}
.contentLibraryContent .ivoryBackground a {
  color: #ffb9ff;
}

.contentLibraryContent {
  letter-spacing: -0.01em;
  line-height: 28px;
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  text-align: center;
  width: 100%;
  max-width: 1200px;
}
.contentLibraryContent img {
  width: 50%;
  height: auto;
}
.contentLibraryContent strong {
  text-align: center;
  display: block;
}
.contentLibraryContent ul,
.contentLibraryContent p {
  text-align: justify;
  display: block;
}
.contentLibraryContent p strong {
  display: inline-block;
}
