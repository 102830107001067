.heading {
  width: 1117px;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.heading-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.artwork-icon3 {
  height: 480.8px;
  width: 545.9px;
  position: absolute;
  margin: 0 !important;
  right: -154.9px;
  bottom: -158.8px;
}
.the-story-behind {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
}
.inspired-by-the {
  width: 507px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.element-3-inner,
.the-story-behind-releva-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.the-story-behind-releva-parent {
  align-self: stretch;
  gap: var(--gap-xs);
}
.element-3-inner {
  width: 705px;
  padding: var(--padding-163xl) 0 0;
  box-sizing: border-box;
  /* min-width: 705px; */
}
.image-83 {
  width: 134.1px;
  height: 134.1px;
  position: relative;
  display: none;
}
.hi-john2 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  display: inline-block;
  min-width: 92px;
}
.youre-going-to {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
}
.element-2-inner,
.hi-john-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.hi-john-group {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-5);
  flex-shrink: 0;
}
.element-2-inner {
  width: 369px;
  flex-direction: row;
  padding: 0 var(--padding-smi) var(--padding-6xs-9) var(--padding-sm-5);
  box-sizing: border-box;
}
.photo-1-icon {
  height: 92px;
  width: 138px;
  position: relative;
  object-fit: cover;
  z-index: 3;
}
.about-documents2,
.about-documents3 {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
  flex-shrink: 0;
}
.about-documents2 {
  align-self: stretch;
}
.about-documents3 {
  width: 109px;
}
.container-wrapper,
.container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.container2 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-6xs-7);
}
.container-wrapper {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 var(--padding-5xl-5);
  box-sizing: border-box;
  min-width: 110px;
}
.logic-gate16 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-11xl-4);
  max-width: 100%;
}
.careers-e-books,
.output-generator1 {
  align-self: stretch;
  box-sizing: border-box;
}
.output-generator1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl) 0 var(--padding-lgi-4);
  max-width: 100%;
}
.careers-e-books {
  height: 1.4px;
  position: relative;
  border-top: 1.4px solid var(--brand-ivory);
}
.jasmin-egger-nb5-8iynmpa-unspl-icon {
  height: 135px;
  width: 111px;
  position: relative;
  object-fit: cover;
}
.promotional-banners10,
.promotional-banners11 {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
}
.promotional-banners10 {
  align-self: stretch;
}
.promotional-banners11 {
  width: 109px;
}
.promotional-banners-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs-7);
}
.frame-wrapper52,
.jasmin-egger-nb5-8iynmpa-unspl-parent {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper52 {
  flex-direction: column;
  padding: var(--padding-26xl-9) 0 0;
  box-sizing: border-box;
  min-width: 110px;
}
.jasmin-egger-nb5-8iynmpa-unspl-parent {
  flex-direction: row;
  gap: var(--gap-31xl-4);
  max-width: 100%;
}
.careers-e-books-parent,
.frame-wrapper51 {
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
}
.frame-wrapper51 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-5xl-4) 0 var(--padding-7xl);
  box-sizing: border-box;
  max-width: 100%;
}
.careers-e-books-parent {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-6xs-3);
}
.checkout4 {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
  min-width: 93px;
}
.get-demo13 {
  border-radius: 13.41px;
  background-color: var(--brand-indigo);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm-4) var(--padding-23xl);
  flex-shrink: 0;
}
.frame-parent127,
.get-demo-wrapper5 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.get-demo-wrapper5 {
  width: 367.6px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.frame-parent127 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base-8);
  font-size: var(--body-large-regular-size);
  color: var(--brand-ivory);
}
.element-21,
.header-icon {
  overflow: hidden;
  flex-shrink: 0;
}
.element-21 img {
  width: 100%;
}
.element-21 {
  width: 430px;
  border-radius: var(--br-sm-6);
  /* background-color: var(--web-secondary-ivory-light); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-26xl-3) var(--padding-4xl-4) var(--padding-27xl-1) var(--padding-6xl-1);
  box-sizing: border-box;
  gap: var(--gap-30xl-3);
  min-width: 430px;
  max-width: 100%;
  z-index: 1;
  font-size: var(--headings-h5-semi-bold-size);
}
.header-icon {
  height: 204px;
  width: 261px;
  position: absolute;
  margin: 0 !important;
  right: 496px;
  bottom: 279px;
  border-radius: 13.12px;
  object-fit: contain;
  z-index: 2;
}
.testimonials-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-plum);
  width: 100%;
  height: 100%;
  z-index: 2;
}
.icon-vuesaxlinearbag-tick {
  position: absolute;
  top: 20px;
  left: 19.5px;
  width: 44px;
  height: 44px;
  z-index: 3;
}
.testimonials2 {
  height: 81px;
  width: 81px;
  position: absolute;
  margin: 0 !important;
  top: 53px;
  right: 132.5px;
}
.element-3,
.element-3-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.element-3 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  padding: var(--padding-70xl) var(--padding-96xl) var(--padding-63xl-3);
  position: relative;
  gap: var(--gap-166xl-5);
}
.element-3-wrapper {
  align-self: stretch;
  padding: 0 0 var(--padding-8xs);
  text-align: left;
  font-size: var(--headings-h3-semi-bold-size);
}
.journey-and-milestones {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.evolving-from-startup {
  width: 561px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.evolving-from-startup-to-indus-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--body-large-regular-size);
}
.evolving-from-startup-to-indus-wrapper,
.frame-wrapper53,
.journey-and-milestones-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.journey-and-milestones-parent {
  width: 774px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.frame-wrapper53 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--headings-h2-semi-bold-size);
}
.about-us-child,
.frame-parent126 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent126 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-66xl);
}
.about-us-child {
  width: 100%;
  flex-direction: row;
  padding: 0 var(--padding-7xl) var(--padding-32xl-5);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1725px) {
  .element-21,
  .element-3-inner {
    flex: 1;
  }
  .element-3 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1325px) {
  .element-3-inner {
    min-width: 100%;
  }
  .element-3 {
    gap: 185.5px 93px;
    padding-left: var(--padding-38xl);
    padding-right: var(--padding-38xl);
    box-sizing: border-box;
  }
  .about-us-child {
    padding-bottom: var(--padding-14xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .heading {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .the-story-behind {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .logic-gate16 {
    flex-wrap: wrap;
  }
  .jasmin-egger-nb5-8iynmpa-unspl-icon {
    flex: 1;
  }
  .jasmin-egger-nb5-8iynmpa-unspl-parent {
    flex-wrap: wrap;
  }
  .element-21 {
    padding-top: var(--padding-10xl);
    padding-bottom: var(--padding-11xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .element-3 {
    gap: 185.5px 46px;
    padding: var(--padding-39xl) var(--padding-9xl) var(--padding-34xl);
    box-sizing: border-box;
  }
  .journey-and-milestones {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .frame-parent126 {
    gap: 42px 85px;
  }
  .about-us-child {
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .the-story-behind {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .element-3-inner {
    padding-top: var(--padding-99xl);
    box-sizing: border-box;
  }
  .hi-john2 {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .logic-gate16 {
    gap: 30.4px 15px;
  }
  .jasmin-egger-nb5-8iynmpa-unspl-parent {
    gap: 50.4px 25px;
  }
  .checkout4 {
    font-size: var(--body-small-regular-size);
  }
  .element-21 {
    gap: 25px 49.3px;
  }
  .element-3 {
    gap: 185.5px 23px;
    padding-top: var(--padding-19xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
  .journey-and-milestones {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .evolving-from-startup {
    font-size: var(--body-small-regular-size);
    line-height: 22px;
  }
  .frame-parent126 {
    gap: 21px 85px;
  }
}
