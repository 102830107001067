.customer-loyalty {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h3-semi-bold-size);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.an-essential-choice,
.button-name30 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.an-essential-choice {
  align-self: stretch;
}
.button-name30 {
  width: 145px;
  display: inline-block;
  text-decoration: none;
}
.button35,
.customer-loyalty-parent,
.frame-wrapper38 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button35 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.customer-loyalty-parent,
.frame-wrapper38 {
  flex-direction: column;
}
.customer-loyalty-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.frame-wrapper38 {
  width: 496px;
  padding: var(--padding-221xl) 0 0;
  box-sizing: border-box;
  min-width: 496px;
  max-width: 100%;
}
.buubles-icon1 {
  position: absolute;
  height: 48.66%;
  width: 79.09%;
  top: 61.26%;
  right: -1.39%;
  bottom: -9.92%;
  left: 22.31%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.image-88-icon {
  position: absolute;
  top: 38px;
  left: -27px;
  width: 258px;
  height: 261px;
  object-fit: cover;
}
.queue-manager1 {
  position: absolute;
  height: 54.78%;
  width: 85%;
  top: 0.39%;
  right: 15%;
  bottom: 44.83%;
  left: 0;
  border-radius: 13.59px;
  background-color: var(--color-plum);
  overflow: hidden;
}
.database-connector-icon,
.image-87-icon1 {
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.database-connector-icon {
  position: absolute;
  height: 42.69%;
  width: 85%;
  top: 57.31%;
  right: 15%;
  bottom: 0;
  left: 0;
  border-radius: 13.59px;
  max-height: 100%;
}
.image-87-icon1 {
  align-self: stretch;
  height: 324px;
  position: relative;
  flex-shrink: 0;
}
.file-handler,
.p35 {
  position: absolute;
  z-index: 1;
}
.file-handler {
  height: 77%;
  width: 85%;
  top: 0;
  right: 0;
  bottom: 23%;
  left: 100%;
  border-radius: 13.59px;
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs) 0 0;
  box-sizing: border-box;
}
.p35 {
  height: 68.77%;
  width: 30.42%;
  top: 15.55%;
  right: 46.39%;
  bottom: 15.68%;
  left: 23.19%;
}
.i-13 {
  /* height: 746px; */
  flex: 1;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  min-width: 513px;
  max-width: 100%;
}
.frame-parent99,
.logic-gate13 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent99 {
  width: 1465px;
  justify-content: flex-start;
  gap: var(--gap-161xl);
}
.logic-gate13 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-45xl) var(--padding-77xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .frame-wrapper38 {
    flex: 1;
  }
  .frame-parent99 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1225px) {
  .frame-parent99 {
    gap: 50px 90px;
  }
  .logic-gate13 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .customer-loyalty {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-wrapper38,
  .i-13 {
    min-width: 100%;
  }
  .frame-parent99 {
    gap: 50px 45px;
  }
  .logic-gate13 {
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .customer-loyalty {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .frame-wrapper38 {
    padding-top: 0;
    box-sizing: border-box;
  }
  .frame-parent99 {
    gap: 50px 22px;
  }
  .logic-gate13 {
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
}
