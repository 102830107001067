@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;0,700;1,400;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap');
body {
  margin: 0;
  line-height: normal;
}
#root {
  overflow-y: hidden;
}
:root {
  /* fonts */
  --body-footnote-regular: Inter;
  --font-noto-sans: 'Noto Sans';

  /* font sizes */
  --font-size-xs: 12px;
  --body-footnote-regular-size: 14px;
  --body-default-semi-bold-size: 18px;
  --font-size-37xl: 56px;
  --font-size-15xl: 34px;
  --font-size-26xl: 45px;
  --headings-h2-semi-bold-size: 57px;
  --font-size-27xl: 46px;
  --body-small-regular-size: 16px;
  --headings-h5-semi-bold-size: 24px;
  --font-size-lgi: 19px;
  --body-large-regular-size: 20px;
  --font-size-9xl: 28px;
  --font-size-3xl: 22px;
  --headings-h3-semi-bold-size: 43px;
  --font-size-7xl: 26px;
  --headings-h4-semi-bold-size: 32px;
  --font-size-48xl-7: 67.7px;
  --font-size-22xl: 41px;
  --font-size-35xl: 54px;
  --headings-big-semi-bold-size: 100px;
  --font-size-11xl: 30px;
  --font-size-31xl: 50px;
  --font-size-smi-9: 12.9px;
  --font-size-6xs: 7px;
  --font-size-7xs-6: 5.6px;
  --font-size-5xs-5: 7.5px;
  --font-size-2xs-1: 10.1px;
  --headings-h1-semi-bold-size: 76px;
  --font-size-42xl: 61px;
  --font-size-29xl: 48px;
  --font-size-19xl: 38px;
  --font-size-10xl: 29px;
  --font-size-14xl-9: 33.9px;
  --font-size-8xl: 27px;
  --font-size-6xl-2: 25.2px;
  --font-size-base-9: 15.9px;
  --font-size-2xl: 21px;
  --font-size-smi-8: 12.8px;
  --font-size-19xl-4: 38.4px;
  --font-size-4xl: 23px;
  --font-size-12xl: 31px;
  --font-size-sm-9: 13.9px;
  --font-size-mini: 15px;
  --font-size-mini-8: 14.8px;
  --font-size-2xs: 11px;
  --font-size-xs-1: 11.1px;
  --font-size-mini-7: 14.7px;
  --font-size-36xl: 55px;
  --font-size-14xl: 33px;
  --font-size-25xl: 44px;
  --font-size-17xl: 36px;
  --font-size-base-4: 15.4px;
  --font-size-smi-7: 12.7px;
  --font-size-19xl-2: 38.2px;
  --font-size-sm-5: 13.5px;
  --font-size-smi-4: 12.4px;
  --font-size-sm-7: 13.7px;
  --font-size-lgi-6: 19.6px;
  --font-size-43xl: 62px;
  --font-size-18xl: 37px;
  --font-size-23xl: 42px;
  --font-size-6xl: 25px;
  --font-size-53xl: 72px;
  --font-size-39xl: 58px;
  --font-size-smi: 13px;

  /* Colors */
  --web-secondary-ivory-light: #fffcf9;
  --w-background: #fff;
  --color-black: #000;
  --color-gray: #212121;
  --web-secondary-indigo-mid: #42275e;
  --brand-indigo: #3a1a4b;
  --color-whitesmoke: #f5f7fa;
  --color-darkslategray: #333;
  --brand-ivory: #f9ebdf;
  --color-linen-100: rgba(249, 235, 223, 0);
  --brand-amethyst: #904dff;
  --brand-lemon: #fcff56;
  --color-plum: #f9a6ea;
  --color-lightslategray: rgba(140, 131, 153, 0.6);
  --web-secondary-ivory-dark: #ecded2;
  --web-secondary-ivory-mid: #fdf5ed;
  --color-seashell-100: #fdf4ed;
  --color-lightsteelblue: #c1c1ff;
  --color-mediumspringgreen: #25d366;
  --keatext-purple: #7a5cfa;
  --color-dimgray: #666;

  /* Gaps */
  --gap-xl: 20px;
  --gap-13xl: 32px;
  --gap-61xl: 80px;
  --gap-21xl: 40px;
  --gap-5xl: 24px;
  --gap-xs: 12px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-lg-7: 18.7px;
  --gap-225xl: 244px;
  --gap-mid: 17px;
  --gap-93xl: 112px;
  --gap-9xl: 28px;
  --gap-37xl: 56px;
  --gap-90xl: 109px;
  --gap-29xl: 48px;
  --gap-60xl: 79px;
  --gap-20xl: 39px;
  --gap-lg: 18px;
  --gap-smi-2: 12.2px;
  --gap-10xl: 29px;
  --gap-sm: 14px;
  --gap-6xl: 25px;
  --gap-base-1: 15.1px;
  --gap-65xl: 84px;
  --gap-23xl: 42px;
  --gap-6xs-8: 6.8px;
  --gap-sm-2: 13.2px;
  --gap-3xs: 10px;
  --gap-240xl: 259px;
  --gap-smi: 13px;
  --gap-xs-8: 11.8px;
  --gap-6xs: 7px;
  --gap-12xs-1: 0.1px;
  --gap-138xl-5: 157.5px;
  --gap-17xl: 36px;
  --gap-57xl: 76px;
  --gap-19xl: 38px;
  --gap-9xs: 4px;
  --gap-66xl-7: 85.7px;
  --gap-2xl: 21px;
  --gap-24xl: 43px;
  --gap-12xs: 1px;
  --gap-35xl: 54px;
  --gap-8xl: 27px;
  --gap-101xl: 120px;
  --gap-11xl: 30px;
  --gap-41xl: 60px;
  --gap-323xl: 342px;
  --gap-27xl: 46px;
  --gap-49xl-3: 68.3px;
  --gap-sm-8: 13.8px;
  --gap-9xs-3: 3.3px;
  --gap-2xl-2: 21.2px;
  --gap-xs-9: 11.9px;
  --gap-6xs-1: 6.1px;
  --gap-30xl: 49px;
  --gap-8xs: 5px;
  --gap-4xs-7: 8.7px;
  --gap-8xs-5: 4.5px;
  --gap-mini: 15px;
  --gap-5xs-3: 7.3px;
  --gap-lgi: 19px;
  --gap-11xs-5: 1.5px;
  --gap-7xs-6: 5.6px;
  --gap-smi-8: 12.8px;
  --gap-169xl: 188px;
  --gap-49xl: 68px;
  --gap-15xl: 34px;
  --gap-190xl: 209px;
  --gap-11xs: 2px;
  --gap-sm-4: 13.4px;
  --gap-291xl: 310px;
  --gap-238xl-2: 257.2px;
  --gap-110xl: 129px;
  --gap-26xl: 45px;
  --gap-159xl: 178px;
  --gap-31xl: 50px;
  --gap-11xl-4: 30.4px;
  --gap-17xl-8: 36.8px;
  --gap-6xs-7: 6.7px;
  --gap-21xl-2: 40.2px;
  --gap-3xs-5: 9.5px;
  --gap-3xl: 22px;
  --gap-73xl: 92px;
  --gap-4xl: 23px;
  --gap-17xl-4: 36.4px;
  --gap-17xl-5: 36.5px;
  --gap-6xl-4: 25.4px;
  --gap-174xl: 193px;
  --gap-77xl: 96px;
  --gap-89xl: 108px;
  --gap-12xl: 31px;
  --gap-mini-2: 14.2px;
  --gap-52xl: 71px;
  --gap-16xl: 35px;
  --gap-140xl: 159px;
  --gap-base-2: 15.2px;
  --gap-lgi-2: 19.2px;
  --gap-4xs: 9px;
  --gap-8xs-6: 4.6px;
  --gap-9xs-7: 3.7px;
  --gap-base-7: 16.7px;
  --gap-138xl: 157px;
  --gap-mid-2: 17.2px;
  --gap-6xs-3: 6.3px;
  --gap-6xs-2: 6.2px;
  --gap-2xl-4: 21.4px;
  --gap-69xl-3: 88.3px;
  --gap-25xl: 44px;
  --gap-4xs-8: 8.8px;
  --gap-14xl-3: 33.3px;
  --gap-21xl-3: 40.3px;
  --gap-smi-5: 12.5px;
  --gap-17xl-1: 36.1px;
  --gap-70xl: 89px;
  --gap-33xl-4: 52.4px;
  --gap-7xl: 26px;
  --gap-27xl-7: 46.7px;
  --gap-7xs-3: 5.3px;
  --gap-9xl-1: 28.1px;
  --gap-xs-7: 11.7px;
  --gap-161xl: 180px;
  --gap-2xs-5: 10.5px;
  --gap-72xl: 91px;
  --gap-38xl: 57px;
  --gap-23xl-5: 42.5px;
  --gap-2xl-5: 21.5px;
  --gap-4xs-4: 8.4px;
  --gap-2xs-9: 10.9px;
  --gap-8xl-5: 27.5px;
  --gap-42xl: 61px;
  --gap-14xl: 33px;
  --gap-18xl: 37px;
  --gap-169xl-4: 188.4px;
  --gap-22xl: 41px;
  --gap-base-9: 15.9px;
  --gap-5xs-1: 7.1px;
  --gap-75xl: 94px;
  --gap-28xl: 47px;
  --gap-30xl-4: 49.4px;
  --gap-125xl: 144px;
  --gap-5xl-5: 24.5px;
  --gap-176xl: 195px;
  --gap-28xl-5: 47.5px;
  --gap-86xl-8: 105.8px;
  --gap-34xl: 53px;
  --gap-67xl: 86px;
  --gap-87xl-1: 106.1px;
  --gap-97xl: 116px;
  --gap-142xl-8: 161.8px;
  --gap-234xl: 253px;
  --gap-87xl: 106px;
  --gap-6xs-4: 6.4px;
  --gap-36xl: 55px;
  --gap-79xl: 98px;
  --gap-82xl: 101px;
  --gap-3xs-3: 9.3px;
  --gap-9xl-2: 28.2px;
  --gap-66xl: 85px;
  --gap-166xl-5: 185.5px;
  --gap-30xl-3: 49.3px;
  --gap-base-8: 16.8px;
  --gap-31xl-4: 50.4px;
  --gap-8xl-7: 27.7px;

  /* Paddings */
  --padding-5xl: 24px;
  --padding-12xs: 1px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-xs: 12px;
  --padding-45xl: 64px;
  --padding-base: 16px;
  --padding-87xl: 106px;
  --padding-28xl-5: 47.5px;
  --padding-13xl: 32px;
  --padding-12856xl-5: 0;
  --padding-3199xl: 3218px;
  --padding-6418xl: 6437px;
  --padding-31xl: 50px;
  --padding-9xs: 4px;
  --padding-9889xl-1: 9908.1px;
  --padding-1131xl: 1150px;
  --padding-1750xl: 1769px;
  --padding-5xl-5: 24.5px;
  --padding-5xl-4: 24.4px;
  --padding-26xl: 45px;
  --padding-5xs-3: 7.3px;
  --padding-10xl: 29px;
  --padding-61xl: 80px;
  --padding-4xs: 9px;
  --padding-8xl: 27px;
  --padding-3xl: 22px;
  --padding-70xl-5: 89.5px;
  --padding-39xl: 58px;
  --padding-8xl-5: 27.5px;
  --padding-3xs: 10px;
  --padding-82xl: 101px;
  --padding-270xl: 289px;
  --padding-9xl: 28px;
  --padding-60xl: 79px;
  --padding-24xl: 43px;
  --padding-103xl: 122px;
  --padding-37xl: 56px;
  --padding-71xl-4: 90.4px;
  --padding-56xl-2: 75.2px;
  --padding-115xl: 134px;
  --padding-17xl: 36px;
  --padding-30xl: 49px;
  --padding-14xl: 33px;
  --padding-48xl: 67px;
  --padding-18xl-8: 37.8px;
  --padding-10xl-7: 29.7px;
  --padding-smi: 13px;
  --padding-13xl-5: 32.5px;
  --padding-16xl: 35px;
  --padding-11xl: 30px;
  --padding-15xl-8: 34.8px;
  --padding-71xl: 90px;
  --padding-121xl: 140px;
  --padding-120xl: 139px;
  --padding-51xl: 70px;
  --padding-8xl-2: 27.2px;
  --padding-8xl-6: 27.6px;
  --padding-63xl-3: 82.3px;
  --padding-18xl-5: 37.5px;
  --padding-14xl-4: 33.4px;
  --padding-11xl-5: 30.5px;
  --padding-34xl: 53px;
  --padding-53xl: 72px;
  --padding-28xl: 47px;
  --padding-50xl: 69px;
  --padding-base-9: 16.9px;
  --padding-7xl: 26px;
  --padding-2xl: 21px;
  --padding-81xl: 100px;
  --padding-15xl: 34px;
  --padding-52xl-7: 71.7px;
  --padding-117xl: 136px;
  --padding-18xl: 37px;
  --padding-143xl: 162px;
  --padding-19xl: 38px;
  --padding-69xl: 88px;
  --padding-86xl: 105px;
  --padding-21xl: 40px;
  --padding-100xl: 119px;
  --padding-4xl: 23px;
  --padding-546xl: 565px;
  --padding-547xl: 566px;
  --padding-122xl: 141px;
  --padding-264xl: 283px;
  --padding-263xl: 282px;
  --padding-458xl: 477px;
  --padding-67xl: 86px;
  --padding-46xl: 65px;
  --padding-12xl: 31px;
  --padding-219xl: 238px;
  --padding-54xl: 73px;
  --padding-lgi: 19px;
  --padding-22xl: 41px;
  --padding-6xl: 25px;
  --padding-23xl: 42px;
  --padding-36xl: 55px;
  --padding-41xl: 60px;
  --padding-302xl: 321px;
  --padding-91xl: 110px;
  --padding-27xl: 46px;
  --padding-52xl: 71px;
  --padding-10xs: 3px;
  --padding-6xs: 7px;
  --padding-5xl-3: 24.3px;
  --padding-8xs: 5px;
  --padding-2xs-4: 10.4px;
  --padding-5xs-4: 7.4px;
  --padding-6xs-4: 6.4px;
  --padding-sm-3: 13.3px;
  --padding-136xl: 155px;
  --padding-47xl: 66px;
  --padding-211xl-3: 230.3px;
  --padding-280xl-3: 299.3px;
  --padding-mid-5: 17.5px;
  --padding-smi-4: 12.4px;
  --padding-sm-8: 13.8px;
  --padding-2xs-9: 10.9px;
  --padding-2xs: 11px;
  --padding-3xs-6: 9.6px;
  --padding-4xs-3: 8.3px;
  --padding-11xs-9: 1.9px;
  --padding-10xs-8: 2.8px;
  --padding-smi-8: 12.8px;
  --padding-3xs-3: 9.3px;
  --padding-6xs-9: 6.9px;
  --padding-4xs-7: 8.7px;
  --padding-lgi-5: 19.5px;
  --padding-2xs-1: 10.1px;
  --padding-27xl-9: 46.9px;
  --padding-8xs-7: 4.7px;
  --padding-9xs-9: 3.9px;
  --padding-2xs-2: 10.2px;
  --padding-10xl-3: 29.3px;
  --padding-smi-2: 12.2px;
  --padding-11xs: 2px;
  --padding-11xs-8: 1.8px;
  --padding-xs-2: 11.2px;
  --padding-3xs-5: 9.5px;
  --padding-2xs-3: 10.3px;
  --padding-8xs-1: 4.1px;
  --padding-5xs-1: 7.1px;
  --padding-smi-7: 12.7px;
  --padding-185xl-9: 204.9px;
  --padding-257xl-4: 276.4px;
  --padding-186xl: 205px;
  --padding-257xl: 276px;
  --padding-44xl: 63px;
  --padding-59xl: 78px;
  --padding-107xl: 126px;
  --padding-91xl-2: 110.2px;
  --padding-116xl: 135px;
  --padding-63xl: 82px;
  --padding-62xl: 81px;
  --padding-110xl: 129px;
  --padding-65xl: 84px;
  --padding-mid: 17px;
  --padding-25xl: 44px;
  --padding-245xl: 264px;
  --padding-153xl: 172px;
  --padding-base-6: 16.6px;
  --padding-mini-6: 14.6px;
  --padding-mid-7: 17.7px;
  --padding-9xl-3: 28.3px;
  --padding-109xl: 128px;
  --padding-64xl: 83px;
  --padding-35xl: 54px;
  --padding-17xl-8: 36.8px;
  --padding-6xl-7: 25.7px;
  --padding-38xl: 57px;
  --padding-73xl: 92px;
  --padding-63xl-1: 82.1px;
  --padding-140xl: 159px;
  --padding-84xl: 103px;
  --padding-20xl: 39px;
  --padding-118xl: 137px;
  --padding-20xl-8: 39.8px;
  --padding-13xl-4: 32.4px;
  --padding-24xl-8: 43.8px;
  --padding-14xl-5: 33.5px;
  --padding-xs-5: 11.5px;
  --padding-sm: 14px;
  --padding-72xl-2: 91.2px;
  --padding-151xl: 170px;
  --padding-61xl-1: 80.1px;
  --padding-40xl: 59px;
  --padding-33xl: 52px;
  --padding-66xl: 85px;
  --padding-26xl-3: 45.3px;
  --padding-4xl-4: 23.4px;
  --padding-27xl-1: 46.1px;
  --padding-6xl-1: 25.1px;
  --padding-sm-4: 13.4px;
  --padding-7xs-6: 5.6px;
  --padding-sm-5: 13.5px;
  --padding-17xl-9: 36.9px;
  --padding-xl-6: 20.6px;
  --padding-8xs-6: 4.6px;
  --padding-35xl-5: 54.5px;
  --padding-194xl: 213px;
  --padding-119xl: 138px;
  --padding-75xl: 94px;
  --padding-203xl: 222px;
  --padding-64xl-9: 83.9px;
  --padding-42xl: 61px;
  --padding-92xl: 111px;
  --padding-7xl-7: 26.7px;
  --padding-90xl-4: 109.4px;
  --padding-33xl-1: 52.1px;
  --padding-mini-3: 14.3px;
  --padding-12xl-6: 31.6px;
  --padding-sm-6: 13.6px;
  --padding-172xl: 191px;
  --padding-16xl-6: 35.6px;
  --padding-69xl-9: 88.9px;
  --padding-82xl-7: 101.7px;
  --padding-32xl: 51px;
  --padding-15xl-3: 34.3px;
  --padding-37xl-7: 56.7px;
  --padding-7xl-5: 26.5px;
  --padding-16xl-1: 35.1px;
  --padding-2xl-3: 21.3px;
  --padding-30xl-7: 49.7px;
  --padding-42xl-7: 61.7px;
  --padding-13xl-8: 32.8px;
  --padding-35xl-8: 54.8px;
  --padding-18xl-3: 37.3px;
  --padding-34xl-3: 53.3px;
  --padding-37xl-9: 56.9px;
  --padding-21xl-1: 40.1px;
  --padding-114xl: 133px;
  --padding-mid-6: 17.6px;
  --padding-lgi-2: 19.2px;
  --padding-mid-2: 17.2px;
  --padding-lgi-3: 19.3px;
  --padding-29xl-4: 48.4px;
  --padding-lg: 18px;
  --padding-9xs-8: 3.8px;
  --padding-5xs-8: 7.8px;
  --padding-67xl-8: 86.8px;
  --padding-49xl: 68px;
  --padding-lg-5: 18.5px;
  --padding-xl-5: 20.5px;
  --padding-18xl-4: 37.4px;
  --padding-3xs-2: 9.2px;
  --padding-mini-1: 14.1px;
  --padding-3xs-4: 9.4px;
  --padding-9xs-6: 3.6px;
  --padding-mini-8: 14.8px;
  --padding-181xl: 200px;
  --padding-111xl: 130px;
  --padding-55xl: 74px;
  --padding-29xl: 48px;
  --padding-2xl-9: 21.9px;
  --padding-4xl-6: 23.6px;
  --padding-mid-3: 17.3px;
  --padding-6xs-7: 6.7px;
  --padding-7xl-4: 26.4px;
  --padding-xl-8: 20.8px;
  --padding-9xs-3: 3.3px;
  --padding-3xl-9: 22.9px;
  --padding-9xl-6: 28.6px;
  --padding-80xl-7: 99.7px;
  --padding-148xl: 167px;
  --padding-29xl-3: 48.3px;
  --padding-3xl-7: 22.7px;
  --padding-25xl-8: 44.8px;
  --padding-10xl-9: 29.9px;
  --padding-5xl-2: 24.2px;
  --padding-15xl-5: 34.5px;
  --padding-135xl: 154px;
  --padding-117xl-7: 136.7px;
  --padding-70xl: 89px;
  --padding-79xl: 98px;
  --padding-48xl-9: 67.9px;
  --padding-10xs-2: 2.2px;
  --padding-43xl: 62px;
  --padding-49xl-4: 68.4px;
  --padding-9xs-5: 3.5px;
  --padding-56xl: 75px;
  --padding-mini-9: 14.9px;
  --padding-mini: 15px;
  --padding-116xl-7: 135.7px;
  --padding-263xl-5: 282.5px;
  --padding-180xl: 199px;
  --padding-141xl-5: 160.5px;
  --padding-165xl: 184px;
  --padding-80xl: 99px;
  --padding-85xl: 104px;
  --padding-101xl: 120px;
  --padding-139xl-8: 158.8px;
  --padding-79xl-9: 98.9px;
  --padding-lgi-6: 19.6px;
  --padding-sm-7: 13.7px;
  --padding-smi-9: 12.9px;
  --padding-164xl: 183px;
  --padding-88xl: 107px;
  --padding-360xl-2: 379.2px;
  --padding-227xl: 246px;
  --padding-141xl: 160px;
  --padding-42xl-8: 61.8px;
  --padding-70xl-8: 89.8px;
  --padding-43xl-5: 62.5px;
  --padding-41xl-8: 60.8px;
  --padding-69xl-8: 88.8px;
  --padding-42xl-5: 61.5px;
  --padding-6xl-5: 25.5px;
  --padding-106xl: 125px;
  --padding-112xl: 131px;
  --padding-7xl-2: 26.2px;
  --padding-2xl-8: 21.8px;
  --padding-lgi-8: 19.8px;
  --padding-138xl-5: 157.5px;
  --padding-83xl: 102px;
  --padding-89xl: 108px;
  --padding-9xl-5: 28.5px;
  --padding-51xl-5: 70.5px;
  --padding-317xl-5: 336.5px;
  --padding-183xl-1: 202.1px;
  --padding-5xl-1: 24.1px;
  --padding-53xl-9: 72.9px;
  --padding-423xl-2: 442.2px;
  --padding-791xl: 810px;
  --padding-268xl: 287px;
  --padding-386xl: 405px;
  --padding-183xl: 202px;
  --padding-168xl: 187px;
  --padding-8xl-8: 27.8px;
  --padding-12xl-7: 31.7px;
  --padding-93xl-7: 112.7px;
  --padding-141xl-4: 160.4px;
  --padding-464xl: 483px;
  --padding-222xl: 241px;
  --padding-272xl: 291px;
  --padding-160xl: 179px;
  --padding-37xl-1: 56.1px;
  --padding-161xl: 180px;
  --padding-77xl: 96px;
  --padding-221xl: 240px;
  --padding-137xl: 156px;
  --padding-206xl: 225px;
  --padding-127xl: 146px;
  --padding-base-7: 16.7px;
  --padding-lgi-9: 19.9px;
  --padding-12xl-8: 31.8px;
  --padding-18xl-9: 37.9px;
  --padding-xs-3: 11.3px;
  --padding-13xl-1: 32.1px;
  --padding-11xl-8: 30.8px;
  --padding-4xs-5: 8.5px;
  --padding-220xl: 239px;
  --padding-108xl: 127px;
  --padding-98xl: 117px;
  --padding-767xl: 786px;
  --padding-314xl: 333px;
  --padding-197xl: 216px;
  --padding-147xl: 166px;
  --padding-313xl: 332px;
  --padding-134xl: 153px;
  --padding-126xl: 145px;
  --padding-4xl-7: 23.7px;
  --padding-13xl-3: 32.3px;
  --padding-smi-6: 12.6px;
  --padding-13xl-6: 32.6px;
  --padding-199xl-7: 218.7px;
  --padding-86xl-1: 105.1px;
  --padding-200xl: 219px;
  --padding-90xl: 109px;
  --padding-7xl-3: 26.3px;
  --padding-21xl-6: 40.6px;
  --padding-8xs-9: 4.9px;
  --padding-24xl-1: 43.1px;
  --padding-157xl: 176px;
  --padding-32xl-7: 51.7px;
  --padding-10xs-3: 2.3px;
  --padding-9xl-2: 28.2px;
  --padding-xs-7: 11.7px;
  --padding-59xl-1: 78.1px;
  --padding-73xl-2: 92.2px;
  --padding-41xl-7: 60.7px;
  --padding-159xl: 178px;
  --padding-274xl: 293px;
  --padding-97xl: 116px;
  --padding-171xl: 190px;
  --padding-104xl: 123px;
  --padding-9xs-7: 3.7px;
  --padding-176xl: 195px;
  --padding-80xl-5: 99.5px;
  --padding-11xl-6: 30.6px;
  --padding-7xs: 6px;
  --padding-85xl-2: 104.2px;
  --padding-133xl: 152px;
  --padding-57xl: 76px;
  --padding-147xl-8: 166.8px;
  --padding-99xl: 118px;
  --padding-116xl-4: 135.4px;
  --padding-99xl-2: 118.2px;
  --padding-58xl: 77px;
  --padding-9xl-7: 28.7px;
  --padding-31xl-8: 50.8px;
  --padding-6xl-8: 25.8px;
  --padding-149xl: 168px;
  --padding-258xl: 277px;
  --padding-193xl: 212px;
  --padding-144xl: 163px;
  --padding-96xl: 115px;
  --padding-88xl-5: 107.5px;
  --padding-10xl-5: 29.5px;
  --padding-36xl-5: 55.5px;
  --padding-460xl: 479px;
  --padding-476xl: 495px;
  --padding-228xl: 247px;
  --padding-63xl-7: 82.7px;
  --padding-3xl-3: 22.3px;
  --padding-170xl: 189px;
  --padding-162xl: 181px;
  --padding-20xl-3: 39.3px;
  --padding-38xl-3: 57.3px;
  --padding-25xl-3: 44.3px;
  --padding-169xl: 188px;
  --padding-167xl: 186px;
  --padding-19xl-3: 38.3px;
  --padding-174xl: 193px;
  --padding-68xl: 87px;
  --padding-330xl: 349px;
  --padding-129xl: 148px;
  --padding-208xl: 227px;
  --padding-269xl: 288px;
  --padding-78xl: 97px;
  --padding-107xl-4: 126.4px;
  --padding-68xl-9: 87.9px;
  --padding-87xl-8: 106.8px;
  --padding-55xl-7: 74.7px;
  --padding-11xs-5: 1.5px;
  --padding-10xs-1: 2.1px;
  --padding-69xl-1: 88.1px;
  --padding-32xl-5: 51.5px;
  --padding-26xl-9: 45.9px;
  --padding-lgi-4: 19.4px;
  --padding-163xl: 182px;
  --padding-301xl-5: 320.5px;
  --padding-189xl: 208px;
  --padding-212xl: 231px;

  /* Border radiuses */
  --br-xs: 12px;
  --br-261xl: 280px;
  --br-5xs: 8px;
  --br-sm-6: 13.6px;
  --br-3xs-1: 9.1px;
  --br-21xl-9: 40.9px;
  --br-5xl: 24px;
  --br-mid-1: 17.1px;
  --br-base-9: 16.9px;
  --br-57xl-4: 76.4px;
  --br-base: 16px;
  --br-9xs-4: 3.4px;
  --br-7xs-2: 5.2px;
  --br-2xl-8: 21.8px;
  --br-49xl: 68px;
  --br-9xs: 4px;
  --br-8xs-8: 4.8px;
  --br-8xs-7: 4.7px;
  --br-11xs: 2px;
  --br-mini-3: 14.3px;
  --br-42xl-6: 61.6px;
  --br-13xl: 32px;
  --br-base-3: 16.3px;
  --br-9xs-7: 3.7px;
  --br-2xs-9: 10.9px;
  --br-29xl-5: 48.5px;
  --br-smi-6: 12.6px;
  --br-32xl: 51px;
  --br-2xs-6: 10.6px;
  --br-32xl-2: 51.2px;
  --br-sm-1: 13.1px;
  --br-sm-4: 13.4px;
  --br-21xl-8: 40.8px;
  --br-3xs-3: 9.3px;
  --br-sm-5: 13.5px;
  --br-3xs-4: 9.4px;
  --br-9xl-3: 28.3px;
  --br-smi-8: 12.8px;
  --br-19xl-3: 38.3px;
  --br-2xs: 11px;
  --br-25xl-5: 44.5px;
  --br-base-4: 16.4px;
  --br-30xl-1: 49.1px;
  --br-mini-7: 14.7px;
  --br-3xs-2: 9.2px;
  --br-25xl-1: 44.1px;
  --br-6xs-7: 6.7px;
  --br-8xl-2: 27.2px;
  --br-5xs-7: 7.7px;
  --br-mid-9: 17.9px;
  --br-sm-7: 13.7px;
  --br-5xs-8: 7.8px;
  --br-4xs: 9px;
  --br-6xs-2: 6.2px;
  --br-6xs-9: 6.9px;
  --br-17xl-3: 36.3px;
}
.webinarForm .u_body .u-col strong {
  font-weight: 600;
  color: var(--brand-indigo);
}
.subscriptionform .u_body .u-col div,
.webinarForm .u_body .u-col div div,
.relevaReset {
  font-family: 'Rubik', sans-serif;
  line-height: 1em;
  margin: 0;
}
.subscriptionform .u_body .u-col div a:hover,
.webinarForm .u_body .u-col div div a:hover {
  color: var(--brand-amethyst) !important;
}
