.data-integration-and,
.ebaglogo1 {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.ebaglogo1 {
  line-height: 38px;
  display: inline-block;
  min-width: 36px;
}
.data-integration-and {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  line-height: 51px;
  font-family: inherit;
}
.releva-simplifies-your {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.button-name25 {
  width: 110px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button28 a {
  color: #fff;
}
.button28 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.data-integration-and-unificati-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--headings-h3-semi-bold-size);
}
.data-integration-and-unificati-parent,
.ebaglogo-parent,
.section-01-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ebaglogo-parent {
  align-self: stretch;
  gap: var(--gap-57xl);
}
.section-01-child {
  width: 518px;
  padding: var(--padding-16xl) 0 0;
  box-sizing: border-box;
  /* min-width: 518px;
  max-width: 100%; */
}
.vuesaxlineardirect-up-icon11 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.tree-builder {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-13xl-6) 0 var(--padding-14xl);
  box-sizing: border-box;
}
.interactions,
.website {
  margin: 0;
}
.website-interactions {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlinearmouse-square-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  top: 26px;
  right: 46.6px;
  z-index: 1;
}
.marketingautomationexpert,
.matrix-multiplier {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.marketingautomationexpert {
  align-self: stretch;
  border-radius: 11.97px;
  background-color: var(--color-plum);
  overflow: hidden;
  align-items: flex-end;
  padding: 15.6px var(--padding-smi-6) var(--padding-9xl);
  position: relative;
  gap: var(--gap-lg);
  flex-shrink: 0;
}
.matrix-multiplier {
  width: 133.6px;
  align-items: flex-start;
  padding: var(--padding-172xl) 0 0;
  box-sizing: border-box;
  min-width: 133.59999999999854px;
}
.body-icon1,
.icon3 {
  width: 454.4px;
  height: 504.8px;
  position: absolute;
  margin: 0 !important;
  right: -271.3px;
  bottom: -117.7px;
}
.body-icon1 {
  width: 100%;
  height: 100%;
  top: 0;
  right: 2.9px;
  bottom: 0;
  max-height: 100%;
  object-fit: cover;
}
.j-s-o-n-generator {
  height: 19.6px;
  width: 74px;
  position: relative;
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  flex-shrink: 0;

  z-index: 1;
}
.buttons-icon1 {
  height: 130.3px;
  width: 290.7px;
  position: absolute;
  margin: 0 !important;
  bottom: -44.1px;
  left: -254px;
  z-index: 1;
}
.vuesaxlineardirect-up-icon12 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.image-processor {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xl) 0 var(--padding-9xl);
  box-sizing: border-box;
}
.customer,
.profile1 {
  margin: 0;
}
.customer-profile {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlinearprofile-circle-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  top: 15px;
  right: 46.6px;
  z-index: 1;
}
.ebaglogo2,
.x-m-l-generator {
  justify-content: flex-start;
  position: relative;
}
.ebaglogo2 {
  flex: 1;
  border-radius: 11.97px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15.6px var(--padding-base-6) var(--padding-9xl) var(--padding-lg);
  gap: var(--gap-lg);
  flex-shrink: 0;

  z-index: 2;
}
.x-m-l-generator {
  align-self: stretch;
  flex-direction: row;
}
.j-s-o-n-parser,
.x-m-l-generator,
.x-m-l-parser {
  display: flex;
  align-items: flex-start;
}
.x-m-l-parser {
  width: 133.6px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-mini-3) 0 0;
  box-sizing: border-box;
}
.j-s-o-n-parser {
  width: 278.2px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.vuesaxlinearnote-icon {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 1;
  top: -8px;
}
.vuesaxlinearnote-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-8xl);
}
.vuesaxlineardirect-up-icon13 {
  width: 42.4px;
  height: 42.4px;
  position: absolute;
  margin: 0 !important;
  top: 15.6px;
  right: 45.2px;
}
.history,
.purchase {
  margin: 0;
}
.purchase-history {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.eleonora-ivanova-marketingauto,
.iphone-14-pro1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.eleonora-ivanova-marketingauto {
  margin-left: -160.40000000000146px;
  width: 133.6px;
  border-radius: 11.97px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  padding: var(--padding-5xl) var(--padding-lgi-6) var(--padding-9xl) var(--padding-xl);
  gap: var(--gap-xs);
  color: var(--brand-indigo);
}
.iphone-14-pro1 {
  width: 292.1px;
  padding: var(--padding-7xl-7) var(--padding-90xl-4) var(--padding-33xl-1);
  gap: var(--gap-174xl);
  min-width: 292.09999999999854px;
  flex-shrink: 0;

  font-size: var(--font-size-3xl);
  color: var(--brand-ivory);
}
.matrix-multiplier-parent,
.section-011,
.value-transformer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.matrix-multiplier-parent {
  justify-content: flex-start;
  gap: var(--gap-6xl-4);
  min-width: 451.09999999999854px;
  text-align: center;
  font-size: var(--body-large-regular-size);
}
.section-011,
.value-transformer {
  box-sizing: border-box;
}
.section-011 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  justify-content: space-between;
  padding: var(--padding-75xl) var(--padding-203xl) var(--padding-64xl-9) var(--padding-116xl);
  gap: var(--gap-xl);
}
.value-transformer {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-45xl) var(--padding-15xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .matrix-multiplier-parent,
  .section-01-child {
    flex: 1;
  }
  .section-011 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1225px) {
  .matrix-multiplier-parent,
  .section-01-child {
    min-width: 100%;
  }
  .section-011,
  .value-transformer {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-92xl);
    box-sizing: border-box;
  }
  .value-transformer {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
  }
}
@media screen and (max-width: 850px) {
  .ebaglogo1 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .data-integration-and {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .ebaglogo-parent {
    gap: 38px 76px;
  }
  .matrix-multiplier {
    flex: 1;
  }
  .iphone-14-pro1 {
    flex: 1;
    gap: 96px 193px;
    padding: var(--padding-xl) var(--padding-35xl) var(--padding-15xl);
    box-sizing: border-box;
  }
  .matrix-multiplier-parent {
    flex-wrap: wrap;
  }
  .section-011 {
    padding: var(--padding-42xl) var(--padding-36xl) var(--padding-36xl) var(--padding-14xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ebaglogo1 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .data-integration-and {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .ebaglogo-parent {
    gap: 19px 76px;
  }
  .website-interactions {
    font-size: var(--body-small-regular-size);
    line-height: 19px;
  }
  .customer-profile,
  .purchase-history {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .section-011 {
    padding-top: var(--padding-21xl);
    padding-right: var(--padding-xl);
    padding-bottom: var(--padding-17xl);
    box-sizing: border-box;
  }
}
