.caseStudiesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap-13xl);
  padding: 0 20px;
}
.caseStudiesContainer a {
  text-decoration: none;
  color: var(--brand-indigo);
}
.social-proof-section-child .section-01.ivory.frame-parent4 {
  gap: 36px;
  padding: 40px;
}
.social-proof-section-child .section-01.ivory.frame-parent4 .frame-parent5 {
  text-align: center;
  width: auto;
  max-width: 220px;
}
.social-proof-section-child .section-01.ivory.frame-parent4 .frame-parent5 .parent {
  width: auto;
}

.resultsHeading {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 51px;
}
.frame-parent174 {
  border-radius: var(--br-3xs);
  background-color: #f8f8f8;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
  width: 340px;
}
.frame-child108 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-7xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.ellipse-parent7 {
  border-radius: var(--br-xl);
  background-color: var(--w-background);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--color-blueviolet-200);
  text-align: left;
  font-size: var(--body-footnote-regular-size);
  color: var(--type-color);
  font-family: var(--body-footnote-regular);
}
.frame-child109 {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #3a1a4b;
}
.slow-moving-consumer-goods9.Medium {
  color: #3a1a4b;
}
.slow-moving-consumer-goods9.Fast-Moving {
  color: #fe14ff;
}
.slow-moving-consumer-goods9.Slow-Moving {
  color: #6c40ff;
}
.frame-child109.Fast-Moving {
  background-color: #fe14ff;
}
.frame-child109.Slow-Moving {
  background-color: #6c40ff;
}

.momax-case-study1 {
  align-self: stretch;
  font-size: var(--body-default-semi-bold-size);
  font-weight: 600;
  text-align: left;
  font-size: var(--body-footnote-regular-size);
  color: var(--type-color);
  font-family: var(--body-footnote-regular);
}
.conversion-rate-from5 {
  text-align: left;
  font-size: var(--body-footnote-regular-size);
  color: var(--type-color);
  font-family: var(--body-footnote-regular);
}
.case-studies .section-01-inner,
.case-studies .frame-parent33 {
  max-width: 700px;
}
@media screen and (max-width: 450px) {
  .case-studies .social-proof-section-child .section-01.ivory.frame-parent4 .frame-parent5 {
    width: 100%;
  }
  .case-studies .social-proof-section-child .div {
    line-height: 100px;
  }
}
