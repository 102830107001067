.case-study-1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.trendobg-is-an {
  width: 561px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}
.trendobg-is-an-online-marketp-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.case-study-trendo-parent {
  width: 1186px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  max-width: 100%;
}
.block-1-parent,
.menu-inner {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.menu-inner {
  flex-direction: row;
  justify-content: center;
  padding: 0 0 var(--padding-118xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.block-1-parent {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-35xl);
}
.try-releva-free {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
}
.book-a-session {
  width: 527px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}
.book-a-session-with-one-of-our-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.try-releva-free-for-30-days-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.button-name {
  width: 110px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  z-index: 1;
  text-decoration: none;
}
.button,
.data-splitter,
.frame-parent {
  display: flex;
  align-items: flex-start;
}
.data-splitter {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  font-size: var(--body-default-semi-bold-size);
  color: var(--web-secondary-ivory-light);
}
.frame-parent {
  width: 706px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-27xl);
  max-width: 100%;
}
.block-7-parent,
.error-handler {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
}
.error-handler {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.block-7-parent {
  gap: var(--gap-19xl);
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.block-7-parent,
.menu,
.trendo-case-study {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.menu {
  width: 1728px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-28xl) var(--padding-45xl) var(--padding-52xl);
  box-sizing: border-box;
  gap: var(--gap-12xl);
  max-width: 100%;
}
.trendo-case-study {
  width: 100%;
  position: relative;
  align-items: flex-start;
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .menu {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .case-study-1 {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .case-study-trendo-parent {
    gap: 16px 32px;
  }
  .block-1-parent {
    gap: 27px 54px;
  }
  .try-releva-free {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .frame-parent {
    gap: 23px 46px;
  }
  .block-7-parent {
    gap: 19px 38px;
  }
  .menu {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .case-study-1 {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .try-releva-free {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .menu {
    gap: 15px 31px;
  }
}
