.onePlatformDivClick:hover,
.onePlatformDivClick:hover h3,
.onePlatformDivClick.active,
.onePlatformDivClick.active h3 {
  background-color: var(--brand-ivory);
  padding: var(--padding-5xs) var(--padding-base) !important;
  border-radius: var(--br-5xs);
  cursor: pointer;
}
.onePlatformDivClick.active,
.onePlatformDivClick.active h3,
.onePlatformDivClick.active h2 {
  font-weight: 600;
}

.personalized-product-recommend {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
}
.icon1,
.personalized-product-recommend-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.personalized-product-recommend-wrapper {
  border-radius: var(--br-xs);
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  white-space: nowrap;
}
.icon1 {
  flex-direction: column;
}
.basket-browse {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.content-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.omnichannel-customer-engagemen {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.content-container1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.post-purchase-strategies {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.content-container2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  max-width: 100%;
}
.cross-sell-upsell {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 26px;
  font-weight: 400;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.content-container3,
.icon-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.content-container3 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
}
.icon-parent {
  width: 432px;
  border-radius: var(--br-xs);
  flex-direction: column;
  gap: var(--gap-29xl);
  min-width: 432px;
}
.p5-icon,
.wrapper-p5 {
  flex: 1;
  min-width: 512px;
}
.p5-icon {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 15px;
  width: 100%;
  transform: scale(1.129);
}
.wrapper-p5 {
  height: 443.5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-parent29,
.frame-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.frame-parent29 {
  width: 1328px;
  gap: var(--gap-90xl);
}
.frame-section {
  align-self: stretch;
  padding: 0 var(--padding-3xl) var(--padding-70xl-5) var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .icon-parent {
    flex: 1;
  }
  .frame-parent29 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 825px) {
  .icon-parent,
  .p5-icon {
    min-width: 100%;
  }
  .frame-parent29 {
    gap: 109px 54px;
  }
  .frame-section {
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .basket-browse,
  .cross-sell-upsell,
  .omnichannel-customer-engagemen,
  .post-purchase-strategies {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .icon-parent {
    gap: 24px 48px;
  }
  .frame-parent29 {
    gap: 27px;
  }
  .wrapper-p5 {
    width: 100%;
    min-width: 100%;
    height: 220px;
    text-align: center;
  }
}
