.notFoundPage.holder {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  max-width: 100%;
  min-height: 400px;
  position: relative;
  padding-bottom: 48px;
  background-image: linear-gradient(to top right, #0f172b 10%, #080812);
}
.notFoundPage.holder img {
  height: 500px;
}

.notFoundPage.holder h1 {
  font-size: 72px !important;
  color: #faeadf;
  font-family: var(--body-footnote-regular) !important;
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
  text-align: center;
}

.notFoundPage.holder p {
  letter-spacing: -0.01em;
  line-height: 28px;
  font-size: var(--body-default-semi-bold-size);
  color: #faeadf;
  font-family: var(--body-footnote-regular);
  padding: 0 20px;
}

.notFoundPage.holder a.button-name5 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-family: var(--body-footnote-regular);
  width: auto;
}

.notFoundPage.holder .contentNotFound {
  position: absolute;
  bottom: 96px;
  text-align: center;
}
