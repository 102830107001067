.data-driven-marketers .i-09 {
  position: initial;
}
.b3-icon {
  width: 100%;
  height: 728px;
  position: absolute;
  margin: 0 !important;
  top: 646px;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.your-data-driven-marketing {
  margin: 0;
  width: 626px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.styling {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-24xl);
  box-sizing: border-box;
}
.adjustments,
.properties,
.styling {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.properties {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-77xl);
}
.adjustments {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-45xl) 137.7px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.data-driven-marketers {
  width: 100%;
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 80px;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .adjustments {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    padding-bottom: var(--padding-19xl);
    box-sizing: border-box;
  }
  .data-driven-marketers {
    gap: 32px 63.3px;
  }
}
@media screen and (max-width: 850px) {
  .your-data-driven-marketing {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .properties {
    gap: 48px 96px;
  }
  .adjustments {
    padding-bottom: var(--padding-6xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .your-data-driven-marketing {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .properties {
    gap: 24px 96px;
  }
  .data-driven-marketers {
    gap: 16px 63.3px;
  }
}
