.slider-container {
  max-width: 100vw !important;
  width: 100% !important;
}
.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: var(--brand-indigo) !important;
  font-size: 20px;
}
.slick-dots li,
.slick-dots li button {
  width: 40px !important;
  height: 40px !important;
}

.seamless-integration-and {
  margin: 0;
  width: 600px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 273%;
}
.signal-processor {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-smi) 0 0;
}
.we-are-extremely {
  width: 626px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 269%;
  flex-shrink: 0;
}
.signal-processor-parent {
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.input-processor .testimonial-central {
  min-height: 292px;
}
.testimonial-image-wrapper .frame-icon {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 76.43px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.frame-wrapper8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-302xl) 0 0;
}
.andreas-schneider,
.mercari {
  margin: 0;
}
.andreas-schneider-mercari-container {
  align-self: stretch;
  width: 214px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
  flex-shrink: 0;
}
.andreas-schneider-mercari-wrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-name3 {
  width: 136px;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  text-decoration: none;
}
.button3 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-base);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.frame-parent8,
.frame-parent9,
.testimonial-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent9 {
  width: 458px;
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-9xs);
  max-width: 169%;
  flex-shrink: 0;
}
.frame-parent8,
.testimonial-left {
  box-sizing: border-box;
}
.frame-parent8 {
  align-self: stretch;
  height: 155px;
  align-items: flex-end;
  padding: 0;
  gap: var(--gap-5xs);
  font-size: var(--body-small-regular-size);
}
.testimonial-left {
  /* margin-left: -1200px;
  width: 789px; */
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  flex-shrink: 0;
  align-items: flex-start;
  /* padding: var(--padding-28xl) var(--padding-22xl) var(--padding-6xl)
    var(--padding-458xl); */
  gap: var(--gap-29xl);
}
.testomonial-background-icon {
  height: 321px;
  width: 764px;
  position: absolute;
  margin: 0 !important;
  top: -19px;
  right: -139px;
  border-radius: var(--br-xs);
  overflow: hidden;
  flex-shrink: 0;
}
.reach-purchase-conversion {
  margin: 0;
  width: 524px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 76px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.reach-purchase-conversion-rate-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-12xs);
  box-sizing: border-box;
  max-width: 100%;
}
.thanks-to-releva {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-width: 100%;
}
.thanks-to-releva-we-manage-to-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-36xl) 0 var(--padding-41xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.testimonial-image-icon {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 76.43px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.testimonial-image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 40px;
}
.ebag,
.eleonora-ivanova,
.marketing-automation-expert {
  margin: 0;
}
.button-name4,
.eleonora-ivanova-marketing-container {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.eleonora-ivanova-marketing-container {
  flex: 1;
  line-height: 17px;
  text-transform: capitalize;
  min-width: 139px;
}
.button-name4 {
  width: 136px;
  font-size: var(--body-small-regular-size);
  line-height: 25px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  text-decoration: none;
}
.button4 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-base);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  flex-direction: row;
  white-space: nowrap;
  text-decoration: none;
}
.button-wrapper,
.button4,
.eleonora-ivanova-marketing-aut-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-wrapper {
  flex-direction: column;
  padding: var(--padding-23xl) 0 0;
}
.eleonora-ivanova-marketing-aut-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.testimonial-central .frame-parent10 {
  width: 458px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-5xs);
  font-size: var(--body-footnote-regular-size);
}
.testimonial-central .frame-parent10,
.output-handler,
.testimonial-central {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.testimonial-central {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-28xl) var(--padding-22xl) var(--padding-6xl);
  box-sizing: border-box;
  gap: var(--gap-xs);
  z-index: 1;
}
.output-handler {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.pagination-icon {
  height: 12px;
  width: 112px;
  position: relative;
}
.pagination-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
}
.input-processor {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--brand-ivory);
  width: 70%;
  margin: 0 auto;
  padding: 20px;
}
.value-added-product {
  margin: 0;
  width: 600px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 243%;
  flex-shrink: 0;
}
.i-hope-releva {
  width: 626px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
  max-width: 275%;
}
.i-hope-releva-becomes-a-big-co-wrapper,
.value-added-product-helping-yo-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.i-hope-releva-becomes-a-big-co-wrapper {
  flex-direction: row;
  padding: 0 0 0 var(--padding-lgi);
  font-size: var(--body-default-semi-bold-size);
}
.value-added-product-helping-yo-parent {
  flex-direction: column;
  padding: 0;
  gap: var(--gap-xs);
}
.image-2-icon,
.image-3-icon {
  position: absolute;
  top: 0.1px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-3-icon {
  top: 0;
  z-index: 1;
}
.image-2-parent {
  height: 97.7px;
  width: 65.2px;
  position: absolute;
  margin: 0 !important;
  right: -1px;
  bottom: -21.4px;
}
.frame-wrapper10,
.frame-wrapper9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper10 {
  height: 64px;
  width: 64px;
  border-radius: 76.43px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}
.frame-wrapper9 {
  width: 22px;
  padding: 0 0 0 var(--padding-54xl);
  box-sizing: border-box;
}
.andreas-schneider1,
.mercari1 {
  margin: 0;
}
.andreas-schneider-mercari-container1 {
  width: 214px;
  height: 38px;
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
}
.frame-parent11,
.testimonial-right,
.testimonials {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.frame-parent11 {
  width: 22px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: var(--gap-5xs);
  font-size: var(--body-small-regular-size);
}
.testimonial-right,
.testimonials {
  flex-shrink: 0;
}
.testimonial-right {
  width: 789px;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-28xl) var(--padding-458xl) var(--padding-67xl) var(--padding-46xl);
  gap: var(--gap-13xl);
}
.testimonials {
  /* margin-left: -96px; */
  margin-bottom: 45px;
  width: 100%;
  min-width: 1824px;
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-4xl) var(--padding-546xl) 0 var(--padding-547xl);
  gap: var(--gap-138xl-5);
  max-width: 106%;
  text-align: center;
  font-size: var(--headings-h3-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .testimonials {
    gap: 157.5px 79px;
    padding-left: var(--padding-264xl);
    padding-right: var(--padding-263xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .seamless-integration-and {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .testimonial-left {
    padding-left: var(--padding-219xl);
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .reach-purchase-conversion {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .thanks-to-releva-we-manage-to-wrapper {
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-8xl);
    box-sizing: border-box;
  }
  .testimonial-central {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .value-added-product {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .testimonial-right {
    padding: var(--padding-12xl) var(--padding-219xl) var(--padding-37xl) var(--padding-13xl);
    box-sizing: border-box;
  }
  .testimonials {
    gap: 157.5px 39px;
    padding-left: var(--padding-122xl);
    padding-right: var(--padding-122xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .slick-dots {
    bottom: -125px;
  }
  .seamless-integration-and {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .frame-wrapper8 {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .testimonial-left {
    gap: 24px 48px;
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .reach-purchase-conversion {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .eleonora-ivanova-marketing-aut-parent {
    gap: var(--gap-19xl);
  }
  .input-processor {
    gap: 18px 36px;
  }
  .value-added-product {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .testimonial-right,
  .testimonials {
    gap: 16px 32px;
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .testimonials {
    gap: 157.5px 20px;
    padding-left: var(--padding-xl);
  }
}
