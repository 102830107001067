.releva-ai-hub .parent6 {
  position: initial;
  background: none;
}
.i-09-child,
.image-120-icon {
  max-width: 100%;
  overflow: hidden;
}
.image-120-icon {
  height: 746.5px;
  flex: 1;
  position: relative;
  object-fit: cover;
  min-width: 513px;
}
.i-09-child {
  position: absolute;
  height: 66.3%;
  width: 70.67%;
  top: 53.35%;
  right: -87.98%;
  bottom: -19.65%;
  left: 117.31%;
  max-height: 100%;
  object-fit: contain;
}
.facebook-wordmark-logo-brandlo {
  position: absolute;
  top: 179px;
  left: 35px;
  width: 135px;
  height: 24.1px;
  overflow: hidden;
}
.engagement {
  top: 58.12%;
  left: 16.15%;
  letter-spacing: -0.04em;
  line-height: 65.32px;
}
.engagement,
.increase,
.save,
.time {
  position: absolute;
  font-weight: 600;
}
.increase {
  width: 48.46%;
  top: 58.12%;
  left: 25.94%;
  font-size: var(--body-footnote-regular-size);
  letter-spacing: -0.01em;
  line-height: 12.02px;
  text-align: center;
  display: inline-block;
}
.save,
.time {
  top: 16.94%;
  left: 36.71%;
  letter-spacing: -0.04em;
  line-height: 65.32px;
}
.save {
  width: 48.46%;
  left: 25.94%;
  font-size: var(--body-footnote-regular-size);
  letter-spacing: -0.01em;
  line-height: 12.02px;
  text-align: center;
  display: inline-block;
}
.frame-child14 {
  position: absolute;
  height: 9.24%;
  width: 7.83%;
  top: 51.58%;
  right: 38.33%;
  bottom: 39.18%;
  left: 53.84%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.facebook-wordmark-logo-brandlo-parent {
  position: absolute;
  height: 48.85%;
  width: 48.61%;
  top: 51.15%;
  right: 51.39%;
  bottom: 0;
  left: 0;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
}
.facebook-wordmark-logo-brandlo1 {
  position: absolute;
  top: 179px;
  left: 35px;
  width: 135px;
  height: 24.1px;
  overflow: hidden;
}
.vuesaxlinearwallet-icon {
  position: absolute;
  top: 49px;
  left: 75px;
  width: 55px;
  height: 55px;
}
.facebook-wordmark-logo-brandlo-group {
  position: absolute;
  height: 48.85%;
  width: 48.61%;
  top: 0;
  right: 51.39%;
  bottom: 51.15%;
  left: 0;
  border-radius: 13.59px;
  background-color: var(--brand-periwinkle);
  overflow: hidden;
}
.ai-generated1 {
  position: absolute;
  top: 58.12%;
  left: 12.24%;
  letter-spacing: -0.04em;
  line-height: 32px;
  font-weight: 600;
}
.pictures-based-on {
  position: absolute;
  top: 190px;
  left: calc(50% - 67.15px);
  font-size: var(--body-footnote-regular-size);
  letter-spacing: -0.01em;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  width: 134px;
}
.frame-child15 {
  position: absolute;
  height: 31.56%;
  width: 40.14%;
  top: 19.63%;
  right: 30%;
  bottom: 48.81%;
  left: 29.86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.ai-generated-parent {
  position: absolute;
  height: 48.85%;
  width: 48.61%;
  top: 0;
  right: 0;
  bottom: 51.15%;
  left: 51.39%;
  border-radius: 13.59px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  font-size: var(--font-size-7xl);
}
.facebook-wordmark-logo-brandlo2 {
  position: absolute;
  top: 179px;
  left: 35px;
  width: 135px;
  height: 24.1px;
  overflow: hidden;
}
.facebook-wordmark-logo-brandlo-wrapper {
  position: absolute;
  height: 48.85%;
  width: 48.61%;
  top: 51.15%;
  right: 0;
  bottom: 0;
  left: 51.39%;
  border-radius: 13.59px;
  background-color: var(--color-plum);
  overflow: hidden;
}
.p241 {
  position: absolute;
  height: 71.29%;
  width: 53.27%;
  top: 14.34%;
  right: 23.41%;
  bottom: 14.37%;
  left: 23.32%;
}
.screenshot-2024-04-09-at-2025-icon {
  position: absolute;
  top: 102px;
  left: 184px;
  width: 185px;
  height: 270px;
  object-fit: cover;
}
.screenshot-2024-04-09-at-2028-icon {
  /* position: absolute;
  top: 384px;
  left: 415px;
  width: 175px;
  height: 258px; */
  width: 100%;
  object-fit: cover;
}
.i-09 {
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
}
.i-09,
.section-05 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.div28,
.left {
  position: relative;
}
.left {
  /* height: 746px;
  width: 789px; */
  /* display: none; */
  max-width: 100%;
}
.div28 {
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  display: inline-block;
  min-width: 42px;
}
.edit-your-documents,
.master-your-generated {
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
}
.master-your-generated {
  width: 640px;
  font-size: var(--headings-h3-semi-bold-size);
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
}
.edit-your-documents {
  align-self: stretch;
  font-size: var(--font-size-9xl);
  font-weight: 500;
}
.customize-your-generated {
  width: 640px;
  height: 67px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.howitworks3,
.howitworks3-inner,
.parent6 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.parent6 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.howitworks3,
.howitworks3-inner {
  box-sizing: border-box;
}
.howitworks3-inner {
  width: 762px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-157xl-7) 0 0;
  min-width: 762px;
  flex-shrink: 0;
  font-size: var(--headings-h4-semi-bold-size);
}
.howitworks3 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-2xs) 0;
  gap: var(--gap-87xl);
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .howitworks3-inner {
    flex: 1;
  }
}
@media screen and (max-width: 1225px) {
  .howitworks3-inner {
    min-width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .image-120-icon {
    min-width: 100%;
  }
  .div28 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .master-your-generated {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .howitworks3 {
    gap: var(--gap-34xl);
  }
}
@media screen and (max-width: 450px) {
  .engagement,
  .time {
    font-size: var(--font-size-lgi);
    line-height: 52px;
  }
  .ai-generated1 {
    font-size: var(--font-size-2xl);
    line-height: 26px;
  }
  .div28 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .master-your-generated {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .edit-your-documents {
    font-size: var(--font-size-3xl);
    line-height: 41px;
  }
  .howitworks3-inner {
    padding-top: var(--padding-96xl);
    box-sizing: border-box;
  }
  .howitworks3 {
    gap: var(--gap-7xl);
  }
  .releva-ai-hub .parent6 {
    height: auto;
  }
  .releva-ai-hub .howitworks3-inner {
    padding-top: 0;
    height: auto;
  }
  .releva-ai-hub .customize-your-generated {
    height: auto;
  }
}
