.vector-icon17 {
  height: 261px;
  width: 241px;
  position: absolute;
  margin: 0 !important;
  bottom: -90.7px;
  left: -52px;
}
.condition-splitter-child,
.frame-child54 {
  position: absolute;
  top: -46.2px;
  left: 25px;
  width: 356.7px;
  display: none;
}
.condition-splitter-child {
  top: 0;
  left: 0;
  border-radius: 10.85px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.8px solid var(--brand-amethyst);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.condition-splitter-inner,
.condition-splitter-item {
  position: absolute;
  top: 303.7px;
  left: 36.3px;
  border-radius: 48.54px;
  background-color: var(--brand-ivory);
  width: 271px;
  height: 16px;
  z-index: 1;
}
.condition-splitter-inner {
  top: 330px;
  width: 174.9px;
}
.image-15-icon2 {
  position: absolute;
  top: 48.1px;
  left: 84px;
  width: 174.4px;
  height: 224.2px;
  object-fit: cover;
  z-index: 1;
}
.condition-splitter1 {
  position: absolute;
  top: 16.8px;
  left: 0;
  border-radius: 10.85px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.8px solid var(--brand-amethyst);
  box-sizing: border-box;
  width: 351.8px;
  height: 424.1px;
}
.data-aggregator-inner {
  position: absolute;
  top: 0;
  left: 330px;
  border-radius: 3.69px;
  width: 38.8px;
  height: 38.8px;
  overflow: hidden;
  z-index: 1;
}
.data-aggregator6 {
  position: absolute;
  top: 18.5px;
  left: 32.7px;
  width: 368.8px;
  height: 440.9px;
}
.vuesaxlineareraser-icon1 {
  height: 32px;
  width: 32px;
  position: relative;
}
.vuesaxlineareraser-container {
  position: absolute;
  top: 432px;
  left: 147px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  z-index: 1;
}
.vuesaxlinearsize-icon1 {
  height: 32px;
  width: 32px;
  position: relative;
}
.vuesaxlinearsize-container {
  position: absolute;
  top: 432px;
  left: 214px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  z-index: 1;
}
.frame-parent89 {
  height: 515.3px;
  flex: 1;
  position: relative;
  border-radius: 14.25px;
  background-color: var(--brand-ivory);
  overflow: hidden;
  max-width: 100%;
  z-index: 1;
}
.vector-parent7 {
  width: 416.9px;
  margin: 0 !important;
  position: absolute;
  top: 98px;
  left: 820px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.ready-to-see3,
.strategy-customization-and {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.ready-to-see3 {
  line-height: 38px;
  display: inline-block;
  min-width: 42px;
}
.strategy-customization-and {
  margin: 0;
  align-self: stretch;
  font-size: var(--headings-h3-semi-bold-size);
  line-height: 51px;
  font-family: inherit;
}
.button-name27,
.with-these-insights {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.with-these-insights {
  width: 507px;
  max-width: 100%;
}
.button-name27 {
  width: 110px;
  min-width: 110px;
  text-decoration: none;
  color: #fff;
}
.button36:hover,
.button35:hover,
.button34:hover,
.button31:hover,
.get-demo9:hover,
.button28:hover,
.button30:hover {
  background-color: var(--color-lightsteelblue);
  cursor: pointer;
}
.button30 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.ready-to-see-action-button-parent,
.strategy-customization-and-aut-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.strategy-customization-and-aut-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.ready-to-see-action-button-parent {
  width: 550px;
  gap: var(--gap-22xl);
  flex-shrink: 0;
}
.vuesaxlineardirect-up-icon14 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper2 {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xl) 0 var(--padding-9xl-3);
  box-sizing: border-box;
}
.email2 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 66px;
  font-weight: 600;
  display: inline-block;
  min-width: 83px;
}
.join-function {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 var(--padding-5xs-3);
}
.send1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-footnote-regular-size);
  letter-spacing: -0.01em;
  line-height: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: -66px;
}
.merge-function {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.vuesaxlinearsms-tracking-icon2 {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  top: 23px;
  right: 46.6px;
  z-index: 1;
}
.filter-function,
.promotional-banners5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.promotional-banners5 {
  align-self: stretch;
  height: 152px;
  border-radius: 11.97px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  padding: 15.6px var(--padding-base-9) var(--padding-mini-6) var(--padding-mid-7);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-sm-4);
  z-index: 2;
}
.filter-function {
  height: 238px;
  width: 133.6px;
  font-size: var(--font-size-14xl-9);
  left: -400px;
  position: relative;
}
.error-handler4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
  margin-bottom: 90px;
}
.vuesaxlineardirect-up-icon15 {
  width: 60px;
  height: 60px;
  position: absolute;
  margin: 0 !important;
  top: 22px;
  right: 64px;
}
.vuesaxlineartag-2-icon1 {
  height: 52px;
  width: 52px;
  position: relative;
  z-index: 1;
}
.var-function {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-25xl);
}
.tags1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 107px;
}
.sin-function {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mid) 0 var(--padding-base);
}
.campaign1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  margin-top: -93px;
}
.abs-function,
.count-function {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.abs-function {
  align-self: stretch;
  flex: 1;
}
.count-function {
  width: 189px;
  height: 215px;
  border-radius: 16.93px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-17xl) var(--padding-5xl) var(--padding-2xl) var(--padding-6xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-smi);
  z-index: 2;
}
.exp-function-icon {
  height: 212px;
  width: 206px;
  position: relative;
}
.atan-function {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.log-function-icon {
  width: 203px;
  height: 129px;
  position: relative;
}
.acos-function,
.asin-function {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.acos-function {
  width: 293px;
  flex-direction: column;
  gap: var(--gap-mid);
}
.asin-function {
  align-self: stretch;
  flex-direction: row;
}
.max-function,
.min-function {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.max-function {
  width: 393px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-35xl);
}
.min-function {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
}
.sqrt-function-icon {
  width: 116px;
  height: 175px;
  position: relative;
}
.average-function {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  max-width: 100%;
}
.analytics-function,
.section-03-wrapper,
.section-031 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.analytics-function {
  height: 533px;
  width: 600px;
  flex-direction: column;
  padding: var(--padding-61xl) 0 0;
  min-width: 476px;
  font-size: var(--font-size-29xl);
  position: absolute;
  right: 20px;
  top: 80px;
}
.section-03-wrapper,
.section-031 {
  flex-direction: row;
}
.section-031 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  padding: var(--padding-79xl) var(--padding-6xs) 0 var(--padding-116xl);
  position: relative;
  gap: var(--gap-169xl-4);
  /* min-height: 768px; */
}
.section-03-wrapper {
  align-self: stretch;
  padding: 0 var(--padding-45xl) var(--padding-126xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .analytics-function {
    flex: 1;
  }
  .section-031 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1225px) {
  .analytics-function {
    padding-top: var(--padding-33xl);
    box-sizing: border-box;
  }
  .section-031 {
    gap: 188.4px 94px;
    padding-left: var(--padding-48xl);
    box-sizing: border-box;
  }
  .section-03-wrapper {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    padding-bottom: var(--padding-75xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .ready-to-see3 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .strategy-customization-and {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .ready-to-see-action-button-parent {
    gap: 20px 41px;
  }
  .email2 {
    font-size: var(--font-size-8xl);
    line-height: 52px;
  }
  .error-handler4 {
    flex-wrap: wrap;
    min-width: 100%;
  }
  .tags1 {
    font-size: var(--font-size-19xl);
    line-height: 74px;
  }
  .analytics-function {
    min-width: 100%;
  }
  .section-031 {
    gap: 188.4px 47px;
    padding-left: var(--padding-14xl);
    padding-top: var(--padding-45xl);
    padding-bottom: var(--padding-45xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-see3 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .strategy-customization-and {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .email2 {
    font-size: var(--body-large-regular-size);
    line-height: 39px;
  }
  .tags1 {
    font-size: var(--font-size-10xl);
    line-height: 55px;
  }
  .max-function {
    gap: 27px 54px;
  }
  .overview .analytics-function {
    position: initial;
    height: auto;
  }
  .overview .data-flow-controller > div,
  .overview .logic-gate13 > div,
  .overview .dijkstra-algorithm > div {
    max-width: 100% !important;
  }
  .overview .frame-parent96,
  .overview .ready-to-see-action-button-parent {
    width: auto;
    max-width: 100%;
  }
  .overview .section-031 {
    gap: 0;
  }
  .analytics-function {
    padding-top: var(--padding-15xl);
    box-sizing: border-box;
  }
  .section-031 {
    gap: 188.4px 24px;
  }
  .section-03-wrapper {
    padding-bottom: var(--padding-42xl);
    box-sizing: border-box;
  }
}
