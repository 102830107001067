.data-processing-and,
.div15 {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.div15 {
  line-height: 38px;
  display: inline-block;
  min-width: 41px;
}
.data-processing-and {
  margin: 0;
  align-self: stretch;
  font-size: var(--headings-h3-semi-bold-size);
  line-height: 51px;
  font-family: inherit;
}
.button-name26,
.once-your-data {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
}
.once-your-data {
  width: 507px;
  max-width: 100%;
}
.button-name26 {
  width: 110px;
  min-width: 110px;
  text-decoration: none;
  color: #fff;
}
.button29,
.c-t-a-background4,
.parent12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button29 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.c-t-a-background4,
.parent12 {
  flex-direction: column;
  max-width: 100%;
}
.c-t-a-background4 {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--body-default-semi-bold-size);
}
.parent12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 518px;
  padding: var(--padding-16xl) 0 0;
  box-sizing: border-box;
  flex: 1;
  margin-bottom: 90px;
}
.c-t-abackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 701.4px;
  height: 701.4px;
  object-fit: contain;
}
.vector-icon16 {
  position: absolute;
  top: 452.2px;
  left: 645.2px;
  width: 116px;
  height: 175px;
}
.parallel-processor1 {
  height: 701.4px;
  width: 761.2px;
  position: absolute;
  margin: 0 !important;
  right: -265px;
  bottom: -447.2px;
}
.segments1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  display: inline-block;
  min-width: 114px;
}
.eleonora-ivanova-marketingauto-child,
.product-data-center-product-re {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.eleonora-ivanova-marketingauto-child {
  width: 99px;
}
.product-data-center-product-re {
  flex: 1;
  min-width: 147px;
}
.eleonora-ivanova-marketingauto1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.eleonora-ivanova-marketingauto-inner,
.eleonora-ivanova-marketingauto-item {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.eleonora-ivanova-marketingauto-item {
  width: 99px;
}
.eleonora-ivanova-marketingauto-inner {
  flex: 1;
  min-width: 147px;
}
.eleonora-ivanova-marketingauto2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.eleonora-ivanova-marketingauto-child1,
.eleonora-ivanova-marketingauto-child2 {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.eleonora-ivanova-marketingauto-child1 {
  width: 99px;
}
.eleonora-ivanova-marketingauto-child2 {
  flex: 1;
  min-width: 147px;
}
.eleonora-ivanova-marketingauto3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.eleonora-ivanova-marketingauto-parent,
.footer5,
.segments-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.eleonora-ivanova-marketingauto-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xl);
}
.footer5,
.segments-group {
  align-self: stretch;
}
.footer5 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-10xs);
  box-sizing: border-box;
}
.segments-group {
  flex-direction: column;
  gap: var(--gap-xl);
}
.facebook-instagram-linked-in-y1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
}
.profile2 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}
.product-data-center4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.frame-child52 {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 51.18px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.anthony-smith1 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 113px;
}
.anthony-smith-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xs) 0 0;
}
.frame-child53 {
  width: 38px;
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.rectangle-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xl) var(--padding-2xs) 0 0;
}
.c-t-a-button {
  align-self: stretch;
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.c-t-a-button-wrapper {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xl) 0 0;
  box-sizing: border-box;
}
.frame-parent87,
.product-data-center-parent1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent87 {
  flex-direction: row;
  gap: var(--gap-base);
  font-size: var(--body-small-regular-size);
}
.product-data-center-parent1 {
  flex-direction: column;
  gap: var(--gap-lgi);
}
.facebook-instagram-linked-in-y-parent,
.readytosee-relevainaction {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.facebook-instagram-linked-in-y-parent {
  align-self: stretch;
  gap: var(--gap-9xl);
  font-size: var(--body-default-semi-bold-size);
}
.readytosee-relevainaction {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  padding: var(--padding-18xl) var(--padding-20xl) var(--padding-21xl) var(--padding-16xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  max-width: 100%;
  z-index: 1;
}
.p29,
.p30,
.p31,
.p32,
.p33,
.p34 {
  margin: 0;
}
.c-t-a-container {
  width: 8px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24.76px;
  display: inline-block;
}
.protilela3 {
  margin: 0;
}
.d561ad66-014-a31-bf2fde {
  margin: 0;
  color: var(--color-plum);
}
.domainid-73 {
  margin: 0;
}
.span5 {
  color: var(--color-lightsteelblue);
}
.email-inforelevaai2,
.group-45453,
.subscribedat2 {
  margin: 0;
}
.protilela-d561ad66-014-a3-container3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24.76px;
  color: var(--brand-ivory);
}
.footer-container {
  margin: 0 !important;
  position: absolute;
  right: -169px;
  bottom: -65px;
  border-radius: 6.19px;
  background-color: var(--brand-indigo);
  overflow: hidden;
  padding: var(--padding-lgi-5) var(--padding-6xl-5);
  gap: var(--gap-3xl);
  z-index: 2;
  font-size: var(--font-size-smi-4);
  color: var(--brand-amethyst);
}
.footer-container,
.iteration-handler,
.relava-symbol-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.iteration-handler {
  flex: 1;
  position: relative;
  min-width: 279px;
  max-width: 100%;
}
.relava-symbol-container {
  padding: 0 var(--padding-14xl) 0 var(--padding-13xl-3);
}
.algorithms,
.relevas-ai {
  margin: 0;
}
.relevas-ai-algorithms {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 21px;
  font-weight: 600;
}
.frame-parent88,
.sequence-controller-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent88 {
  border-radius: 10.57px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  padding: var(--padding-lg) var(--padding-6xl) var(--padding-4xl-7);
  gap: var(--gap-smi);
}
.sequence-controller-inner {
  padding: var(--padding-118xl) 0 0;
  font-size: var(--body-default-semi-bold-size);
}
.condition-checker,
.sequence-controller {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.sequence-controller {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-23xl);
}
.condition-checker {
  width: 619.9px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
  min-width: 619.9000000000015px;
  font-size: var(--headings-h5-semi-bold-size);
  margin-top: 20px;
}
.pressure-control,
.section-021 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.section-021 {
  flex: 1 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 var(--padding-45xl) 0;
  gap: var(--gap-xl);
}
.pressure-control {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-45xl) var(--padding-20xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .condition-checker,
  .parent12 {
    flex: 1;
  }
  .section-021 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1225px) {
  .condition-checker,
  .parent12 {
    min-width: 100%;
  }
  .pressure-control,
  .section-021 {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-22xl);
    box-sizing: border-box;
  }
  .pressure-control {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
  }
}
@media screen and (max-width: 850px) {
  .div15 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .data-processing-and {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .parent12 {
    gap: 18px 36px;
  }
  .eleonora-ivanova-marketingauto1,
  .eleonora-ivanova-marketingauto2,
  .eleonora-ivanova-marketingauto3,
  .frame-parent87 {
    flex-wrap: wrap;
  }
  .readytosee-relevainaction {
    padding-top: var(--padding-5xl);
    padding-bottom: var(--padding-7xl);
    box-sizing: border-box;
  }
  .sequence-controller {
    flex-wrap: wrap;
    gap: 42px 21px;
  }
  .section-021 {
    padding-left: var(--padding-14xl);
    padding-top: var(--padding-41xl);
    padding-bottom: var(--padding-71xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .div15 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .data-processing-and {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .segments1 {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .readytosee-relevainaction {
    gap: 24px 48px;
  }
  .section-021 {
    padding-top: var(--padding-20xl);
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
