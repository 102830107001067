.trusted-by-1001 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
}
.from-diverse-industries {
  width: 527px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.edge-connector {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--body-default-semi-bold-size);
}
.edge-connector,
.priority-queue,
.queue-processor {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.priority-queue {
  width: 928px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  flex-shrink: 0;
}
.queue-processor {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.adres-icon1,
.ebag-icon1,
.imoteka-icon1,
.mercari-icon1,
.trendo-icon1 {
  align-self: stretch;
  width: 153.4px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 35px;
}
.adres-icon1,
.ebag-icon1,
.mercari-icon1,
.trendo-icon1 {
  width: 75.5px;
}
.adres-icon1,
.mercari-icon1,
.trendo-icon1 {
  width: 82.7px;
}
.adres-icon1,
.mercari-icon1 {
  width: 142.6px;
}
.mercari-icon1 {
  width: 167.5px;
}
.merchantprointegration,
.stack-memory {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.merchantprointegration {
  align-self: stretch;
  border-radius: var(--br-base);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--padding-21xl) 0;

  gap: var(--gap-xl);
}
.stack-memory {
  width: 1337.6px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-35xl);
  max-width: 100%;
}
.frame-child1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  max-width: 100%;
}
.social-proof-section-inner {
  width: 1337.6px;
  height: 14.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) var(--padding-sm-3) var(--padding-9xs);
  box-sizing: border-box;
  max-width: 100%;
}
.div,
.h11 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.div {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 126px;
}
.h11 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.parent,
.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-smi) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.parent {
  width: 154px;
  flex-direction: row;
  gap: var(--gap-12xs);
}
.increase-in-conversion {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.increase-in-conversion-rates-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-6xs-4) 0 0;
  font-size: var(--body-small-regular-size);
}
.frame-parent5 {
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div1,
.h12 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.div1 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 128px;
}
.h12 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.container,
.group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.container {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-smi) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.group {
  width: 156px;
  flex-direction: row;
  gap: var(--gap-12xs);
}
.increase-in-average {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.increase-in-average-order-valu-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-5xs-4) 0 0;
  font-size: var(--body-small-regular-size);
}
.frame-parent6 {
  width: 176px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div2,
.h13 {
  position: relative;
  font-weight: 600;
  display: inline-block;
}
.div2 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 128px;
}
.h13 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.frame,
.parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.parent1 {
  width: 159px;
  flex-direction: row;
  gap: var(--gap-9xs);
}
.increase-in-sales {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.increase-in-sales-volumes-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-2xs-4) 0 0;
  font-size: var(--body-small-regular-size);
}
.frame-parent7 {
  width: 179px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.div3,
.times {
  position: relative;
  font-weight: 600;
  flex-shrink: 0;
}
.div3 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  z-index: 1;
}
.times {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  display: inline-block;
  min-width: 82px;
}
.c-t-a-background1,
.footer,
.times-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.times-wrapper {
  width: 82px;
  flex-direction: column;
  padding: var(--padding-4xs) 0 0;
  box-sizing: border-box;
  margin-left: -2px;
  font-size: var(--headings-h4-semi-bold-size);
}
.c-t-a-background1,
.footer {
  flex-direction: row;
}
.c-t-a-background1 {
  flex: 1;
}
.footer {
  align-self: stretch;
  padding: 0 0 0 var(--padding-4xl);
}
.investment,
.return-on {
  margin: 0;
}
.return-on-investment {
  width: 168.6px;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.reporting1 {
  width: 226px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frame-parent4,
.social-proof-section-child {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent4 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.case-studies .social-proof-section-child .div {
  font-family: var(--body-footnote-regular);
  color: var(--brand-indigo);
}
.case-studies .social-proof-section-child {
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.case-studies .social-proof-section-child .div,
.releva-homepage .social-proof-section-child .div,
.releva-homepage .div1,
.releva-homepage .div2,
.releva-homepage .div3 {
  font-size: var(--headings-big-semi-bold-size);
}
.case-studies .social-proof-section-child {
  width: 100%;
}
.social-proof-section-child {
  width: 1337.6px;
  justify-content: flex-start;
  padding: 0 var(--padding-6xs) var(--padding-5xl-3) var(--padding-8xs);
  box-sizing: border-box;
  font-size: var(--headings-big-semi-bold-size);
}
.why-releva2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  max-width: 100%;
}

.why-releva-container {
  width: 1337.6px;
  height: 1px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-10xs) 0 var(--padding-9xs);
}
.social-proof-section,
.social-proof-section-wrapper,
.why-releva-container {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.social-proof-section {
  /* height: 768px; */
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  /* padding: 0 var(--padding-xl) var(--padding-91xl) var(--padding-2xl); */
  gap: var(--gap-66xl-7);
}
.social-proof-section-wrapper {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-12xs) var(--padding-26xl) 0;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .frame-parent4 {
    flex-wrap: wrap;
  }
  .social-proof-section {
    padding-bottom: var(--padding-52xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .trusted-by-1001 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .merchantprointegration {
    justify-content: center;
  }
  .stack-memory {
    gap: 27px 54px;
  }
  .div {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .h11 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div1 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .h12 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div2 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .h13 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div3 {
    font-size: var(--font-size-31xl);
    line-height: 60px;
  }
  .times {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .social-proof-section {
    height: auto;
    gap: 43px 85.7px;
  }
  .social-proof-section-wrapper {
    padding-bottom: var(--padding-10xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .trusted-by-1001 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .div {
    font-size: var(--font-size-11xl);
    line-height: 75px;
  }
  .reporting1 .c-t-a-background1 .times-wrapper,
  .frame-parent7 .parent1 .frame,
  .frame-parent6 .group .container,
  .wrapper {
    padding-top: 0;
  }
  .h11 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div1 {
    font-size: var(--font-size-11xl);
    line-height: 75px;
  }
  .h12 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div2 {
    font-size: var(--font-size-11xl);
    line-height: 75px;
  }
  .h13 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div3 {
    font-size: var(--font-size-11xl);
    line-height: 75px;
  }
  .times {
    font-size: var(--font-size-lgi);
    line-height: 23px;
    text-align: left;
  }
  .social-proof-section {
    gap: 21px 85.7px;
    padding-bottom: var(--padding-27xl);
    box-sizing: border-box;
  }
  .social-proof-section-wrapper {
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
