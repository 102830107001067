.heyday2 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 31px;
  display: inline-block;
  font-weight: 900;
  min-width: 85px;
}
.heyday-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}
.frame-child39 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
}
.frame-parent61 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xs-8);
  gap: var(--gap-base-7);
}
.hi-susan {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 31px;
  font-weight: 600;
  display: inline-block;
  min-width: 88px;
}
.youve-left-two1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-mini-8);
  letter-spacing: -0.01em;
}
.hi-susan-parent,
.product-recommendations-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.hi-susan-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-9xs-7);
}
.product-recommendations-inner {
  width: 325.2px;
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}
.image-106-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.image-106-wrapper {
  height: 169px;
  width: 115px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-8xs);
  box-sizing: border-box;
  min-width: 115px;
}
.your-basket1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 31px;
  font-weight: 600;
  display: inline-block;
  min-width: 84px;
}
.paid-search1,
.paid-search2 {
  height: 10.5px;
  position: relative;
  border-radius: 28.26px;
  background-color: var(--brand-ivory);
}
.paid-search1 {
  align-self: stretch;
}
.paid-search2 {
  width: 75.5px;
}
.paid-search-parent {
  width: 117.3px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-6xs-4);
  box-sizing: border-box;
  gap: var(--gap-8xs-6);
}
.button-cluster-child {
  height: 1px;
  flex: 1;
  position: relative;
  border-top: 1px solid var(--brand-ivory);
  box-sizing: border-box;
}
.button-cluster {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xs);
}
.quantity {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 12px;
  display: inline-block;
  min-width: 47px;
}
.progress-indicator-child {
  width: 27px;
  height: 10px;
  position: relative;
  border-radius: 28.26px;
  background-color: var(--brand-ivory);
}
.progress-indicator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.icon-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.color {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 12px;
  display: inline-block;
  min-width: 30px;
}
.frame-child40 {
  width: 27px;
  height: 10px;
  position: relative;
  border-radius: 28.26px;
  background-color: var(--brand-ivory);
}
.icon-container-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.icon-container1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.size {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 12px;
  display: inline-block;
  min-width: 24px;
}
.company-resources {
  width: 27px;
  height: 10px;
  position: relative;
  border-radius: 28.26px;
  background-color: var(--brand-ivory);
}
.info-box,
.label-bank {
  display: flex;
  align-items: flex-start;
}
.label-bank {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0 0;
}
.info-box {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.about-documents1 {
  align-self: stretch;
  height: 1px;
  position: relative;
  border-top: 1px solid var(--brand-ivory);
  box-sizing: border-box;
  z-index: 1;
}
.info-box-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.total {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 12px;
  font-weight: 600;
  display: inline-block;
  min-width: 28px;
}
.header-content {
  width: 27px;
  height: 10px;
  position: relative;
  border-radius: 28.26px;
  background-color: var(--brand-ivory);
}
.checkbox-set,
.data-field {
  display: flex;
  align-items: flex-start;
}
.data-field {
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0 0;
}
.checkbox-set {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frame-parent63,
.icon-container-parent,
.shape-set {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent63 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-xs);
}
.icon-container-parent,
.shape-set {
  gap: var(--gap-5xs);
}
.icon-container-parent {
  align-self: stretch;
  align-items: flex-end;
  font-size: var(--font-size-xs);
}
.shape-set {
  flex: 1;
  align-items: flex-start;
  min-width: 110px;
}
.frame-parent62,
.product-recommendations-child {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.frame-parent62 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  align-items: flex-end;
  padding: var(--padding-base) var(--padding-lg) var(--padding-7xl);
  gap: var(--gap-10xl);
}
.product-recommendations-child {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 var(--padding-10xs) var(--padding-9xs-8) var(--padding-11xs);
  font-size: var(--font-size-mini);
}
.continue-shopping {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
  min-width: 123px;
}
.get-demo4 {
  border-radius: 9.29px;
  background-color: var(--color-lightsteelblue);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs-3) var(--padding-xl);
  white-space: nowrap;
}
.checkout1 {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
  min-width: 62px;
}
.get-demo5,
.toggle-switch {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.get-demo5 {
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  padding: var(--padding-3xs-3) var(--padding-35xl);
  color: var(--brand-ivory);
}
.toggle-switch {
  flex: 1;
  gap: var(--gap-3xl);
  max-width: 100%;
}
.product-data-center1,
.product-recommendations2,
.range-slider {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.range-slider {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-10xs);
  font-size: var(--font-size-sm-9);
}
.product-data-center1,
.product-recommendations2 {
  overflow: hidden;
}
.product-recommendations2 {
  flex: 1;
  border-radius: 9.42px;
  background-color: var(--web-secondary-ivory-mid);
  flex-direction: column;
  padding: var(--padding-lgi-3) var(--padding-mid) var(--padding-29xl-4) var(--padding-lg);
  gap: var(--gap-lgi-2);
}
.product-data-center1 {
  position: absolute;
  top: 96px;
  left: 100px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  width: 426px;
  flex-direction: row;
  padding: var(--padding-2xl) var(--padding-base) var(--padding-4xl);
}
.vuesaxlineardirect-up-icon5 {
  height: 48px;
  width: 48px;
  position: relative;
}
.auto-complete {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-13xl);
  box-sizing: border-box;
}
.bag {
  position: relative;
  letter-spacing: -0.04em;
  /* line-height: 74px; */
  font-weight: 600;
  display: inline-block;
  min-width: 69px;
}
.alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-6xl) 0 var(--padding-mid-6);
}
.we-saved-your {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-smi-8);
  letter-spacing: -0.01em;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: -54px;
}
.modal-window {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.hicon-linear-buy-2,
.image-logo {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
.hicon-linear-buy-2 {
  width: 52px;
  height: 52px;
  margin: 0 !important;
  top: 23px;
  left: 48px;
  flex-shrink: 0;
}
.image-logo {
  top: 56px;
  left: 400px;
  border-radius: 13.54px;
  background-color: var(--color-plum);
  width: 151.2px;
  height: 172px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mid-6) var(--padding-lgi-2) var(--padding-mid-2) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-base-2);
  font-size: var(--font-size-19xl-4);
}
.i-04 {
  /* height: 746px; */
  flex: 1;
  /* position: relative; */
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  /* min-width: 513px;
  max-width: 100%; */
}
.conversion-catalyst {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name16,
.when-a-visitor1 {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.when-a-visitor1 {
  align-self: stretch;
}
.button-name16 {
  width: 110px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button18,
.email-marketing-label,
.toast-notification {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button18 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  flex-direction: row;
  white-space: nowrap;
  text-decoration: none;
}
.email-marketing-label,
.toast-notification {
  flex-direction: column;
}
.email-marketing-label {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.toast-notification {
  width: 517px;
  min-width: 517px;
  min-height: 474px;
  max-width: 100%;
  font-size: var(--headings-h3-semi-bold-size);
}
.i-04-parent,
.product-page-inner2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.i-04-parent {
  width: 1465px;
  align-items: center;
  gap: var(--gap-140xl);
}
.product-page-inner2 {
  align-self: stretch;
  align-items: center;
  padding: 0 var(--padding-15xl) var(--padding-114xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  justify-content: center;
}
@media screen and (max-width: 1550px) {
  .toast-notification {
    flex: 1;
    min-height: auto;
  }
  .i-04-parent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1250px) {
  .i-04-parent {
    gap: 159px 79px;
  }
  .product-page-inner2 {
    padding-bottom: var(--padding-67xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .toggle-switch {
    flex-wrap: wrap;
  }
  .bag {
    font-size: var(--font-size-12xl);
    /* line-height: 59px; */
  }
  .i-04 {
    min-width: 100%;
  }
  .conversion-catalyst {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .toast-notification {
    min-width: 100%;
  }
  .i-04-parent {
    gap: 159px 40px;
  }
}
@media screen and (max-width: 450px) {
  .heyday2,
  .hi-susan {
    font-size: var(--body-small-regular-size);
    line-height: 24px;
  }
  .image-106-icon {
    align-self: stretch;
    width: auto;
  }
  .image-106-wrapper {
    flex: 1;
  }
  .frame-parent62 {
    flex-wrap: wrap;
  }
  .bag {
    font-size: var(--font-size-4xl);
    /* line-height: 44px; */
  }
  .i-04 {
    height: auto;
    min-height: 746;
  }
  .conversion-catalyst {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .i-04-parent {
    gap: 59px 20px;
  }
  .product-page-inner2 {
    padding-bottom: var(--padding-37xl);
    box-sizing: border-box;
  }
}
