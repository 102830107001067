.pricing-page .emails-included9 {
  font-weight: 500;
  color: var(--color-black);
}
.pricing-page .emails-included8 {
  position: absolute;
  top: 240px;
  left: 34px;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  width: 250px;
  height: 27px;
}
.pricing-page .days-free-trial5 {
  font-weight: 500;
  color: var(--color-black);
}
.pricing-page .days-free-trial-container5 {
  position: absolute;
  top: 210px;
  left: 34.2px;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  width: 250px;
  height: 27px;
}
.pricing-page .base-tariff-7991,
.mau-usage-traffic3 {
  margin: 0;
}
.pricing-page .base-tariff-799-container1,
.price-subscription5 {
  position: absolute;
  top: 272px;
  left: 34.2px;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  width: 250px;
  height: 62px;
}
.pricing-page .price-subscription5 {
  top: 225px;
  left: 34px;
  text-decoration: underline;
  color: var(--color-black);
  width: 286px;
  height: 27px;
}
.pricing-page .monthly-active-users8 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 30px;
}
.pricing-page .div56,
.monthly-active-users-wrapper {
  position: absolute;
  left: 34px;
  width: 250px;
}
.pricing-page .monthly-active-users-wrapper {
  top: 170px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-default-semi-bold-size);
}
.pricing-page .div56 {
  top: 117px;
  font-size: var(--font-size-23xl);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  display: inline-block;
}
.pricing-page .startup3,
.up-to3 {
  letter-spacing: -0.01em;
  display: inline-block;
}
.pricing-page .up-to3 {
  position: absolute;
  top: 87px;
  left: 34px;
  font-size: var(--body-default-semi-bold-size);
  line-height: 28px;
  width: 250px;
}
.pricing-page .startup3 {
  /* width: 129px;
  position: relative; */
  line-height: 26px;
  font-weight: 600;
  flex-shrink: 0;
}
.pricing-page .title13 {
  position: absolute;
  top: 0;
  left: 20px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  min-width: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  font-size: var(--body-large-regular-size);
  color: var(--w-background);
}
.pricing-page .startup-plan1 {
  height: 300px;
  width: 340px;
  position: relative;
  max-width: 100%;
  text-align: left;
  font-size: var(--body-small-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 750px) {
  .pricing-page .div56 {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
}
@media screen and (max-width: 450px) {
  .pricing-page .div56 {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .pricing-page .startup3 {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
}

.startup3 em {
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 44px;
}
