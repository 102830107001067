.persistent-engagement {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name15,
.when-a-visitor {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.when-a-visitor {
  align-self: stretch;
}
.button-name15 {
  width: 110px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button17 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.secondary-navigation,
.secondary-navigation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.secondary-navigation {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.secondary-navigation-wrapper {
  width: 519px;
  padding: var(--padding-181xl) 0 0;
  box-sizing: border-box;
  min-width: 519px;
  max-width: 100%;
}
.view-in-browser1 {
  position: relative;
  letter-spacing: -0.01em;
  display: inline-block;
  min-width: 82px;
}
.parallel-processor {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-5xs);
}
.heyday1,
.home1,
.kids1,
.man1,
.woman1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 31px;
  display: inline-block;
}
.heyday1 {
  font-weight: 900;
  min-width: 85px;
}
.home1,
.kids1,
.man1,
.woman1 {
  font-weight: 600;
}
.woman1 {
  width: 53px;
  min-width: 53px;
}
.home1,
.kids1,
.man1 {
  min-width: 31px;
}
.home1 {
  min-width: 42px;
}
.heyday-parent,
.woman-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.woman-parent {
  border-radius: var(--br-9xs);
  background-color: var(--web-secondary-ivory-light);
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-5xs) var(--padding-7xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-mini-8);
}
.heyday-parent {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-mini-8);
  gap: var(--gap-xs-8);
}
.hi-mark1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 31px;
  display: inline-block;
  min-width: 78px;
}
.come-back-and1,
.hi-mark1 {
  font-weight: 600;
}
.special-offers-are-container1 {
  align-self: stretch;
  height: 42.1px;
  position: relative;
  font-size: var(--font-size-mini-8);
  letter-spacing: -0.01em;
  display: inline-block;
  flex-shrink: 0;
}
.frame-parent58,
.hi-mark-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.hi-mark-group {
  width: 321.2px;
  flex-direction: column;
  gap: var(--gap-9xs-7);
  max-width: 100%;
}
.frame-parent58 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-5xs-8);
  gap: var(--gap-4xs);
  font-size: var(--font-size-2xs);
}
.see-all-new1 {
  position: relative;
  letter-spacing: 0.01em;
}
.get-demo-frame,
.get-demo3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.get-demo3 {
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  justify-content: flex-start;
  padding: var(--padding-3xs-2) var(--padding-mini-1) var(--padding-3xs-4);
  white-space: nowrap;
}
.get-demo-frame {
  align-self: stretch;
  justify-content: center;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--font-size-sm-9);
  color: var(--brand-ivory);
}
.promotional-banners2 {
  width: 393px;
  border-radius: 9.42px;
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-mid-2) var(--padding-xl) var(--padding-18xl-4);
  gap: var(--gap-mid-2);
  font-size: var(--body-large-regular-size);
}
.decision-maker,
.product-recommendations1,
.promotional-banners2 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.product-recommendations1 {
  width: 426px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-lg-5) var(--padding-smi-4) var(--padding-xl-5);
  gap: var(--gap-xs);
}
.decision-maker {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 0 var(--padding-12xs);
}
.vuesaxlineardirect-up-icon4 {
  height: 48px;
  width: 48px;
  position: relative;
}
.vuesaxlineardirect-up-frame {
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-13xl);
  box-sizing: border-box;
}
.fast {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 74px;
  font-weight: 600;
  display: inline-block;
  min-width: 76px;
}
.fast-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lg) 0 var(--padding-mid-6);
}
.delivery {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-smi-8);
  letter-spacing: -0.01em;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: -74px;
}
.frame-parent60 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.vuesaxlineargroup-icon {
  width: 41.6px;
  height: 41.6px;
  position: absolute;
  margin: 0 !important;
  top: 30.4px;
  left: 54.4px;
  z-index: 1;
}
.frame-parent59,
.i-03 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
.frame-parent59 {
  width: 151.2px;
  height: 172px;
  border-radius: 13.54px;
  background-color: var(--brand-lemon);
  flex-shrink: 0;
  align-items: flex-start;
  padding: var(--padding-mid-6) var(--padding-lgi-2) var(--padding-mid-2) var(--padding-xl);
  position: relative;
  gap: var(--gap-base-2);
  z-index: 1;
  margin-top: -231px;
  font-size: var(--font-size-19xl-4);
}
.i-03 {
  flex: 1;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  align-items: flex-end;
  font-size: var(--font-size-xs-1);
}
.frame-parent57,
.product-page-inner1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent57 {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-138xl);
}
.product-page-inner1 > div {
  width: 1593px;
  max-width: 1593px;
}
.product-page-inner1 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-45xl) var(--padding-62xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h3-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1250px) {
  .secondary-navigation-wrapper {
    flex: 1;
  }
  .frame-parent57 {
    flex-wrap: wrap;
    gap: 157px 78px;
  }
  .product-page-inner1 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .persistent-engagement {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .secondary-navigation-wrapper {
    min-width: 100%;
  }
  .promotional-banners2 {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-5xl);
    box-sizing: border-box;
  }
  .fast {
    font-size: var(--font-size-12xl);
    line-height: 59px;
  }
  .i-03 {
    padding: var(--padding-25xl) var(--padding-24xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .frame-parent57 {
    gap: 157px 39px;
  }
  .product-page-inner1 {
    padding-bottom: var(--padding-34xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .persistent-engagement {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .secondary-navigation-wrapper {
    padding-top: 0;
    box-sizing: border-box;
  }
  .heyday1 {
    font-size: var(--body-small-regular-size);
    line-height: 24px;
  }
  .woman-parent {
    flex-wrap: wrap;
  }
  .hi-mark1 {
    font-size: var(--body-small-regular-size);
    line-height: 24px;
  }
  .frame-parent58 {
    flex-wrap: wrap;
  }
  .fast {
    font-size: var(--font-size-4xl);
    line-height: 44px;
  }
  .i-03 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-parent57 {
    gap: 57px 20px;
  }
  .product-page-inner1 {
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
}
