.products {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.trendobg-offers-a {
  width: 376px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.block-1-inner,
.products-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.products-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.block-1-inner {
  width: 498px;
  padding: var(--padding-104xl) 0 0;
  box-sizing: border-box;
  min-width: 498px;
}
.vuesaxlineardirect-up-icon20 {
  height: 60px;
  width: 60px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper3 {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-40xl);
  box-sizing: border-box;
}
.click-rate-from,
.try-relevafree {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
}
.click-rate-from {
  flex: 1;
  letter-spacing: -0.01em;
  line-height: 17px;
}
.click-rate-from-automation-wrapper,
.e-commerce-brands-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.click-rate-from-automation-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-lgi);
  text-align: center;
  font-size: var(--body-small-regular-size);
}
.e-commerce-brands-list {
  width: 215px;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-117xl) var(--padding-lg) var(--padding-143xl)
    var(--padding-lgi);
  box-sizing: border-box;
  min-width: 215px;
  z-index: 1;
}
.vuesaxlineardirect-up-icon21 {
  margin-top: -71.5px;
  margin-left: -45.59999999999991px;
  height: 45.5px;
  width: 45.5px;
  position: relative;
}
.vuesaxlinearshopping-cart-icon3 {
  width: 60px;
  height: 60px;
  position: relative;
}
.vuesaxlinearshopping-cart-container {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs-7) 0 0;
  box-sizing: border-box;
}
.boost3 {
  margin: 0;
  width: 73px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 20px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 73px;
}
.boost-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 var(--padding-14xl);
}
.e-commerce-growth2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
}
.frame-parent107,
.vuesaxlineardirect-up-parent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent107 {
  width: 140px;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.vuesaxlineardirect-up-parent2 {
  align-self: stretch;
  border-radius: 16.93px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-7xl) var(--padding-smi) var(--padding-2xl)
    var(--padding-9xl-2);
  gap: var(--gap-12xs-1);
}
.promotionalbanners-icon {
  height: 474px;
  width: 511px;
  position: absolute;
  margin: 0 !important;
  bottom: -271px;
  left: -284px;
  object-fit: contain;
}
.communicationcenter-child {
  height: 361px;
  flex: 1;
  position: relative;
  border-radius: 17.11px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.communicationcenter1 {
  align-self: stretch;
  height: 361px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.frame-parent106 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-base);
  min-width: 165px;
  text-align: center;
  font-size: var(--font-size-9xl);
}
.block-11,
.e-commerce-brands-list-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.e-commerce-brands-list-parent {
  width: 482px;
  gap: var(--gap-smi);
  min-width: 482px;
  font-size: var(--font-size-43xl);
}
.block-11 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  padding: var(--padding-44xl) var(--padding-144xl) var(--padding-96xl);
  box-sizing: border-box;
  gap: var(--gap-234xl);
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-23xl);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1600px) {
  .block-1-inner,
  .e-commerce-brands-list-parent {
    flex: 1;
  }
  .block-11 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1275px) {
  .block-1-inner,
  .e-commerce-brands-list-parent {
    min-width: 100%;
  }
  .block-11 {
    gap: 253px 126px;
    padding-left: var(--padding-62xl);
    padding-right: var(--padding-62xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .products {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .try-relevafree {
    font-size: var(--font-size-31xl);
    line-height: 74px;
  }
  .e-commerce-brands-list {
    flex: 1;
    padding-top: var(--padding-69xl);
    padding-bottom: var(--padding-86xl);
    box-sizing: border-box;
  }
  .e-commerce-brands-list-parent {
    flex-wrap: wrap;
  }
  .block-11 {
    gap: 253px 63px;
    padding: var(--padding-22xl) var(--padding-21xl) var(--padding-56xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .products {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .try-relevafree {
    font-size: var(--font-size-18xl);
    line-height: 55px;
  }
  .boost3 {
    font-size: var(--font-size-3xl);
    line-height: 16px;
  }
  .vuesaxlineardirect-up-parent2 {
    flex-wrap: wrap;
  }
  .block-11 {
    gap: 253px 32px;
  }
}
