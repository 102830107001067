.releva-icon1,
.symbol-icon1 {
  height: 31.7px;
  width: 12.8px;
  position: relative;
}
.releva-icon1 {
  height: 27.2px;
  width: 113.7px;
}
.condition-splitter,
.releva-logo1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.releva-logo1 {
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-smi-8);
}
.condition-splitter {
  margin-top: -3407px;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 var(--padding-47xl) 3353px;
}
.engage-convert-and {
  margin: 0;
  width: 1117px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.engage-convert-and-delight-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-3xs) var(--padding-2xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.with-releva-every {
  width: 561px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.with-releva-every-interaction-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-3xs) var(--padding-2xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}
.book-a-demo {
  width: 112px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 112px;
  text-decoration: none;
}
.get-demo {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-13xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.get-demo-wrapper,
.product-page-inner,
.splitting-connections {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.get-demo-wrapper {
  align-self: stretch;
  padding: 0 var(--padding-xl) var(--padding-181xl);
}
.product-page-inner,
.splitting-connections {
  width: 569.6px;
  padding: 0 var(--padding-xl) 5.7px;
  box-sizing: border-box;
  max-width: 100%;
}
.splitting-connections {
  width: 754.8px;
  padding: 0 var(--padding-xl) 469.9px;
}
.trusted-by-100 {
  width: 406px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  display: inline-block;
}
.trusted-by-100-ecommerce-bran-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-sm) var(--padding-3xl);
  font-size: var(--headings-h5-semi-bold-size);
}
.adres-icon,
.ebag-icon,
.imoteka-icon,
.mercari-icon,
.trendo-icon {
  align-self: stretch;
  width: 153.4px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 35px;
}
.adres-icon,
.ebag-icon,
.mercari-icon,
.trendo-icon {
  width: 75.5px;
}
.adres-icon,
.mercari-icon,
.trendo-icon {
  width: 82.7px;
}
.adres-icon,
.mercari-icon {
  width: 142.6px;
}
.mercari-icon {
  width: 167.5px;
}
.logic-comparators,
.search-field-with-dropdown {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.search-field-with-dropdown {
  width: 1337.6px;
  border-radius: var(--br-base);
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--padding-21xl) 0;
  gap: var(--gap-xl);
}
.logic-comparators {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl) 41.3px var(--padding-3xl);
}
.b2-icon {
  height: 728px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.image-112-icon {
  position: absolute;
  top: 0;
  left: calc(50% - 496px);
  width: 874px;
  height: 741px;
  object-fit: cover;
}
.h2 {
  height: 698px;
  width: 1294px;
  position: absolute;
  margin: 0 !important;
  top: -51px;
  left: calc(50% - 647px);
  border-radius: var(--br-base);
  background-color: var(--web-secondary-ivory-dark);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.b2-parent,
.product-page {
  display: flex;
  justify-content: flex-start;
}
.b2-parent {
  width: 1728px;
  margin: 0 !important;
  position: absolute;
  top: 759px;
  left: 0;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.product-page {
  width: 100%;
  /* height: 8452px; */
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  /* padding: var(--padding-5xl) 0 0; */
  box-sizing: border-box;
  gap: 80px;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1250px) {
  .condition-splitter {
    padding-bottom: 1416px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .condition-splitter {
    padding-bottom: 920px;
    box-sizing: border-box;
  }
  .engage-convert-and {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .splitting-connections {
    padding-bottom: 305px;
    box-sizing: border-box;
  }
  .search-field-with-dropdown {
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .get-demo-wrapper {
    padding-bottom: 50px;
  }
  .condition-splitter {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    padding-bottom: 598px;
    box-sizing: border-box;
  }
  .engage-convert-and {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .trusted-by-100 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .product-page {
    height: auto;
  }
}
