.vector-icon27 {
  position: absolute;
  height: 86.05%;
  width: 78.8%;
  top: 46.78%;
  right: -24.93%;
  bottom: -32.83%;
  left: 46.13%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.a-star-search-child,
.frame-child56 {
  position: absolute;
  top: -46.2px;
  left: 25px;
  width: 356.7px;
  display: none;
}
.a-star-search-child {
  top: 0;
  left: 0;
  border-radius: 10.85px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.8px solid var(--brand-amethyst);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.image-125-icon {
  position: absolute;
  top: 47.7px;
  left: 76.3px;
  width: 188px;
  height: 205px;
  object-fit: cover;
  z-index: 1;
}
.a-star-search-inner,
.a-star-search-item {
  position: absolute;
  top: 303.7px;
  left: 36.3px;
  border-radius: 48.54px;
  background-color: var(--brand-ivory);
  width: 271px;
  height: 16px;
  z-index: 1;
}
.a-star-search-inner {
  top: 330px;
  width: 174.9px;
}
.a-star-search {
  position: absolute;
  top: 16.8px;
  left: 0;
  border-radius: 10.85px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.8px solid var(--brand-amethyst);
  box-sizing: border-box;
  width: 351.8px;
  height: 424.1px;
}
.b-f-s-heuristic-child {
  position: absolute;
  top: 0;
  left: 330px;
  border-radius: 3.69px;
  width: 38.8px;
  height: 38.8px;
  overflow: hidden;
  z-index: 1;
}
.b-f-s-heuristic {
  position: absolute;
  top: 18.5px;
  left: 32.7px;
  width: 368.8px;
  height: 440.9px;
}
.vuesaxlineareraser-icon2 {
  height: 32px;
  width: 32px;
  position: relative;
}
.vuesaxlineareraser-frame {
  position: absolute;
  top: 432px;
  left: 147px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  z-index: 1;
}
.vuesaxlinearsize-icon2 {
  height: 32px;
  width: 32px;
  position: relative;
}
.vuesaxlinearsize-frame {
  position: absolute;
  top: 432px;
  left: 214px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  z-index: 1;
}
.frame-parent98 {
  position: absolute;
  top: 98px;
  left: 162px;
  border-radius: 14.25px;
  background-color: var(--web-secondary-ivory-light);
  width: 416.9px;
  height: 515.3px;
  overflow: hidden;
  z-index: 1;
}
.vuesaxlineardirect-up-icon16 {
  height: 47.7px;
  width: 47.7px;
  position: relative;
}
.i-d-a-star-algorithm {
  height: 47.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-13xl) 0 var(--padding-12xl-8);
  box-sizing: border-box;
}
.tags2 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 74px;
  font-weight: 600;
  display: inline-block;
  min-width: 85px;
}
.expand-all {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 var(--padding-smi-7);
}
.campaign2 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-smi-7);
  letter-spacing: -0.01em;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: -74px;
}
.bidirectional-i-d-a-star {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.vuesaxlineartag-2-icon2 {
  width: 41.4px;
  height: 41.4px;
  position: absolute;
  margin: 0 !important;
  top: 28.6px;
  right: 54px;
  z-index: 1;
}
.i-d-a-star-algorithm-parent {
  position: absolute;
  top: 185px;
  left: 570px;
  border-radius: 13.47px;
  background-color: var(--color-lightsteelblue);
  width: 150.3px;
  height: 171px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mid-5) var(--padding-lgi) var(--padding-base-7) var(--padding-lgi-9);
  box-sizing: border-box;
  gap: var(--gap-base-1);
  z-index: 2;
}
.vuesaxlineardirect-up-icon17 {
  width: 42.4px;
  height: 42.4px;
  position: absolute;
  margin: 0 !important;
  top: 15.6px;
  right: 45.2px;
}
.vuesaxlinearsms-tracking-icon3 {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 1;
}
.input-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xl) 0 var(--padding-10xl-3);
}
.email3 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 66px;
  font-weight: 600;
  display: inline-block;
  min-width: 83px;
}
.error-handler5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-4xs) 0 var(--padding-5xs-3);
}
.send2 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-footnote-regular-size);
  letter-spacing: -0.01em;
  line-height: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: -66px;
}
.condition-checker1,
.vuesaxlineardirect-up-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.condition-checker1 {
  align-self: stretch;
  flex: 1;
}
.vuesaxlineardirect-up-parent1 {
  position: absolute;
  top: 393px;
  left: 37px;
  border-radius: 11.97px;
  background-color: var(--color-plum);
  width: 133.6px;
  height: 152px;
  overflow: hidden;
  padding: var(--padding-4xl) var(--padding-base-9) var(--padding-mini-6) var(--padding-mid-7);
  box-sizing: border-box;
  gap: var(--gap-4xs-4);
  z-index: 2;
  font-size: var(--font-size-14xl-9);
}
.i-12 {
  /* height: 746px; */
  flex: 1;
  position: relative;
  border-radius: var(--br-base);
  background-color: var(--brand-ivory);
  overflow: hidden;
  min-width: 513px;
  max-width: 100%;
}
.omnichannel-marketing {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h3-semi-bold-size);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.button-name29,
.effortlessly-manage-communicat {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.effortlessly-manage-communicat {
  align-self: stretch;
}
.button-name29 {
  width: 145px;
  display: inline-block;
}
.button34,
.omnichannel-marketing-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button34 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.omnichannel-marketing-parent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.bellman-ford-algorithm,
.dijkstra-algorithm,
.record-merger {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.record-merger {
  width: 436px;
  flex-direction: column;
  padding: var(--padding-206xl) 0 0;
  box-sizing: border-box;
  min-width: 436px;
  font-size: var(--body-default-semi-bold-size);
}
.bellman-ford-algorithm,
.dijkstra-algorithm {
  flex-direction: row;
}
.bellman-ford-algorithm {
  flex: 1;
  gap: var(--gap-140xl);
}
.dijkstra-algorithm {
  width: 1512px;
  padding: 0 var(--padding-45xl) var(--padding-77xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-19xl-2);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1225px) {
  .record-merger {
    flex: 1;
  }
  .bellman-ford-algorithm {
    flex-wrap: wrap;
    gap: 159px 79px;
  }
  .dijkstra-algorithm {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .tags2 {
    font-size: var(--font-size-12xl);
    line-height: 59px;
  }
  .email3 {
    font-size: var(--font-size-8xl);
    line-height: 52px;
  }
  .i-12 {
    min-width: 100%;
  }
  .omnichannel-marketing {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .record-merger {
    padding-top: var(--padding-127xl);
    box-sizing: border-box;
    min-width: 100%;
  }
  .bellman-ford-algorithm {
    gap: 59px 40px;
  }
  .dijkstra-algorithm {
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .tags2 {
    font-size: var(--font-size-4xl);
    line-height: 44px;
  }
  .email3 {
    font-size: var(--body-large-regular-size);
    line-height: 39px;
  }
  .omnichannel-marketing {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .bellman-ford-algorithm {
    gap: 59px 20px;
  }
  .record-merger {
    padding: 0;
  }
  .dijkstra-algorithm {
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
}
