.about-us .element-3-wrapper {
  justify-content: center;
}
.about-us .element-3-wrapper .element-3 {
  max-width: 1700px;
}
.about-us .frame-parent141,
.about-us .distance-calculator,
.about-us .distance-calculator .block-41 {
  justify-content: center;
}
.about-us .frame-parent141,
.about-us .frame-parent141 div {
  align-items: center;
  align-self: auto;
}
.about-us .frame-parent141 div,
.about-us .distance-calculator .block-41 > div {
  max-width: 1700px;
}
.distance-calculator,
.path-finder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.distance-calculator {
  flex: 1;
  flex-direction: column;
  gap: 73px;
}
.path-finder {
  width: 100%;
  flex-direction: row;
  padding: 0 var(--padding-7xl) var(--padding-17xl);
  box-sizing: border-box;
}
.artwork-icon {
  position: absolute;
  top: 89px;
  left: 0;
  width: 1763px;
  height: 664px;
  overflow: hidden;
}
.businesswoman-holding-light-bu-icon {
  position: absolute;
  top: 0;
  left: 294px;
  width: 1155.2px;
  height: 698px;
  object-fit: cover;
  z-index: 1;
}
.artwork-parent {
  width: 100%;
  height: 753px;
  position: absolute;
  margin: 0 !important;
  top: 458px;
  right: 0;
  left: 0;
}
.about-us {
  width: 100%;
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 80px;
  letter-spacing: normal;
}
@media screen and (max-width: 1325px) {
  .about-us {
    padding-left: var(--padding-8xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .distance-calculator {
    gap: 36px 73px;
  }
  .about-us {
    gap: 48px 97px;
  }
}
@media screen and (max-width: 450px) {
  .distance-calculator {
    gap: 18px 73px;
  }
  .about-us .about-us-inner {
    padding-bottom: 0;
  }
  .about-us .element-3 {
    gap: 20px;
  }
  .about-us {
    padding: 0;
    gap: 24px 97px;
  }
  .about-us .frame-parent141 {
    padding: 0 20px;
  }
  .about-us .slick-dots {
    line-height: 0px;
    bottom: -100px;
  }
}
