.facebook-ads-personalization {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
}
.referral-programs5 {
  flex: 1;
  position: relative;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
}
.logo-background {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  display: inline-block;
  min-width: 27px;
}
.ready-to-see-action6 {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-wrapper49,
.referral-programs-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.referral-programs-parent {
  flex: 1;
  gap: var(--gap-6xs);
}
.frame-wrapper49 {
  align-self: stretch;
  padding: 0 0 0 var(--padding-8xs);
}
.click-through-rate {
  width: 168.6px;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.frame-parent119 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
  min-width: 132px;
}
.header-block {
  width: 146px;
  position: relative;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
  display: inline-block;
}
.cost-per-click {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.cost-per-click-decrease-wrapper,
.header-block-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cost-per-click-decrease-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--body-small-regular-size);
}
.header-block-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.ebag-trendo-adres3 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  display: inline-block;
  min-width: 27px;
}
.frame-parent120,
.ready-to-see-action7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ready-to-see-action7 {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  margin-left: -21.6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-parent120 {
  flex: 1;
  flex-direction: row;
  min-width: 132px;
}
.mercari4 {
  width: 148px;
  position: relative;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
  display: inline-block;
}
.price-per-result {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.input-filter3,
.price-per-result-decrease-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.price-per-result-decrease-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-11xs);
  font-size: var(--body-small-regular-size);
}
.input-filter3 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.get-a-demo5 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  display: inline-block;
  min-width: 27px;
}
.ready-to-see-action8 {
  width: 27px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  margin-left: -21.6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.ready-to-see-action8,
.try-relevafree1,
.value-formater {
  display: flex;
  align-items: flex-start;
}
.value-formater {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.try-relevafree1 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 var(--padding-10xs);
  box-sizing: border-box;
  min-width: 132px;
}
.frame-parent118,
.frame-wrapper48 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.frame-parent118 {
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-28xl-5);
}
.frame-wrapper48 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-44xl) 0 var(--padding-48xl);
  box-sizing: border-box;
  font-size: var(--font-size-53xl);
}
.block-7-inner,
.facebook-ads-personalization-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.facebook-ads-personalization-parent {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-26xl);
  max-width: 100%;
}
.block-7-inner {
  flex: 1;
  align-items: flex-start;
  padding: var(--padding-22xl) 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 773px);
  flex-shrink: 0;
}
.vuesaxlineardirect-up-icon22 {
  width: 60px;
  height: 60px;
  position: absolute;
  margin: 0 !important;
  top: 22px;
  right: 64px;
}
.vuesaxlineartag-2-icon3 {
  height: 52px;
  width: 52px;
  position: relative;
  z-index: 1;
}
.vuesaxlineartag-2-container {
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-42xl) 0 var(--padding-35xl);
  box-sizing: border-box;
}
.personalized,
.retargeting {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
}
.personalized {
  flex: 1;
  letter-spacing: -0.01em;
  line-height: 17px;
}
.customer-datasegmentation,
.retargeting-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.customer-datasegmentation {
  flex-direction: row;
  padding: 0 var(--padding-mid) 0 var(--padding-3xs);
  margin-top: -93px;
  text-align: center;
  font-size: var(--body-small-regular-size);
}
.retargeting-parent {
  flex: 1;
  flex-direction: column;
}
.c-t-a-background6,
.ecommerceowners-marketingspeci {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.ecommerceowners-marketingspeci {
  align-self: stretch;
  width: 189px;
  border-radius: 16.93px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-17xl) var(--padding-6xs) var(--padding-2xl)
    var(--padding-mini);
  position: relative;
  gap: var(--gap-smi);
  z-index: 2;
}
.c-t-a-background6 {
  width: 355px;
  height: 215px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  max-width: 100%;
}
.artwork-icon2 {
  height: 539px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.c-t-a-button-child {
  position: absolute;
  top: -46.2px;
  left: 25px;
  width: 356.7px;
  display: none;
}
.vuesaxlineareraser-icon3 {
  height: 32px;
  width: 32px;
  position: relative;
}
.vuesaxlineareraser-wrapper1 {
  position: absolute;
  top: 432px;
  left: 147px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  z-index: 1;
}
.vuesaxlinearsize-icon3 {
  height: 32px;
  width: 32px;
  position: relative;
}
.vuesaxlinearsize-wrapper1 {
  position: absolute;
  top: 432px;
  left: 214px;
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  z-index: 1;
}
.frame-child65 {
  position: absolute;
  top: 0;
  left: 330px;
  border-radius: 3.69px;
  width: 38.8px;
  height: 38.8px;
  overflow: hidden;
  z-index: 1;
}
.frame-child66 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10.85px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.8px solid var(--brand-amethyst);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: none;
}
.frame-child67,
.frame-child68 {
  position: absolute;
  top: 303.7px;
  left: 36.3px;
  border-radius: 48.54px;
  background-color: var(--brand-ivory);
  width: 271px;
  height: 16px;
  z-index: 1;
}
.frame-child68 {
  top: 330px;
  width: 174.9px;
}
.black-t-shirt-with-tag-hanging-icon {
  position: absolute;
  top: 20.7px;
  left: 82.3px;
  width: 188px;
  height: 273px;
  object-fit: cover;
  z-index: 1;
}
.rectangle-parent12 {
  position: absolute;
  top: 16.8px;
  left: 0;
  border-radius: 10.85px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.8px solid var(--brand-amethyst);
  box-sizing: border-box;
  width: 351.8px;
  height: 424.1px;
}
.c-t-a-button1,
.frame-parent121 {
  position: absolute;
  top: 18.5px;
  left: 32.7px;
  width: 368.8px;
  height: 440.9px;
}
.c-t-a-button1 {
  height: 515.3px;
  width: 416.9px;
  margin: 0 !important;
  top: -345px;
  left: 52px;
  border-radius: 14.25px;
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.vuesaxlineardirect-up-icon23 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper4 {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-14xl) 0 var(--padding-13xl);
  box-sizing: border-box;
}
.on-ads,
.save-money {
  position: relative;
  font-weight: 600;
}
.on-ads {
  letter-spacing: -0.04em;
  line-height: 66px;
  display: inline-block;
  min-width: 107px;
}
.save-money {
  flex: 1;
  letter-spacing: -0.01em;
  line-height: 13px;
}
.on-ads-parent,
.save-money-wrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.save-money-wrapper {
  flex-direction: row;
  padding: 0 var(--padding-9xs) 0 var(--padding-9xs-7);
  margin-top: -65.6px;
  text-align: center;
  font-size: var(--body-footnote-regular-size);
}
.on-ads-parent {
  flex: 1;
  flex-direction: column;
}
.frame-parent122,
.money-change-1-icon {
  margin: 0 !important;
  position: absolute;
  overflow: hidden;
  flex-shrink: 0;
}
.money-change-1-icon {
  width: 33px;
  height: 33px;
  top: 30px;
  right: 49.6px;
  z-index: 1;
}
.frame-parent122 {
  height: 152px;
  width: 133.6px;
  top: -50px;
  left: -73px;
  border-radius: 11.97px;
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15.6px var(--padding-smi-6) var(--padding-mini) var(--padding-sm);
  box-sizing: border-box;
  gap: var(--gap-smi);
  z-index: 2;
}
.artwork-group {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  max-width: 100%;
  font-size: var(--font-size-14xl-9);
}
.artwork-group,
.block-7,
.footer10 {
  display: flex;
  justify-content: flex-start;
}
.footer10 {
  height: 804px;
  width: 725px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-31xl);
  max-width: calc(100% - 798px);
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
}
.block-7 {
  align-self: stretch;
  height: 768px;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-159xl) var(--padding-6xs) var(--padding-274xl)
    var(--padding-51xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  max-width: 100%;
  z-index: 1;
  text-align: center;
  font-size: var(--font-size-23xl);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1275px) {
  .block-7-inner {
    max-width: 100%;
  }
  .footer10 {
    display: none;
  }
  .block-7 {
    padding-left: var(--padding-16xl);
    padding-top: var(--padding-97xl);
    padding-bottom: var(--padding-171xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .facebook-ads-personalization {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .referral-programs5 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .logo-background {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .header-block {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .ebag-trendo-adres3 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .mercari4 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .get-a-demo5 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .frame-parent118 {
    gap: var(--gap-5xl);
  }
  .frame-wrapper48 {
    padding-left: var(--padding-14xl);
    padding-right: var(--padding-12xl);
    box-sizing: border-box;
  }
  .facebook-ads-personalization-parent {
    gap: 22px 45px;
  }
  .retargeting {
    font-size: var(--font-size-7xl);
    line-height: 74px;
  }
  .on-ads {
    font-size: var(--font-size-8xl);
    line-height: 52px;
  }
  .footer10 {
    gap: 25px 50px;
  }
  .block-7 {
    height: auto;
    gap: 48px 24px;
    padding-top: var(--padding-56xl);
    padding-bottom: var(--padding-104xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .facebook-ads-personalization {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .referral-programs5 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .logo-background {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .header-block {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .ebag-trendo-adres3 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .mercari4 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .get-a-demo5,
  .retargeting {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .retargeting {
    line-height: 55px;
  }
  .on-ads {
    font-size: var(--body-large-regular-size);
    line-height: 39px;
  }
}
