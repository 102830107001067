.vector-icon21 {
  width: 70px;
  height: 70px;
  position: relative;
}
.cs-card1 {
  width: 62px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 62px;
}
.cs-card-wrapper,
.vector-parent9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.vector-parent9 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-43xl) var(--padding-xl) var(--padding-8xl)
    var(--padding-3xl);
  gap: var(--gap-23xl);
  min-width: 187px;
}
.vector-icon22 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon23,
.vector-icon24 {
  position: absolute;
  top: 8.3px;
  left: 39.8px;
  width: 21.6px;
  height: 61.7px;
  z-index: 1;
}
.vector-icon24 {
  top: 24px;
  left: 9.4px;
  width: 23.1px;
  height: 35.5px;
}
.vector-parent10 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-wrapper36 {
  width: 61.5px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.shopify3 {
  width: 57px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 57px;
}
.shopify-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs-2) 0 var(--padding-11xs);
}
.frame-parent94 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-48xl-9) var(--padding-xl) var(--padding-8xl);
  gap: var(--gap-17xl-1);
  min-width: 187px;
}
.get-started5 {
  width: 128px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  display: inline-block;
  min-width: 128px;
  white-space: nowrap;
}
.frame-parent93,
.input-provider,
.overview-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.input-provider {
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  overflow: hidden;
  justify-content: flex-start;
  padding: var(--padding-75xl) var(--padding-40xl) var(--padding-79xl);
  font-size: var(--headings-h5-semi-bold-size);
}
.frame-parent93,
.overview-inner {
  justify-content: center;
  max-width: 100%;
}
.frame-parent93 {
  width: 788px;
  gap: var(--gap-2xl);
}
.overview-inner {
  align-self: stretch;
  padding: 0 var(--padding-xl) var(--padding-134xl) var(--padding-19xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-small-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 850px) {
  .frame-parent94,
  .vector-parent9 {
    flex: 1;
  }
  .frame-parent93 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .get-started5 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .overview-inner {
    padding-bottom: var(--padding-80xl);
    box-sizing: border-box;
  }
}
