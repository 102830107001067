.features {
  align-self: stretch;
  /* height: 435px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: left;
  font-size: var(--body-large-semi-bold-size);
}
.web-search-multi-channel-pe,
.mail-push-center,
.message-hub,
.social-media-hub,
.cdp-for-offline-shops-email {
  position: initial;
}
.web-search-multi-channel-pe,
.mail-push-center,
.message-hub,
.social-media-hub,
.cdp-for-offline-shops-email {
  position: initial;
  flex: 1;
  max-width: 30% !important;
}
.social-media-hub,
.cdp-for-offline-shops-email {
  margin-top: 40px !important;
}
