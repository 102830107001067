.purchaseconversionrateboost-icon {
  position: absolute;
  top: 0;
  left: 40px;
  border-radius: 13.71px;
  width: 344px;
  height: 475.4px;
  overflow: hidden;
}
.testimonial-image-big-icon {
  position: absolute;
  top: 36.3px;
  left: 0;
  border-radius: 17.93px;
  width: 346.3px;
  height: 490.3px;
  object-fit: cover;
  z-index: 1;
}
.boolean-operator {
  height: 526.6px;
  flex: 1;
  position: relative;
  min-width: 250px;
  max-width: 100%;
}
.ebag-logo-1-icon {
  height: 47.5px;
  width: 101.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.matrix-operator {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
}
.reach-purchase-conversion2 {
  width: 320px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  display: inline-block;
}
.thanks-to-releva2 {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  max-width: 100%;
}
.exporter,
.visualizer {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.exporter {
  flex-direction: row;
  padding: 0 0 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--body-default-semi-bold-size);
}
.visualizer {
  flex-direction: column;
  gap: var(--gap-5xl);
}
.ebag2,
.eleonora-ivanova2,
.marketing-automation-expert2 {
  margin: 0;
}
.eleonora-ivanova-marketing-container2 {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  text-transform: capitalize;
}
.navigator {
  width: 218px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
  font-size: var(--body-footnote-regular-size);
}
.array-operator,
.debugging-tools {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.debugging-tools {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-lgi);
  max-width: 100%;
}
.array-operator {
  gap: var(--gap-23xl-5);
}
.array-operator,
.compare-values,
.function-operator,
.negate-value {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.function-operator {
  width: 349px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-mini) 0 0;
  box-sizing: border-box;
  min-width: 349px;
}
.compare-values,
.negate-value {
  flex-direction: row;
  justify-content: center;
}
.negate-value {
  width: 790px;
  gap: var(--gap-38xl);
}
.compare-values {
  align-self: stretch;
  padding: 0 var(--padding-xl) var(--padding-141xl-4);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 850px) {
  .function-operator {
    flex: 1;
  }
  .negate-value {
    flex-wrap: wrap;
  }
  .compare-values {
    padding-bottom: var(--padding-85xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .reach-purchase-conversion2 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .array-operator {
    gap: 21px 42.5px;
  }
  .function-operator {
    min-width: 100%;
  }
  .negate-value {
    gap: 57px 28px;
  }
}
