.div12,
.your-message-everywhere {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.div12 {
  line-height: 38px;
  display: inline-block;
  min-width: 41px;
}
.your-message-everywhere {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  line-height: 51px;
  font-family: inherit;
}
.button-name18,
.seamlessly-engaging-users {
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.seamlessly-engaging-users {
  align-self: stretch;
}
.button-name18 {
  width: 110px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 110px;
  text-decoration: none;
}
.button20 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-5xl);
  background-color: var(--brand-amethyst);
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  text-decoration: none;
}
.your-message-everywhere-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--headings-h3-semi-bold-size);
}
.parent9,
.section-06-inner,
.your-message-everywhere-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent9 {
  align-self: stretch;
  gap: var(--gap-89xl);
}
.section-06-inner {
  width: 518px;
  padding: var(--padding-16xl) 0 0;
  box-sizing: border-box;
  /* min-width: 518px;
  max-width: 100%; */
}
.vuesaxlinearsend-2-icon {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 1;
}
.vuesaxlinearsend-2-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs);
}
.vuesaxlineardirect-up-icon6 {
  width: 42.4px;
  height: 42.4px;
  position: absolute;
  margin: 0 !important;
  top: 15.6px;
  right: 45.2px;
}
.media,
.social {
  margin: 0;
}
.social-media {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.frame-parent68,
.frame-wrapper29 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent68 {
  align-self: stretch;
  border-radius: 11.97px;
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  padding: var(--padding-9xl) var(--padding-16xl-6) var(--padding-9xl)
    var(--padding-17xl);
  position: relative;
  gap: var(--gap-5xs);
  flex-shrink: 0;
  
}
.frame-wrapper29 {
  width: 133.6px;
  padding: var(--padding-172xl) 0 0;
  box-sizing: border-box;
  min-width: 133.59999999999854px;
}
.body-icon,
.icon2 {
  width: 454.4px;
  height: 504.8px;
  position: absolute;
  margin: 0 !important;
  right: -271.3px;
  bottom: -117.7px;
}
.body-icon {
  width: 100%;
  height: 100%;
  top: 0;
  right: 2.9px;
  bottom: 0;
  max-height: 100%;
  object-fit: cover;
}
.rectangle {
  height: 19.6px;
  width: 74px;
  position: relative;
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  flex-shrink: 0;
  
  z-index: 1;
}
.buttons-icon {
  height: 130.3px;
  width: 290.7px;
  position: absolute;
  margin: 0 !important;
  bottom: -44.1px;
  left: -254px;
  z-index: 1;
}
.vuesaxlineardirect-up-icon7 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper1 {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-sm-6) 0 var(--padding-sm);
  box-sizing: border-box;
}
.paid,
.search {
  margin: 0;
}
.paid-search3 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlinearsearch-status-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  top: 24px;
  right: 45.6px;
  z-index: 1;
}
.frame-parent69 {
  flex: 1;
  border-radius: 11.97px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 15.6px var(--padding-12xl-6) var(--padding-9xl);
  position: relative;
  gap: var(--gap-lg);
  flex-shrink: 0;
  
  z-index: 2;
}
.buttons-parent,
.frame-wrapper30 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttons-parent {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.frame-wrapper30 {
  width: 133.6px;
  flex-direction: column;
  padding: var(--padding-mini-3) 0 0;
  box-sizing: border-box;
}
.rectangle-parent7 {
  width: 278.2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.vuesaxlineardirect-up-icon8 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.data-aggregator2 {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-11xl) 0 var(--padding-10xl);
  box-sizing: border-box;
}
.email1,
.marketing {
  margin: 0;
}
.email-marketing {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlinearsms-tracking-icon1 {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  top: 29px;
  right: 46.6px;
  z-index: 1;
}
.data-aggregator-parent,
.iphone-14-pro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}
.data-aggregator-parent {
  margin-left: -160.40000000000146px;
  width: 133.6px;
  border-radius: 11.97px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  padding: 15.6px 15.6px var(--padding-9xl) var(--padding-mid);
  gap: var(--gap-lg);
  color: var(--brand-indigo);
}
.iphone-14-pro {
  width: 292.1px;
  padding: var(--padding-7xl-7) var(--padding-90xl-4) var(--padding-33xl-1);
  gap: var(--gap-174xl);
  min-width: 292.09999999999854px;
  flex-shrink: 0;
  
  color: var(--brand-ivory);
}
.frame-parent67,
.section-06-wrapper,
.section-061 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent67 {
  justify-content: flex-start;
  gap: var(--gap-6xl-4);
  min-width: 451.09999999999854px;
  text-align: center;
  font-size: var(--font-size-3xl);
}
.section-06-wrapper,
.section-061 {
  box-sizing: border-box;
}
.section-061 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--color-plum);
  overflow: hidden;
  justify-content: space-between;
  padding: var(--padding-75xl) var(--padding-203xl) var(--padding-64xl-9)
    var(--padding-116xl);
  gap: var(--gap-xl);
}
.section-06-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-45xl) var(--padding-194xl);
  flex-shrink: 0;
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1550px) {
  .frame-parent67,
  .section-06-inner {
    flex: 1;
  }
  .section-061 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1250px) {
  .frame-parent67,
  .section-06-inner {
    min-width: 100%;
  }
  .section-06-wrapper,
  .section-061 {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-92xl);
    box-sizing: border-box;
  }
  .section-06-wrapper {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    padding-bottom: var(--padding-119xl);
  }
}
@media screen and (max-width: 825px) {
  .div12 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .your-message-everywhere {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .parent9 {
    gap: 54px 108px;
  }
  .frame-wrapper29 {
    flex: 1;
  }
  .iphone-14-pro {
    flex: 1;
    gap: 96px 193px;
    padding: var(--padding-xl) var(--padding-35xl) var(--padding-15xl);
    box-sizing: border-box;
  }
  .frame-parent67 {
    flex-wrap: wrap;
  }
  .section-061 {
    padding: var(--padding-42xl) var(--padding-36xl) var(--padding-36xl)
      var(--padding-14xl);
    box-sizing: border-box;
  }
  .section-06-wrapper {
    padding-bottom: var(--padding-71xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .div12 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .your-message-everywhere {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .parent9 {
    gap: 27px 108px;
  }
  .email-marketing,
  .paid-search3,
  .social-media {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .section-061 {
    padding-top: var(--padding-21xl);
    padding-right: var(--padding-xl);
    padding-bottom: var(--padding-17xl);
    box-sizing: border-box;
  }
}
