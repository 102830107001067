.pricing-page .monthly-active-users11 {
  margin: 0;
  /* width: 690px;
  position: relative; */
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 500;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 180%;
}
.pricing-page .slider4,
.title16 {
  position: initial;
  left: 0;
}
.pricing-page .title16 {
  top: 0;
  width: 392px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  max-width: 100%;
}
.pricing-page .slider5,
.pricing-page .slider4 {
  top: 90px;
  border-radius: var(--br-5xs);
  background-color: var(--color-seashell-100);
  height: 78px;
  font-size: var(--small-600-size);
  color: var(--gray-100);
  padding: 0 40px;
  position: initial;
  max-width: 100%;
  display: flex;
  margin-top: 19px;
}
.rc-slider-dot {
  border-color: var(--brand-indigo);
}
.rc-slider-mark-text {
  color: var(--brand-indigo);
}
.pricing-page .mau-slider1 {
  position: relative;
  max-width: 100%;
}

.pricing-page .monthly-sent-emails1 {
  margin: 0;
  width: 690px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 500;
  font-family: inherit;
  white-space: pre-wrap;
  display: inline-block;
  flex-shrink: 0;
  max-width: 180%;
}
.pricing-page .title17 {
  width: 392px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs);
  box-sizing: border-box;
  max-width: 100%;
}
.pricing-page .emails-slider1 {
  max-width: 100%;
}
.pricing-page .rc-slider {
  top: 40%;
}
.pricing-page .input-range__label-container {
  font-size: 14px;
}
.pricing-page .custom-handle {
  position: absolute;
  top: 50%;
  background-color: #fff;
  border: 9px solid var(--brand-indigo);
  box-shadow: 0 0 0 1px #d9d9d9;
  cursor: pointer;
  z-index: 1;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  outline-color: var(--brand-indigo);
}

.pricing-page .custom-handle .tooltip {
  position: absolute;
  bottom: calc(100% + 12px); /* Position the tooltip above the handle */
  left: 50%; /* Position the tooltip horizontally centered */
  transform: translateX(-50%);
  background-color: var(--brand-indigo);
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.pricing-page .custom-handle:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.pricing-page .mau-slider1,
.pricing-page .emails-slider1 {
  flex: 1;
  width: calc(50% - 20px);
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

.pricing-page .rc-slider-rail {
  background: #fff;
}
.pricing-page .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.pricing-page .rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 3px var(--brand-indigo);
}
.pricing-page .rc-slider-dot-active,
.pricing-page .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.pricing-page .rc-slider-handle:focus-visible,
.pricing-page .rc-slider-handle,
.pricing-page .rc-slider-track,
.pricing-page .rc-slider-tracks {
  background: var(--brand-indigo);
  border-color: var(--brand-indigo);
  outline-color: var(--brand-indigo);
}
.pricing-page .monthly-active-users12 {
  padding: 26px 18px;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 500;
  font-size: 20px;
}
.pricing-page .selected-mau-emails1 {
  border-radius: var(--br-5xs);
  background-color: var(--w-background);
  border: 3px solid var(--brand-indigo);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--headings-h5-semi-bold-size);
  width: 100%;
  margin-top: 20px;
}
.pricing-page .base-tariff-4999-mau1,
.growth-plan3 {
  /* position: absolute;
  left: 21px; */
  letter-spacing: -0.01em;
  line-height: 28px;
}
.pricing-page .growth-plan3 {
  /* top: 11px; */
  display: inline-block;
  min-width: 113px;
}
.pricing-page .base-tariff-4999-mau1 {
  top: 39px;
  font-size: var(--font-size-3xl);
  font-weight: 500;
}
.pricing-page .plan-price-according-to-sele1 {
  /* height: 78px; */
  /* width: 524px; */
  /* position: relative; */
  border-radius: var(--br-5xs);
  background-color: var(--brand-indigo);
  /* max-width: 100%; */
  font-size: var(--body-large-regular-size);
  color: var(--w-background);
  text-align: left;
  padding: 15px 18px;
  margin-top: 20px;
}
.pricing-page .choose-your-plan1 {
  /* position: absolute;
  top: 545px;
  left: 262px; */
  width: 1216px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 44px 96px;
  min-height: 290px;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-23xl);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  /* display: none; */
}

@media screen and (max-width: 750px) {
  .pricing-page .monthly-active-users11,
  .monthly-sent-emails1 {
    font-size: var(--font-size-15xl);
    line-height: 50px;
  }
}

@media screen and (max-width: 450px) {
  .pricing-page .monthly-active-users11,
  .monthly-sent-emails1 {
    font-size: var(--font-size-6xl);
    line-height: 38px;
  }
  .pricing-page .growth-plan3,
  .monthly-active-users12 {
    font-size: var(--font-size-lgi);
    line-height: 22px;
  }
  .pricing-page .growth-plan3 {
    font-size: var(--body-small-regular-size);
  }
  .pricing-page .base-tariff-4999-mau1 {
    font-size: var(--body-default-semi-bold-size);
    line-height: 22px;
  }
}
