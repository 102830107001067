.integrations {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
}
.we-provide-seamless1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.accordion,
.modal {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.modal {
  width: 617px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.accordion {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.magento-child {
  width: 69.3px;
  height: 80px;
  position: relative;
}
.magento1 {
  width: 66px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 66px;
}
.magento {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-39xl) var(--padding-xl) var(--padding-8xl);
  gap: var(--gap-17xl);
  min-width: 187px;
  max-width: 249px;
}
.vector-icon13 {
  height: 70px;
  width: 67.1px;
  position: relative;
}
.vector-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-mini-9) 0 var(--padding-mini);
}
.gomba-shop {
  width: 97px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 97px;
}
.magento2 {
  flex: 0.4686;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-46xl) var(--padding-56xl) var(--padding-8xl);
  gap: var(--gap-20xl);
  min-width: 187px;
  max-width: 249px;
}
.vector-icon14 {
  height: 70px;
  width: 70px;
  position: relative;
}
.vector-wrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) 0 var(--padding-9xs-5);
}
.cloudcard {
  width: 77px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 77px;
}
.magento3 {
  flex: 0.971;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-43xl) var(--padding-xl) var(--padding-8xl) var(--padding-7xl);
  gap: var(--gap-23xl);
  min-width: 187px;
  max-width: 249px;
}
.vector-icon15 {
  width: 80px;
  height: 57.6px;
  position: relative;
}
.opencard {
  width: 74px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 74px;
}
.magento4 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-43xl) var(--padding-xl) var(--padding-8xl) var(--padding-7xl);
  gap: var(--gap-29xl);
  min-width: 187px;
  max-width: 249px;
}
.tab,
.toggle-button {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.tab {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-2xl);
  font-size: var(--body-small-regular-size);
}
.toggle-button {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-70xl);
  max-width: 100%;
}
.magento-gomba-shop-cloudcard {
  width: 70px;
  height: 70px;
  position: relative;
}
.cs-card {
  width: 62px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 62px;
}
.flowchart,
.magento-gomba-shop-cloudcard-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.magento-gomba-shop-cloudcard-parent {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-43xl) var(--padding-xl) var(--padding-8xl) var(--padding-3xl);
  gap: var(--gap-23xl);
  min-width: 187px;
}
.product-recommendations-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.communication-center-icon,
.customer-data-segmentation {
  position: absolute;
  top: 8.3px;
  left: 39.8px;
  width: 21.6px;
  height: 61.7px;
  z-index: 1;
}
.communication-center-icon {
  top: 24px;
  left: 9.4px;
  width: 23.1px;
  height: 35.5px;
}
.wizard1 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.wizard-wrapper {
  width: 61.5px;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.shopify2 {
  width: 57px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 57px;
}
.shopify-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-10xs-2) 0 var(--padding-11xs);
}
.frame-parent84 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-48xl-9) var(--padding-xl) var(--padding-8xl);
  gap: var(--gap-17xl-1);
  min-width: 187px;
}
.get-started4 {
  width: 128px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  display: inline-block;
  min-width: 128px;
  white-space: nowrap;
}
.marketing-specialist3 {
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  padding: var(--padding-75xl) var(--padding-40xl) var(--padding-79xl);
  font-size: var(--headings-h5-semi-bold-size);
}
.card,
.marketing-specialist3,
.stepper {
  flex-direction: row;
  align-items: flex-start;
}
.stepper {
  width: 788px;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-2xl);
  max-width: 100%;
}
.card {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
  box-sizing: border-box;
  font-size: var(--body-small-regular-size);
}
.card,
.clock,
.slider {
  display: flex;
  max-width: 100%;
}
.slider {
  width: 1059px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-2xl);
}
.clock {
  margin-bottom: 150px;
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-117xl-7) var(--padding-2xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1200px) {
  .tab {
    flex-wrap: wrap;
  }
  .toggle-button {
    gap: 44px 89px;
  }
}
@media screen and (max-width: 850px) {
  .integrations {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .toggle-button {
    gap: 22px 89px;
  }
  .frame-parent84,
  .magento-gomba-shop-cloudcard-parent {
    flex: 1;
  }
  .stepper {
    flex-wrap: wrap;
  }
  .clock {
    padding-bottom: var(--padding-70xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .integrations {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .magento2,
  .magento3 {
    flex: 1;
  }
  .get-started4 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
  .clock {
    padding-bottom: var(--padding-39xl);
    box-sizing: border-box;
  }
}
