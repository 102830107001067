.trusted-by-4001 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
}
.from-diverse-industries2 {
  width: 527px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.from-diverse-industries-and-gl-container {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--body-default-semi-bold-size);
}
.from-diverse-industries-and-gl-container,
.progress-bar2,
.table-cell {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.table-cell {
  width: 948px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.progress-bar2 {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.imoteka-icon4,
.modal-dialog-icon {
  height: 29px;
  width: 19.5px;
  position: relative;
}
.imoteka-icon4 {
  height: 35.3px;
  width: 153.4px;
  overflow: hidden;
  flex-shrink: 0;
}
.slider1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.ebag-icon4,
.trendo-icon4 {
  align-self: stretch;
  max-height: 100%;
  min-height: 35px;
}
.ebag-icon4 {
  width: 75.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.trendo-icon4 {
  width: 82.7px;
}
.adres-icon4,
.mercari-icon4,
.trendo-icon4 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.adres-icon4 {
  align-self: stretch;
  width: 142.6px;
  max-height: 100%;
  min-height: 35px;
}
.mercari-icon4 {
  height: 35.3px;
  width: 167.5px;
}
.tooltip-icon {
  width: 19.5px;
  height: 29px;
  position: relative;
  object-fit: contain;
}
.form-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-10xs-3);
}
.slider-parent,
.steps1 {
  display: flex;
  flex-direction: row;
}
.steps1 {
  width: 236.4px;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-30xl-4);
}
.slider-parent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.dropdown-menu1,
.input-field5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.dropdown-menu1 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-75xl);
}
.input-field5 {
  width: 100%;
  flex-direction: row;
  padding: 0 var(--padding-19xl) var(--padding-63xl-7);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1325px) {
  .slider-parent {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 900px) {
  .trusted-by-4001 {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .dropdown-menu1 {
    gap: 47px 94px;
  }
}
@media screen and (max-width: 450px) {
  .trusted-by-4001 {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .about-us .image-118-icon,
  .about-us .artwork-icon4,
  .about-us .frame-parent141 div,
  .about-us .distance-calculator .block-41 > div,
  .about-us .tabs,
  .about-us .tabs .tab-1,
  .about-us .tabs .tab-2,
  .about-us .tabs .tab-3,
  .about-us .tabs .tab-4,
  .about-us .interactiveJourneyImages {
    width: 100%;
    gap: 0;
    min-width: 200px;
  }
  .dropdown-menu1 {
    gap: 23px 94px;
  }
  .input-field5 {
    padding-bottom: var(--padding-35xl);
    box-sizing: border-box;
  }
}
