.frame-child55 {
  width: 69.3px;
  height: 80px;
  position: relative;
}
.magento5 {
  width: 66px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 66px;
}
.frame-parent90 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-38xl) var(--padding-xl) var(--padding-8xl);
  gap: var(--gap-18xl);
  min-width: 162px;
}
.vector-icon18 {
  height: 70px;
  width: 67.1px;
  position: relative;
}
.vector-wrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-mini-9) 0 var(--padding-mini);
}
.gomba-shop1 {
  width: 97px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 97px;
}
.frame-parent91 {
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-46xl) var(--padding-56xl) var(--padding-8xl);
  gap: var(--gap-20xl);
}
.vector-icon19 {
  height: 70px;
  width: 70px;
  position: relative;
}
.vector-wrapper3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) 0 var(--padding-9xs-5);
}
.cloudcard1 {
  width: 77px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 77px;
}
.frame-parent92 {
  flex: 0.971;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-43xl) var(--padding-xl) var(--padding-8xl) var(--padding-7xl);
  gap: var(--gap-23xl);
  min-width: 162px;
}
.vector-icon20 {
  width: 80px;
  height: 57.6px;
  position: relative;
}
.opencard1 {
  width: 74px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 74px;
}
.vector-parent8 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-43xl) var(--padding-xl) var(--padding-8xl) var(--padding-7xl);
  gap: var(--gap-29xl);
  min-width: 162px;
}
.pow-function,
.round-function {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.round-function {
  width: 1059px;
  gap: var(--gap-2xl);
}
.pow-function {
  align-self: stretch;
  padding: 0 var(--padding-xl) var(--padding-2xl) var(--padding-18xl);
  box-sizing: border-box;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--body-small-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1225px) {
  .round-function {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .frame-parent92 {
    flex: 1;
  }
}
