.releva-was-founded,
.the-beginning-of {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
}
.the-beginning-of {
  line-height: 26px;
  font-weight: 600;
}
.releva-was-founded {
  font-size: var(--body-small-regular-size);
  line-height: 25px;
}
.about-us .interactiveDesctiption {
  display: none;
}
.about-us .interactiveHeading.active .interactiveDesctiption {
  display: block;
}
.button44:hover {
  background-color: var(--color-lightsteelblue);
}
.about-us .tab-1:hover,
.about-us .tab-2:hover,
.about-us .tab-3:hover,
.about-us .tab-4:hover {
  background-color: var(--brand-ivory);
  cursor: pointer;
  border-radius: var(--br-5xs);
}
.customer-data-segmentation2 {
  border-radius: var(--br-xs);
  /* background-color: var(--brand-ivory); */
  padding: var(--padding-xl) var(--padding-5xl);
  gap: var(--gap-9xs);
}
.customer-data-segmentation2,
.tab-1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rapid-growth {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  max-width: 100%;
}
.tab-2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.worldwide-recognition {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  max-width: 100%;
}
.tab-3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.awards {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 26px;
  display: inline-block;
  max-width: 100%;
}
.tab-4,
.tabs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.tab-4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.about-us .tabs {
  width: 432px;
  border-radius: var(--br-xs);
  flex-direction: column;
  gap: 10px;
  min-width: 432px;
}
.shorts-icon1 {
  height: 249.2px;
  width: 211.9px;
  position: absolute;
  margin: 0 !important;
  top: -48px;
  right: -131px;
  border-radius: 6.73px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 1;
}
.image-94-icon2,
.recommended-for-you2 {
  position: relative;
  flex-shrink: 0;
}
.recommended-for-you2 {
  width: 104.8px;
  letter-spacing: -0.02em;
  line-height: 17.13px;
  font-weight: 600;
  display: inline-block;
}
.image-94-icon2 {
  height: 59.9px;
  width: 130.3px;
  object-fit: cover;
}
.image-94-wrapper,
.recommended-for-you-group {
  height: 59.9px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-smi-9);
  box-sizing: border-box;
}
.recommended-for-you-group {
  align-self: stretch;
  height: 123.1px;
  flex-direction: column;
  padding: 0 0 var(--padding-69xl-1);
  gap: var(--gap-9xl-2);
}
.frame-child71,
.frame-child72 {
  height: 9.1px;
  position: relative;
  border-radius: 27.21px;
  background-color: var(--brand-ivory);
  flex-shrink: 0;
}
.frame-child71 {
  align-self: stretch;
}
.frame-child72 {
  width: 92.8px;
}
.rectangle-parent13 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-7xs-3);
}
.rectangle-parent13,
.shorts-group,
.sneakers2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.sneakers2 {
  flex: 1;
  border-radius: 6.73px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.6px solid var(--web-secondary-ivory-dark);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-lgi-6) var(--padding-9xs) var(--padding-10xs-1) var(--padding-sm-7);
  gap: var(--gap-27xl-7);
  z-index: 2;
}
.shorts-group {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.facebook-instagram-linked-in-y-inner {
  width: 162px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-xl);
  box-sizing: border-box;
  min-width: 162px;
}
.group-153-1 {
  height: 363px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 3;
}
.image-94 {
  position: absolute;
  top: 82.8px;
  left: 26.6px;
  width: 130.3px;
  height: 59.9px;
  display: none;
}
.frame-165-1 {
  /* position: absolute;
  top: 10px;
  left: 3px;
  width: 164px;
  height: 192px; */
  object-fit: cover;
  z-index: 1;
}
.sneakers3 {
  height: 184px;
  width: 149px;
  position: absolute;
  margin: 0 !important;
  right: -14px;
  bottom: -30px;
  border-radius: 6.73px;
  background-color: var(--web-secondary-ivory-light);
  border: 0.6px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 4;
}
.group-153-1-parent {
  height: 363px;
  width: 295px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  min-width: 295px;
}
.facebook-instagram-linked-in-y5 {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--brand-indigo);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-6xs) var(--padding-sm) var(--padding-19xl) var(--padding-15xl);
  box-sizing: border-box;
  max-width: 100%;
  gap: var(--gap-xl);
}
.element-4,
.element-4-wrapper,
.p251 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.p251 {
  flex: 1;
  flex-direction: row;
}
.element-4,
.element-4-wrapper {
  box-sizing: border-box;
}
.about-us .element-4-wrapper,
.about-us .element-4 {
  background: none;
  padding: 0;
}
.element-4 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-28xl) var(--padding-37xl) var(--padding-27xl);
}
.element-4-wrapper {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xs-5) 0 0;
  min-width: 513px;
  font-size: var(--font-size-mini-7);
  padding-top: 10%;
}
.distance-calculator-inner,
.tabs-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.tabs-parent {
  width: 1330px;
  justify-content: flex-start;
  gap: var(--gap-90xl);
}
.distance-calculator-inner {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-large-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1325px) {
  .tabs {
    flex: 1;
  }
  .tabs-parent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 900px) {
  .tabs {
    min-width: 100%;
  }
  .facebook-instagram-linked-in-y-inner,
  .group-153-1,
  .group-153-1-parent {
    flex: 1;
  }
  .facebook-instagram-linked-in-y5 {
    flex-wrap: wrap;
  }
  .element-4 {
    padding: var(--padding-12xl) var(--padding-9xl) var(--padding-11xl);
    box-sizing: border-box;
  }
  .element-4-wrapper {
    min-width: 100%;
  }
  .tabs-parent {
    gap: 109px 54px;
  }
}
@media screen and (max-width: 450px) {
  .awards,
  .rapid-growth,
  .the-beginning-of,
  .worldwide-recognition {
    font-size: var(--body-small-regular-size);
    line-height: 21px;
  }
  .tabs {
    gap: 24px 48px;
  }
  .facebook-instagram-linked-in-y5 {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-6xl);
    box-sizing: border-box;
  }
  .tabs-parent {
    gap: 109px 27px;
  }
}
