.trusted-by-1002 {
  margin: 0;
  width: 406px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  
}
.trusted-by-100-ecommerce-bran-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
}
.adres-icon2,
.ebag-icon2,
.imoteka-icon2,
.mercari-icon2,
.trendo-icon2 {
  align-self: stretch;
  width: 153.4px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 35px;
}
.adres-icon2,
.ebag-icon2,
.mercari-icon2,
.trendo-icon2 {
  width: 75.5px;
}
.adres-icon2,
.mercari-icon2,
.trendo-icon2 {
  width: 82.7px;
}
.adres-icon2,
.mercari-icon2 {
  width: 142.6px;
}
.mercari-icon2 {
  width: 167.5px;
}
.why-work-with-us1 {
  align-self: stretch;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-21xl) 0;
  flex-shrink: 0;
  
  gap: var(--gap-xl);
}
.data-driven-marketers-inner,
.frame-parent73 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.frame-parent73 {
  width: 1337.6px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-17xl);
}
.data-driven-marketers-inner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-3xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--headings-h5-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 850px) {
  .why-work-with-us1 {
    justify-content: center;
  }
  .frame-parent73 {
    gap: 18px 36px;
  }
}
@media screen and (max-width: 450px) {
  .trusted-by-1002 {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
}
