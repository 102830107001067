.get-my-free {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-23xl);
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  color: var(--brand-indigo);
  text-align: center;
}
.componentvariant6-group {
  margin-top: -82px;
}
.componentvariant6-group,
.labeled-icon-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.componentvariant6-parent,
.frame-wrapper43 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.componentvariant6-parent {
  width: 368px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.frame-wrapper43 {
  width: 592px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.button-name34 {
  width: 118px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  font-family: var(--body-footnote-regular);
  color: var(--web-secondary-ivory-light);
  text-align: center;
  display: inline-block;
  min-width: 118px;
  text-decoration: none;
}
.button39 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-decoration: none;
}
.button-frame,
.get-my-free-ebook-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.button-frame {
  width: 374px;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.get-my-free-ebook-parent {
  margin: 0;
  width: 690px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xl-5);
  min-width: 690px;
}
.vuesaxlineardirect-up-icon18 {
  height: 60px;
  width: 60px;
  position: relative;
}
.image-collection1 {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-22xl) 0 var(--padding-21xl);
  box-sizing: border-box;
}
.loop-structure {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 126px;
}
.loop-structure-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-mini);
}
.releva-contrubited-to {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
}
.image-collection-parent {
  width: 215px;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-117xl) var(--padding-17xl) var(--padding-126xl)
    var(--padding-19xl);
  box-sizing: border-box;
  min-width: 215px;
  z-index: 1;
}
.vuesaxlineardirect-up-icon19 {
  margin-top: -71.5px;
  margin-left: -45.599999999999966px;
  height: 45.5px;
  width: 45.5px;
  position: relative;
}
.vuesaxlinearshopping-cart-icon2 {
  width: 60px;
  height: 60px;
  position: relative;
}
.error-handler7 {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs-7) 0 0;
  box-sizing: border-box;
}
.boost2 {
  margin: 0;
  width: 73px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 20px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 73px;
}
.function-call-stack {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 var(--padding-14xl);
}
.e-commerce-growth1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
}
.data-split,
.parallel-process {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.parallel-process {
  width: 140px;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.data-split {
  align-self: stretch;
  border-radius: 16.93px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-7xl) var(--padding-smi) var(--padding-2xl)
    var(--padding-9xl-2);
  gap: var(--gap-12xs-1);
}
.event-trigger-icon {
  position: absolute;
  top: 93px;
  left: 0;
  width: 511px;
  height: 474px;
  object-fit: contain;
}
.a-boy-and-a-girl-lie-on-the-fl-icon {
  position: absolute;
  top: 0;
  left: 228px;
  width: 482px;
  height: 310px;
  object-fit: cover;
  z-index: 2;
}
.flow-control {
  position: absolute;
  bottom: -271px;
  left: -284px;
  width: 710px;
  height: 567px;
}
.output-display {
  align-self: stretch;
  height: 361px;
  position: relative;
  border-radius: 17.11px;
  background-color: var(--brand-amethyst);
  z-index: 1;
}
.data-split-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-base);
  min-width: 165px;
  text-align: center;
  font-size: var(--font-size-9xl);
}
.block-1,
.data-aggregator7,
.function-chain,
.oval-shape {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.function-chain {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-smi);
}
.block-1,
.data-aggregator7,
.oval-shape {
  box-sizing: border-box;
  max-width: 100%;
}
.data-aggregator7 {
  width: 482px;
  flex-direction: column;
  padding: var(--padding-13xl) 0 0;
  min-width: 482px;
}
.block-1,
.oval-shape {
  flex-direction: row;
}
.block-1 {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  padding: var(--padding-12xl) var(--padding-61xl) var(--padding-64xl);
  gap: var(--gap-125xl);
}
.oval-shape {
  width: 1712px;
  padding: 0 var(--padding-37xl) var(--padding-5xl);
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1525px) {
  .data-aggregator7,
  .get-my-free-ebook-parent {
    flex: 1;
  }
  .block-1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1225px) {
  .get-my-free-ebook-parent {
    min-width: 100%;
  }
  .block-1 {
    gap: 144px 72px;
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  .oval-shape {
    padding-left: var(--padding-9xl);
    padding-right: var(--padding-9xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 850px) {
  .get-my-free {
    font-size: var(--font-size-15xl);
    line-height: 50px;
  }
  .loop-structure {
    font-size: var(--font-size-31xl);
    line-height: 74px;
  }
  .image-collection-parent {
    flex: 1;
    padding-top: var(--padding-69xl);
    padding-bottom: var(--padding-75xl);
    box-sizing: border-box;
  }
  .function-chain {
    flex-wrap: wrap;
  }
  .data-aggregator7 {
    min-width: 100%;
  }
  .block-1 {
    gap: 144px 36px;
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-35xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .get-my-free {
    font-size: var(--font-size-6xl);
    line-height: 38px;
  }
  .loop-structure {
    font-size: var(--font-size-18xl);
    line-height: 55px;
  }
  .boost2 {
    font-size: var(--font-size-3xl);
    line-height: 16px;
  }
  .data-split {
    flex-wrap: wrap;
  }
  .block-1 {
    gap: 144px 18px;
  }
}
