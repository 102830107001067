.cookie-policy3 p,
.cookie-policy3 span{
  font-family: var(--body-footnote-regular) !important;
}

.releva-icon6,
.symbol-icon6 {
  height: 31.7px;
  width: 12.8px;
  position: relative;
}
.releva-icon6 {
  height: 27.2px;
  width: 113.7px;
}
.releva-logo-wrapper1,
.releva-logo6 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.releva-logo6 {
  height: 32px;
  overflow: hidden;
  align-items: flex-end;
  gap: var(--gap-smi-8);
}
.releva-logo-wrapper1 {
  margin-top: -8966px;
  align-items: flex-start;
  padding: 0 var(--padding-47xl) 8874px;
}
.cookie-policy4 {
  margin: 0;
  width: 1117px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: var(--body-footnote-regular);
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.cookie-policy-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-2xl) 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.at-releva-we {
  font-weight: 500;
  font-family: var(--body-footnote-regular);
}
.at-releva-we-respect-individua2 {
  font-size: var(--body-default-semi-bold-size);
}
.at-releva-we-respect-individua1 {
  color: var(--brand-indigo);
}
.at-releva-we-respect-individua {
  margin: 0;
}
.we-use-cookies {
  font-weight: 500;
  font-family: var(--body-footnote-regular);
}
.we-use-cookies-is-to-ensure-th2 {
  font-size: var(--body-default-semi-bold-size);
}
.we-use-cookies-is-to-ensure-th1 {
  color: var(--brand-indigo);
}
.we-use-cookies-is-to-ensure-th {
  margin: 0;
}
.this-policy-covers {
  font-weight: 500;
  font-family: var(--body-footnote-regular);
}
.this-policy-covers-information2 {
  font-size: var(--body-default-semi-bold-size);
}
.this-policy-covers-information1 {
  color: var(--brand-indigo);
}
.this-policy-covers-information {
  margin: 0;
}
.blank-line301 {
  font-family: var(--body-small-regular);
}
.blank-line300 {
  font-size: var(--body-default-semi-bold-size);
}
.blank-line299 {
  color: var(--brand-indigo);
}
.blank-line298 {
  margin: 0;
}
.blank-line304 {
  font-size: var(--font-size-7xl);
  font-family: var(--body-small-regular);
}
.blank-line303 {
  color: var(--brand-indigo);
}
.blank-line302 {
  margin: 0;
}
.what-is-a {
  font-size: var(--font-size-7xl);
  font-family: var(--body-small-regular);
}
.what-is-a-cookie-and-what-is-i1 {
  color: var(--brand-indigo);
}
.what-is-a-cookie-and-what-is-i {
  margin: 0;
}
.blank-line307 {
  font-size: var(--font-size-7xl);
  font-family: var(--body-small-regular);
}
.blank-line306 {
  color: var(--brand-indigo);
}
.blank-line305 {
  margin: 0;
}
.cookies-are-small {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.cookies-are-small-text-files-t {
  margin: 0;
}
.blank-line309 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line308 {
  margin: 0;
}
.blank-line311 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line310 {
  margin: 0;
}
.what-kind-of {
  font-size: var(--font-size-7xl);
  font-family: var(--body-small-regular);
  color: var(--brand-indigo);
}
.what-kind-of-cookies-do-we-use {
  margin: 0;
}
.blank-line313 {
  font-size: var(--font-size-7xl);
  font-family: var(--body-small-regular);
  color: var(--brand-indigo);
}
.blank-line312 {
  margin: 0;
}
.we-use-few {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.we-use-few-essential-cookies {
  margin: 0;
}
.blank-line315 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line314 {
  margin: 0;
}
.all-cookies-are {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.all-cookies-are-considered-to {
  margin: 0;
}
.blank-line317 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line316 {
  margin: 0;
}
.the-hotjar-cookie {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.the-hotjar-cookie-analyses-you {
  margin: 0;
}
.blank-line319 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line318 {
  margin: 0;
}
.blank-line321 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line320 {
  margin: 0;
}
.how-long-do {
  font-size: var(--font-size-7xl);
  font-family: var(--body-small-regular);
  color: var(--brand-indigo);
}
.how-long-do-you-keep-cookie-da {
  margin: 0;
}
.blank-line323 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line322 {
  margin: 0;
}
.each-cookie-is {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.each-cookie-is-kept-for-a-diff {
  margin: 0;
}
.blank-line325 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line324 {
  margin: 0;
}
.blank-line327 {
  font-size: var(--body-default-semi-bold-size);
  font-family: var(--body-small-regular);
  color: var(--color-gray-100);
}
.blank-line326 {
  margin: 0;
}
.how-do-i {
  font-size: var(--font-size-7xl);
}
.how-do-i-manage-my-cookie {
  margin: 0;
}
.blank-line329 {
  text-decoration: underline;
}
.blank-line328 {
  margin: 0;
}
.most-internet-browsers-automat {
  margin: 0;
  color: var(--color-gray-100);
}
.wwwallaboutcookiesorg1 {
  text-decoration: underline;
}
.wwwallaboutcookiesorg {
  color: inherit;
}
.if-you-want-to-find-out-more-a {
  margin: 0;
}
.at-releva-we-container {
  /* height: 1036px; */
  width: 1521px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.at-releva-we-respect-individua-wrapper,
.cookie-policy3 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
.at-releva-we-respect-individua-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-77xl) var(--padding-40xl);
  max-width: 100%;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-small-regular);
}
.cookie-policy3 {
  width: 100%;
  /* height: 2989px; */
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl) 0 0;
  gap: var(--gap-41xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .releva-logo-wrapper1 {
    padding-bottom: 1584px;
    box-sizing: border-box;
  }
  .at-releva-we-respect-individua-wrapper {
    padding-left: var(--padding-10xl);
    padding-bottom: var(--padding-43xl);
    box-sizing: border-box;
  }
  .cookie-policy3 {
    gap: 30px 60px;
  }
}
@media screen and (max-width: 850px) {
  .cookie-policy4 {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
  .at-releva-we-respect-individua-wrapper {
    padding-bottom: var(--padding-21xl);
    box-sizing: border-box;
  }
  .cookie-policy3 {
    height: auto;
  }
}
@media screen and (max-width: 450px) {
  .releva-logo-wrapper1 {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    padding-bottom: 1030px;
    box-sizing: border-box;
  }
  .cookie-policy4 {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
}
