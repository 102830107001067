.case-study- {
  margin: 0;
  width: 1186px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 82px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.case-study-kidso-wrapper {
  width: 1699px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--headings-h1-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.kidso-is-one {
  width: 688px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.kidso-is-one-of-the-top-brands-wrapper {
  width: 1699px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-2xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.locked-case-study {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-28xl) 0 4010px;
  box-sizing: border-box;
  gap: var(--gap-13xl);
  letter-spacing: normal;
}
@media screen and (max-width: 850px) {
  .case-study- {
    font-size: var(--font-size-42xl);
    line-height: 66px;
  }
}
@media screen and (max-width: 450px) {
  .case-study- {
    font-size: var(--font-size-27xl);
    line-height: 49px;
  }
  .locked-case-study {
    gap: 16px 32px;
  }
}
