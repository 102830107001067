.chat-bot-bubble {
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 998;
}

.chat-bot-bubble-off {
  background: #5f2a6a;
  width: 48px;
  height: 48px;
  margin: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s ease-in-out;
  opacity: 0.5;
  color: #fff;
}

.chat-bot-bubble-off:hover {
  opacity: 1;
}

.chat-bot-bubble-on {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #e4e9f0;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.chat-bot-bubble-on>img {
  max-width: 50%;
}

.chat-bot-bubble-on:hover {
  transform: scale(1.1);
}

.chat-bot-bubble-on:after {
  content: '';
  right: 0px;
  bottom: 0px;
  display: block;
  position: absolute;
  z-index: 9;
  height: 60px;
  width: 60px;
  animation: chat-bot-gradient-spin 1.7s linear infinite;
  text-align: center;
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 0px -5px 20px 0px #5f2a6a, 0px 5px 20px 0px #8d3e9d;
}

.chat-bot-bubble-on:hover::after {
  animation-play-state: paused;
}

@keyframes chat-bot-gradient-spin {
  to {
    transform: rotate(360deg);
  }
}

.chat-bot-container {
  position: fixed;
  bottom: 84px;
  right: 20px;
  width: 400px;
  top: 60px;
  z-index: 999;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 0px 7px #e4e9f0;
  background-color: #fff;
  transition: all 0.3s ease;
  font-family: 'Inter';
}

.chat-bot-container-fullscreen {
  height: 100vh;
  bottom: 0px;
  right: 0;
  top: 0px;
  border: 0px;
  border-radius: 0;
  z-index: 1010;
  width: 550px;
}

.chat-bot-header {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #5f2a6a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chat-bot-container.chat-bot-container-fullscreen .chat-bot-header {
  border-radius: 0;
}

.chat-bot-info {
  color: #fff;
  margin-left: 8px;
}

.chat-bot-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #e4e9f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-bot-logo>img {
  max-width: 50%;
}

.chat-bot-status {
  font-size: 0px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: solid 0.8px #fff;
  margin-top: -8px;
  margin-right: 2px;
  margin-bottom: 0;
  background-color: #74bea9;
  animation: 1.5s status-pulse infinite ease-in-out;
}

@keyframes status-pulse {
  0% {
    box-shadow: 0 0 0 0 #74bea9;
  }

  100% {
    box-shadow: 0 0 0 14px #43ee7d00;
  }
}

.chat-bot-messages-container {
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding: 20px;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
}

.chat-bot-footer {
  padding: 16px;
  background-color: #e4e9f0;
}

.chat-bot-user-container,
.chat-bot-system-container {
  margin-bottom: 10px;
}

.chat-bot-user-response>.response-data-container,
.chat-bot-user-response>.error-response {
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #e4e9f0;
}

.chat-bot-system-response>.response-data-container,
.chat-bot-system-response>.error-response {
  padding: 12px 16px;
  border-radius: 5px;
  background-color: rgba(121, 54, 134, 0.1);
}

.chat-bot-user-response {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.chat-bot-user-response>div>p,
.chat-bot-system-response>div>p {
  margin: 0;
}

.chat-bot-user-response-img,
.chat-bot-system-response-img {
  margin-top: -14px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 35%;
  border: 2px solid #793686;
  margin-left: 6px;
  margin-right: 6px;
}

.chat-bot-user-response-img>img,
.chat-bot-system-response-img>img {
  width: 80%;
}

.chat-bot-navigation {
  margin-left: 0.25rem;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: #fff;
  background-color: #5f2a6a;
  border-color: #5f2a6a;
  display: inline-block;
  font-weight: 300;
  color: #222;
  text-align: center;

  border: 1px solid transparent;
  padding: 0.375rem 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: calc(7px - 1px);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-bot-navigation>img {
  height: 15px;
}


.chat-bot-align {
  display: flex;
  align-items: center;
}

.chat-bot-title {
  font-weight: 700 !important;
  margin: 0 !important;
  font-size: 1.171875rem;
  line-height: 1.2;
}

.chat-bot-status-title {
  margin: 0 0 0.5rem 0;
  font-size: 0.9375rem;
  font-weight: 100 !important;
}

#submit-button {
  background: #5f2a6a url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='white' d='M2.01 21L23 12 2.01 3 2 10l15 2-15 2z'/></svg>") no-repeat center center;
  color: #5f2a6a !important;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#submit-button.disabled {
  background-color: #cccccc;
  opacity: 0.6;
}

#input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid;
  border-color: #6a3d7441;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}