.ionut-leoveanu {
  position: absolute;
  top: 190px;
  left: 41.3px;
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  min-width: 119px;
}
.vector-icon32 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
.whatsapp-image-2023-08-12-at-1-icon {
  position: absolute;
  top: 30px;
  left: 23px;
  width: 155px;
  height: 155px;
  object-fit: cover;
  z-index: 2;
}
.delay-processor {
  position: absolute;
  top: 0;
  left: 0;
  width: 207.3px;
  height: 204px;
}
.customer-success-specialist,
.timer {
  position: relative;
  flex-shrink: 0;
}
.timer {
  margin-top: -215px;
  align-self: stretch;
  height: 215px;
}
.customer-success-specialist {
  /* width: 172px; */
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.filter-chain,
.ionut {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xl) 0 var(--padding-mini-3);
  font-size: var(--font-size-smi);
}
.ionut {
  height: 226px;
  border-radius: var(--br-5xs);
  background-color: var(--web-secondary-ivory-light);
  border: 1px solid var(--web-secondary-ivory-dark);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-171xl) var(--padding-xl) var(--padding-2xs);
  text-align: center;
  font-size: var(--body-small-regular-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 450px) {
  .ionut {
    padding-top: var(--padding-104xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
}
