.customers1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.as-an-online {
  width: 405px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.customers-parent,
.data-collector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.customers-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.data-collector {
  width: 498px;
  padding: var(--padding-193xl) 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 923px);
  flex-shrink: 0;
}
.segments3 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  display: inline-block;
  min-width: 114px;
}
.company-resources2,
.ready-to-act {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.ready-to-act {
  width: 99px;
}
.company-resources2 {
  flex: 1;
  min-width: 147px;
}
.ready-to-act-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frame-child58,
.frame-child59 {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.frame-child58 {
  width: 99px;
}
.frame-child59 {
  flex: 1;
  min-width: 147px;
}
.rectangle-parent9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frame-child60,
.frame-child61 {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.frame-child60 {
  width: 99px;
}
.frame-child61 {
  flex: 1;
  min-width: 147px;
}
.c-t-a-background5,
.footer9,
.rectangle-parent10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-parent10 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-5xl);
}
.c-t-a-background5,
.footer9 {
  max-width: 100%;
}
.c-t-a-background5 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xl);
}
.footer9 {
  flex-direction: row;
  padding: 0 0 0 var(--padding-10xs);
}
.footer9,
.segments-parent1,
.why-releva8 {
  align-self: stretch;
  box-sizing: border-box;
}
.segments-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-lgi);
  gap: var(--gap-xl);
  max-width: 100%;
}
.why-releva8 {
  height: 1px;
  position: relative;
  border-top: 1px solid var(--web-secondary-ivory-dark);
}
.profile4 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}
.product-datacenter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.image-84,
.mask-group-3-1 {
  position: absolute;
  top: 4px;
  left: 4px;
}
.image-84 {
  width: calc(100% - 8px);
  right: 4px;
  height: 68px;
  display: none;
}
.mask-group-3-1 {
  width: calc(100% - 4px);
  right: 0;
  max-width: 100%;
  overflow: hidden;
  height: 60px;
  object-fit: cover;
  z-index: 1;
}
.image-84-parent {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 51.18px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  flex-shrink: 0;
}
.anthony-smith3 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 113px;
}
.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xs) 0 0;
}
.ecommerce-brands-container {
  width: 38px;
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.paragraph {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xl) var(--padding-2xs) 0 0;
}
.paragraph-child {
  align-self: stretch;
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.frame-parent108,
.paragraph1,
.product-datacenter-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.paragraph1 {
  width: 70px;
  flex-direction: column;
  padding: var(--padding-3xl) 0 0;
  box-sizing: border-box;
}
.frame-parent108,
.product-datacenter-parent {
  flex-direction: row;
  gap: var(--gap-base);
  font-size: var(--body-small-regular-size);
}
.product-datacenter-parent {
  flex-direction: column;
  padding: 0 0 var(--padding-7xs);
  gap: var(--gap-lgi);
  font-size: var(--body-default-semi-bold-size);
}
.communication-center5 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: -8px;
  left: -4px;
  border-radius: 51.18px;
  background-color: var(--color-plum);
  overflow: hidden;
  flex-shrink: 0;
}
.mask-group-4-1 {
  height: 59px;
  width: 59px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}
.customer-data-segmentation1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.jessica-ford2 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 600;
  display: inline-block;
  min-width: 96px;
}
.column6,
.jessica-ford-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.jessica-ford-container {
  flex-direction: column;
  padding: var(--padding-xs) 0 0;
}
.column6 {
  height: 59px;
  flex-direction: row;
  gap: var(--gap-mid);
}
.from-diverse-industries-and-gl,
.from-diverse-industries-and-gl1 {
  height: 12px;
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--brand-ivory);
}
.from-diverse-industries-and-gl {
  width: 38px;
}
.from-diverse-industries-and-gl1 {
  flex: 1;
}
.trusted-by-ecommerce-industrie {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xl);
}
.triangle1 {
  width: 135px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-lg) 0 0;
  box-sizing: border-box;
}
.ebag-trendo-adres,
.row,
.triangle1 {
  display: flex;
  justify-content: flex-start;
}
.row {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-26xl);
  font-size: var(--body-small-regular-size);
}
.ebag-trendo-adres {
  position: absolute;
  top: 0;
  left: 66.2px;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-light);
  width: 430px;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-18xl) var(--padding-20xl) var(--padding-18xl)
    var(--padding-16xl);
  box-sizing: border-box;
  gap: var(--gap-10xl);
  max-width: 100%;
  z-index: 1;
}
.artwork-icon1 {
  position: absolute;
  top: 161.8px;
  left: 0;
  width: 701.4px;
  height: 701.4px;
  object-fit: contain;
}
.vector-icon28 {
  position: absolute;
  top: 614px;
  left: 645.2px;
  width: 116px;
  height: 175px;
}
.ebag-adres-block,
.oval-child {
  position: absolute;
  overflow: hidden;
  z-index: 2;
}
.oval-child {
  top: 253px;
  left: 464.2px;
  border-radius: var(--br-xs);
  width: 168px;
  height: 201px;
}
.ebag-adres-block {
  top: 18px;
  left: 473.2px;
  border-radius: 10.57px;
  background-color: var(--brand-lemon);
  width: 147.9px;
  height: 177px;
}
.tobias-tullius-fg15ldqpwrs-uns-icon {
  position: absolute;
  top: 0;
  left: 473.2px;
  width: 151px;
  height: 184px;
  object-fit: cover;
  z-index: 3;
}
.oval {
  position: absolute;
  top: 101px;
  left: 0;
  width: 761.2px;
  height: 863.2px;
}
.list-item1 {
  height: 964.2px;
  flex: 1;
  position: relative;
  max-width: calc(100% - 660px);
  font-size: var(--headings-h5-semi-bold-size);
}
.block-2 {
  align-self: stretch;
  height: 768px;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-73xl) var(--padding-6xs) var(--padding-258xl)
    var(--padding-153xl);
  box-sizing: border-box;
  gap: var(--gap-142xl-8);
  max-width: 100%;
  z-index: 1;
}
.key-objectives {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.trendo-aims-to {
  width: 440px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.grid1,
.key-objectives-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.key-objectives-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.grid1 {
  width: 550px;
  padding: var(--padding-149xl) 0 0;
  box-sizing: border-box;
  min-width: 550px;
}
.vuesaxlinearuser-octagon-icon {
  height: 73px;
  width: 74px;
  position: relative;
}
.opacity {
  height: 73px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-25xl) 0 var(--padding-16xl);
  box-sizing: border-box;
}
.website1 {
  margin: 0;
  width: 144px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
}
.personalization,
.product5 {
  margin: 0;
}
.product-personalization {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 500;
  font-family: inherit;
  color: var(--brand-indigo);
}
.opacity-parent,
.website-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.website-parent {
  align-self: stretch;
  gap: var(--gap-mid);
}
.opacity-parent {
  width: 204.3px;
  border-radius: 13.59px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-21xl) var(--padding-7xl) var(--padding-13xl-8);
  box-sizing: border-box;
  gap: var(--gap-8xl);
  min-width: 204.30000000000015px;
  
}
.vector-icon29 {
  height: 428px;
  width: 439px;
  position: absolute;
  margin: 0 !important;
  right: -150.7px;
  bottom: -173.2px;
}
.link-list {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-2xl);
}
.engagement {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-weight: 600;
  font-family: inherit;
}
.marketing1,
.omnichannel {
  margin: 0;
}
.omnichannel-marketing1 {
  margin: 0;
  width: 135px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 500;
  font-family: inherit;
  color: var(--brand-indigo);
  display: inline-block;
}
.dropdown-set,
.link-list-parent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.link-list-parent {
  flex: 1;
  border-radius: 13.59px;
  background-color: var(--color-plum);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-19xl) var(--padding-15xl) var(--padding-6xl-8)
    var(--padding-7xl);
  gap: var(--gap-5xl);
  flex-shrink: 0;
  
}
.dropdown-set {
  height: 259.8px;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}
.vuesaxlinearstory-icon {
  height: 87px;
  width: 87px;
  position: relative;
}
.vuesaxlinearstory-wrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-9xl) 0 var(--padding-10xl);
}
.automation5,
.marketing2 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.marketing2 {
  align-self: stretch;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-weight: 600;
}
.automation5 {
  width: 114px;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 114px;
}
.square-shape-set {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-xs) 0 var(--padding-lg);
  color: var(--brand-indigo);
}
.dropdown-set-parent,
.frame-parent109 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-parent109 {
  align-self: stretch;
  height: 259.8px;
  border-radius: 13.59px;
  background-color: var(--color-lightsteelblue);
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-end;
  padding: var(--padding-21xl) var(--padding-11xl) var(--padding-31xl-8);
  box-sizing: border-box;
  gap: var(--gap-lg);
  
}
.dropdown-set-parent {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-smi-2);
  min-width: 133px;
}
.frame-child62 {
  height: 79px;
  width: 79px;
  position: relative;
}
.global-locations-list-inner {
  height: 79px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-13xl) 0 var(--padding-14xl);
  box-sizing: border-box;
}
.predict1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 22px;
  font-weight: 600;
}
.purchase1,
.users-next {
  margin: 0;
}
.users-next-purchase-container {
  width: 112px;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
}
.users-next-purchase-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-mini) 0 var(--padding-mid);
  color: var(--brand-indigo);
}
.global-locations-list,
.predict-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.predict-group {
  align-self: stretch;
  gap: var(--gap-base);
}
.global-locations-list {
  flex: 0.7029;
  border-radius: 13.59px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  padding: var(--padding-20xl) var(--padding-13xl) var(--padding-11xl-8)
    var(--padding-9xl-7);
  box-sizing: border-box;
  gap: var(--gap-6xl);
  min-width: 133px;
}
.block-3,
.elements,
.image-stack {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}
.image-stack {
  flex: 1;
  align-items: flex-end;
  gap: var(--gap-xs);
  min-width: 274px;
  flex-shrink: 0;
  
}
.block-3,
.elements {
  align-items: flex-start;
  z-index: 1;
}
.elements {
  flex: 1;
  gap: var(--gap-xs-7);
  min-width: 414px;
  text-align: center;
  font-size: var(--font-size-3xl);
  color: var(--color-black);
}
.block-3 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  padding: var(--padding-99xl) var(--padding-116xl-4) var(--padding-99xl-2)
    var(--padding-143xl);
  box-sizing: border-box;
  gap: var(--gap-97xl);
}
.trendo-implemented {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.ai-marketing-automation,
.automated-email-newsletters,
.omnichannel-communication {
  margin-bottom: 0;
}
.automated-email-newsletters-ai {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-5xl);
}
.automated-email-newsletters-container {
  width: 416px;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.block-4-inner,
.trendo-implemented-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
}
.trendo-implemented-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
  max-width: 100%;
}
.block-4-inner {
  width: 570px;
  padding: var(--padding-147xl-8) 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 871px);
}
.hi-jane {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 90px;
}
.youve-left-two3 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
}
.ebag-trendo-adres-inner,
.hi-jane-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.hi-jane-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs-5);
}
.ebag-trendo-adres-inner {
  width: 369px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  box-sizing: border-box;
}
.image-83-icon3 {
  height: 134.1px;
  width: 134.1px;
  position: relative;
  object-fit: cover;
  flex-shrink: 0;
  
}
.ecommerce-owners-market-specia,
.frame-child63 {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
}
.frame-child63 {
  align-self: stretch;
}
.ecommerce-owners-market-specia {
  width: 109px;
}
.rectangle-parent11 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-6xs-7);
  flex-shrink: 0;
  
}
.frame-wrapper44,
.frame-wrapper45,
.image-83-group,
.rectangle-parent11 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-wrapper45 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-17xl-9) 0 0;
  box-sizing: border-box;
  min-width: 110px;
}
.frame-wrapper44,
.image-83-group {
  flex-direction: row;
  max-width: 100%;
}
.image-83-group {
  flex: 1;
  gap: var(--gap-21xl-2);
}
.frame-wrapper44 {
  width: 370.6px;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
  box-sizing: border-box;
  flex-shrink: 0;
}
.frame-child64 {
  align-self: stretch;
  height: 1.4px;
  position: relative;
  border-top: 1.4px solid var(--brand-ivory);
  box-sizing: border-box;
}
.frame-parent111 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm-4);
  max-width: 100%;
}
.image-13-icon3 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
}
.ebag-trendo-adres2 {
  height: 72.1px;
  width: 137.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.button-name37,
.try-releva {
  height: 15.1px;
  position: relative;
  border-radius: var(--br-21xl-8);
  background-color: var(--brand-ivory);
  flex-shrink: 0;
  text-decoration: none;
}
.try-releva {
  align-self: stretch;
}
.button-name37 {
  width: 109px;
}
.stack-container,
.tabbed-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tabbed-container {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-6xs-7);
}
.stack-container {
  flex: 1;
  justify-content: flex-end;
  padding: 0 0 var(--padding-sm-4);
  box-sizing: border-box;
  min-width: 110px;
}
.card-stack,
.frame-parent110,
.grid-layout {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.grid-layout {
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: var(--gap-17xl-8);
}
.card-stack,
.frame-parent110 {
  align-items: flex-start;
  box-sizing: border-box;
}
.card-stack {
  width: 370.6px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-sm-5);
}
.frame-parent110 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 var(--padding-7xs-6);
  gap: var(--gap-11xl-4);
}
.checkout3 {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
  min-width: 93px;
}
.get-demo11,
.logic-gate15 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.get-demo11 {
  border-radius: 13.41px;
  background-color: var(--brand-indigo);
  justify-content: flex-start;
  padding: var(--padding-sm-4) var(--padding-23xl);
}
.logic-gate15 {
  width: 367.6px;
  justify-content: center;
  max-width: 100%;
  font-size: var(--body-large-regular-size);
  color: var(--brand-ivory);
}
.icon7 {
  width: 81px;
  height: 81px;
  position: absolute;
  margin: 0 !important;
  top: -36.2px;
  right: -31px;
  z-index: 1;
}
.ebag-trendo-adres1 {
  position: absolute;
  top: 0;
  left: 208px;
  border-radius: var(--br-sm-6);
  background-color: var(--web-secondary-ivory-light);
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-26xl-3) var(--padding-4xl-4) var(--padding-27xl-1)
    var(--padding-6xl-1);
  box-sizing: border-box;
  gap: var(--gap-31xl);
  max-width: 100%;
}
.image-841 {
  position: absolute;
  top: 13.1px;
  left: -13.1px;
  width: 209.9px;
  height: 255.8px;
  display: none;
}
.marketingspecialist {
  position: absolute;
  top: 28px;
  left: 28px;
  border-radius: 13.12px;
  background-color: var(--brand-amethyst);
  width: 196.8px;
  height: 264px;
  overflow: hidden;
  z-index: 1;
}
.tamara-bellis-joks3xwev50-unsp-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  height: 292px;
  object-fit: cover;
  z-index: 2;
}
.ecommerceowners {
  position: absolute;
  top: 138.8px;
  left: 0;
  width: 225px;
  height: 292px;
}
.ebag-trendo-adres-parent {
  height: 596.7px;
  width: 638px;
  position: relative;
  max-width: 100%;
}
.heart-shape-set-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.referralprograms-icon {
  width: 116px;
  height: 175px;
  position: relative;
}
.block-4,
.heart-shape-set {
  display: flex;
  justify-content: flex-start;
}
.heart-shape-set {
  height: 877.8px;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-87xl-1);
  max-width: calc(100% - 656px);
  font-size: var(--headings-h5-semi-bold-size);
}
.block-4 {
  align-self: stretch;
  height: 768px;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-85xl-2) var(--padding-6xs) var(--padding-263xl)
    var(--padding-133xl);
  box-sizing: border-box;
  gap: var(--gap-67xl);
  max-width: 100%;
  z-index: 1;
}
.achieved-results {
  margin: 0;
  width: 426px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 37.7px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}
.achieved-results-wrapper {
  width: 1302.6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.marketeers {
  width: 146px;
  position: relative;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.open-rate-from {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 25px;
  z-index: 1;
}
.marketeers-parent,
.open-rate-from-automations-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.open-rate-from-automations-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--body-small-regular-size);
}
.marketeers-parent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.ebag-trendo-address {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 27px;
  z-index: 1;
}
.frame-parent112,
.ready-to-see-action1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.ready-to-see-action1 {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  margin-left: -21.6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-parent112 {
  width: 181px;
  flex-direction: row;
  padding: 0 var(--padding-7xs) 0 0;
}
.click-rate-from1,
.div19 {
  position: relative;
  z-index: 1;
}
.div19 {
  width: 145px;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
  display: inline-block;
}
.click-rate-from1 {
  align-self: stretch;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
}
.parent16 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.h14 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 27px;
  z-index: 1;
}
.frame-parent113,
.ready-to-see-action2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.ready-to-see-action2 {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  margin-left: -21.6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-parent113 {
  width: 180px;
  flex-direction: row;
  padding: 0 var(--padding-7xs) 0 0;
}
.div20,
.h15 {
  position: relative;
  font-weight: 600;
  display: inline-block;
  z-index: 1;
}
.div20 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
  min-width: 106px;
}
.h15 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  min-width: 27px;
}
.header-logo-container1 {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xs) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-wrapper46,
.parent17 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.parent17 {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.frame-wrapper46 {
  width: 153.2px;
  justify-content: flex-end;
  padding: 0 var(--padding-9xs-6) 0 var(--padding-9xs);
  box-sizing: border-box;
}
.conversion-rate,
.from-automations {
  margin: 0;
}
.conversion-rate-from-container {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  z-index: 1;
}
.frame-parent114 {
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-7xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-6xs);
}
.div21,
.open-rate-from1 {
  position: relative;
  z-index: 1;
}
.div21 {
  width: 146px;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
  display: inline-block;
}
.open-rate-from1 {
  flex: 1;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.open-rate-from-campaigns-wrapper,
.parent18 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.open-rate-from-campaigns-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--body-small-regular-size);
}
.parent18 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.h16 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 27px;
  z-index: 1;
}
.frame-parent115,
.ready-to-see-action3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.ready-to-see-action3 {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  margin-left: -21.6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-parent115 {
  width: 188px;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 0;
}
.click-rate-from2,
.div22 {
  position: relative;
  z-index: 1;
}
.div22 {
  width: 146px;
  letter-spacing: -0.06em;
  line-height: 100px;
  font-weight: 600;
  display: inline-block;
}
.click-rate-from2 {
  flex: 1;
  letter-spacing: -0.01em;
  line-height: 25px;
}
.click-rate-from-automations-wrapper,
.parent19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.click-rate-from-automations-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  font-size: var(--body-small-regular-size);
}
.parent19 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-6xs);
}
.h17 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 27px;
  z-index: 1;
}
.frame-parent116,
.ready-to-see-action4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.ready-to-see-action4 {
  width: 27px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  margin-left: -21.6px;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-parent116 {
  width: 175px;
  flex-direction: row;
}
.div23,
.h18 {
  position: relative;
  font-weight: 600;
  z-index: 1;
}
.div23 {
  flex: 1;
  letter-spacing: -0.06em;
  line-height: 100px;
}
.h18 {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 38px;
  font-family: inherit;
  display: inline-block;
  min-width: 27px;
}
.ready-to-see-action5 {
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  font-size: var(--headings-h4-semi-bold-size);
}
.frame-wrapper47,
.parent20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.parent20 {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame-wrapper47 {
  width: 149.2px;
  justify-content: flex-end;
  padding: 0 var(--padding-11xl-6) 0 var(--padding-12xl);
  box-sizing: border-box;
}
.conversion-rate-from {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  z-index: 1;
}
.frame-parent117 {
  width: 168.6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.error-handler9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-28xl);
  max-width: 100%;
  font-size: var(--font-size-53xl);
}
.background,
.block-5 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.background {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-40xl) var(--padding-xl) var(--padding-80xl-5)
    var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-86xl-8);
}
.block-5 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  z-index: 1;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
}
.key-metrics {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
  font-family: inherit;
}
.average-active-users,
.average-page-views {
  margin-block-start: 0;
  margin-block-end: 12px;
}
.emails-sent-1 {
  margin: 0;
}
.average-page-views-container {
  width: 507px;
  position: relative;
  font-size: var(--body-large-regular-size);
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
}
.block-6-inner,
.key-metrics-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.key-metrics-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.block-6-inner {
  width: 550px;
  padding: var(--padding-176xl) 0 0;
  box-sizing: border-box;
  min-width: 550px;
}
.photo-icon {
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.055);
}
.wrapper-photo {
  height: 614px;
  width: 787px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-2-parent,
.block-3-parent,
.block-6,
.triangle-shape-set {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.block-6 {
  border-radius: var(--br-xs);
  background-color: var(--color-seashell-100);
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-54xl) var(--padding-xl) var(--padding-62xl)
    var(--padding-120xl);
  box-sizing: border-box;
  row-gap: 20px;
  z-index: 1;
}
.block-2-parent,
.block-3-parent,
.triangle-shape-set {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-12xl);
}
.block-2-parent,
.block-3-parent {
  gap: var(--gap-24xl);
  font-size: var(--headings-h3-semi-bold-size);
}
.block-2-parent {
  gap: var(--gap-20xl);
  text-align: left;
  font-size: var(--font-size-23xl);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1600px) {
  .grid1 {
    flex: 1;
  }
  .block-3 {
    flex-wrap: wrap;
  }
  .block-4-inner {
    max-width: 100%;
  }
  .heart-shape-set {
    display: none;
  }
  .error-handler9 {
    flex-wrap: wrap;
  }
  .block-6-inner,
  .photo-icon {
    flex: 1;
  }
  .block-6 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .data-collector,
  .list-item1 {
    display: none;
    max-width: 100%;
  }
  .block-2 {
    gap: 161.8px 81px;
    padding-left: var(--padding-67xl);
    padding-top: var(--padding-41xl);
    padding-bottom: var(--padding-161xl);
    box-sizing: border-box;
  }
  .elements,
  .grid1 {
    min-width: 100%;
  }
  .block-3 {
    gap: 116px 58px;
    padding-left: var(--padding-62xl);
    padding-right: var(--padding-48xl);
    box-sizing: border-box;
  }
  .block-4-inner {
    display: none;
  }
  .heart-shape-set {
    max-width: 100%;
  }
  .block-4 {
    padding-left: var(--padding-57xl);
    padding-top: var(--padding-49xl);
    padding-bottom: var(--padding-164xl);
    box-sizing: border-box;
  }
  .block-6-inner {
    min-width: 100%;
  }
  .block-6 {
    padding-left: var(--padding-50xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .customers1 {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-parent108,
  .row {
    flex-wrap: wrap;
  }
  .block-2 {
    gap: 161.8px 40px;
    padding-left: var(--padding-24xl);
    padding-top: var(--padding-20xl);
    padding-bottom: var(--padding-98xl);
    box-sizing: border-box;
  }
  .key-objectives {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .opacity-parent {
    flex: 1;
  }
  .elements,
  .image-stack {
    flex-wrap: wrap;
  }
  .block-3 {
    gap: 116px 29px;
    padding: var(--padding-58xl) var(--padding-14xl) var(--padding-58xl)
      var(--padding-21xl);
    box-sizing: border-box;
  }
  .trendo-implemented {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .heart-shape-set {
    gap: 53px 106.1px;
  }
  .block-4 {
    gap: 86px 43px;
    padding-left: var(--padding-19xl);
    padding-top: var(--padding-25xl);
    padding-bottom: var(--padding-100xl);
    box-sizing: border-box;
  }
  .achieved-results {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .marketeers {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .ebag-trendo-address {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div19 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .h14 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div20 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .h15 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div21 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .h16 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div22 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .h17 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .div23 {
    font-size: var(--font-size-39xl);
    line-height: 80px;
  }
  .h18 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .error-handler9 {
    gap: 47px 23px;
  }
  .background {
    gap: 53px 105.8px;
  }
  .key-metrics {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .block-6 {
    padding-left: var(--padding-15xl);
    padding-top: var(--padding-28xl);
    padding-bottom: var(--padding-34xl);
    box-sizing: border-box;
  }
  .triangle-shape-set {
    gap: 15px 31px;
  }
  .block-3-parent {
    gap: 21px 43px;
  }
  .block-2-parent {
    gap: 19px 39px;
  }
}
@media screen and (max-width: 450px) {
  .customers1 {
    font-size: var(--font-size-6xl);
    line-height: 31px;
  }
  .data-collector {
    padding-top: var(--padding-119xl);
    box-sizing: border-box;
  }
  .segments3 {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .list-item1 {
    height: auto;
    min-height: 964.1999999999998;
  }
  .block-2 {
    height: auto;
    gap: 161.8px 20px;
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .key-objectives {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .grid1 {
    padding-top: var(--padding-90xl);
    box-sizing: border-box;
  }
  .product-personalization,
  .website1 {
    font-size: var(--body-default-semi-bold-size);
    line-height: 18px;
  }
  .product-personalization {
    line-height: 19px;
  }
  .engagement,
  .marketing2,
  .omnichannel-marketing1 {
    font-size: var(--body-default-semi-bold-size);
    line-height: 18px;
  }
  .marketing2,
  .omnichannel-marketing1 {
    line-height: 19px;
  }
  .marketing2 {
    line-height: 18px;
  }
  .automation5,
  .predict1,
  .users-next-purchase-container {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .predict1,
  .users-next-purchase-container {
    line-height: 18px;
  }
  .users-next-purchase-container {
    line-height: 19px;
  }
  .global-locations-list {
    flex: 1;
  }
  .block-3 {
    padding-top: var(--padding-31xl);
    padding-bottom: var(--padding-31xl);
    box-sizing: border-box;
  }
  .trendo-implemented {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .block-4-inner {
    padding-top: var(--padding-89xl);
    box-sizing: border-box;
  }
  .hi-jane {
    font-size: var(--font-size-lgi);
    line-height: 35px;
  }
  .image-83-icon3 {
    flex: 1;
  }
  .image-83-group {
    flex-wrap: wrap;
  }
  .checkout3 {
    font-size: var(--body-small-regular-size);
  }
  .ebag-trendo-adres-parent {
    height: auto;
    min-height: 596.7;
  }
  .block-4,
  .heart-shape-set {
    height: auto;
    gap: 27px 106.1px;
  }
  .block-4 {
    gap: 86px 21px;
  }
  .achieved-results {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .marketeers {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .ebag-trendo-address {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div19 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .h14 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div20 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .h15 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div21 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .h16 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div22 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .h17 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .div23 {
    font-size: var(--headings-h3-semi-bold-size);
    line-height: 60px;
  }
  .h18 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .background {
    gap: 26px 105.8px;
    padding-top: var(--padding-19xl);
    padding-bottom: var(--padding-46xl);
    box-sizing: border-box;
  }
  .key-metrics {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .average-page-views-container {
    font-size: var(--body-small-regular-size);
    line-height: 22px;
  }
  .block-6,
  .block-6-inner {
    padding-top: var(--padding-108xl);
    box-sizing: border-box;
  }
  .block-6 {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-15xl);
  }
}
