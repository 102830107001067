.relevas-vision-for {
  width: 776px;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.relevas-vision-for-the-future-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
}
.at-releva-were {
  width: 527px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.at-releva-were-not-just-keep-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--body-default-semi-bold-size);
}
.icon-011 {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
}
.new-market-niche {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
}
.blank-line2,
.through-generative-personaliza {
  margin: 0;
}
.through-generative-personaliza-container {
  align-self: stretch;
  height: 150px;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
}
.text-1,
.text4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.text4 {
  align-self: stretch;
  gap: var(--gap-xs);
}
.text-1 {
  width: 423px;
  gap: var(--gap-9xl);
  max-width: 100%;
}
.icon-021 {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
}
.actions-based-on {
  width: 375px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
}
.we-use-real-time {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  font-weight: 400;
}
.banner1 {
  align-self: stretch;
  gap: var(--gap-xs);
}
.banner1,
.text-2,
.text5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.text5 {
  align-self: stretch;
  padding: var(--padding-9xs);
  box-sizing: border-box;
}
.text-2 {
  width: 490px;
  gap: var(--gap-9xl);
}
.icon-03 {
  width: 64px;
  height: 64px;
  position: relative;
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-shrink: 0;
}
.exceptional-experience-more {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 34px;
  font-weight: 600;
}
.releva-leverages-10x {
  width: 427px;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 25px;
  display: inline-block;
  max-width: 100%;
}
.text-3,
.text6 {
  flex-direction: column;
  justify-content: flex-start;
}
.text6 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-xs);
  max-width: 100%;
}
.text-3 {
  width: 456px;
  gap: var(--gap-9xl);
}
.elements1,
.frame-parent141,
.text-3 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.elements1 {
  flex-wrap: wrap;
  justify-content: center;

  align-self: stretch;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--headings-h5-semi-bold-size);
}
.frame-parent141 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 0 var(--padding-xl) var(--padding-63xl) 0; */
  box-sizing: border-box;
  gap: var(--gap-23xl);
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
  margin-bottom: 100px;
}
@media screen and (max-width: 1725px) {
  .elements1 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 900px) {
  .relevas-vision-for {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .frame-parent141 {
    gap: 21px 42px;
    padding-bottom: var(--padding-34xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .relevas-vision-for {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .actions-based-on,
  .exceptional-experience-more,
  .new-market-niche {
    font-size: var(--font-size-lgi);
    line-height: 27px;
  }
}
