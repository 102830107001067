.b1-icon {
  width: 100%;
  height: 734px;
  position: absolute;
  margin: 0 !important;
  top: 616px;
  right: -2px;
  object-fit: cover;
}
.HomePageHeroImage {
  width: 100%;
}
.one-platform-for {
  margin: 0;
  width: 690px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.one-platform-for-ecommerce-suc-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-8xl-5);
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
.logo-icon {
  height: 36px;
  width: 141px;
  object-fit: cover;
}
.navbar-brand {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.about {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.navbar-link {
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
}
.features {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.navbar-link1 {
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
}
.pricing {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.navbar-link2 {
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
}
.get-started {
  align-self: stretch;
  position: relative;
  line-height: 140%;
  font-weight: 500;
}
.navbar-button,
.navbar-content,
.navbar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar-button {
  height: 33px;
  background-color: var(--color-black);
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  white-space: nowrap;
  font-size: var(--font-size-xs);
  color: var(--w-background);
}
.navbar-content,
.navbar-menu {
  max-width: 100%;
}
.navbar-menu {
  overflow: hidden;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.navbar-content {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.navbar-logo-left,
.navbarcontainer {
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}
.navbarcontainer {
  width: 1200px;
  display: flex;
  align-items: center;
}
.navbar-logo-left {
  width: 1440px;
  background-color: var(--w-background);
  overflow: hidden;
  display: none;
  align-items: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  z-index: 9;
}
.logo-icon1 {
  height: 36px;
  width: 141px;
  object-fit: cover;
}
.navbar-brand1 {
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.about1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.navbar-link3 {
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
}
.features1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.navbar-link4 {
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
}
.pricing1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.navbar-link5 {
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-xs);
  box-sizing: border-box;
}
.get-started1 {
  align-self: stretch;
  position: relative;
  line-height: 140%;
  font-weight: 500;
}
.navbar-button1,
.navbar-content1,
.navbar-menu1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar-button1 {
  height: 33px;
  background-color: var(--color-black);
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  white-space: nowrap;
  font-size: var(--font-size-xs);
  color: var(--w-background);
}
.navbar-content1,
.navbar-menu1 {
  max-width: 100%;
}
.navbar-menu1 {
  overflow: hidden;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.navbar-content1 {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.navbar-logo-left1,
.navbarcontainer1 {
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}
.navbarcontainer1 {
  width: 1200px;
  display: flex;
  align-items: center;
}
.navbar-logo-left1 {
  width: 1440px;
  background-color: var(--w-background);
  overflow: hidden;
  display: none;
  align-items: flex-start;
  padding: 0 var(--padding-5xl);
  box-sizing: border-box;
  z-index: 10;
}
.releva-homepage,
.releva-homepage-inner {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.releva-homepage,
.releva-homepage-inner {
  align-items: flex-start;
}
.releva-homepage-inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 var(--padding-12xs);
  max-width: 100%;
}
.releva-homepage {
  width: 100%;
  position: relative;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  padding: 0;
  gap: 80px;
  letter-spacing: normal;
  text-align: left;
  font-size: var(--body-footnote-regular-size);
  color: var(--color-black);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1575px) {
}
@media screen and (max-width: 1275px) {
  .releva-homepage {
    gap: 64px 129px;
  }
}
@media screen and (max-width: 825px) {
  .one-platform-for {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .navbar-content,
  .navbar-content1 {
    flex-wrap: wrap;
  }
  .releva-homepage {
    gap: 32px 129px;
  }
}
@media screen and (max-width: 450px) {
  .one-platform-for {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .navbar-menu,
  .navbar-menu1 {
    flex-wrap: wrap;
  }
  .footer-parent {
    gap: 514px 2056.2px;
  }
  .releva-homepage {
    gap: 16px 129px;
  }
  .releva-homepage .personalized-product-recommend {
    max-width: 340px;
    white-space: break-spaces;
  }
}
.releva-homepage .how-it-works1 {
  margin-bottom: 0;
}
.releva-homepage .how-it-works1.achievements {
  margin-bottom: 150px;
}
