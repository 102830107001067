.frame-parent23 img,
.frame-parent18 img,
.frame-parent14 img {
  width: 100%;
}
.your-ai-fueled-growth {
  margin: 0;
  width: 528px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 63px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
}
.your-ai-fueled-growth-engine-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.div4,
.ignite-ecommerce-growth {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.div4 {
  line-height: 38px;
  display: inline-block;
  min-width: 36px;
}
.ignite-ecommerce-growth {
  margin: 0;
  align-self: stretch;
  font-size: var(--headings-h3-semi-bold-size);
  line-height: 51px;
  font-family: inherit;
}
.ai-powered-personalization-opt {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.frame-wrapper11,
.parent2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent2 {
  align-self: stretch;
  gap: var(--gap-6xl);
}
.frame-wrapper11 {
  width: 50%;
  padding: var(--padding-100xl) 0 0;
  box-sizing: border-box;
  /* min-width: 521px;
  max-width: 100%; */
}
.vector-icon {
  height: 474px;
  width: 511px;
  position: absolute;
  margin: 0 !important;
  right: -240px;
  bottom: -262px;
  object-fit: contain;
}
.vuesaxlineardirect-up-icon {
  height: 60px;
  width: 60px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl);
}
.x {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 120px;
}
.x-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
}
.return-on-investment1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
}
.frame-parent15,
.vector-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent15 {
  flex: 1;
  border-radius: 16.93px;
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-117xl) var(--padding-18xl) var(--padding-143xl) var(--padding-19xl);
  z-index: 1;
}
.vector-parent {
  width: 215px;
  flex-direction: row;
  position: relative;
  min-width: 215px;
}
.vuesaxlineardirect-up-icon1 {
  height: 45.5px;
  width: 45.5px;
  position: relative;
}
.vuesaxlinearshopping-cart-icon {
  width: 60px;
  height: 60px;
  position: relative;
}
.vuesaxlinearshopping-cart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-52xl-7) 0 0;
}
.vuesaxlineardirect-up-parent {
  margin-left: -117.39999999999964px;
  height: 131.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs-1);
  flex-shrink: 0;
}
.boost {
  margin: 0;
  width: 73px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.04em;
  line-height: 20px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  min-width: 73px;
}
.boost-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-15xl) 0 var(--padding-14xl);
}
.e-commerce-growth {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
}
.dropdown-item,
.frame-parent16 {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.frame-parent16 {
  width: 140px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-6xs);
}
.dropdown-item {
  align-self: stretch;
  height: 108px;
  border-radius: 16.93px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-direction: row;
  align-items: flex-end;
  padding: var(--padding-7xl) var(--padding-smi) var(--padding-2xl) var(--padding-81xl);
  box-sizing: border-box;
  gap: var(--gap-xs-8);
}
.image-84-icon1 {
  width: 273.8px;
  height: 333.7px;
  position: relative;
  object-fit: contain;
}
.dropdown-item-parent,
.main-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.main-content-container {
  align-self: stretch;
  height: 361px;
  border-radius: 17.11px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 0 0 0 15.9px;
  box-sizing: border-box;
  z-index: 1;
}
.dropdown-item-parent {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-base);
  min-width: 165px;
  text-align: center;
  font-size: var(--font-size-9xl);
}
.releva-homepage .frame-parent13 {
  width: 100%;
}
.frame-parent13,
.frame-parent14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent14 {
  width: 482px;
  gap: var(--gap-smi);
  min-width: 482px;
  font-size: var(--font-size-48xl-7);
}
.frame-parent13 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  padding: var(--padding-53xl) var(--padding-115xl) var(--padding-87xl);
  box-sizing: border-box;
  gap: var(--gap-240xl);
}
.div5,
.effortless-marketing-tasks {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.div5 {
  line-height: 38px;
  display: inline-block;
  min-width: 41px;
}
.effortless-marketing-tasks {
  margin: 0;
  align-self: stretch;
  font-size: var(--headings-h3-semi-bold-size);
  line-height: 51px;
  font-family: inherit;
}
.releva-simplifies-complex {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.frame-wrapper12,
.parent3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent3 {
  align-self: stretch;
  gap: var(--gap-6xl);
}
.frame-wrapper12 {
  /* width: 521px;
  min-width: 521px; */
  min-height: 333px;
  /* max-width: 100%; */
}
.frame-child2,
.frame-child3,
.frame-child4,
.frame-child5,
.rectangle-div {
  width: 113px;
  height: 10px;
  position: relative;
  border-radius: var(--br-5xl);
  background-color: var(--color-lightslategray);
}
.frame-child2,
.frame-child3,
.frame-child4,
.frame-child5 {
  width: 141px;
}
.frame-child4,
.frame-child5 {
  width: 108px;
}
.frame-child5 {
  width: 125px;
}
.rectangle-parent {
  align-self: stretch;
  height: 161px;
  border-radius: var(--br-xs);
  background-color: var(--brand-indigo);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-8xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.vector-icon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.parent-container-icon {
  position: absolute;
  top: 43px;
  left: 105px;
  width: 223px;
  height: 222px;
  z-index: 1;
}
.frame-wrapper13,
.vector-group {
  margin: 0 !important;
  position: absolute;
}
.vector-group {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.frame-wrapper13 {
  height: 328px;
  width: 379px;
  right: -101.7px;
  bottom: -90px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-child6 {
  height: 259.8px;
  flex: 1;
  position: relative;
  border-radius: 13.59px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  flex-shrink: 0;

  z-index: 2;
}
.frame-parent19,
.frame-parent20 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent20 {
  align-self: stretch;
  flex-direction: row;
  position: relative;
}
.frame-parent19 {
  width: 204.3px;
  flex-direction: column;
  gap: var(--gap-sm-2);
  min-width: 204.29999999999927px;
}
.frame-child7 {
  height: 58px;
  width: 300.4px;
  display: none;
}
.frame-child8 {
  height: 229px;
  width: 229px;
  display: none;
}
.frame-child9 {
  width: 296.2px;
  height: 357.1px;
  position: relative;
  border-radius: 9.13px;
  background-color: var(--web-secondary-ivory-light);
  display: none;
}
.image-13-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  z-index: 2;
}
.frame-child10,
.frame-child11 {
  height: 15.3px;
  position: relative;
  border-radius: 40.87px;
  background-color: var(--brand-ivory);
  flex-shrink: 0;

  z-index: 2;
}
.frame-child10 {
  align-self: stretch;
}
.frame-child11 {
  width: 147.3px;
}
.rectangle-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs-8);
}
.frame-parent21,
.rectangle-group {
  flex: 1;
  justify-content: flex-start;
  box-sizing: border-box;
}
.rectangle-group {
  height: 357.1px;
  border-radius: 9.13px;
  background-color: var(--web-secondary-ivory-light);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-63xl-3) var(--padding-18xl-5) var(--padding-14xl-4) var(--padding-11xl-5);
  gap: var(--gap-65xl);
  z-index: 1;
}
.frame-parent21 {
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-10xl-7) var(--padding-8xl-2) var(--padding-11xl) var(--padding-8xl-6);
  min-width: 228px;
  min-height: 434px;
  z-index: 2;
}
.frame-parent17,
.frame-parent18,
.frame-parent21 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.frame-parent18 {
  width: 571.4px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16.1px;
  min-width: 571.3999999999996px;
}
.frame-parent17 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--web-secondary-ivory-light);
  overflow: hidden;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-71xl) var(--padding-121xl) var(--padding-120xl) var(--padding-115xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.div6,
.trust-your-decisions {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.div6 {
  line-height: 38px;
  display: inline-block;
  min-width: 42px;
}
.trust-your-decisions {
  margin: 0;
  align-self: stretch;
  font-size: var(--headings-h3-semi-bold-size);
  line-height: 51px;
  font-family: inherit;
}
.precision-in-advertising {
  align-self: stretch;
  position: relative;
  font-size: var(--body-default-semi-bold-size);
  letter-spacing: -0.01em;
  line-height: 28px;
}
.frame-wrapper14,
.parent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.parent4 {
  align-self: stretch;
  gap: var(--gap-6xl);
}
.frame-wrapper14 {
  width: 521px;
  min-height: 397px;
  max-width: 100%;
  z-index: 1;
}
.frame-child12,
.group-icon {
  position: absolute;
  top: 135px;
  left: 25.8px;
  width: 385.4px;
  height: 434.5px;
}
.frame-child12 {
  top: 0;
  left: 0;
  border-radius: 13.59px;
  width: 204.3px;
  height: 259.8px;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.frame-parent26 {
  height: 569.5px;
  width: 411.2px;
  position: absolute;
  margin: 0 !important;
  bottom: -309.7px;
  left: -216px;
}
.vuesaxlinearbag-tick-icon {
  height: 79px;
  width: 79px;
  position: relative;
}
.vuesaxlinearbag-tick-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-14xl) 0 var(--padding-13xl);
}
.predict,
.users-next-purchases {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.predict {
  margin: 0;
  font-size: inherit;
  line-height: 22px;
  font-family: inherit;
}
.users-next-purchases {
  font-size: var(--body-small-regular-size);
  line-height: 19px;
}
.predict-parent {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.frame-parent25,
.frame-parent27,
.predict-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.frame-parent27 {
  flex: 1;
  border-radius: 13.59px;
  background-color: var(--color-plum);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--padding-16xl) var(--padding-11xl) var(--padding-15xl-8);
  gap: var(--gap-10xl);
  flex-shrink: 0;

  z-index: 1;
}
.frame-parent25 {
  height: 259.8px;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}
.frame-child13,
.frame-parent24 {
  flex-shrink: 0;
}
.frame-child13 {
  align-self: stretch;
  height: 259.8px;
  position: relative;
  border-radius: 13.59px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 1;
}
.frame-parent24 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi-2);
  min-width: 133px;
}
.vuesaxlinearpeople-icon {
  height: 79px;
  width: 79px;
  position: relative;
}
.vuesaxlinearpeople-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-14xl) 0 var(--padding-13xl-5);
}
.anonymous-users,
.engage {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 600;
}
.engage {
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  line-height: 22px;
  font-family: inherit;
}
.anonymous-users {
  flex: 1;
  line-height: 19px;
}
.anonymous-users-wrapper,
.frame-parent28 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.anonymous-users-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-smi) 0 var(--padding-xs);
  font-size: var(--body-small-regular-size);
}
.frame-parent28 {
  flex: 1;
  border-radius: 13.59px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-18xl-8) var(--padding-10xl-7) var(--padding-30xl);
  box-sizing: border-box;
  gap: var(--gap-mid);
  min-width: 133px;
  flex-shrink: 0;
}
.frame-parent22,
.frame-parent23 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 100%;
}
.frame-parent23 {
  width: 420.6px;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--font-size-9xl);
  color: var(--color-black);
}
.frame-parent22 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  justify-content: space-between;
  padding: var(--padding-37xl) var(--padding-71xl-4) var(--padding-56xl-2) var(--padding-115xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.benefits-section,
.benefits-section-wrapper,
.frame-parent12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent12 {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--headings-h4-semi-bold-size);
  color: var(--brand-indigo);
}
.benefits-section,
.benefits-section-wrapper {
  box-sizing: border-box;
}
.benefits-section {
  flex: 1;
  background-color: var(--brand-indigo);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-82xl) var(--padding-45xl) var(--padding-82xl);
  gap: var(--gap-60xl);
}
.benefits-section-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 var(--padding-3xs);
  text-align: center;
  font-size: var(--headings-h2-semi-bold-size);
  color: var(--brand-ivory);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1575px) {
  .frame-parent14,
  .frame-wrapper11 {
    flex: 1;
  }
  .frame-parent13 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .frame-wrapper12 {
    flex: 1;
    min-height: auto;
  }
  .frame-parent18 {
    flex: 1;
  }
  .frame-parent17 {
    flex-wrap: wrap;
  }
  .frame-wrapper14 {
    min-height: auto;
  }
  .frame-parent22 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1275px) {
  .frame-parent14,
  .frame-wrapper11 {
    min-width: 100%;
  }
  .frame-parent13 {
    gap: 20px 32px;
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-48xl);
    box-sizing: border-box;
  }
  .frame-parent18,
  .frame-wrapper12 {
    min-width: 100%;
  }
  .frame-parent17,
  .frame-parent22 {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-51xl);
    box-sizing: border-box;
  }
  .frame-parent22 {
    padding-right: var(--padding-26xl);
  }
  .benefits-section {
    gap: 39px 79px;
    padding: var(--padding-24xl) var(--padding-13xl) var(--padding-103xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 825px) {
  .your-ai-fueled-growth {
    font-size: var(--font-size-27xl);
    line-height: 50px;
  }
  .div4 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .ignite-ecommerce-growth {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .x {
    font-size: var(--font-size-35xl);
    line-height: 74px;
  }
  .frame-parent15 {
    padding-top: var(--padding-69xl);
    padding-bottom: var(--padding-86xl);
    box-sizing: border-box;
  }
  .vector-parent {
    flex: 1;
  }
  .frame-parent14 {
    flex-wrap: wrap;
  }
  .frame-parent13 {
    gap: 20px 32px;
    padding: var(--padding-28xl) var(--padding-14xl) var(--padding-50xl);
    box-sizing: border-box;
  }
  .div5 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .effortless-marketing-tasks {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-parent19 {
    flex: 1;
  }
  .frame-parent21 {
    min-height: auto;
  }
  .frame-parent18 {
    flex-wrap: wrap;
  }
  .frame-parent17 {
    padding: var(--padding-39xl) var(--padding-16xl) var(--padding-71xl) var(--padding-14xl);
    box-sizing: border-box;
  }
  .div6 {
    font-size: var(--font-size-7xl);
    line-height: 30px;
  }
  .trust-your-decisions {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .frame-parent22 {
    padding: var(--padding-17xl) var(--padding-3xl) var(--padding-30xl) var(--padding-14xl);
    box-sizing: border-box;
  }
  .benefits-section {
    padding-top: var(--padding-9xl);
    padding-bottom: var(--padding-60xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .frame-wrapper11 {
    padding-top: 0 !important;
  }
  .your-ai-fueled-growth {
    font-size: var(--font-size-15xl);
    line-height: 38px;
  }
  .div4 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .ignite-ecommerce-growth {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .x {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .boost {
    font-size: var(--font-size-3xl);
    line-height: 16px;
  }
  .dropdown-item {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
  .frame-parent13 {
    gap: 20px 32px;
  }
  .div5 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .effortless-marketing-tasks {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .rectangle-group {
    gap: 42px 84px;
    padding-top: var(--padding-34xl);
    padding-bottom: var(--padding-3xl);
    box-sizing: border-box;
  }
  .frame-parent21 {
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-xl);
    box-sizing: border-box;
  }
  .div6 {
    font-size: var(--font-size-lgi);
    line-height: 23px;
  }
  .trust-your-decisions {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .engage,
  .predict {
    font-size: var(--font-size-3xl);
    line-height: 18px;
  }
  .frame-parent28 {
    flex: 1;
  }
  .frame-parent23 {
    flex-wrap: wrap;
  }
  .benefits-section {
    gap: 20px 79px;
  }
}
