.terms-of-use14 {
  width: 111px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 111px;
}
.terms-of-use-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
}
.cookie-policy5 {
  width: 113px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 113px;
}
.menu-items3 div:hover {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
}
.active {
  border-radius: var(--br-5xs);
  background-color: var(--brand-ivory);
  padding: var(--padding-5xs) var(--padding-base) !important;
}
.cookie-policy-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  padding: var(--padding-5xs) var(--padding-base);
}
.privacy-policy17 {
  width: auto;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 116px;
}
.privacy-policy16 {
  width: 116px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  display: inline-block;
  min-width: 116px;
}
.menu-items3 div div a {
  text-decoration: none;
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular) !important;
}
.privacy-policy-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
}
.input,
.menu-items-child2 {
  height: 44px;
  width: 0;
}
.menu-items3,
.sub-menu3 {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}
.menu-items3 {
  width: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  gap: var(--gap-41xl);
}
.sub-menu3 {
  align-self: stretch;
  background-color: var(--web-secondary-ivory-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-xl);
  text-align: center;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-small-regular);
}
@media screen and (max-width: 850px) {
  .menu-items3 {
    flex-wrap: wrap;
    gap: 60px 30px;
    justify-content: center;
  }
}
