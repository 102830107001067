.artwork-icon4 {
  height: 509.4px;
  width: 454.4px;
  position: absolute;
  margin: 0 !important;
  right: -49.4px;
  bottom: -34.1px;
}
.frame-parent128 .image-118-icon {
  z-index: 10;
}
.our-mission {
  align-self: stretch;
  position: relative;
  font-size: var(--headings-h3-semi-bold-size);
  letter-spacing: -0.03em;
  line-height: 51px;
  font-weight: 600;
}
.driving-growth-simplifying,
.it-more-intuitive {
  margin: 0;
}
.button-name40,
.driving-growth-simplifying-container {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
}
.driving-growth-simplifying-container {
  align-self: stretch;
}
.button-name40 {
  width: 110px;
  display: inline-block;
  min-width: 110px;
  color: #fff;
  text-decoration: none;
}
.block-4-child,
.button44,
.our-mission-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.button44 {
  border-radius: var(--br-5xs);
  background-color: var(--brand-amethyst);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  white-space: nowrap;
  text-align: center;
  color: var(--web-secondary-ivory-light);
  text-decoration: none;
}
.block-4-child,
.our-mission-parent {
  flex-direction: column;
}
.our-mission-parent {
  align-self: stretch;
  gap: var(--gap-5xl);
}
.block-4-child {
  width: 518px;
  min-width: 518px;
  min-height: 410px;
  max-width: 100%;
}
.vuesaxlinearsend-2-icon2 {
  height: 40px;
  width: 40px;
  position: relative;
  z-index: 1;
  top: -10px;
}
.vuesaxlinearsend-2-container {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-23xl) 0 var(--padding-22xl);
  box-sizing: border-box;
}
.vuesaxlineardirect-up-icon24 {
  width: 42.4px;
  height: 42.4px;
  position: absolute;
  margin: 0 !important;
  top: 15.6px;
  right: 45.2px;
}
.ecommerce1,
.growth {
  margin: 0;
}
.ecommerce-growth {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.ecommerce-growth-element,
.ecommerce-growth-element-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ecommerce-growth-element {
  align-self: stretch;
  border-radius: 11.97px;
  background-color: var(--web-secondary-ivory-mid);
  overflow: hidden;
  padding: var(--padding-9xl) var(--padding-8xs-6) var(--padding-9xl) var(--padding-7xs);
  position: relative;
  gap: var(--gap-5xs);
  flex-shrink: 0;
}
.ecommerce-growth-element-wrapper {
  width: 133.6px;
  padding: var(--padding-168xl) 0 0;
  box-sizing: border-box;
  min-width: 133.5999999999999px;
}
.body-icon2,
.rectangle1 {
  position: absolute;
  margin: 0 !important;
}
.body-icon2 {
  width: 286.3px;
  height: 100%;
  top: 0;
  right: 2.9px;
  bottom: 0;
  max-height: 100%;
  object-fit: cover;
}
.rectangle1 {
  width: 74px;
  height: 19.6px;
  top: 26.7px;
  right: 108.7px;
  border-radius: 9.29px;
  background-color: var(--brand-indigo);
  z-index: 1;
}
.buttons-icon2 {
  height: 130.3px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  z-index: 1;
}
.vuesaxlineardirect-up-icon25 {
  height: 60px;
  width: 60px;
  position: absolute;
  margin: 0 !important;
  top: -9.8px;
  right: 114.7px;
  z-index: 2;
}
.buttons-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 290.7px;
  height: 130.3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.x3 {
  position: absolute;
  top: 50.2px;
  left: 86px;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 120px;
  z-index: 2;
}
.frame-parent130 {
  align-self: stretch;
  height: 143.2px;
  position: relative;
}
.return-on-investment3 {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  z-index: 1;
}
.return-on-investment-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-55xl-7) 0 var(--padding-57xl);
  text-align: center;
  font-size: var(--body-small-regular-size);
}
.frame-parent129 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.vuesaxlineardirect-up-icon26 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper5 {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-31xl) 0 var(--padding-32xl);
  box-sizing: border-box;
}
.better,
.engagement1 {
  margin: 0;
}
.better-engagement {
  flex: 1;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlinearsms-tracking-icon4 {
  height: 40px;
  width: 40px;
  position: absolute;
  margin: 0 !important;
  top: -59px;
  left: 51px;
  z-index: 1;
}
.better-engagement-parent {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.frame-parent132,
.vuesaxlinearactivity-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.frame-parent132 {
  border-radius: 11.97px;
  background-color: var(--brand-lemon);
  overflow: hidden;
  flex-direction: column;
  padding: 15.6px var(--padding-12xs) var(--padding-9xl);
  gap: var(--gap-lg);
  min-width: 145px;
  z-index: 1;
}
.vuesaxlinearactivity-container {
  height: 55px;
  flex-direction: row;
  padding: 0 var(--padding-6xl) 0 var(--padding-6xl-5);
}
.x4 {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 93px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
  flex-shrink: 0;
  z-index: 1;
}
.x-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) var(--padding-5xs);
  margin-top: -8px;
}
.purchase-rates1 {
  align-self: stretch;
  position: relative;
  font-size: var(--body-small-regular-size);
  letter-spacing: -0.01em;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  flex-shrink: 0;
  z-index: 1;
  margin-top: -8px;
}
.frame-parent133,
.frame-wrapper54 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frame-parent133 {
  align-self: stretch;
  height: 166px;
  justify-content: flex-start;
}
.frame-wrapper54 {
  width: 106px;
  justify-content: flex-end;
  padding: 0 0 var(--padding-14xl);
  box-sizing: border-box;
  min-width: 106px;
  text-align: left;
  font-size: var(--font-size-48xl-7);
}
.frame-parent131,
.iphone-14-pro2 {
  display: flex;
  justify-content: flex-start;
}
.frame-parent131 {
  margin-left: -62px;
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--font-size-3xl);
}
.iphone-14-pro2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-87xl-8) var(--padding-11xs) var(--padding-33xl-1) 0;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-36xl);
  min-width: 190px;
  flex-shrink: 0;
  z-index: 1;
}
.vuesaxlineardirect-up-icon27 {
  height: 42.4px;
  width: 42.4px;
  position: relative;
}
.vuesaxlineardirect-up-wrapper6 {
  height: 42.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-21xl) 0 var(--padding-20xl);
  box-sizing: border-box;
}
.results2,
.sustainable {
  margin: 0;
}
.sustainable-results1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.04em;
  line-height: 24px;
  font-weight: 600;
}
.vuesaxlinearsearch-status-icon1 {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  top: 16px;
  right: 45.6px;
  z-index: 1;
}
.sustainable-results,
.sustainable-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sustainable-results {
  align-self: stretch;
  border-radius: 11.97px;
  background-color: var(--brand-amethyst);
  overflow: hidden;
  padding: 15.6px var(--padding-7xs-6) var(--padding-9xl) var(--padding-6xs);
  position: relative;
  gap: var(--gap-lg);
  flex-shrink: 0;
  z-index: 3;
}
.sustainable-results-wrapper {
  width: 133.6px;
  padding: var(--padding-22xl) 0 0;
  box-sizing: border-box;
  min-width: 133.5999999999999px;
  margin-left: -38.1px;
  text-align: center;
  font-size: var(--font-size-3xl);
  color: var(--brand-ivory);
}
.iphone-14-pro-parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  min-width: 252px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-48xl-7);
}
.block-41,
.frame-parent128 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.frame-parent128 {
  width: 546.6px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl-4);
  min-width: 546.5999999999999px;
  text-align: center;
  font-size: var(--font-size-3xl);
}
.block-41 {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--brand-ivory);
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-78xl) var(--padding-107xl-4) var(--padding-68xl-9) var(--padding-116xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--body-default-semi-bold-size);
  color: var(--brand-indigo);
  font-family: var(--body-footnote-regular);
}
@media screen and (max-width: 1725px) {
  .block-4-child {
    flex: 1;
    min-height: auto;
  }
  .frame-parent128 {
    flex: 1;
  }
  .block-41 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 1325px) {
  .block-4-child,
  .frame-parent128 {
    min-width: 100%;
  }
  .block-41 {
    padding-left: var(--padding-48xl);
    padding-right: var(--padding-44xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) {
  .our-mission {
    font-size: var(--font-size-15xl);
    line-height: 41px;
  }
  .ecommerce-growth-element-wrapper {
    flex: 1;
  }
  .x3,
  .x4 {
    font-size: var(--font-size-35xl);
    line-height: 74px;
  }
  .iphone-14-pro2 {
    padding-top: var(--padding-50xl);
    padding-bottom: var(--padding-15xl);
    box-sizing: border-box;
  }
  .frame-parent128 {
    flex-wrap: wrap;
  }
  .block-41 {
    padding: var(--padding-44xl) var(--padding-12xl) var(--padding-38xl) var(--padding-14xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .our-mission {
    font-size: var(--font-size-7xl);
    line-height: 31px;
  }
  .ecommerce-growth {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .x3 {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .return-on-investment-wrapper {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .better-engagement {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .frame-parent132 {
    flex: 1;
  }
  .x4 {
    font-size: var(--font-size-22xl);
    line-height: 55px;
  }
  .frame-wrapper54 {
    flex: 1;
  }
  .frame-parent131 {
    flex-wrap: wrap;
  }
  .iphone-14-pro2 {
    gap: 27px 55px;
  }
  .sustainable-results1 {
    font-size: var(--body-default-semi-bold-size);
    line-height: 19px;
  }
  .sustainable-results-wrapper {
    flex: 1;
    margin-left: 0;
  }
  .iphone-14-pro-parent {
    flex-wrap: wrap;
  }
  .block-41 {
    padding-top: var(--padding-22xl);
    padding-bottom: var(--padding-18xl);
    box-sizing: border-box;
  }
}
